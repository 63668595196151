/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThreadLastMessageBy,
    ThreadLastMessageByFromJSON,
    ThreadLastMessageByFromJSONTyped,
    ThreadLastMessageByToJSON,
} from './ThreadLastMessageBy';
import {
    ThreadTagRead,
    ThreadTagReadFromJSON,
    ThreadTagReadFromJSONTyped,
    ThreadTagReadToJSON,
} from './ThreadTagRead';
import {
    ToFromEnum,
    ToFromEnumFromJSON,
    ToFromEnumFromJSONTyped,
    ToFromEnumToJSON,
} from './ToFromEnum';

/**
 * 
 * @export
 * @interface Thread
 */
export interface Thread {
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    readonly id: number;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isConversation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly needsReply: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isUnseen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isClosed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isArchived: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isSystemThread: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isOnHold: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isSold: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isAuction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isPurchased: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isSoldToOtherParty: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Thread
     */
    readonly isMarkedOnHoldToParty: boolean;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    readonly inquiryId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly lastMessageSeenAt: string | null;
    /**
     * 
     * @type {ThreadLastMessageBy}
     * @memberof Thread
     */
    lastMessageBy: ThreadLastMessageBy | null;
    /**
     * 
     * @type {ToFromEnum}
     * @memberof Thread
     */
    readonly toFrom: ToFromEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly otherPartyNameUsername: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly otherPartyPath: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly otherPartyUsername: string;
    /**
     * Returns party level, which is profile.current_buyer_level for a buyer and store.seller_rating_score for
     * a store. BUT, as it may be confusing for users, we don't want to show level for the store side, so for now
     * it returns None for stores. FE should not render level/rating if the value is null.
     * @type {number}
     * @memberof Thread
     */
    readonly otherPartyLevel: number | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly lastMessageTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly lastMessageTimeDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly threadSubject: string;
    /**
     * This will be None for general conversations, but for the inquiries it will point to the inquired animals' public url.
     * @type {string}
     * @memberof Thread
     */
    readonly threadSubjectPath: string | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly lastMessageText: string | null;
    /**
     * 
     * @type {Array<ThreadTagRead>}
     * @memberof Thread
     */
    tags: Array<ThreadTagRead>;
}

export function ThreadFromJSON(json: any): Thread {
    return ThreadFromJSONTyped(json, false);
}

export function ThreadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Thread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isConversation': json['is_conversation'],
        'needsReply': json['needs_reply'],
        'isUnseen': json['is_unseen'],
        'isClosed': json['is_closed'],
        'isArchived': json['is_archived'],
        'isBlocked': json['is_blocked'],
        'isSystemThread': json['is_system_thread'],
        'isOnHold': json['is_on_hold'],
        'isSold': json['is_sold'],
        'isAuction': json['is_auction'],
        'isPurchased': json['is_purchased'],
        'isSoldToOtherParty': json['is_sold_to_other_party'],
        'isMarkedOnHoldToParty': json['is_marked_on_hold_to_party'],
        'inquiryId': json['inquiry_id'],
        'lastMessageSeenAt': json['last_message_seen_at'],
        'lastMessageBy': ThreadLastMessageByFromJSON(json['last_message_by']),
        'toFrom': ToFromEnumFromJSON(json['to_from']),
        'otherPartyNameUsername': json['other_party_name_username'],
        'otherPartyPath': json['other_party_path'],
        'otherPartyUsername': json['other_party_username'],
        'otherPartyLevel': json['other_party_level'],
        'lastMessageTime': json['last_message_time'],
        'lastMessageTimeDisplay': json['last_message_time_display'],
        'threadSubject': json['thread_subject'],
        'threadSubjectPath': json['thread_subject_path'],
        'lastMessageText': json['last_message_text'],
        'tags': ((json['tags'] as Array<any>).map(ThreadTagReadFromJSON)),
    };
}

export function ThreadToJSON(value?: Thread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_message_by': ThreadLastMessageByToJSON(value.lastMessageBy),
        'tags': ((value.tags as Array<any>).map(ThreadTagReadToJSON)),
    };
}

