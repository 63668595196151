import {
  IMAGE_ORDERING,
  CATEGORY_ORDERING,
  DAM_ORDERING,
  SIRE_ORDERING,
  TRAITS_ORDERING,
} from 'api/common/ordering';
import { ASCENDING, DESCENDING } from 'api/ordering';

// Modes
export const CHILDREN_ORDERING = Symbol('CHILDREN_ORDERING');
export const BIRTH_DATE_ORDERING = Symbol('BIRTH_DATE_ORDERING');
export const DAM_ID_ORDERING = Symbol('DAM_ID_ORDERING');
export const EGGS_ORDERING = Symbol('EGGS_ORDERING');
export const GROUP_ID_ORDERING = Symbol('GROUP_ID_ORDERING');
export const LAY_DATE_ORDERING = Symbol('LAY_DATE_ORDERING');
export const MODIFIED_ORDERING = Symbol('MODIFIED_ORDERING');
export const NON_VIABLE_EGGS_ORDERING = Symbol('NON_VIABLE_EGGS_ORDERING');
export const SIRE_ID_ORDERING = Symbol('SIRE_ID_ORDERING');
export const VISIBILITY_ORDERING = Symbol('VISIBILITY_ORDERING');
export const VIABLE_EGGS_ORDERING = Symbol('VIABLE_EGGS_ORDERING');
export const NOTES_ORDERING = Symbol('NOTES_ORDERING');

const params = [
  [CHILDREN_ORDERING, 'children'],
  [BIRTH_DATE_ORDERING, 'birth_date'],
  [CATEGORY_ORDERING, 'category'],
  [DAM_ORDERING, 'dams'],
  [EGGS_ORDERING, 'eggs'],
  [GROUP_ID_ORDERING, 'group_id'],
  [IMAGE_ORDERING, 'image'],
  [LAY_DATE_ORDERING, 'lay_date'],
  [MODIFIED_ORDERING, 'modified'],
  [NON_VIABLE_EGGS_ORDERING, 'non_viable_eggs'],
  [NOTES_ORDERING, 'notes'],
  [SIRE_ORDERING, 'sires'],
  [TRAITS_ORDERING, 'traits'],
  [VIABLE_EGGS_ORDERING, 'viable_eggs'],
  [VISIBILITY_ORDERING, 'visibility'],
];

const offspringOrdering = params.reduce(
  (result, [mode, param]) => [
    ...result,
    {
      mode,
      direction: ASCENDING,
      param,
    },
    {
      mode,
      direction: DESCENDING,
      param: `-${param}`,
    },
  ],
  []
);

export default offspringOrdering;
