/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    MaturityEnum,
    MaturityEnumFromJSON,
    MaturityEnumFromJSONTyped,
    MaturityEnumToJSON,
} from './MaturityEnum';
import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';

/**
 * @type AnimalMaturity
 * 
 * @export
 */
export type AnimalMaturity = MaturityEnum | NullEnum;

export function AnimalMaturityFromJSON(json: any): AnimalMaturity {
    return AnimalMaturityFromJSONTyped(json, false);
}

export function AnimalMaturityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalMaturity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return MaturityEnumFromJSONTyped(json, true);
}

export function AnimalMaturityToJSON(value?: AnimalMaturity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return value;
}

