/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `public` - Public
 * * `unlisted` - Unlisted
 * * `private` - Private
 * @export
 */
export const VisibilityEnum = {
    Public: 'public',
    Unlisted: 'unlisted',
    Private: 'private'
} as const;
export type VisibilityEnum = typeof VisibilityEnum[keyof typeof VisibilityEnum];


export function VisibilityEnumFromJSON(json: any): VisibilityEnum {
    return VisibilityEnumFromJSONTyped(json, false);
}

export function VisibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilityEnum {
    return json as VisibilityEnum;
}

export function VisibilityEnumToJSON(value?: VisibilityEnum | null): any {
    return value as any;
}

