import { createContext, useContext } from 'react';

/**
 * Accepts a custom React hook and returns a ContextProvider and a custom hook wrapper for that hook.
 * This means that a single instance of a custom hook can be passed around, while mantaining its internal state in
 * all the instances it is used in.
 * The main target are performance intensive hooks and hooks that need to maintain a single state throughout the entire application
 * or a large branch of it.
 * @param useHook
 * @returns
 */
export const generateSingletonHook = <T extends (...props: any) => ReturnType<T>>(
  useHook: T,
  props?: Parameters<T>
) => {
  const SingletonContext = createContext<ReturnType<T> | undefined>(undefined);
  const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const customHook = useHook(...(props || [{}]));
    return <SingletonContext.Provider value={customHook}>{children}</SingletonContext.Provider>;
  };

  return [Provider, () => useContext(SingletonContext)] as [React.FC<object>, () => ReturnType<T>];
};
