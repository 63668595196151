import SexIcon from 'components/Icons/SexIcon/SexIcon';
import { SEX_FILTER } from 'components/Inventory/Filters/SexFilter/definitions';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import inventoryFilters from 'api/inventory/filters';
import { SEX_ORDERING } from 'api/inventory/ordering';
import { queryValueToFilterValue } from 'api/url';

export const SEX_COLUMN = Symbol('SEX_COLUMN');

interface SexColumnRenderProps {
  sex: string;
}

const sexColumn = {
  id: SEX_COLUMN,
  ordering: SEX_ORDERING,
  label: 'Sex',
  hideable: true,
  className: style.center,

  render: ({ sex }: SexColumnRenderProps) => (
    <SexIcon
      sex={queryValueToFilterValue(inventoryFilters, SEX_FILTER, sex || 'unknown')}
      className={style.sexIcon}
    />
  ),
};

export default sexColumn;
