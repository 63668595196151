import { createContext, useContext } from 'react';

type AnimalImageUploadContextType = {
  isLoading: boolean;
  clickableRef: React.MutableRefObject<null>;
};

export const AnimalImageUploadContext = createContext<AnimalImageUploadContextType>({
  isLoading: false,
  clickableRef: { current: null },
});

export const useAnimalImageUploadContext = () => {
  const context = useContext(AnimalImageUploadContext);

  if (context === null) {
    throw new Error('useAnimalImageUploadContext must be used within AnimalImageUploadContext');
  }

  return context;
};
