import { HTMLAttributes } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import styles from './SettingsMenuItems.module.scss';

export type PrefixedButtonProps = HTMLAttributes<HTMLButtonElement> & {
  name?: string;
  icon: IconDefinition;
};

export const PrefixedButton: React.FC<PrefixedButtonProps> = ({
  children,
  name,
  icon,
  ...props
}) => {
  return (
    <button tabIndex={0} role="menuitem" className={styles.root} {...props}>
      <span className={styles.prefix}>
        <Icon icon={icon} />
      </span>
      <span className={styles.label}>{children || name}</span>
    </button>
  );
};
