import { useCallback, useEffect, useState } from 'react';
import { generateSingletonHook } from 'context/SingletonContext';
import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../src/js/app/rn-messages';
import { IS_APP } from '../constants';
import { isAddEditAnimalPath } from '../services/urls';
import { useGlobalContext } from './GlobalContext';

// This hook is used to enable or disable pull to refresh in React Native on certain pages.
// Currently we have two ways of using this hook:
//   1. We use it in useOpenState.tsx to disable pull to refresh on bottom sheets
//   2. We use it on ReactJS pages in the following way:
//       useEffect(() => {
//         handleSetIsPullToRefreshEnabledInReactNative({ isEnabled: false });
//         return () => handleSetIsPullToRefreshEnabledInReactNative({ isEnabled: true });
//       }, []);
//       a) Empty deps array ensures that this code runs on component mount only
//       b) Return function fires on component unmount and it re-enables pull to refresh in React Native
// Important note:
//   1. See isPageWhereWeDisablePullToRefresh where we set pages on which the pull to refresh is disabled.
//   2. This overrides isEnabled with the following logical statement (isEnabledOnPage && isEnabled)
//
const useDecoratedHook = () => {
  const [isPullToRefreshEnabledInRn, setIsPullToRefreshEnabledInRn] = useState<boolean>(true);
  // Required to update pull to refresh state on navigation. See MainApp.tsx.
  const { pageHasUnsavedChanges } = useGlobalContext();

  const isPageWhereWeDisablePullToRefresh = useCallback(() => {
    const { pathname, search } = window.location;
    const url = pathname + search;
    // The below criteria is used across the app to disable pull to refresh on given paths
    return pageHasUnsavedChanges || isAddEditAnimalPath(url);
  }, [pageHasUnsavedChanges]);

  const handleSetIsPullToRefreshEnabledInReactNative = useCallback(
    (props: { isEnabled: boolean }): void => {
      const { isEnabled } = props;
      if (!IS_APP) return;

      // This is critical to avoid bugs where if isEnabled is true, the refresh gets enabled,
      // but on a page where it should always be disabled.
      const isEnabledOnPage = !isPageWhereWeDisablePullToRefresh();

      setIsPullToRefreshEnabledInRn(isEnabledOnPage && isEnabled);
    },
    [isPageWhereWeDisablePullToRefresh]
  );

  const handleSendMessageToReactNative = useCallback((): void => {
    if (!IS_APP) return;

    sendMessageToReactNative(MESSAGE_TYPES.IS_PULL_TO_REFRESH_ENABLED_IN_RN, {
      isEnabled: isPullToRefreshEnabledInRn,
    });
  }, [isPullToRefreshEnabledInRn]);

  // Handle disabling of pull to refresh on critical pages within the app such as Messenger
  useEffect(() => {
    const isDisabledPage = isPageWhereWeDisablePullToRefresh();
    setIsPullToRefreshEnabledInRn(!isDisabledPage);
  }, [isPageWhereWeDisablePullToRefresh]);

  useEffect(() => {
    handleSendMessageToReactNative();
  }, [isPullToRefreshEnabledInRn, handleSendMessageToReactNative]);

  return { handleSetIsPullToRefreshEnabledInReactNative };
};

const [NativeAppPullToReloadContext, useNativeAppPullToReloadContext] =
  generateSingletonHook(useDecoratedHook);
export { NativeAppPullToReloadContext, useNativeAppPullToReloadContext };
