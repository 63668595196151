import { generatePath } from 'react-router-dom';

import {
  CATEGORY_FILTER,
  rootCategory,
} from 'components/Common/Filters/CategoryFilter/definitions';
import { INCLUDING_TRAITS_FILTER } from 'components/Common/Filters/TraitsFilter/definitions';
import { ANY_TRAIT_FORM_FILTER } from 'components/Search/Filters/GenesAndTraitsFilter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';

import {
  NEAR_TO_COORDS_FILTER,
  NEAR_TO_DISTANCE_FILTER,
} from 'components/Search/Filters/NearToFilter/definitions';
import FiltersJar from 'api/FiltersJar';
import { ORDERING_FILTER } from 'api/ordering';
import searchFilters from 'api/search/filters';
import { filtersToUrl } from 'api/url';
import { betterTypeOf } from 'services/debugging';

import { findCategory } from './helpers/categories';
import { findTrait } from './helpers/traits';

/**
 * Checks if only one trait is selected in INCLUDING_TRAITS_FILTER.
 */
export const hasOnlyOneTrait = (filters: FiltersJar) =>
  filters.getValuesByKey(INCLUDING_TRAITS_FILTER)?.length === 1;

export const hasGeolocationEntries = (filters: FiltersJar) => {
  if (filters.has(NEAR_TO_DISTANCE_FILTER) || filters.hasEntry(ORDERING_FILTER, 'distance')) {
    return true;
  }

  return false;
};

export const getTraitType = (filters: InstanceType<typeof FiltersJar>) => {
  const hasOneTrait = hasOnlyOneTrait(filters);

  if (!hasOneTrait) {
    return undefined;
  } else {
    const anyTraitFormFilter = filters.get(ANY_TRAIT_FORM_FILTER) === true;
    const isMultigene = (filters.get(INCLUDING_TRAITS_FILTER)?.match(/-/g) || []).length > 1;

    if (isMultigene) {
      return 'multigene';
    } else if (anyTraitFormFilter) {
      return 'gene';
    } else {
      return 'trait';
    }
  }
};

/**
 * Generates a canonical path based on the current state of filters.
 */
export const generateCanonicalPath = (filters: InstanceType<typeof FiltersJar>, base: string) => {
  // This is debugging code to understanding a common bug in Sentry
  if (typeof filters?.get !== 'function') {
    throw new Error(
      `InvalidFilters: Its type is ${betterTypeOf(filters)}. Data: ${JSON.stringify(filters)}`
    );
  }

  const category = findCategory(filters?.get(CATEGORY_FILTER)) || rootCategory;
  const location = filters?.get(LOCATION_FILTER) || 'all';
  const hasOneTrait = hasOnlyOneTrait(filters);
  const trait = hasOneTrait
    ? encodeURIComponent(
        findTrait(filters?.get(INCLUDING_TRAITS_FILTER), category?.id)?.value || ''
      )
    : undefined;
  const type = getTraitType(filters);

  const pieces = [
    ['category', category?.path],
    ['type', type],
    ['trait', trait],
  ].filter(([, value]) => !!value);

  const path = [base, ...pieces.map(([key]) => `:${key}`)].join('/');
  return generatePath(path, Object.fromEntries([['region', location], ...pieces]));
};

/**
 * Generates search parameters based on the state of the current filters.
 */
export const generateCanonicalParams = (filters: InstanceType<typeof FiltersJar>) => {
  // This is debugging code to understanding a common bug in Sentry
  if (typeof filters?.remove !== 'function') {
    throw new Error(`InvalidFilters: Its type is ${betterTypeOf(filters)}. Data: ${JSON.stringify(filters)}`);
  }

  const searchParamFilters = filters.remove(LOCATION_FILTER, CATEGORY_FILTER);
  const cascade: [
    boolean,
    (filters: InstanceType<typeof FiltersJar>) => InstanceType<typeof FiltersJar>
  ][] = [
    [
      hasOnlyOneTrait(filters),
      (filters) => filters.remove(INCLUDING_TRAITS_FILTER).remove(ANY_TRAIT_FORM_FILTER),
    ],
    [!hasGeolocationEntries(filters), (filters) => filters.remove(NEAR_TO_COORDS_FILTER) as any],
  ];

  return filtersToUrl(
    searchFilters,
    cascade.reduce((acc, [isTrue, func]) => {
      return isTrue ? func(acc) : acc;
    }, searchParamFilters as InstanceType<typeof FiltersJar>)
  );
};

export type CanonicalParameters = {
  location: string;
  category: string;
  traits__in?: string;
  any_trait_form?: boolean;
  page?: string;
  ordering?: string;
  page_size?: string;
};

export type SearchEntry = [string, string];

/**
 * Merges parameters in the canonical URL with searchParams.
 */
export const mergeSearchParams = (
  searchParams: URLSearchParams,
  canonical: Record<string, string | undefined>
) => {
  let path, type, trait;
  if ('*' in canonical) {
    const data = parseCanonicalFragment(canonical['*'] as string);
    (path = data.path), (type = data.type);
    trait = data.trait;
  } else {
    type = undefined;
    trait = undefined;
    path = window.location.pathname.split('/').slice(3).join('/');
  }
  const { region } = canonical;
  const isGene = type === 'gene';
  const cat = findCategory(path, 'path');
  const traits__in = findTrait(trait?.toUpperCase() as string, cat?.id)?.id;
  const properties = [];
  cat?.value && properties.push(['category', cat?.value]);
  !!traits__in && properties.push(['traits__in', traits__in]);
  isGene && properties.push(['any_trait_form', true]);
  region && properties.push(['region', region]);

  return new URLSearchParams([...properties, ...searchParams] as [string, string][]);
};

/**
 * Due to some extremely stupid changes in react-router-dom v6, we need to parse the searchParams fragment manually.
 * See here for more info: https://github.com/remix-run/react-router/issues/8254
 */
const parseCanonicalFragment = (fragment: string) => {
  const [path, type, ...rest] = fragment.split(/\/?(gene|trait|multigene)\/?/) || [];
  const trait = rest.join('');

  return {
    path,
    type,
    trait:
      decodeURIComponent(trait.replace('%', 'PERCENTILE')).replace('PERCENTILE', '%') || undefined,
  };
};
