import { useMemo } from 'react';
import { replaceRegionInMenu } from 'components/experimental/Navigation/helpers';
import { useRegion } from 'hooks';

import { categoryMenu } from 'services/menu/extendedCategories';

export const useCategoryDrawerOptions = () => {
  const region = useRegion();

  const categories = useMemo(
    () => replaceRegionInMenu(categoryMenu, region),
    [categoryMenu, region]
  );

  return categories;
};
