import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { generateSingletonHook } from 'context/SingletonContext';
import { isRtoR } from 'services/isRtoR';
import { safeGetLocalStorageItem, safeSetLocalStorageItem } from 'services/localStorage';
import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../../src/js/app/rn-messages';
import { webViewNavigationEventService } from '../../../../src/js/web-view-navigation-event-service';

/**
 * Most URLs should be added to the stack, but there are some that are not supported and should not be added.
 * This function is used to check for unsupported URLs.
 */
const isSupportedURL = (url: string) => {
  const ignoreList = ['coords', 'tab=', 'service_worker__controlling_v2'];

  if (ignoreList.some((match) => !!url.includes(match))) {
    return false;
  }

  return true;
};

/**
 * Custom hook for internal use only, used to encapsulate the URL handling logic and present a clean interface.
 */
const useManageURLs = () => {
  const [previousUrls, setPreviousUrls] = useState<string[]>([]);
  const loadUrlsFromLocalStorage = () => {
    const currentUrl = window.location.href.replace(window.location.origin, '');

    const previousUrls = JSON.parse(safeGetLocalStorageItem('previousUrls', '[]'));
    setPreviousUrls(() =>
      currentUrl !== previousUrls[previousUrls.length - 1]
        ? [...previousUrls, currentUrl]
        : previousUrls
    );
  };
  const saveUrlsToLocalStorage = () => {
    const currentPreviousUrls = previousUrls;
    const locallyStoredPreviousUrls = JSON.parse(safeGetLocalStorageItem('previousUrls', '[]'));
    // History can't go backwards, so if we have less currentPreviousUrls than
    // locallyStoredPreviousUrls, we don't want to save and overwrite history.
    if (currentPreviousUrls.length < locallyStoredPreviousUrls.length) return;

    safeSetLocalStorageItem('previousUrls', JSON.stringify(previousUrls));
  };

  useEffect(() => {
    loadUrlsFromLocalStorage();
    webViewNavigationEventService.setUpListenerForWebViewToListenForPreviousUrlsUpdate({
      callback: loadUrlsFromLocalStorage,
    });
    return () =>
      webViewNavigationEventService.removeListenerForWebViewToListenForPreviousUrlsUpdate({
        callback: loadUrlsFromLocalStorage,
      });
  }, []);

  useEffect(() => {
    saveUrlsToLocalStorage();
    // Send the navigation stack to the React Native app only when it's larger than 0 to prevent overwriting.
    // Or should it be 1, because on app reload the previousUrls.length is 1?
    // If navigation stack length is 0, that means WebView just started and we don't need to send it.
    if (window.IS_APP && previousUrls.length > 1) {
      sendMessageToReactNative(MESSAGE_TYPES.SEND_WEBVIEW_NAVIGATION_STACK_TO_RN, { previousUrls });
    }
  }, [JSON.stringify(previousUrls)]);

  return {
    previousUrls,
    push: (url = window.location.href) => {
      const currentUrl = url.replace(window.location.origin, '');

      if (isSupportedURL(currentUrl)) {
        setPreviousUrls((previousUrls) =>
          currentUrl !== previousUrls[previousUrls.length - 1]
            ? [...previousUrls, currentUrl]
            : previousUrls
        );
      }
    },
    pop: (navigate?: NavigateFunction) => {
      const to = previousUrls[Math.max(0, previousUrls.length - 2)];
      setPreviousUrls(previousUrls.slice(0, previousUrls.length - 1));

      if (!!isRtoR(to) && !!navigate) {
        navigate(to);
      } else {
        window.location.href = to;
      }

      // We need to dispatch a popstate event to trigger the scroll restoration logic in the browser.
      // useRememberScrollPosition handles this event and will restore the scroll position.
      window.dispatchEvent(new Event('popstate'));
    },
  };
};

const [CustomHistoryProvider, useCustomHistory] = generateSingletonHook(useManageURLs);

export { CustomHistoryProvider, useCustomHistory };
export default useCustomHistory;
