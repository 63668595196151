import { EnumsBucket } from 'constants/enums';
import { MorphmarketEventListItem, RegistrationStatus, User } from './types';

export const clearQueryParams = (queryParams: { [key in string]: string }) => {
  const newQueryParams = JSON.parse(JSON.stringify(queryParams));
  Object.keys(newQueryParams).forEach((key) => {
    if (isValueNull(newQueryParams[key])) {
      delete newQueryParams[key];
    }
  });
  return newQueryParams;
};

export const isValueNull = (value: any) => {
  return !value && value !== 0;
};

export const formatCurrency = (value: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(Math.round(value));
};

export const formattLongNumbers = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value);
};

export const formatNumberToKMBTNotation = (value: number) => {
  try {
    return new Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
      value
    );
  } catch (e) {
    // This is necessary because of a bug in Safari 14's implementation of Intl.NumberFormat. See #5041
    return value;
  }
};

export const setQueryParam = (param: string, value: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (value === '') {
    urlSearchParams.delete(param);
  } else {
    urlSearchParams.set(param, value);
  }

  const querySeparator = [...urlSearchParams].length >= 1 ? '?' : '';

  const newRelativePathQuery = `${window.location.pathname}${querySeparator}${urlSearchParams}`;

  window.history.replaceState(null, '', `${newRelativePathQuery}${window.location.hash}`);
};

export const removeAllQueryParams = () => {
  window.history.replaceState(null, '', window.location.pathname + window.location.hash);
};

export const removeQueryParam = (field: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete(field);
  const searchParams = urlSearchParams.toString();
  const newRelativePathQuery = searchParams
    ? `${window.location.pathname}?${urlSearchParams.toString()}`
    : window.location.pathname;
  window.history.replaceState(null, '', newRelativePathQuery + window.location.hash);
};

export const removeQueryParams = (...fields: string[]) => {
  fields.forEach((field) => removeQueryParam(field));
};

export const formatAddress = (address?: MorphmarketEventListItem['formatted_address']) => {
  return address ? address : '-';
};

export const getRegion = () => {
  const slahsPosition = window.location.pathname.indexOf('/', 1);
  return window.location.pathname.slice(1, slahsPosition);
};

export const getOwnerFullName = (owner?: User) => {
  let result = '';
  if (owner?.first_name) {
    result += owner.first_name;
  }

  if (owner?.last_name) {
    if (owner) {
      result += ' ';
    }

    result += owner.last_name;
  }

  return result;
};

export const showVendorsInfo = (event?: MorphmarketEventListItem) => {
  return (
    event?.registration_status !== RegistrationStatus.DISABLED &&
    event?.registration_status !== RegistrationStatus.FUTURE &&
    (event?.vendor_count || 0) > 0
  );
};

export type IsActiveUrl = (current: Location, target: string, strict?: boolean) => boolean;

/**
 * Checks whether the provided locations are the "same".
 * Not all possible link combinations are currently supported.
 * If strict is set to true, only category/calculator links with matching categories are considered equal.
 */
export const isActiveUrl: IsActiveUrl = (current, target, strict = true) => {
  // We need to prepare URLs containing regions before we can check them for equality
  const region = current.pathname?.split('/')?.[1];
  const hasRegion = EnumsBucket?.regions?.some(({ id }) => id === region);
  // Clear the region from the target in case it comes included
  const targetWithRegion = hasRegion ? `/${region}${target.replace(`${region}/`, '')}` : undefined;
  const url = new URL(targetWithRegion || target, window.location.href);

  if (current.href === url.href || current.href === `${url.href}/`) {
    return true;
  }

  if (!strict && current.pathname !== '/') {
    if (current.pathname === url.pathname || `${current.pathname}/` === url.pathname) {
      return true;
    }
  }

  return false;
};
