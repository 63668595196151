import { HTMLAttributes } from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import styles from '../Button.module.scss';

export const SearchButton: React.FC<HTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button className={styles.button} {...props}>
      <Icon icon={faSearch} />
    </button>
  );
};
