import React from 'react';
import { WhenWrapper } from 'components/experimental/Wrappers';
import { Feedback } from './Feedback';
import style from '../NativeAppReviewModal.module.scss';

type PostSubmitProps = {
  isError: boolean;
  submitted: boolean;
  showRateInAppStore: boolean;
  onFeedbackChange: (feedback: string) => void;
};

export const PostRating: React.FC<PostSubmitProps> = ({
  isError,
  submitted,
  showRateInAppStore,
  onFeedbackChange,
}) => {
  const showFeedback = !showRateInAppStore && !submitted;
  const feedbackLabel = "Let us know what you don't like and our team will work on fixing it";

  return (
    <div className={style.success}>
      <WhenWrapper condition={showFeedback}>
        <Feedback onChange={onFeedbackChange} isError={isError} label={feedbackLabel} />
      </WhenWrapper>
      <WhenWrapper condition={showRateInAppStore}>
        <p>Would you like to leave a review on the app store?</p>
      </WhenWrapper>
      <WhenWrapper condition={submitted}>
        <p>Thank you for your rating and valuable feedback!</p>
      </WhenWrapper>
    </div>
  );
};
