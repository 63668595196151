import { ErrorPage } from 'pages/ErrorPage/ErrorPage';

const refresh = () => window.location.reload();

const ErrorLocalStorage = ({ errorName, errorMessage }) => {
  const detail = `If you're talking with support, send them this info: "${errorName}: ${errorMessage}"`;

  return (
    <ErrorPage
      title="Your browser settings are incompatible with this website"
      text="You need to re-enable localStorage or sessionStorage"
      detail={detail}
      img="https://d20zyr0oabjxc2.cloudfront.net/static/img/server-error-images/500.svg"
      imgAlt="Something Went Wrong"
      buttonText="Try Again"
      buttonAction={refresh}
    />
  );
};

export default ErrorLocalStorage;
