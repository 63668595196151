/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnimalGridGroupViewEnum,
    AnimalGridGroupViewEnumFromJSON,
    AnimalGridGroupViewEnumFromJSONTyped,
    AnimalGridGroupViewEnumToJSON,
} from './AnimalGridGroupViewEnum';
import {
    BreedingPairingGroupingEnum,
    BreedingPairingGroupingEnumFromJSON,
    BreedingPairingGroupingEnumFromJSONTyped,
    BreedingPairingGroupingEnumToJSON,
} from './BreedingPairingGroupingEnum';
import {
    CardGridViewChoices,
    CardGridViewChoicesFromJSON,
    CardGridViewChoicesFromJSONTyped,
    CardGridViewChoicesToJSON,
} from './CardGridViewChoices';
import {
    ColumnPreferences,
    ColumnPreferencesFromJSON,
    ColumnPreferencesFromJSONTyped,
    ColumnPreferencesToJSON,
} from './ColumnPreferences';
import {
    DisplayPreferencesRetrieveDeviceTypeEnum,
    DisplayPreferencesRetrieveDeviceTypeEnumFromJSON,
    DisplayPreferencesRetrieveDeviceTypeEnumFromJSONTyped,
    DisplayPreferencesRetrieveDeviceTypeEnumToJSON,
} from './DisplayPreferencesRetrieveDeviceTypeEnum';
import {
    DisplayPreferencesRetrieveDisplayCurrency,
    DisplayPreferencesRetrieveDisplayCurrencyFromJSON,
    DisplayPreferencesRetrieveDisplayCurrencyFromJSONTyped,
    DisplayPreferencesRetrieveDisplayCurrencyToJSON,
} from './DisplayPreferencesRetrieveDisplayCurrency';
import {
    DisplayPreferencesRetrieveNotifications,
    DisplayPreferencesRetrieveNotificationsFromJSON,
    DisplayPreferencesRetrieveNotificationsFromJSONTyped,
    DisplayPreferencesRetrieveNotificationsToJSON,
} from './DisplayPreferencesRetrieveNotifications';
import {
    DistanceUnitEnum,
    DistanceUnitEnumFromJSON,
    DistanceUnitEnumFromJSONTyped,
    DistanceUnitEnumToJSON,
} from './DistanceUnitEnum';
import {
    ListCardViewChoices,
    ListCardViewChoicesFromJSON,
    ListCardViewChoicesFromJSONTyped,
    ListCardViewChoicesToJSON,
} from './ListCardViewChoices';
import {
    ListGridCardViewChoices,
    ListGridCardViewChoicesFromJSON,
    ListGridCardViewChoicesFromJSONTyped,
    ListGridCardViewChoicesToJSON,
} from './ListGridCardViewChoices';
import {
    ListGridViewChoices,
    ListGridViewChoicesFromJSON,
    ListGridViewChoicesFromJSONTyped,
    ListGridViewChoicesToJSON,
} from './ListGridViewChoices';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface DisplayPreferencesRetrieve
 */
export interface DisplayPreferencesRetrieve {
    /**
     * 
     * @type {DisplayPreferencesRetrieveDeviceTypeEnum}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly deviceType: DisplayPreferencesRetrieveDeviceTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly hasStore: boolean;
    /**
     * 
     * @type {ListGridCardViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly inventoryView: ListGridCardViewChoices | null;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly inventoryNumberOfRows: number;
    /**
     * 
     * @type {Array<ColumnPreferences>}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly inventoryColumnPreferences: Array<ColumnPreferences> | null;
    /**
     * 
     * @type {ListGridCardViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly husbandryInventoryView: ListGridCardViewChoices | null;
    /**
     * 
     * @type {Array<ColumnPreferences>}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly husbandryInventoryColumnPreferences: Array<ColumnPreferences> | null;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly offspringView: ListCardViewChoices | null;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly offspringNumberOfRows: number;
    /**
     * 
     * @type {Array<ColumnPreferences>}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly offspringColumnPreferences: Array<ColumnPreferences> | null;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly searchNumberOfRows: number;
    /**
     * 
     * @type {CardGridViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly searchView: CardGridViewChoices | null;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly publicOffspringView: ListCardViewChoices | null;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly indexView: ListCardViewChoices | null;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly publicOffspringNumberOfRows: number;
    /**
     * 
     * @type {Array<ColumnPreferences>}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly publicOffspringColumnPreferences: Array<ColumnPreferences> | null;
    /**
     * 
     * @type {Array<ColumnPreferences>}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly siblingsColumnPreferences: Array<ColumnPreferences> | null;
    /**
     * 
     * @type {DistanceUnitEnum}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly distanceUnit: DistanceUnitEnum | null;
    /**
     * 
     * @type {DisplayPreferencesRetrieveDisplayCurrency}
     * @memberof DisplayPreferencesRetrieve
     */
    displayCurrency: DisplayPreferencesRetrieveDisplayCurrency | null;
    /**
     * 
     * @type {BreedingPairingGroupingEnum}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly breedingPairingGrouping: BreedingPairingGroupingEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly breedingPairingSeason: string;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly followingStoresView: ListGridViewChoices | null;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly blockedStoresView: ListGridViewChoices | null;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly snakeOrdersView: ListGridViewChoices | null;
    /**
     * 
     * @type {DisplayPreferencesRetrieveNotifications}
     * @memberof DisplayPreferencesRetrieve
     */
    notifications: DisplayPreferencesRetrieveNotifications;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly radiusPreferences: number;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly showSocialActionsOnListingsDetail: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly postalCodeForListingSearch: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesRetrieve
     */
    weightDisplayUnit: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesRetrieve
     */
    lengthDisplayUnit: string;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly theme: ThemeEnum | null;
    /**
     * 
     * @type {AnimalGridGroupViewEnum}
     * @memberof DisplayPreferencesRetrieve
     */
    readonly animalGridGroupView: AnimalGridGroupViewEnum | null;
}

export function DisplayPreferencesRetrieveFromJSON(json: any): DisplayPreferencesRetrieve {
    return DisplayPreferencesRetrieveFromJSONTyped(json, false);
}

export function DisplayPreferencesRetrieveFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesRetrieve {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': DisplayPreferencesRetrieveDeviceTypeEnumFromJSON(json['device_type']),
        'hasStore': json['has_store'],
        'inventoryView': ListGridCardViewChoicesFromJSON(json['inventory_view']),
        'inventoryNumberOfRows': json['inventory_number_of_rows'],
        'inventoryColumnPreferences': (json['inventory_column_preferences'] === null ? null : (json['inventory_column_preferences'] as Array<any>).map(ColumnPreferencesFromJSON)),
        'husbandryInventoryView': ListGridCardViewChoicesFromJSON(json['husbandry_inventory_view']),
        'husbandryInventoryColumnPreferences': (json['husbandry_inventory_column_preferences'] === null ? null : (json['husbandry_inventory_column_preferences'] as Array<any>).map(ColumnPreferencesFromJSON)),
        'offspringView': ListCardViewChoicesFromJSON(json['offspring_view']),
        'offspringNumberOfRows': json['offspring_number_of_rows'],
        'offspringColumnPreferences': (json['offspring_column_preferences'] === null ? null : (json['offspring_column_preferences'] as Array<any>).map(ColumnPreferencesFromJSON)),
        'searchNumberOfRows': json['search_number_of_rows'],
        'searchView': CardGridViewChoicesFromJSON(json['search_view']),
        'publicOffspringView': ListCardViewChoicesFromJSON(json['public_offspring_view']),
        'indexView': ListCardViewChoicesFromJSON(json['index_view']),
        'publicOffspringNumberOfRows': json['public_offspring_number_of_rows'],
        'publicOffspringColumnPreferences': (json['public_offspring_column_preferences'] === null ? null : (json['public_offspring_column_preferences'] as Array<any>).map(ColumnPreferencesFromJSON)),
        'siblingsColumnPreferences': (json['siblings_column_preferences'] === null ? null : (json['siblings_column_preferences'] as Array<any>).map(ColumnPreferencesFromJSON)),
        'distanceUnit': DistanceUnitEnumFromJSON(json['distance_unit']),
        'displayCurrency': DisplayPreferencesRetrieveDisplayCurrencyFromJSON(json['display_currency']),
        'breedingPairingGrouping': BreedingPairingGroupingEnumFromJSON(json['breeding_pairing_grouping']),
        'breedingPairingSeason': json['breeding_pairing_season'],
        'followingStoresView': ListGridViewChoicesFromJSON(json['following_stores_view']),
        'blockedStoresView': ListGridViewChoicesFromJSON(json['blocked_stores_view']),
        'snakeOrdersView': ListGridViewChoicesFromJSON(json['snake_orders_view']),
        'notifications': DisplayPreferencesRetrieveNotificationsFromJSON(json['notifications']),
        'radiusPreferences': json['radius_preferences'],
        'showSocialActionsOnListingsDetail': json['show_social_actions_on_listings_detail'],
        'postalCodeForListingSearch': json['postal_code_for_listing_search'],
        'weightDisplayUnit': json['weight_display_unit'],
        'lengthDisplayUnit': json['length_display_unit'],
        'theme': ThemeEnumFromJSON(json['theme']),
        'animalGridGroupView': AnimalGridGroupViewEnumFromJSON(json['animal_grid_group_view']),
    };
}

export function DisplayPreferencesRetrieveToJSON(value?: DisplayPreferencesRetrieve | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_currency': DisplayPreferencesRetrieveDisplayCurrencyToJSON(value.displayCurrency),
        'notifications': DisplayPreferencesRetrieveNotificationsToJSON(value.notifications),
        'weight_display_unit': value.weightDisplayUnit,
        'length_display_unit': value.lengthDisplayUnit,
    };
}

