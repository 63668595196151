import { useState, useRef, useCallback } from 'react';
import { API_URL } from 'api/config';

const fetchDefaults = {
  credentials: 'same-origin', // It's not a default in Safari 11 or older
};

export function fetchApi(url, options) {
  let sanitizedUrl = url;

  const isAbsolute = /^([a-z]+:)?\/\//i.test(sanitizedUrl); // Check if URL starts with protocol:// or //
  const isLocal = sanitizedUrl.startsWith(API_URL);

  if (!isAbsolute && !isLocal) {
    const [, path] = url.match(/^\/*(.*)/); // Trim left slash
    sanitizedUrl = `${API_URL}/${path}`;
  }

  const optionsWithDefaults = {
    ...fetchDefaults,
    ...options,
  };

  return fetch(sanitizedUrl, optionsWithDefaults);
}

export function useFetchApi() {
  const [isFetching, setIsFetching] = useState(false);
  const abortController = useRef();

  const selfAbortingFetchApi = useCallback(async (url, options) => {
    setIsFetching(true);

    abortController.current?.abort();
    abortController.current = new AbortController();

    try {
      const response = await fetchApi(url, {
        signal: abortController.current.signal,
        ...options,
      });

      setIsFetching(false);

      return response;
    } catch (exception) {
      if (exception instanceof DOMException && exception.name === 'AbortError') {
        return null;
      }

      setIsFetching(false);
    }
  }, []);

  return [selfAbortingFetchApi, isFetching];
}
