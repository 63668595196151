import { useEffect } from 'react';

import {
  isActivityListPath,
  isAddEditAnimalPath,
  isAddEditActivityPath,
  isAnimalActivitiesOverviewPath,
  isAnimalActivityListPath,
  isBreedingPath,
  isCalculatorPath,
  isDashboardPath,
  isEditAnimalPath,
  isHusbandryPath,
  isIndexPath,
  isInventoryPath,
  isListingsPath,
  isOffspringPath,
  isOrdersPath,
  isProjectsPath,
  isSavedSearchesPath,
  isStoresPath,
  isAnimalGroupsPath,
  isHomePath,
  isHusbandryInventory,
  isMessengerPath,
} from 'services/urls';

const isSingleMessageViewRegex = /^\/messages\/([^\/]+)\/(\d+)$/;

/**
 * Handles the logic for resizing the min-height of the mobile header.
 * Without this, there would be an empty space beneath the mobile navigation on non-React pages where the
 * expanded part of the header should be (if the expanded part is retracted, of course).
 */
const useHandleExpandableNavHeader = () => {
  useEffect(() => {
    const el = document.querySelector('#react-navbar-container');

    if (el) {
      (el as HTMLDivElement).style.minHeight = '0';
    }
  }, []);
};

export const Services: React.FC = () => {
  useHandleExpandableNavHeader();

  return null;
};

// Sometimes the quick search is a poor use of limited space, therefore we hide it on those pages.
export const isPageWhereWeHideQuickSearch = () => {
  const animalPagePattern = new RegExp(
    /\/[a-z]{2,3}\/c\/[a-z-]+(?:\/[a-z-]+)?(?:\/[a-z-]+)?(?:\/[a-z-]+)?\/\d+$/
  );
  const { pathname, search } = window.location;
  const url = pathname + search;

  const isAnimalPage = pathname.match(animalPagePattern);
  const isMessengerPage = url.startsWith('/messages');

  return (
    isMessengerPage ||
    isAnimalPage ||
    isInventoryAnimalNavPath() ||
    isAnimalsNavPath() ||
    isAddEditAnimalPath(pathname) ||
    isHusbandryPath(pathname)
  );
};

// Whether there's a nav bar on this page
export const hasNavMenu = () => {
  return (
    isListingsNavPath() ||
    isInventoryAnimalNavPath() ||
    isAnimalsNavPath() ||
    isMessengerNavPath() ||
    isHomePath(window.location.pathname)
  );
};

export const isListingsNavPath = () => {
  const { pathname } = window.location;

  return (
    isStoresPath(pathname) ||
    isIndexPath(pathname) ||
    isListingsPath(pathname) ||
    isSavedSearchesPath(pathname) ||
    isCalculatorPath(pathname) ||
    isProjectsPath(pathname) ||
    pathname.includes('genetic-calculator')
  );
};

export const isInventoryAnimalNavPath = () => {
  const { pathname } = window.location;

  return (
    isEditAnimalPath(pathname) ||
    isAnimalActivityListPath(pathname) ||
    isAnimalActivitiesOverviewPath(pathname)
  );
};

export const isAnimalsNavPath = () => {
  const { pathname } = window.location;

  return (
    isDashboardPath(pathname) ||
    isInventoryPath(pathname) ||
    isHusbandryInventory(pathname) ||
    isActivityListPath(pathname) ||
    isAnimalGroupsPath(pathname) ||
    isAddEditActivityPath(pathname) ||
    isOrdersPath(pathname) ||
    isBreedingPath(pathname) ||
    isOffspringPath(pathname)
  );
};

export const isMessengerNavPath = () => {
  const { pathname, search } = window.location;
  const url = pathname + search;

  return (
    url.startsWith('/messages') &&
    !(url.includes('/thread/') || url.includes('thread=') || url.match(isSingleMessageViewRegex))
  );
};
