import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteClasses, Popper } from '@mui/material';
import debounce from 'lodash/debounce';
import { SEARCH_FILTER } from 'components/Common/Filters/SearchFilter/definitions';
import { InventorySearchIcon } from 'components/experimental/Navigation/components';
import { InputField } from 'components/experimental/Navigation/components/Search/SearchInput';
import FiltersJar from 'api/FiltersJar';
import { fetchAnimals } from 'api/inventory/actions';
import { AnimalManagerGrid } from 'api/sdk';
import { FiltersObject } from 'api/types';
import { openInventoryFiltersDrawer } from 'hooks/inventory/drawerFilter/useInventoryFilterDrawer';
import { useOpenState } from 'hooks/utils/useOpenState';
import { camelCaseKeys } from '../../../../../../../../src/js/utils';
import { useLastScrollDirection } from './useLastScrollDirection';
import styles from './QuickSearch.module.scss';

type Option = {
  href: string;
  label: string;
};

export const QuickSearchInventory = () => {
  const classes: Partial<AutocompleteClasses> = {
    root: styles.root,
    endAdornment: styles.hidden,
    listbox: styles.listbox,
    option: styles.option,
    noOptions: styles.noOption,
    inputRoot: styles.inputRoot,
  };
  const { isOpen, open, close } = useOpenState();
  const [value, setValue] = useState('');
  const direction = useLastScrollDirection();
  const [options, setOptions] = useState<Option[]>([]);

  const getOptions = () => {
    (async () => {
      const filters = new FiltersJar([[SEARCH_FILTER, value]]) as unknown as FiltersObject;
      const response = await fetchAnimals(filters, new AbortController());
      const options = response.results.map(camelCaseKeys).map((animal: AnimalManagerGrid) => ({
        href: animal.editUrl,
        label: `${animal.titleDisplay} (${animal.animalId})`,
      }));
      setOptions(options);
    })();
  };

  useEffect(
    function closeOnScrollDown() {
      if (direction === 'down') {
        close();
        setOptions([]);
        setValue('');
      }
    },
    [direction]
  );

  useEffect(
    function debounceOptions() {
      const debouncedGetOptions = debounce(() => {
        if (value.length > 3) {
          getOptions();
        }
      }, 300);

      debouncedGetOptions();

      return () => {
        debouncedGetOptions.cancel();
      };
    },
    [value]
  );

  return (
    // Tech Debt: This presentation is repeated in multiple quick search components.
    // We should consider extracting this into a shared component.
    <div className={styles.searchBar}>
      <div className={styles.innerContainer}>
        <Autocomplete
          classes={classes}
          open={isOpen}
          onOpen={open}
          onClose={() => {
            close();
            setOptions([]);
            setValue('');
          }}
          inputValue={value}
          clearOnBlur={true}
          slotProps={{ paper: { className: styles.paper } }}
          PopperComponent={({ ...props }) => {
            const anchorEl = props.anchorEl as HTMLElement;

            if (!anchorEl) {
              console.error(
                'Missing hoisting element, the search suggestions might not be positioned properly'
              );
            }

            return (
              <Popper
                {...props}
                placement="bottom-start"
                anchorEl={anchorEl}
                slotProps={{ root: { style: { width: '100%' } } }}
              />
            );
          }}
          onChange={(_e, value) => {
            if (!!value) {
              // Check if we can use the navigate hook here
              window.location.href = value?.href;
            }
          }}
          onInputChange={(e, value, reason) => {
            if (reason === 'reset') {
              setValue('');
            } else if (e && e?.type !== 'keydown') {
              setValue(value);
            }
          }}
          filterOptions={(all) => all}
          options={options}
          noOptionsText="Enter a few more letters..."
          renderInput={(params) => <InputField {...params} placeholder="Search My Animals" />}
        />
        <InventorySearchIcon handleClick={openInventoryFiltersDrawer} />
      </div>
    </div>
  );
};
