/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageAttachmentRequest,
    MessageAttachmentRequestFromJSON,
    MessageAttachmentRequestFromJSONTyped,
    MessageAttachmentRequestToJSON,
} from './MessageAttachmentRequest';

/**
 * 
 * @export
 * @interface NewMessageRequest
 */
export interface NewMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof NewMessageRequest
     */
    messageText?: string | null;
    /**
     * 
     * @type {Array<MessageAttachmentRequest>}
     * @memberof NewMessageRequest
     */
    attachments?: Array<MessageAttachmentRequest> | null;
}

export function NewMessageRequestFromJSON(json: any): NewMessageRequest {
    return NewMessageRequestFromJSONTyped(json, false);
}

export function NewMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageText': !exists(json, 'message_text') ? undefined : json['message_text'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(MessageAttachmentRequestFromJSON)),
    };
}

export function NewMessageRequestToJSON(value?: NewMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message_text': value.messageText,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(MessageAttachmentRequestToJSON)),
    };
}

