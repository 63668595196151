import { useContext, useEffect, useState } from 'react';
import ModalContext from 'context/ModalContext/ModalContext';
import { NativeAppReviewContext } from 'context/NativeAppReviewModalContext';
import { reactNativeAPIs } from 'services/mmAppAPIs';
import type { fiveStarRating, zeroStarRating } from '../components/StarRating';
interface UseHandleAppReviewFormData {
  labels: {
    cancelLabel: string;
    submitLabel: string;
  };
  disableSubmit: boolean;
  showControlButton: boolean;
  showRateInAppStore: boolean;
  userRating: fiveStarRating | zeroStarRating;
  feedback: string;
  errorMessage: string;
  touched: boolean;
  isRated: boolean;
  showNativeAppReviewModal: boolean;
  actions: {
    setUserRating: React.Dispatch<React.SetStateAction<zeroStarRating | fiveStarRating>>;
    setFeedback: React.Dispatch<React.SetStateAction<string>>;
    setTouched: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRated: React.Dispatch<React.SetStateAction<boolean>>;
    onModalClose: () => void;
    handleDisableSubmit: () => boolean;
  };
}

interface UseHandleAppReviewFormProps {
  submitted: boolean;
}

export const useHandleAppReviewForm = ({ submitted }: UseHandleAppReviewFormProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [isRated, setIsRated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [userRating, setUserRating] = useState<fiveStarRating | zeroStarRating>(0);

  const { closeModal } = useContext(ModalContext);
  const { showNativeAppReviewModal, setShowNativeAppReviewModal } =
    useContext(NativeAppReviewContext);

  const showRateInAppStore = isRated && userRating >= 4;

  const {
    MM_APP_RATING_EVENTS,
    trySaveIsLastAppRatingPromptClosedByUserInLocalStorage,
    sendHandleRatingPromptMessageToReactNative,
  } = reactNativeAPIs;

  const handleDisableSubmit = () => {
    if (userRating === 0) {
      setDisableSubmit(true);
      setErrorMessage('Rating is required!');
      return false;
    } else if (isRated && !showRateInAppStore && feedback === '') {
      setDisableSubmit(true);
      setErrorMessage('Feedback is required!');
      return false;
    } else {
      setDisableSubmit(false);
      setErrorMessage('');
      return true;
    }
  };

  useEffect(
    function handleRatingAndFeedBackChange() {
      if (touched) handleDisableSubmit();
    },
    [userRating, feedback]
  );

  useEffect(function blurActiveElementInCurrentPageOnModalOpen() {
    if (!document.activeElement) return;
    (document.activeElement as HTMLFormElement).blur();
  }, []);

  const cancelLabel = showRateInAppStore ? 'No' : 'Close';
  const submitLabel = showRateInAppStore ? 'Yes' : 'Submit';

  const showControlButton = !submitted || showRateInAppStore;

  const onModalClose = () => {
    closeModal();
    setShowNativeAppReviewModal(false);
    trySaveIsLastAppRatingPromptClosedByUserInLocalStorage(false);
    sendHandleRatingPromptMessageToReactNative(MM_APP_RATING_EVENTS.UPDATE_LAST_PROMPTED_AT);
  };

  return {
    labels: {
      cancelLabel,
      submitLabel,
    },
    touched,
    disableSubmit,
    errorMessage,
    showRateInAppStore,
    showControlButton,
    userRating,
    feedback,
    isRated,
    showNativeAppReviewModal,
    actions: {
      setUserRating,
      setFeedback,
      setTouched,
      setIsRated,
      onModalClose,
      handleDisableSubmit,
    },
  } as UseHandleAppReviewFormData;
};
