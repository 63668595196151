import { forwardRef } from 'react';
import classnames from 'classnames';
import { Link as GenericLink } from 'react-router-dom';
import { ButtonProps } from 'components/experimental';
import buttonStyles from 'components/experimental/Button/Button.module.scss';
import styles from './Links.module.scss';

export type AnchorProps = React.HTMLProps<HTMLAnchorElement> & {
  disabled?: boolean;
  bold?: boolean;
  sm?: boolean;
  variant?: ButtonProps['variant'] | 'button';
};

/**
 * Styled anchor element for external links.
 * @deprecated Use https://mui.com/material-ui/react-link/ instead.
 */
export const A = forwardRef<'a', AnchorProps>(
  ({ children, disabled, bold, sm, variant, ...props }, ref) => {
    const buttonClassNames = classnames([
      styles.clear,
      buttonStyles.base,
      sm && buttonStyles.small,
      variant === 'outline' && buttonStyles.outline,
      variant === 'link' && buttonStyles.link,
    ]);

    const linkClassNames = classnames(
      styles.link,
      disabled && styles.disabled,
      sm && styles.small,
      bold && styles.bold
    );

    return (
      <a className={variant ? buttonClassNames : linkClassNames} {...props} ref={ref as any}>
        {variant ? <span>{children}</span> : children}
      </a>
    );
  }
);

export type LinkProps = React.ComponentProps<typeof GenericLink> & {
  disabled?: boolean;
  bold?: boolean;
  sm?: boolean;
  variant?: ButtonProps['variant'] | 'button';
};

/**
 * Styled Link element for internal links.
 * @deprecated
 */
export const Link: React.FC<LinkProps> = ({
  children,
  to,
  disabled,
  bold,
  variant,
  sm,
  ...props
}) => {
  const buttonClassNames = classnames([
    styles.clear,
    buttonStyles.base,
    sm && buttonStyles.small,
    variant === 'outline' && buttonStyles.outline,
    variant === 'link' && buttonStyles.link,
  ]);

  const linkClassNames = classnames(
    styles.link,
    disabled && styles.disabled,
    sm && styles.small,
    bold && styles.bold
  );

  return (
    <GenericLink className={variant ? buttonClassNames : linkClassNames} to={to} {...props}>
      {variant ? <span>{children}</span> : children}
    </GenericLink>
  );
};
