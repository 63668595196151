import { traitsForCategory } from 'services/categories';

export const findTrait = (match: string, category?: number) => {
  if (!category) {
    return;
  }

  const traits_with_aliases_list = traitsForCategory(category);
  const matcher = ([key, value]: [string, string]) =>
    match === key || match?.toLowerCase() === value?.toLowerCase();
  const format = (tuple?: [string, string]) => {
    if (tuple) {
      const [id, value] = tuple;

      return {
        id,
        value,
      };
    }
  };

  const list = Object.entries(traits_with_aliases_list || {});
  return format(list.find(matcher));
};
