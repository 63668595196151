import React from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks';
import style from './Badge.module.scss';

export const BADGE_BLACK = Symbol('BADGE_BLACK');
export const BADGE_BLUE = Symbol('BADGE_BLUE');
export const BADGE_DARK_GRAY = Symbol('BADGE_DARK_GRAY');
export const BADGE_GOLD = Symbol('BADGE_GOLD');
export const BADGE_GRAY = Symbol('BADGE_GRAY');
export const BADGE_GREEN = Symbol('BADGE_GREEN');
export const BADGE_LIGHT_BLUE = Symbol('BADGE_LIGHT_BLUE');
export const BADGE_MINT = Symbol('BADGE_MINT');
export const BADGE_ORANGE = Symbol('BADGE_ORANGE');
export const BADGE_PINK = Symbol('BADGE_PINK');
export const BADGE_PURPLE = Symbol('BADGE_PURPLE');
export const BADGE_DARK_PURPLE = Symbol('BADGE_DARK_PURPLE');
export const BADGE_RED = Symbol('BADGE_RED');

const colorToClassName = {
  [BADGE_BLACK]: 'black',
  [BADGE_BLUE]: 'blue',
  [BADGE_DARK_GRAY]: 'dark_gray',
  [BADGE_GOLD]: 'gold',
  [BADGE_GRAY]: 'gray',
  [BADGE_GREEN]: 'green',
  [BADGE_LIGHT_BLUE]: 'light_blue',
  [BADGE_MINT]: 'mint',
  [BADGE_ORANGE]: 'orange',
  [BADGE_PINK]: 'pink',
  [BADGE_PURPLE]: 'purple',
  [BADGE_DARK_PURPLE]: 'dark_purple',
  [BADGE_RED]: 'red',
};

export type BadgeProps = {
  element?: keyof JSX.IntrinsicElements;
  type: symbol;
  className?: string;
  children?: React.ReactNode;
  dataTip?: string;
  role?: string;
  tabIndex?: number;
};
/**
 * @deprecated Use https://mui.com/material-ui/react-chip/ instead.
 */
const Badge: React.FC<BadgeProps> = ({
  element = ({ children, className }: { children: React.ReactNode; className?: string }) => (
    <div className={className} {...props}>
      {children}
    </div>
  ),
  type,
  className,
  children,
  dataTip,
  ...props
}) => {
  const Element = element;
  const { isMobile } = useWindowSize();

  return (
    <Element
      className={classNames(
        style.badge,
        style[colorToClassName[type as keyof typeof colorToClassName]],
        className
      )}
      {...props}
      data-tip={isMobile ? '' : dataTip}
    >
      {children}
    </Element>
  );
};

export default Badge;
