import { createTheme } from '@mui/material';
// DataGrid declaration
// https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation';
import {
  MuiButton,
  MuiIconButton,
  MuiTextField,
  MuiCheckbox,
  MuiSelect,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiAutocomplete,
  MuiInputLabel,
  MuiFormControl,
  MuiInputBase,
  MuiLink,
  MuiSwitch,
  MuiPopover,
  MuiSnackbarContent,
  MuiChip,
  MuiSlider,
  MuiDialog,
  MuiDialogContent,
  MuiStack,
  MuiTypography,
  MuiDataGrid,
} from './overrides';
import breakpoint from 'style/breakpoints.scss';
import space from 'style/spacing.scss';
import fonts from 'style/typo.scss';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    thin?: number;
    extraThin?: number;
    light?: number;
    regular?: number;
    regularMedium?: number;
    semiBold?: number;
    bold?: number;
    extraBold?: number;
    black?: number;
  }

  interface Typography {
    thin: number;
    extraThin: number;
    light: number;
    regular: number;
    regularMedium: number;
    semiBold: number;
    bold: number;
    extraBold: number;
    black: number;
  }
}

export const base = createTheme({
  spacing: (x: number | string) => (typeof x === 'number' ? `${8 * x}px` : space[x] ?? x),
  breakpoints: {
    values: {
      xs: 0,
      mobileMedium: parseInt(breakpoint.mobileMedium, 10),
      mobileLarge: parseInt(breakpoint.mobileLarge, 10),
      tablet: parseInt(breakpoint.tablet, 10),
      tabletLarge: parseInt(breakpoint.tabletLarge, 10),
      desktop: parseInt(breakpoint.desktop, 10),
      desktopLarge: parseInt(breakpoint.desktopLarge, 10),
    },
  },
  typography: {
    fontFamily: '"Outfit", sans-serif',
    fontWeightBold: 700,

    xxxs: { fontSize: fonts.f_xxxs },
    xxs: { fontSize: fonts.f_xxs },
    xs: { fontSize: fonts.f_xs },
    sm: { fontSize: fonts.f_sm },
    md: { fontSize: fonts.f_md },
    lg: { fontSize: fonts.f_lg },
    xl: { fontSize: fonts.f_xl },
    xxl: { fontSize: fonts.f_xxl },
    xxxl: { fontSize: fonts.f_xxxl },

    thin: parseInt(fonts.fw_thin, 10),
    extraThin: parseInt(fonts.fw_extra_thin, 10),
    light: parseInt(fonts.fw_light, 10),
    regular: parseInt(fonts.fw_regular, 10),
    regularMedium: parseInt(fonts.fw_regular_medium, 10),
    semiBold: parseInt(fonts.fw_semi_bold, 10),
    bold: parseInt(fonts.fw_bold, 10),
    extraBold: parseInt(fonts.fw_extra_bold, 10),
    black: parseInt(fonts.fw_black, 10),
  },

  components: {
    MuiLink,
    MuiChip,
    MuiAutocomplete,
    MuiButton,
    MuiIconButton,
    MuiTextField,
    MuiCheckbox,
    MuiSelect,
    MuiMenu,
    MuiMenuItem,
    MuiOutlinedInput,
    MuiFormControl,
    MuiInputLabel,
    MuiSwitch,
    MuiInputBase,
    MuiPopover,
    MuiSnackbarContent,
    MuiSlider,
    MuiDialog,
    MuiDialogContent,
    MuiStack,
    MuiTypography,
    MuiDataGrid,
  },
});
