import { useEffect, useRef, useState } from 'react';

// Since there is no way to check if a scroll event was generated by the user or by code, we need to "guess"
// In this case, we use the delta between the previous and current scrollY value, if it's greater than the threshold,
// it's probably caused by code
// 300 is a nice value, since even a "flick" touch scroll by the user can't hit it
const SCROLL_THRESHOLD_PX = 300;

/**
 * Returns the direction the user is scrolling.
 * Due to various UI issues specific to certain browsers, this custom hook contains a bunch of checks and workarounds.
 */
export const useLastScrollDirection = () => {
  const previousValue = useRef<number>(0);
  const [direction, setDirection] = useState<'up' | 'down' | undefined>();
  const updateScrollDirection = () => {
    const isViewportAtBottom = document.body.getBoundingClientRect().bottom <= window.innerHeight;
    const isScrollProgrammatic =
      Math.abs(window.scrollY - previousValue.current) > SCROLL_THRESHOLD_PX;

    // Right now, the only code-generated scroll event is when we're scrolling to the top of the
    // website on page change
    // If in the future we have different programmatically generated scroll events, this solution will not be sufficient
    if (isScrollProgrammatic) {
      setDirection('up');
      previousValue.current = window.scrollY;
      return;
    }

    if (!isViewportAtBottom) {
      const isUserScrollingDown = window.scrollY > previousValue.current;
      const isViewportOnTop = document.body.getBoundingClientRect().top >= 0;
      setDirection(isUserScrollingDown && !isViewportOnTop ? 'down' : 'up');
      previousValue.current = window.scrollY;
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', updateScrollDirection);
    return () => document.removeEventListener('scroll', updateScrollDirection);
  }, []);

  return direction;
};
