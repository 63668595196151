// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon--uk4Nn{color:var(--color-var-gold)}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Offspring/NotesIcon/NotesIcon.module.scss"],"names":[],"mappings":"AAEA,aACE,2BAAA","sourcesContent":["@use 'style/colors' as *;\n\n.icon {\n  color: var(--color-var-gold);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gold": `#ffa41c`,
	"male": `#468cc8`,
	"female": `#fd798a`,
	"mixed": `#926cdb`,
	"unknown": `#828282`,
	"gray000": `#333`,
	"gray100": `#4a4b4d`,
	"gray200": `#8b8b8b`,
	"gray300": `#b4b4b4`,
	"gray400": `#d9d9d9`,
	"gray500": `#ced4da`,
	"gray600": `#e9ecef`,
	"gray700": `#f9f9f9`,
	"gray800": `#ffffff`,
	"white": `#ffffff`,
	"whiteSmoke": `#f7f7f7`,
	"black": `#000`,
	"icon": `icon--uk4Nn`
};
export default ___CSS_LOADER_EXPORT___;
