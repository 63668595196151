import { doesThisPathContainReactComponent, isReactRoute } from 'routes/utils/routeUtils';

/**
 * Checks whether both the current page and the destination page is a React page.
 * If yes, it prevent a regular redirect and just updates the route, improving page loading speed.
 */

export const isRtoR = (to: string) => {
  const from = window.location.pathname;
  const isBothReactRoutes = [from, to].every(isReactRoute);
  if (!isBothReactRoutes) {
    return false;
  }
  const doesBothRoutesContainReactComponent = [from, to].every(doesThisPathContainReactComponent);
  return doesBothRoutesContainReactComponent;
};
