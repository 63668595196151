const formControlCommonHeight = '2.75rem'; // 44px

const heights = {
  input: formControlCommonHeight,
  select: formControlCommonHeight,
  dropdown: formControlCommonHeight,
  autocomplete: formControlCommonHeight,
  toggleButtonGroup: '3.4rem', // 55px
};

export default heights;
