import { Components, Theme } from '@mui/material';
import colors from '../constants/colors';
import spacing from 'style/spacing.scss';

export const MuiStack: Components<Omit<Theme, 'components'>>['MuiStack'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.bordered': {
        borderRadius: spacing.borderRadius,
        border: '1px solid',
        borderColor: colors.gray400,
        fontSize: theme.typography.md.fontSize,
      },
    }),
  },
};
