import { createContext, useState, useMemo } from 'react';

type ExpoItem = {
  value: number;
  title: string;
};

export type ExpoContextType = {
  expoFilterOption: ExpoItem | null;
  onSetExpoFilterOption: (value: ExpoItem | null) => void;
};

export const ExpoContext = createContext<ExpoContextType>({
  expoFilterOption: null,
  onSetExpoFilterOption: () => {},
});

export const ExpoContextProvider: React.FC = ({ children }) => {
  const [expoFilterOption, setExpoFilterOption] = useState<ExpoItem | null>(null);
  const expoValues = useMemo(
    () => ({
      expoFilterOption,
      onSetExpoFilterOption: setExpoFilterOption,
    }),
    [expoFilterOption?.title, expoFilterOption?.value]
  );
  return <ExpoContext.Provider value={expoValues}>{children}</ExpoContext.Provider>;
};
