import { useAboutMe } from 'context/UserContext/UserContext';
import { useShowExtendedContent } from './utils/useShowExtendedContent';

/**
 * Returns the basic permissions information about a user, if available.
 * This is used to determine what settings options should be available to the user.
 */
export const useSettingsPermission = () => {
  const { about } = useAboutMe();
  const isSeller = !!about?.hasStore;
  const hasOrganization = !!about?.hasOrganization;
  const hasBetaAccess = !!about?.hasBetaAccess;
  const { showPricingToUser } = useShowExtendedContent();

  const accessPermissions = {
    isSeller,
    hasOrganization,
    hasBetaAccess,
    showPricingToUser,
  };

  return accessPermissions;
};
