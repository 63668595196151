import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

const Analytics = () => import('pages/Analytics/Analytics');
const DashboardPage = () => import('pages/Dashboard/DashboardPage');
const Inventory = () => import('pages/Inventory/Animals/Inventory');
const Index = () => import('pages/Index/Index');
const Event = () => import('pages/Event/EventPage');
const Events = () => import('pages/Events/EventsPage');
const Tags = () => import('pages/Tags/Tags');
const SavedSearches = () => import('pages/SavedSearches/SavedSearches');
const CollectionNFS = () => import('pages/CollectionNFS/CollectionNFS');
const Stores = () => import('pages/Stores/Stores');
const Waitlist = () => import('pages/Waitlist/Waitlist');
const PushNotificationsList = () => import('pages/Search/PushNotificationsList');
const ReactPlayground = () => import('pages/ReactPlayground/ReactPlayground');
const ReactComponents = () => import('pages/ReactComponents/ReactComponents');
const HomePage = () => import('pages/Home/HomePage');
const LabelSettings = () => import('pages/LabelSettings');
const Customer = () => import('pages/Customers/Customers');
const HusbandryInventory = () => import('pages/Inventory/Husbandry/HusbandryInventory');
const QRScanner = () => import('pages/QRScanner/QRScanner');

export const mainRouteComponents = {
  Analytics,
  DashboardPage,
  Inventory,
  Index,
  Event,
  Events,
  Tags,
  SavedSearches,
  CollectionNFS,
  Stores,
  Waitlist,
  PushNotificationsList,
  ReactPlayground,
  ReactComponents,
  HomePage,
  LabelSettings,
  Customer,
  NotFoundPage,
  HusbandryInventory,
  QRScanner,
};
