/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `active` - Active
 * * `inactive` - Inactive
 * * `expired` - Expired
 * @export
 */
export const ActiveEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Expired: 'expired'
} as const;
export type ActiveEnum = typeof ActiveEnum[keyof typeof ActiveEnum];


export function ActiveEnumFromJSON(json: any): ActiveEnum {
    return ActiveEnumFromJSONTyped(json, false);
}

export function ActiveEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveEnum {
    return json as ActiveEnum;
}

export function ActiveEnumToJSON(value?: ActiveEnum | null): any {
    return value as any;
}

