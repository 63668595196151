export const ACTUAL_PHOTO_FILTER = Symbol('ACTUAL_PHOTO_FILTER');
export const HAS_VIDEO_FILTER = Symbol('HAS_VIDEO_FILTER');
export const INQUIRE_FOR_PRICE_FILTER = Symbol('INQUIRE_FOR_PRICE_FILTER');
export const PROVEN_BREEDER_FILTER = Symbol('PROVEN_BREEDER_FILTER');
export const USARK_MEMBERSHIP_FILTER = Symbol('USARK_MEMBERSHIP_FILTER');
export const WILL_TRADE_FILTER = Symbol('WILL_TRADE_FILTER');
export const FOLLOWED_STORES_ONLY_FILTER = Symbol('FOLLOWED_STORES_ONLY_FILTER');
export const HAS_LINEAGE_FILTER = Symbol('HAS_LINEAGE_FILTER');
export const IS_AVAILABLE_FOR_ORDER_FILTER = Symbol('IS_AVAILABLE_FOR_ORDER_FILTER');
export const ACCEPTS_OFFERS_FILTER = Symbol('ACCEPTS_OFFERS_FILTER');
export const AUCTION_FILTER = Symbol('AUCTION_FILTER');
export const EXCLUDE_BLOCKED_STORES_FILTER = Symbol('EXCLUDE_BLOCKED_STORES_FILTER');
export const MY_BIDS_ONLY_FILTER = Symbol('MY_BIDS_ONLY_FILTER');
export const PET_ONLY_FILTER = Symbol('PET_ONLY_FILTER');
