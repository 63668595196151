/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `list` - list
 * * `grid` - grid
 * @export
 */
export const ListGridViewChoices = {
    List: 'list',
    Grid: 'grid'
} as const;
export type ListGridViewChoices = typeof ListGridViewChoices[keyof typeof ListGridViewChoices];


export function ListGridViewChoicesFromJSON(json: any): ListGridViewChoices {
    return ListGridViewChoicesFromJSONTyped(json, false);
}

export function ListGridViewChoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListGridViewChoices {
    return json as ListGridViewChoices;
}

export function ListGridViewChoicesToJSON(value?: ListGridViewChoices | null): any {
    return value as any;
}

