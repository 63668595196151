import React from 'react';
import { useRegion } from 'hooks';
import { useRecentlyVisitedCategories } from 'hooks/useRecentlyVisited';
import { recentCategoriesToCalcLinks, recentCategoriesToLinks } from '../../helpers';
import { SettingsMenuItem } from '../MenuItems/Item';
import { TreeMenu } from './Nested';
import styles from './Horizontal.module.scss';

/**
 * Plain horizontal menu.
 */
export const HorizontalMenu: React.FC<{ links: any[] }> = ({ links }) => {
  const region = useRegion();
  const { recentCategories, trackVisit } = useRecentlyVisitedCategories();
  const onClickCapture = (e: any) => {
    const isAnchor = e?.target?.tagName?.toLowerCase() === 'a';
    const categoryId = e?.target.getAttribute('id');
    !!isAnchor && trackVisit(categoryId);
  };

  return (
    <ul className={styles.container}>
      {links.map(
        ({
          children,
          links,
          showRecentlyVisitedCategories,
          showRecentlyVisitedCalculators,
          ...rest
        }) => {
          if (links) {
            const props =
              showRecentlyVisitedCalculators || showRecentlyVisitedCategories
                ? { NestedMenuItemProps: { onClickCapture } }
                : {};
            const linksWithRecentlyVisitedCategories = [
              ...(showRecentlyVisitedCategories
                ? recentCategoriesToLinks(recentCategories, region)
                : []),
              ...(showRecentlyVisitedCalculators
                ? recentCategoriesToCalcLinks(recentCategories)
                : []),
              ...links,
            ];

            return (
              <li key={children}>
                <TreeMenu
                  name={children}
                  links={linksWithRecentlyVisitedCategories}
                  props={props}
                  {...rest}
                />
              </li>
            );
          } else {
            return (
              <li key={children}>
                <SettingsMenuItem children={children} {...rest} />
              </li>
            );
          }
        }
      )}
    </ul>
  );
};
