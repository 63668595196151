import React, { ReactNode, useRef, memo, useMemo } from 'react';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, MenuList, SwipeableDrawer, SwipeableDrawerProps } from '@mui/material';
import { DrawerDash } from 'components/DrawerDash/DrawerDash';
import { useOpenState } from 'hooks/utils/useOpenState';
import { OptionProps, MenuOption } from './components/MenuOption';
import { NestedMenu } from './components/Nested';
import { DrawerAnchor, getPaperStyle } from './helper';

export type DrawerActions = {
  open: () => void;
  close: () => void;
  onTransitionEnd?: () => void;
  onTransitionEnter?: () => void;
};

export type NestedMenuDrawerProps = {
  options: DrawerOptions;
  fontAwesomeIcon?: ReactNode;
  ariaLabel?: string;
  clickCallback?: () => void;
};

export type DrawerProps = {
  isOpen: boolean;
  actions: DrawerActions;
  children: ReactNode;
  anchor: DrawerAnchor | undefined;
  isExpanded?: boolean;
  isFullScreen?: boolean;
  isBackdropHidden?: boolean;
} & Partial<SwipeableDrawerProps>;

export type DrawerOptions = Array<OptionProps>;

const _Drawer: React.FC<DrawerProps> = ({
  actions,
  isOpen,
  anchor,
  children,
  isExpanded = false,
  isFullScreen = false,
  isBackdropHidden = false, //needed to prevent too dark backdrop for stacked drawers #6228
  ...props
}) => {
  const paperStyle = useMemo(
    () => getPaperStyle(isExpanded, isFullScreen, anchor),
    [isExpanded, isFullScreen, anchor]
  );
  return (
    <SwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onOpen={actions.open}
      onClose={actions.close}
      disableSwipeToOpen={true}
      PaperProps={{ sx: paperStyle }}
      onTransitionEnter={() => actions.onTransitionEnter?.()}
      onTransitionEnd={() => !isOpen && actions.onTransitionEnd?.()}
      slotProps={isBackdropHidden ? { backdrop: { invisible: true } } : {}}
      {...props}
    >
      {children}
    </SwipeableDrawer>
  );
};

export const Drawer = memo(_Drawer);

export const NestedMenuDrawer: React.FC<NestedMenuDrawerProps> = ({
  options,
  fontAwesomeIcon,
  ariaLabel,
  clickCallback,
}) => {
  const anchorEl = useRef(null);
  const { isOpen, toggle, close, open } = useOpenState();

  const isApp = window && window.IS_APP;
  const appVersion = window && window.MOBILE_APP_VERSION;
  const webVersion = window && window.WEBSITE_VERSION;
  const bothVersions = `${appVersion} / ${webVersion}`;

  const handleOnClick = () => {
    toggle();
    clickCallback?.();
  };

  return (
    <>
      <IconButton
        onClick={handleOnClick}
        ref={anchorEl}
        sx={{ fontSize: 24, padding: 0 }}
        aria-label={ariaLabel}
      >
        {fontAwesomeIcon ? fontAwesomeIcon : <FontAwesomeIcon icon={faBars} />}
      </IconButton>
      <NestedMenu options={options}>
        {({ branch, resetToRoot }) => {
          return (
            <Drawer
              anchor="bottom"
              isOpen={isOpen}
              isExpanded
              actions={{
                open,
                close,
                onTransitionEnter: resetToRoot,
              }}
            >
              <DrawerDash />
              <MenuList>
                {branch?.map((option, index) => (
                  <MenuOption key={`${index}-${option.children}`} closeMenu={close} {...option} />
                ))}
                {isApp && <MenuOption appVersion={bothVersions || ''} />}
                {!isApp && webVersion && <MenuOption appVersion={webVersion || ''} />}
              </MenuList>
            </Drawer>
          );
        }}
      </NestedMenu>
    </>
  );
};
