/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LabelConfigRequest
 */
export interface LabelConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof LabelConfigRequest
     */
    css: string;
    /**
     * 
     * @type {string}
     * @memberof LabelConfigRequest
     */
    html: string;
    /**
     * 
     * @type {string}
     * @memberof LabelConfigRequest
     */
    name?: string;
}

export function LabelConfigRequestFromJSON(json: any): LabelConfigRequest {
    return LabelConfigRequestFromJSONTyped(json, false);
}

export function LabelConfigRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelConfigRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'css': json['css'],
        'html': json['html'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function LabelConfigRequestToJSON(value?: LabelConfigRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'css': value.css,
        'html': value.html,
        'name': value.name,
    };
}

