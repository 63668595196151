import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { LAST_UPDATED_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';

export const LAST_UPDATED_COLUMN = Symbol('LAST_UPDATED_COLUMN');

const lastUpdatedColumn = {
  id: LAST_UPDATED_COLUMN,
  ordering: LAST_UPDATED_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Last Updated',
  hideable: true,
  className: style.lastUpdatedColumn,

  render: ({ last_updated }: { last_updated: string }) => last_updated,
};

export default lastUpdatedColumn;
