/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `list` - list
 * * `grid` - grid
 * * `card` - card
 * @export
 */
export const ListGridCardViewChoices = {
    List: 'list',
    Grid: 'grid',
    Card: 'card'
} as const;
export type ListGridCardViewChoices = typeof ListGridCardViewChoices[keyof typeof ListGridCardViewChoices];


export function ListGridCardViewChoicesFromJSON(json: any): ListGridCardViewChoices {
    return ListGridCardViewChoicesFromJSONTyped(json, false);
}

export function ListGridCardViewChoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListGridCardViewChoices {
    return json as ListGridCardViewChoices;
}

export function ListGridCardViewChoicesToJSON(value?: ListGridCardViewChoices | null): any {
    return value as any;
}

