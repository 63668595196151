/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AcceptTradesEnum,
    AcceptTradesEnumFromJSON,
    AcceptTradesEnumFromJSONTyped,
    AcceptTradesEnumToJSON,
} from './AcceptTradesEnum';
import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';

/**
 * @type AnimalAcceptTrades
 * 
 * @export
 */
export type AnimalAcceptTrades = AcceptTradesEnum | NullEnum;

export function AnimalAcceptTradesFromJSON(json: any): AnimalAcceptTrades {
    return AnimalAcceptTradesFromJSONTyped(json, false);
}

export function AnimalAcceptTradesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalAcceptTrades {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return AcceptTradesEnumFromJSONTyped(json, true);
}

export function AnimalAcceptTradesToJSON(value?: AnimalAcceptTrades | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return value;
}

