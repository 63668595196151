/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `dark` - dark
 * * `light` - light
 * @export
 */
export const ThemeEnum = {
    Dark: 'dark',
    Light: 'light'
} as const;
export type ThemeEnum = typeof ThemeEnum[keyof typeof ThemeEnum];


export function ThemeEnumFromJSON(json: any): ThemeEnum {
    return ThemeEnumFromJSONTyped(json, false);
}

export function ThemeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeEnum {
    return json as ThemeEnum;
}

export function ThemeEnumToJSON(value?: ThemeEnum | null): any {
    return value as any;
}

