import categoryColumn from 'components/Common/Columns/CategoryColumn/CategoryColumn';
import damColumn from 'components/Common/Columns/DamColumn/DamColumn';
import imageColumn from 'components/Common/Columns/ImageColumn/ImageColumn';
import sireColumn from 'components/Common/Columns/SireColumn/SireColumn';
import traitsColumn from 'components/Common/Columns/TraitsColumn/TraitsColumn';
import birthDateColumn from 'components/Offspring/Columns/BirthDateColumn/BirthDateColumn';
import childrenColumn from 'components/Offspring/Columns/ChildrenColumn/ChildrenColumn';
import eggsColumn from 'components/Offspring/Columns/EggsColumn/EggsColumn';
import groupIdColumn from 'components/Offspring/Columns/GroupIdColumn/GroupIdColumn';
import layDateColumn from 'components/Offspring/Columns/LayDateColumn/LayDateColumn';
import modifiedColumn from 'components/Offspring/Columns/ModifiedColumn/ModifiedColumn';
import nonViableEggsColumn from 'components/Offspring/Columns/NonViableEggsColumn/NonViableEggsColumn';
import notesColumn from 'components/Offspring/Columns/NotesColumn/NotesColumn';
import viableEggsColumn from 'components/Offspring/Columns/ViableEggsColumn/ViableEggsColumn';
import visibilityColumn from 'components/Offspring/Columns/VisibilityColumn/VisibilityColumn';

const defaultColumns = [
  [imageColumn, true],
  [groupIdColumn, true],
  [categoryColumn, true],
  [damColumn, true],
  [sireColumn, true],
  [traitsColumn, true],
  [layDateColumn, true],
  [notesColumn, true],
  [eggsColumn, true],
  [viableEggsColumn, true],
  [nonViableEggsColumn, true],
  [birthDateColumn, true],
  [childrenColumn, true],
  [visibilityColumn, true],
  [modifiedColumn, true],
];

export default defaultColumns;
