// Content shown in Dropzone container
export const DEFAULT_AVATAR_MESSAGE =
  "<div class='d-flex flex-column'><i class='fa-solid fa-flag fa-3x'></i><b>Drag image here</b>OR<div class='btn btn-sm btn-secondary'>Select image</div></div>";
export const DEFAULT_IMAGE_MESSAGE =
  "<div class='d-flex flex-column'><i class='fa-solid fa-flag fa-3x'></i><b>Drag logo here</b>OR<div class='btn btn-sm btn-secondary'>Select logo</div></div>";
export const DEFAULT_PHOTO_MESSAGE =
  "<div class='d-flex flex-column'><i class='fa-solid fa-flag fa-3x'></i><b>Drag photo here</b>OR<div class='btn btn-sm btn-secondary'>Select photo</div></div>";

// this list should be in sync with the AWS lambda regex `ACCEPTED_FILE_TYPES_REGEX` in the /AWS/lambdas/image_processing/common/common.js
export const ACCEPTED_FILES =
  'image/jpeg,image/jpg,image/png,image/gif,image/webp,image/svg,image/pipeg,image/heic,image/tiff,.heic';

export const IMG_LOAD_TIMEOUT_MS = 10 * 1000;

export const TO_TYPE = 'image/png';
export const TO_TYPE_EXT = '.png';

// From template
export var MAX_IMAGE_SIZE_MB: number;
export const PER_IMAGE_UPLOAD_TIMEOUT_MS: number = 60 * 1000; // 1 minute
// Used by the API
export const enum ImageType {
  UserAvatar = 'user-profile-avatar',
  UserCover = 'user-profile-cover-photo',

  StoreLogo = 'store-page-logo',
  StoreCover = 'store-page-cover-photo',

  AdImage = 'ad',
  OffspringImage = 'offspring',
}
