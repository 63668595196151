import { MALE, FEMALE } from 'pages/BreedingManager/Private/pages/List/definitions';
import { CARD_VIEW, GRID_VIEW, LIST_VIEW } from 'components/ViewMenu/definitions';

export const SEARCH_PER_PAGE_PREFERENCE = Symbol('SEARCH_PER_PAGE_PREFERENCE');
export const SEARCH_VIEW_PREFERENCE = Symbol('SEARCH_VIEW_PREFERENCE');

export const INVENTORY_PER_PAGE_PREFERENCE = Symbol('INVENTORY_PER_PAGE_PREFERENCE');
export const INVENTORY_VIEW_PREFERENCE = Symbol('INVENTORY_VIEW_PREFERENCE');
export const INVENTORY_COLUMNS_PREFERENCE = Symbol('INVENTORY_COLUMNS_PREFERENCE');

export const HUSBANDRY_INVENTORY_VIEW_PREFERENCE = Symbol('HUSBANDRY_INVENTORY_VIEW_PREFERENCE');
export const HUSBANDRY_INVENTORY_COLUMNS_PREFERENCE = Symbol(
  'HUSBANDRY_INVENTORY_COLUMNS_PREFERENCE'
);

export const OFFSPRING_PRIVATE_PER_PAGE_PREFERENCE = Symbol(
  'OFFSPRING_PRIVATE_PER_PAGE_PREFERENCE'
);
export const OFFSPRING_PRIVATE_COLUMNS_PREFERENCE = Symbol('OFFSPRING_PRIVATE_COLUMNS_PREFERENCE');
export const OFFSPRING_PRIVATE_VIEW_PREFERENCE = Symbol('OFFSPRING_PRIVATE_VIEW_PREFERENCE');

export const OFFSPRING_PUBLIC_PER_PAGE_PREFERENCE = Symbol('OFFSPRING_PUBLIC_PER_PAGE_PREFERENCE');
export const OFFSPRING_PUBLIC_COLUMNS_PREFERENCE = Symbol('OFFSPRING_PUBLIC_COLUMNS_PREFERENCE');
export const OFFSPRING_PUBLIC_VIEW_PREFERENCE = Symbol('OFFSPRING_PUBLIC_VIEW_PREFERENCE');

export const SIBLINGS_COLUMNS_PREFERENCE = Symbol('SIBLINGS_COLUMNS_PREFERENCE');

export const INDEX_VIEW_PREFERENCE = Symbol('INDEX_VIEW_PREFERENCE');

export const BREEDER_PAIRING_PREFERENCE = Symbol('BREEDER_PAIRING_PREFERENCE');

export const FOLLOWING_STORES_PREFERENCE = Symbol('FOLLOWING_STORES_PREFERENCE');
export const BLOCKED_STORES_PREFERENCE = Symbol('BLOCKED_STORES_PREFERENCE');

export const SNAKE_ORDERS_PREFERENCE = Symbol('SNAKE_ORDERS_PREFERENCE');
export const LIKED_SAVED_ANIMALS_PREFERENCES = Symbol('LIKED_SAVED_ANIMALS_PREFERENCES');

export const RADIUS_PREFERENCES = Symbol('RADIUS_PREFERENCES');
export const POSTAL_CODE_PREFERENCES = Symbol('POSTAL_CODE_PREFERENCES');

export const SHOW_SOCIAL_ACTIONS_ON_LISTINGS_DETAIL = Symbol(
  'SHOW_SOCIAL_ACTIONS_ON_LISTINGS_DETAIL'
);
export const THEME_PREFERENCE = Symbol('THEME_PREFERENCE');
export const DARK_THEME = Symbol('DARK_THEME');
export const LIGHT_THEME = Symbol('LIGHT_THEME');

export const inventoryViews = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'grid'],
  [CARD_VIEW, 'card'],
];

export const offspringViews = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'card'],
];

export const searchViews = [
  [CARD_VIEW, 'card'],
  [GRID_VIEW, 'grid'],
];

export const indexViews = [
  [LIST_VIEW, 'list'],
  [CARD_VIEW, 'card'],
];

export const breederPairingPreferences = [
  [MALE, 'male'],
  [FEMALE, 'female'],
];

export const followingViews = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'grid'],
];

export const blockedViews = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'grid'],
];

export const snakeOrdersView = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'grid'],
];

export const likedSavedViews = [
  [LIST_VIEW, 'list'],
  [GRID_VIEW, 'grid'],
];

export const BREEDING_PAIRING_SEASON = Symbol('BREEDING_PAIRING_SEASON');
export const theme = [
  [DARK_THEME, 'dark'],
  [LIGHT_THEME, 'light'],
];
