import { matchRoutes } from 'react-router-dom';
import { excludeUserProfile } from './utilsExcludes';
import { routes } from 'routes/routes';

export const isReactRoute = (path: string) => {
  const matches = matchRoutes(routes, path);

  const excludeUser = excludeUserProfile(path);
  if (!excludeUser) return false;

  if (!matches || matches.length === 0) {
    return false;
  }

  // Deepest matched route is the last element in the matches array.
  // This is most likely the route we are looking for current navigation.
  const deepestMatch = matches[matches.length - 1].route;

  // We'll discard route matches that end in a wildcard
  // NOTE: This means the NotFoundPage is treated as a non-React page, even though it is a React page
  if (deepestMatch.path === '*') return false;

  return true;
};

/**
 * @param path: string - The path to check if path contains react component.
 * Usage: To check if the path contains a react component or lazy-loaded react component.
 * Background: In our react app, we have conflicting react routes with django views.
 * Route can have a react component via `element` or `lazy` property.
 * For example:
 * <Route path="/"" element={<HomePage />} /> or <Route path="/" lazy={import('pages/HomePage/HomePage')} />
 * Without react component, the route will render empty page and throws console warning i.e - "'router-outlet' is not a known element".
 * Example:
 * /users/:id route have nested routes like /users/:id/animals, but parent route /users/:id doesn't have a component, instead it is a django view.
 * If we do R2R navigation from some react page to /users/:id, it will show warning - "'router-outlet' is not a known element".
 * So, this function is used to check if the path contains a react component or lazy loaded react component, especially used in R2R navigation.
 */
export const doesThisPathContainReactComponent = (path: string) => {
  const matches = matchRoutes(routes, path);
  if (!matches || matches.length === 0) {
    return false;
  }
  const deepestMatch = matches[matches.length - 1].route;
  return !!deepestMatch.element || !!deepestMatch.lazy;
};
