import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useInventoryNavigation } from 'pages/Inventory/hooks/useInventoryNavigation';
import { useToggleFormOrRedirect } from 'pages/Search/components/services/useAdvancedSearchRedirect';
import { mmAnimal, mmMessages } from 'components/experimental/IconDefinitions';
import { isRtoR } from 'services/isRtoR';
import { isReactRoute } from 'routes/utils/routeUtils';
import styles from './Icons.module.scss';

const Indicator: React.FC<{ count?: number }> = ({ count }) => {
  const truncatedCount = !!count && count > 99 ? '99+' : count;

  return <div className={styles.indicator}>{truncatedCount}</div>;
};

const MessagesIndicator: React.FC<{ count?: number; leftDisplay?: boolean }> = ({
  count,
  leftDisplay,
}) => {
  const truncatedCount = !!count && count > 99 ? '99+' : count;

  return (
    <div
      className={classNames(
        styles.indicator,
        leftDisplay ? styles.indicatorMessagesLeftDisplay : styles.indicatorMessagesRightDisplay
      )}
    >
      {truncatedCount}
    </div>
  );
};

export type IconsProps = {
  count?: number;
  href: string;
};

// Unlike the other icons, it can have two different counts
type MessagesIconProps = {
  href: string;
  unseenMessages?: number;
};

export const Notifications: React.FC<IconsProps> = ({ count, href }) => {
  return (
    <div className={styles.icon}>
      <a href={href}>
        <Icon icon={faBell} />
        {!!count && <Indicator count={count} />}
      </a>
    </div>
  );
};

export const Messages: React.FC<MessagesIconProps> = ({ unseenMessages, href }) => {
  const isReact = isReactRoute(window.location.pathname);

  if (isReact) {
    return (
      <div className={styles.icon}>
        <Link to={href} aria-label="Messages">
          <Icon icon={mmMessages} />
          {!!unseenMessages && <MessagesIndicator count={unseenMessages} />}
        </Link>
      </div>
    );
  }
  return (
    <div className={styles.icon}>
      <a href={href} aria-label="Messages">
        <Icon icon={mmMessages} />
        {!!unseenMessages && <MessagesIndicator count={unseenMessages} />}
      </a>
    </div>
  );
};

export const AnimalManager: React.FC<IconsProps> = ({ href }) => {
  const isReact = isRtoR(href);
  const navigateToInventory = useInventoryNavigation();

  const handleClick = (e: React.MouseEvent) => {
    if (!isReact) return;
    e.preventDefault();
    navigateToInventory(href);
  };

  return (
    <div className={styles.icon}>
      <a href={href} aria-label="Animal Manager" onClick={handleClick}>
        <Icon icon={mmAnimal} />
      </a>
    </div>
  );
};

/**
 * Icon button for toggling the legacy advanced search form.
 */

export const AdvancedSearchIcon: React.FC = () => {
  const linkProps = useToggleFormOrRedirect();

  return (
    <a className={styles.advancedSearchButton} {...linkProps}>
      <FontAwesomeIcon icon={faBarsFilter} />
      <span>Filter</span>
    </a>
  );
};

const FilterIcon: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <span className={styles.advancedSearchButton} onClick={() => onClick?.()}>
      <FontAwesomeIcon icon={faBarsFilter} />
      <span>Filter</span>
    </span>
  );
};

export const MessengerSearchIcon = ({ handleClick }: { handleClick: () => void }) => {
  return <FilterIcon onClick={handleClick} />;
};

export const InventorySearchIcon = ({ handleClick }: { handleClick: () => void }) => {
  return <FilterIcon onClick={handleClick} />;
};
