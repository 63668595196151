import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const mmNewMessages: IconDefinition = {
  prefix: 'fab',
  iconName: 'accessible-icon', // We need to fake the icon name here
  icon: [
    23,
    22,
    [],
    '',
    `M11.75,0.129C17.826,0.129 22.711,4.654 22.711,10.227C22.711,15.8 17.826,20.32 11.75,20.32C10.345,20.32 8.961,20.066 7.758,19.628C6.469,20.621 4.424,21.871 1.781,21.871C1.369,21.871 0.999,21.587 0.832,21.166C0.668,20.741 0.746,20.243 1.029,19.903C1.051,19.886 2.383,18.266 3.023,16.35C1.605,14.652 0.75,12.534 0.75,10.222C0.75,4.649 5.674,0.129 11.75,0.129ZM11.754,18.253C16.683,18.253 20.687,14.652 20.687,10.227C20.687,5.801 16.678,2.2 11.75,2.2C6.821,2.2 2.812,5.801 2.812,10.227C2.812,12.341 3.749,13.943 4.523,14.91L5.408,16.015L4.952,17.42C4.716,18.146 4.415,18.833 4.097,19.439C5.111,19.057 5.958,18.49 6.568,17.982L7.406,17.291L8.385,17.647C9.473,18.047 10.603,18.253 11.754,18.253ZM13.438,7.734L12.243,10.51L13.966,10.51C14.173,10.51 14.357,10.635 14.43,10.828C14.504,11.021 14.448,11.236 14.293,11.374L10.34,14.833C10.168,14.983 9.91,14.992 9.725,14.859C9.541,14.725 9.472,14.476 9.562,14.266L10.757,11.494L9.034,11.494C8.827,11.494 8.643,11.365 8.57,11.172C8.496,10.979 8.552,10.764 8.707,10.626L12.66,7.167C12.832,7.017 13.09,7.008 13.275,7.141C13.459,7.275 13.528,7.524 13.438,7.734Z`,
  ],
};
