import { StateEnum } from 'api/sdk';
import { SymbolIndex } from '../../../../types';

export const STATE_FILTER = Symbol('STATE_FILTER');

export const STATE_ARCHIVED = Symbol('STATE_ARCHIVED');
export const STATE_FOR_SALE = Symbol('STATE_FOR_SALE');
export const STATE_LOANED = Symbol('STATE_LOANED');
export const STATE_NOT_FOR_SALE = Symbol('STATE_NOT_FOR_SALE');
export const STATE_ON_HOLD = Symbol('STATE_ON_HOLD');
export const STATE_FOR_SALE_OR_ON_HOLD = Symbol('STATE_FOR_SALE_OR_ON_HOLD');
export const STATE_SOLD = Symbol('STATE_SOLD');
export const STATE_ON_HOLD_AUCTION = Symbol('STATE_ON_HOLD_AUCTION');
export const STATE_SOLD_AUCTION = Symbol('STATE_SOLD_AUCTION');
export const STATE_BREEDERS = Symbol('STATE_BREEDERS');
export const STATE_HOLDBACKS = Symbol('STATE_HOLDBACKS');
export const STATE_PETS = Symbol('STATE_PETS');
export const STATE_ANY = Symbol('STATE_ANY');

export const stateNames: SymbolIndex = {
  [STATE_ARCHIVED]: 'Archived',
  [STATE_FOR_SALE]: 'For Sale',
  [STATE_LOANED]: 'Loaned',
  [STATE_FOR_SALE_OR_ON_HOLD]: 'For Sale or On Hold',
  [STATE_NOT_FOR_SALE]: 'NFS',
  [STATE_ON_HOLD]: 'On Hold',
  [STATE_SOLD]: 'Sold',
  [STATE_ON_HOLD_AUCTION]: 'On Hold - Auction',
  [STATE_SOLD_AUCTION]: 'Sold - Auction',
  [STATE_BREEDERS]: 'Breeders',
  [STATE_HOLDBACKS]: 'Holdbacks',
  [STATE_PETS]: 'Pets',
};

// Ordered options
export const stateOptions = [
  STATE_FOR_SALE,
  STATE_ON_HOLD,
  STATE_SOLD,
  STATE_NOT_FOR_SALE,
  STATE_LOANED,
  STATE_ARCHIVED,
  STATE_BREEDERS,
  STATE_HOLDBACKS,
  STATE_PETS,
];

export const stateEnumToSymbol = {
  [StateEnum.ForSale]: STATE_FOR_SALE,
  [StateEnum.OnHold]: STATE_ON_HOLD,
  [StateEnum.Sold]: STATE_SOLD,
  [StateEnum.Nfs]: STATE_NOT_FOR_SALE,
  [StateEnum.Loaned]: STATE_LOANED,
  [StateEnum.Archived]: STATE_ARCHIVED,
  [StateEnum.OnHoldAuction]: STATE_ON_HOLD_AUCTION,
  [StateEnum.SoldAuction]: STATE_SOLD_AUCTION,
  [StateEnum.Breeder]: STATE_BREEDERS,
  [StateEnum.Holdback]: STATE_HOLDBACKS,
  [StateEnum.Pet]: STATE_PETS,
};

export const stateSymbolToEnum: Record<symbol, StateEnum> = Object.fromEntries(
  Object.entries(stateEnumToSymbol).map(([key, value]) => [value, key])
);

export const ANY_STATE = 'any';
