// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div>button.backButton--zvOo2{font-weight:700;border-bottom:1px solid var(--color-var-gray400)}div>button.backButton--zvOo2 span{margin-left:.5rem}div>button.closeButton--yB2Vj{font-weight:700;border-bottom:1px solid var(--color-var-gray400);display:flex;justify-content:space-between}div>button.forwardButton--gzwzI{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Navigation/components/MenuItems/MenuItems.module.scss","webpack://./mysite/static/react-app/src/style/typo/weights.scss"],"names":[],"mappings":"AAIA,6BACE,eCGQ,CDFR,gDAAA,CAEA,kCACE,iBAAA,CAIJ,8BACE,eCNQ,CDOR,gDAAA,CACA,YAAA,CACA,6BAAA,CAGF,gCACE,YAAA,CACA,6BAAA","sourcesContent":["@use 'style/colors' as *;\n@use 'style/typo' as *;\n\n// Styles for forward/back buttons in tree menus, like Categories or Calculators\ndiv > button.backButton {\n  font-weight: $fw_bold;\n  border-bottom: 1px solid var(--color-var-gray400);\n\n  span {\n    margin-left: 0.5rem;\n  }\n}\n\ndiv > button.closeButton {\n  font-weight: $fw_bold;\n  border-bottom: 1px solid var(--color-var-gray400);\n  display: flex;\n  justify-content: space-between;\n}\n\ndiv > button.forwardButton {\n  display: flex;\n  justify-content: space-between;\n}\n","// Font weight variables\n$fw_thin: 100;\n$fw_extra_light: 200;\n$fw_light: 300;\n$fw_regular: 400;\n$fw_regular_medium: 450;\n$fw_medium: 500;\n$fw_semi_bold: 600;\n$fw_bold: 700;\n$fw_extra_bold: 800;\n$fw_black: 900;\n\n\n$weights: (\n  'fw_thin': $fw_thin,\n  'fw_extra_light': $fw_extra_light,\n  'fw_light': $fw_light,\n  'fw_regular': $fw_regular,\n  'fw_regular_medium': $fw_regular_medium,\n  'fw_medium': $fw_medium,\n  'fw_semi_bold': $fw_semi_bold,\n  'fw_bold': $fw_bold,\n  'fw_extra_bold': $fw_extra_bold,\n  'fw_black': $fw_black,\n);\n\n:export {\n  @each $weight, $value in $weights {\n    #{unquote($weight)}: #{$value};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gold": `#ffa41c`,
	"male": `#468cc8`,
	"female": `#fd798a`,
	"mixed": `#926cdb`,
	"unknown": `#828282`,
	"gray000": `#333`,
	"gray100": `#4a4b4d`,
	"gray200": `#8b8b8b`,
	"gray300": `#b4b4b4`,
	"gray400": `#d9d9d9`,
	"gray500": `#ced4da`,
	"gray600": `#e9ecef`,
	"gray700": `#f9f9f9`,
	"gray800": `#ffffff`,
	"white": `#ffffff`,
	"whiteSmoke": `#f7f7f7`,
	"black": `#000`,
	"f_xxxl": `2.5rem`,
	"f_xxl": `2rem`,
	"f_xl": `1.5rem`,
	"f_lg": `1.25rem`,
	"f_md": `1rem`,
	"f_sm": `0.875rem`,
	"f_xs": `0.75rem`,
	"f_xxs": `0.625rem`,
	"f_xxxs": `0.5rem`,
	"fw_thin": `100`,
	"fw_extra_light": `200`,
	"fw_light": `300`,
	"fw_regular": `400`,
	"fw_regular_medium": `450`,
	"fw_medium": `500`,
	"fw_semi_bold": `600`,
	"fw_bold": `700`,
	"fw_extra_bold": `800`,
	"fw_black": `900`,
	"backButton": `backButton--zvOo2`,
	"closeButton": `closeButton--yB2Vj`,
	"forwardButton": `forwardButton--gzwzI`
};
export default ___CSS_LOADER_EXPORT___;
