/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `total_m` - m (total length)
 * * `total_cm` - cm (total length)
 * * `snout_to_vent_m` - m (snout to vent)
 * * `snout_to_vent_cm` - cm (snout to vent)
 * @export
 */
export const LengthTypeEnum = {
    TotalM: 'total_m',
    TotalCm: 'total_cm',
    SnoutToVentM: 'snout_to_vent_m',
    SnoutToVentCm: 'snout_to_vent_cm'
} as const;
export type LengthTypeEnum = typeof LengthTypeEnum[keyof typeof LengthTypeEnum];


export function LengthTypeEnumFromJSON(json: any): LengthTypeEnum {
    return LengthTypeEnumFromJSONTyped(json, false);
}

export function LengthTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LengthTypeEnum {
    return json as LengthTypeEnum;
}

export function LengthTypeEnumToJSON(value?: LengthTypeEnum | null): any {
    return value as any;
}

