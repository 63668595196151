/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BreedingPairingList,
    BreedingPairingListFromJSON,
    BreedingPairingListFromJSONTyped,
    BreedingPairingListToJSON,
} from './BreedingPairingList';
import {
    BreedingPairingListByPrimaryAnimalPrimaryAnimal,
    BreedingPairingListByPrimaryAnimalPrimaryAnimalFromJSON,
    BreedingPairingListByPrimaryAnimalPrimaryAnimalFromJSONTyped,
    BreedingPairingListByPrimaryAnimalPrimaryAnimalToJSON,
} from './BreedingPairingListByPrimaryAnimalPrimaryAnimal';

/**
 * 
 * @export
 * @interface BreedingPairingListByPrimaryAnimal
 */
export interface BreedingPairingListByPrimaryAnimal {
    /**
     * 
     * @type {BreedingPairingListByPrimaryAnimalPrimaryAnimal}
     * @memberof BreedingPairingListByPrimaryAnimal
     */
    primaryAnimal: BreedingPairingListByPrimaryAnimalPrimaryAnimal;
    /**
     * 
     * @type {Array<BreedingPairingList>}
     * @memberof BreedingPairingListByPrimaryAnimal
     */
    breedingPairs: Array<BreedingPairingList>;
}

export function BreedingPairingListByPrimaryAnimalFromJSON(json: any): BreedingPairingListByPrimaryAnimal {
    return BreedingPairingListByPrimaryAnimalFromJSONTyped(json, false);
}

export function BreedingPairingListByPrimaryAnimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingListByPrimaryAnimal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primaryAnimal': BreedingPairingListByPrimaryAnimalPrimaryAnimalFromJSON(json['primary_animal']),
        'breedingPairs': ((json['breeding_pairs'] as Array<any>).map(BreedingPairingListFromJSON)),
    };
}

export function BreedingPairingListByPrimaryAnimalToJSON(value?: BreedingPairingListByPrimaryAnimal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_animal': BreedingPairingListByPrimaryAnimalPrimaryAnimalToJSON(value.primaryAnimal),
        'breeding_pairs': ((value.breedingPairs as Array<any>).map(BreedingPairingListToJSON)),
    };
}

