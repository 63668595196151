/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DisplayPreferencesUpdateDeviceTypeEnum,
    DisplayPreferencesUpdateDeviceTypeEnumFromJSON,
    DisplayPreferencesUpdateDeviceTypeEnumFromJSONTyped,
    DisplayPreferencesUpdateDeviceTypeEnumToJSON,
} from './DisplayPreferencesUpdateDeviceTypeEnum';
import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';

/**
 * @type DisplayPreferencesUpdateDeviceType
 * 
 * @export
 */
export type DisplayPreferencesUpdateDeviceType = DisplayPreferencesUpdateDeviceTypeEnum | NullEnum;

export function DisplayPreferencesUpdateDeviceTypeFromJSON(json: any): DisplayPreferencesUpdateDeviceType {
    return DisplayPreferencesUpdateDeviceTypeFromJSONTyped(json, false);
}

export function DisplayPreferencesUpdateDeviceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesUpdateDeviceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...DisplayPreferencesUpdateDeviceTypeEnumFromJSONTyped(json, true), ...NullEnumFromJSONTyped(json, true) };
}

export function DisplayPreferencesUpdateDeviceTypeToJSON(value?: DisplayPreferencesUpdateDeviceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...DisplayPreferencesUpdateDeviceTypeEnumToJSON(value), ...NullEnumToJSON(value) };
}

