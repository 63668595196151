import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { openCollapsibleSidebars, toggleSearchModal } from 'components/Sidebar/helpers';
import { useRegion, useWindowSize } from 'hooks';
import { useCategory } from 'hooks/useCategory';

// TODO: Clean this implementation up when removing the openCollapsibleSidebars() and toggleSearchModal() functions.

/**
 * Handles the cleanup of the URL after the user is redirected to the AS page.
 */
export const useAdvancedSearchRedirect = () => {
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  const { width } = useWindowSize();
  const isSidebarVisible = !!width ? width > 1149 : undefined;
  const listingsURL = `${pathname}${search}`;

  useEffect(() => {
    if (isSidebarVisible !== undefined && hash === '#open-modal') {
      navigate(listingsURL, { replace: true });

      setTimeout(() => {
        isSidebarVisible ? openCollapsibleSidebars() : toggleSearchModal();
      });
    }
  }, [isSidebarVisible !== undefined, hash]);
};

/**
 * Encapsulates the redirect and open filters logic for when the user clicks on the advanced filters button in the navigation bar.
 */
export const useToggleFormOrRedirect = () => {
  const region = useRegion();
  const category = useCategory();
  const location = useLocation();
  const isListingsPage =
    location.pathname.includes('/c/') && !location.pathname.includes('/traits');
  const href = `/${region}/c/${category?.path}#open-modal`;
  const { width } = useWindowSize();
  const isSidebarVisible = !!width ? width > 1149 : undefined;
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    isSidebarVisible ? openCollapsibleSidebars() : toggleSearchModal();
  };

  return {
    href,
    ...(isListingsPage ? { onClick } : {}),
  };
};
