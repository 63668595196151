/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Unknown` - Unknown
 * * `Live` - Live
 * * `Frozen/Thawed` - Frozen/Thawed
 * * `Pre-killed` - Pre-killed
 * @export
 */
export const PreyStatusDisplayEnum = {
    Unknown: 'Unknown',
    Live: 'Live',
    FrozenThawed: 'Frozen/Thawed',
    PreKilled: 'Pre-killed'
} as const;
export type PreyStatusDisplayEnum = typeof PreyStatusDisplayEnum[keyof typeof PreyStatusDisplayEnum];


export function PreyStatusDisplayEnumFromJSON(json: any): PreyStatusDisplayEnum {
    return PreyStatusDisplayEnumFromJSONTyped(json, false);
}

export function PreyStatusDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreyStatusDisplayEnum {
    return json as PreyStatusDisplayEnum;
}

export function PreyStatusDisplayEnumToJSON(value?: PreyStatusDisplayEnum | null): any {
    return value as any;
}

