import { CATEGORY_FILTER, categories } from 'components/Common/Filters/CategoryFilter/definitions';
import {
  IS_ACTIVE_ACTIVE,
  IS_ACTIVE_EXPIRED,
  IS_ACTIVE_FILTER,
  IS_ACTIVE_INACTIVE,
} from 'components/Common/Filters/IsActiveFilter/definitions';
import { SEARCH_FILTER } from 'components/Common/Filters/SearchFilter/definitions';
import {
  STATE_ARCHIVED,
  STATE_FILTER,
  STATE_FOR_SALE,
  STATE_LOANED,
  STATE_NOT_FOR_SALE,
  STATE_ON_HOLD,
  STATE_SOLD,
  STATE_BREEDERS,
  STATE_PETS,
  STATE_HOLDBACKS,
} from 'components/Common/Filters/StatusFilter/definitions';
import {
  EXCLUDING_TAGS_FILTER,
  INCLUDING_TAGS_FILTER,
} from 'components/Common/Filters/TagsFilter/definitions';
import {
  INCLUDING_TRAITS_FILTER,
  EXCLUDING_TRAITS_FILTER,
} from 'components/Common/Filters/TraitsFilter/definitions';
import {
  VISIBILITY_FILTER,
  VISIBILITY_PUBLIC,
  VISIBILITY_PRIVATE,
  VISIBILITY_UNLISTED,
} from 'components/Common/Filters/VisibilityFilter/definitions';
import { ANIMAL_ID_FILTER } from 'components/Inventory/Filters/AnimalIdFilter/definitions';
import {
  DIET_FOOD_FILTER,
  DIET_FORM_FILTER,
  foodOptions as dietFoodOptions,
  formOptions as dietFormOptions,
} from 'components/Inventory/Filters/DietFilter/definitions';
import { GROUP_ID_FILTER } from 'components/Inventory/Filters/GroupIdFilter/definitions';
import {
  MATURITY_ADULT,
  MATURITY_BABY,
  MATURITY_FILTER,
  MATURITY_JUVENILE,
  MATURITY_NOT_READY,
  MATURITY_SUBADULT,
  MATURITY_UNKNOWN,
} from 'components/Inventory/Filters/MaturityFilter/definitions';
import { DAM_FILTER, SIRE_FILTER } from 'components/Inventory/Filters/ParentsFilter/definitions';
import {
  PRICE_MIN_FILTER,
  PRICE_MAX_FILTER,
} from 'components/Inventory/Filters/PriceFilter/definitions';
import {
  SEX_FILTER,
  SEX_MALE,
  SEX_FEMALE,
  SEX_UNKNOWN,
  SEX_MIXED,
} from 'components/Inventory/Filters/SexFilter/definitions';
import {
  STATUS_ACTIVE_COLLECTIONS,
  STATUS_COLLECTION,
  STATUS_EXPIRED,
  STATUS_FILTER,
  STATUS_FOR_SALE,
  STATUS_INACTIVE,
  STATUS_LIVE_LISTINGS,
  STATUS_LOANED,
  STATUS_NOT_ACTIVE_COLLECTIONS,
  STATUS_NOT_FOR_SALE,
  STATUS_NOT_LIVE_LISTINGS,
  STATUS_ON_HOLD,
  STATUS_SOLD,
  STATUS_NEEDS_UPDATE,
  STATUS_PURCHASED,
  STATUS_BREEDERS,
  STATUS_HOLDBACKS,
  STATUS_PETS,
} from 'components/Inventory/Filters/StatusMenu/definitions';
import {
  TRAIT_COUNT_MIN_FILTER,
  TRAIT_COUNT_MAX_FILTER,
} from 'components/Inventory/Filters/TraitCountFilter/definitions';
import {
  WEIGHT_MIN_FILTER,
  WEIGHT_MAX_FILTER,
} from 'components/Inventory/Filters/WeightFilter/definitions';
import { PAGE_FILTER, PER_PAGE_FILTER } from 'components/ResultsCounter/definitions';
import { CARD_VIEW, LIST_VIEW } from 'components/ViewMenu/definitions';
import inventoryOrdering, { LAST_UPDATED_ORDERING } from 'api/inventory/ordering';
import { ORDERING_FILTER, DESCENDING, orderingToParam } from 'api/ordering';
import preferences from 'services/preferences';
import { INVENTORY_VIEW_PREFERENCE } from 'services/preferences/definitions';
import {
  TRANSFER_FILTER,
  TRANSFER_NOT_TRANSFERRED,
  TRANSFER_TRANSFERRED,
} from '../../components/Inventory/Filters/TransferFilter/definitions';
import {
  DEFAULT_RESULTS_PER_PAGE_INVENTORY,
  DEFAULT_RESULTS_PER_PAGE_INVENTORY_GRID,
} from 'config';

export interface InventoryFilters {
  [key: string]: {
    apiParam: string;
    values?: Map<string, string>;
    unserialize?: (value: string) => number;
    defaultValue?: () => string | number | symbol;
  };
}

const inventoryFilters = {
  // Ordering
  [ORDERING_FILTER]: {
    apiParam: 'ordering',
    values: inventoryOrdering.map(({ param }) => param),
    defaultValue: () => orderingToParam(inventoryOrdering, LAST_UPDATED_ORDERING, DESCENDING),
  },

  // Pagination
  [PAGE_FILTER]: {
    apiParam: 'page',
    unserialize: Number,
    defaultValue: () => 1,
  },
  [PER_PAGE_FILTER]: {
    apiParam: 'page_size',
    unserialize: Number,
    defaultValue: () => {
      const view = preferences.get(INVENTORY_VIEW_PREFERENCE) || LIST_VIEW;

      if (view === CARD_VIEW) {
        return DEFAULT_RESULTS_PER_PAGE_INVENTORY_GRID;
      } else {
        return DEFAULT_RESULTS_PER_PAGE_INVENTORY;
      }
    },
  },

  // Search
  [SEARCH_FILTER]: {
    apiParam: 'search',
  },

  // Status
  [STATUS_FILTER]: {
    apiParam: 'status',
    values: new Map([
      [STATUS_ACTIVE_COLLECTIONS, 'active_collection'],
      [STATUS_COLLECTION, 'collection'],
      [STATUS_EXPIRED, 'expired'],
      [STATUS_FOR_SALE, 'for_sale'],
      [STATUS_INACTIVE, 'inactive'],
      [STATUS_LIVE_LISTINGS, 'live_listings'],
      [STATUS_LOANED, 'loaned'],
      [STATUS_NOT_ACTIVE_COLLECTIONS, 'not_active_collection'],
      [STATUS_NOT_FOR_SALE, 'nfs'],
      [STATUS_NOT_LIVE_LISTINGS, 'not_live_listings'],
      [STATUS_ON_HOLD, 'on_hold'],
      [STATUS_SOLD, 'sold'],
      [STATUS_NEEDS_UPDATE, 'needs_update'],
      [STATUS_PURCHASED, 'purchased'],
      [STATUS_BREEDERS, 'breeder'],
      [STATUS_HOLDBACKS, 'holdback'],
      [STATUS_PETS, 'pet'],
    ]),
  },

  // State
  [STATE_FILTER]: {
    apiParam: 'state',
    values: new Map([
      [STATE_ARCHIVED, 'archived'],
      [STATE_FOR_SALE, 'for_sale'],
      [STATE_LOANED, 'loaned'],
      [STATE_NOT_FOR_SALE, 'nfs'],
      [STATE_ON_HOLD, 'on_hold'],
      [STATE_SOLD, 'sold'],
      [STATE_BREEDERS, 'breeder'],
      [STATE_HOLDBACKS, 'holdback'],
      [STATE_PETS, 'pet'],
      [STATUS_NEEDS_UPDATE, 'needs_update'],
    ]),
  },

  // Visibility
  [VISIBILITY_FILTER]: {
    apiParam: 'visibility',
    values: new Map([
      [VISIBILITY_PRIVATE, 'private'],
      [VISIBILITY_PUBLIC, 'public'],
      [VISIBILITY_UNLISTED, 'unlisted'],
    ]),
  },

  // Is Active
  [IS_ACTIVE_FILTER]: {
    apiParam: 'active',
    values: new Map([
      [IS_ACTIVE_ACTIVE, 'active'],
      [IS_ACTIVE_EXPIRED, 'expired'],
      [IS_ACTIVE_INACTIVE, 'inactive'],
    ]),
  },

  // Category
  [CATEGORY_FILTER]: {
    apiParam: 'category',
    values: new Map(categories.map(({ id, value }) => [id, value])),
  },

  // Sex
  [SEX_FILTER]: {
    apiParam: 'sex',
    values: new Map([
      [SEX_FEMALE, 'female'],
      [SEX_MALE, 'male'],
      [SEX_UNKNOWN, 'unknown'],
      [SEX_MIXED, 'mixed'],
    ]),
  },

  // Weight
  [WEIGHT_MIN_FILTER]: {
    apiParam: 'weight__min',
    unserialize: Number,
  },
  [WEIGHT_MAX_FILTER]: {
    apiParam: 'weight__max',
    unserialize: Number,
  },

  // Maturity
  [MATURITY_FILTER]: {
    apiParam: 'maturity__in',
    values: new Map([
      [MATURITY_ADULT, 'adult'],
      [MATURITY_BABY, 'baby'],
      [MATURITY_JUVENILE, 'juvenile'],
      [MATURITY_SUBADULT, 'subadult'],
      [MATURITY_NOT_READY, 'not_ready'],
      [MATURITY_UNKNOWN, 'unknown'],
    ]),
  },

  // Animal ID
  [ANIMAL_ID_FILTER]: {
    apiParam: 'animal_id',
  },

  // Parents
  [DAM_FILTER]: {
    apiParam: 'dam_id',
  },
  [SIRE_FILTER]: {
    apiParam: 'sire_id',
  },

  // Clutch ID
  [GROUP_ID_FILTER]: {
    apiParam: 'clutch_id',
  },

  // Price
  [PRICE_MIN_FILTER]: {
    apiParam: 'price__min',
    unserialize: Number,
  },
  [PRICE_MAX_FILTER]: {
    apiParam: 'price__max',
    unserialize: Number,
  },

  // Diet
  [DIET_FOOD_FILTER]: {
    apiParam: 'prey_food',
    values: dietFoodOptions.map(([value]) => value),
  },
  [DIET_FORM_FILTER]: {
    apiParam: 'prey_status',
    values: dietFormOptions.map(([value]) => value),
  },

  // Trait Count
  [TRAIT_COUNT_MIN_FILTER]: {
    apiParam: 'trait_count__min',
    unserialize: Number,
  },
  [TRAIT_COUNT_MAX_FILTER]: {
    apiParam: 'trait_count__max',
    unserialize: Number,
  },

  // Traits
  [INCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__in',
  },
  [EXCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__not__in',
  },

  // Tags
  [INCLUDING_TAGS_FILTER]: {
    apiParam: 'tags__in',
  },
  [EXCLUDING_TAGS_FILTER]: {
    apiParam: 'tags__not__in',
  },

  // Transfer State
  [TRANSFER_FILTER]: {
    apiParam: 'transferred',
    values: new Map([
      [TRANSFER_TRANSFERRED, 'transferred'],
      [TRANSFER_NOT_TRANSFERRED, 'not_transferred'],
    ]),
  },

  [INVENTORY_VIEW_PREFERENCE]: {
    apiParam: 'view',
    values: new Map([
      [CARD_VIEW, 'card'],
      [LIST_VIEW, 'list'],
    ]),
    defaultValue: () => {
      const view = preferences.get(INVENTORY_VIEW_PREFERENCE) || LIST_VIEW;
      return view;
    },
  },
};

export default inventoryFilters;

export type InventoryFilterSymbol = keyof typeof inventoryFilters;
