import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './NotesIcon.module.scss';

const NotesIcon = ({ notes, className, onClose }) =>
  notes && (
    <FontAwesomeIcon
      icon={faFileAlt}
      data-tip={notes}
      className={classNames(style.icon, className)}
    />
  );

NotesIcon.propTypes = {
  notes: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default NotesIcon;
