/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `for_sale` - For Sale
 * * `on_hold` - On Hold
 * * `sold` - Sold
 * * `nfs` - Not For Sale
 * * `breeder` - Breeder
 * * `holdback` - Holdback
 * * `pet` - Pet
 * * `loaned` - Loaned
 * * `archived` - Archived
 * * `on_hold_auction` - On Hold - Auction
 * * `sold_auction` - Sold - Auction
 * @export
 */
export const StateEnum = {
    ForSale: 'for_sale',
    OnHold: 'on_hold',
    Sold: 'sold',
    Nfs: 'nfs',
    Breeder: 'breeder',
    Holdback: 'holdback',
    Pet: 'pet',
    Loaned: 'loaned',
    Archived: 'archived',
    OnHoldAuction: 'on_hold_auction',
    SoldAuction: 'sold_auction'
} as const;
export type StateEnum = typeof StateEnum[keyof typeof StateEnum];


export function StateEnumFromJSON(json: any): StateEnum {
    return StateEnumFromJSONTyped(json, false);
}

export function StateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateEnum {
    return json as StateEnum;
}

export function StateEnumToJSON(value?: StateEnum | null): any {
    return value as any;
}

