import { RouteObject } from 'react-router-dom';

const EditOffspringGroup = () => import('pages/EditOffspringGroup/EditOffspringGroup');
const OffspringPublic = () => import('pages/Offspring/OffspringPublic');
const OffspringPrivate = () => import('pages/Offspring/OffspringPrivate');
const PublicOffspringGroup = () => import('pages/PublicOffspringGroup/PublicOffspringGroup');

export const offspringRoutes: RouteObject[] = [
  {
    path: 'offspring',
    children: [
      { path: '', lazy: OffspringPrivate },
      { path: 'add', lazy: EditOffspringGroup },
      {
        path: ':offspringId',
        async lazy() {
          const { Component } = await EditOffspringGroup();
          return { element: <Component readonly /> };
        },
      },
      { path: ':offspringId/edit', lazy: EditOffspringGroup },
    ],
  },
  {
    path: 'users/:username/offspring',
    children: [
      { path: '', lazy: OffspringPublic },
      { path: ':offspringId', lazy: PublicOffspringGroup },
    ],
  },
  {
    path: 'stores/:storeUsername/offspring',
    children: [
      { path: '', lazy: OffspringPublic },
      { path: ':offspringId', lazy: PublicOffspringGroup },
    ],
  },
];
