import { ErrorPage } from 'pages/ErrorPage/ErrorPage';

const refresh = () => window.location.reload();

interface Error500Props {
  errorName?: string;
  errorMessage?: string;
}

const Error500 = ({ errorName, errorMessage }: Error500Props) => {
  const detail = `If you're talking with support, send them this info: "${errorName}: ${errorMessage}"`;

  return (
    <ErrorPage
      title="Something Went Wrong"
      text="Oops, that wasn't supposed to happen!"
      detail={detail}
      img="https://d20zyr0oabjxc2.cloudfront.net/static/img/server-error-images/500.svg"
      imgAlt="Something Went Wrong"
      buttonText="Try Again"
      buttonAction={refresh}
    />
  );
};

export default Error500;
