import React from 'react';
import { faGenderless } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './SexUnknown.module.scss';

interface SexUnknownProps {
  className?: string;
}

const SexUnknown: React.FC<SexUnknownProps> = ({ className, ...props }) => (
  <FontAwesomeIcon icon={faGenderless} className={classNames(style.icon, className)} {...props} />
);

export default SexUnknown;
