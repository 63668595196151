// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.defaultSize--vumN8{font-size:1.3rem}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Icons/FilterIcon/FilterIcon.module.scss"],"names":[],"mappings":"AAGA,oBACI,gBAJa","sourcesContent":["$filterIconSize: 1.3rem;\n\n// To maintain the size proportion with other view icons.\n.defaultSize {\n    font-size: $filterIconSize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultSize": `defaultSize--vumN8`
};
export default ___CSS_LOADER_EXPORT___;
