import { CATEGORY_FILTER } from 'components/Common/Filters/CategoryFilter/definitions';
import { STATE_FILTER } from 'components/Common/Filters/StatusFilter/definitions';
import {
  EXCLUDING_TRAITS_FILTER,
  INCLUDING_TRAITS_FILTER,
} from 'components/Common/Filters/TraitsFilter/definitions';
import { MATURITY_FILTER } from 'components/Inventory/Filters/MaturityFilter/definitions';
import {
  TRAIT_COUNT_MAX_FILTER,
  TRAIT_COUNT_MIN_FILTER,
} from 'components/Inventory/Filters/TraitCountFilter/definitions';
import { PAGE_FILTER, PER_PAGE_FILTER } from 'components/ResultsCounter/definitions';
import {
  ANY_TRAIT_FORM_FILTER,
  NO_ADDITIONAL_TRAITS_FILTER,
} from 'components/Search/Filters/GenesAndTraitsFilter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import { NEAR_TO_COORDS_FILTER } from 'components/Search/Filters/NearToFilter/definitions';
import { PAYMENT_METHOD_FILTER } from 'components/Search/Filters/PaymentOptionsFilter/definitions';
import { ORDERING_FILTER } from 'api/ordering';
import { SearchFilterSymbol } from 'api/search/filters';

export const lockedFilters = [
  CATEGORY_FILTER,
  LOCATION_FILTER,
  ORDERING_FILTER,
  PER_PAGE_FILTER,
  PAGE_FILTER,
  STATE_FILTER,
  NEAR_TO_COORDS_FILTER,
];

/**
 * List of filters to remove when the animal category is changed on AS.
 */
export const removeOnCategoryChange = [
  INCLUDING_TRAITS_FILTER,
  EXCLUDING_TRAITS_FILTER,
  ANY_TRAIT_FORM_FILTER,
  NO_ADDITIONAL_TRAITS_FILTER,
  TRAIT_COUNT_MIN_FILTER,
  TRAIT_COUNT_MAX_FILTER,
  PAGE_FILTER,
];

/**
 * List of filters that can be applied multiple times with different values.
 */
export const multiValueFilters: SearchFilterSymbol[] = [
  MATURITY_FILTER,
  PAYMENT_METHOD_FILTER,
  INCLUDING_TRAITS_FILTER,
  EXCLUDING_TRAITS_FILTER,
];
