import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      // Making modal full screen on mobile.
      [theme.breakpoints.down('mobileLarge')]: {
        width: '100%',
        minHeight: '100%',
        margin: 0,
        borderRadius: 0,
      },
    }),
  },
};
