import {
  NEAR_TO_COORDS_FILTER,
  NEAR_TO_DISTANCE_FILTER,
} from 'components/Search/Filters/NearToFilter/definitions';
import { ORDERING_FILTER } from 'api/ordering';
import EntriesJar from 'services/EntriesJar';

// This class should extend the abstract EntriesJar class with business rules
// ensuring consistent filter state.
export default class FiltersJar extends EntriesJar {
  #defaults;

  constructor(entries = [], defaults = []) {
    super(entries);
    this.#defaults = new Map(defaults);
  }

  get(key) {
    const value = super.get(key);

    if (value != null) {
      return value;
    }

    return this.getDefault(key);
  }

  setEntries(entries) {
    return new FiltersJar(entries, this.#defaults);
  }

  getDefaults() {
    return this.#defaults;
  }

  getDefault(key) {
    return this.#defaults.get(key) ?? null;
  }

  setDefaults(defaults) {
    return new FiltersJar(this.getEntries(), defaults);
  }

  // Test carefully when extending this because it's possible to get Maximum call stack size exceeded errors.
  removeEntry(key, value) {
    // When removing coordinates, we also need to remove "dependent" filters
    // such as NEAR_TO_DISTANCE_FILTER and ORDERING_FILTER. Otherwise the
    // system will enter an inconsistent state (e.g. "sort by distance without knowing
    // where the user is") and this will cause errors. See #4653.
    // FIXME: This is probably not the correct place for this 
    if (
      [NEAR_TO_COORDS_FILTER, NEAR_TO_DISTANCE_FILTER].includes(key) &&
      !this.get(ORDERING_FILTER) == 'distance'
    ) {
      return this.remove(NEAR_TO_DISTANCE_FILTER)
        .remove(NEAR_TO_COORDS_FILTER)
        .removeEntry(ORDERING_FILTER, 'distance');
    } else {
      return super.removeEntry(key, value);
    }
  }
}
