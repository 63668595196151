import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { FIRST_LISTED_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';
export const FIRST_LISTED_COLUMN = Symbol('FIRST_LISTED_COLUMN');

const firstListedColumn = {
  id: FIRST_LISTED_COLUMN,
  ordering: FIRST_LISTED_ORDERING,
  defaultDirection: DESCENDING,
  label: 'First Listed',
  hideable: true,
  className: style.firstListedColumn,

  render: ({ first_listed }: { first_listed: string }) => first_listed,
};

export default firstListedColumn;
