import { useEffect, useRef } from 'react';

/**
 * When the Safari Mobile virtual keyboard is opened and the user scrolls, weird visual glitches happen.
 * This custom hook is meant to resolve those glitches by bluring the element
 * if the user tries to scroll the page while the virtual keyboard is open.
 */
export const useSafariMobileScrollFix = (ref?: HTMLInputElement) => {
  const initialHeight = useRef(window?.visualViewport?.height);
  const currentHeight = window?.visualViewport?.height;
  const isVirtualKeyboardOpen =
    initialHeight.current && currentHeight && initialHeight.current > currentHeight;
  const blurOnScroll = () => {
    (document.activeElement as HTMLElement)?.blur();
    [...document.querySelectorAll('input')].map((e) => e.blur());
    window.removeEventListener('scroll', blurOnScroll);
  };

  useEffect(() => {
    const isSearchInputFocused = !!document.activeElement && document.activeElement === ref;
    if (isVirtualKeyboardOpen && isSearchInputFocused) {
      window.addEventListener('scroll', blurOnScroll);
    }
    return () => window.removeEventListener('scroll', blurOnScroll);
  }, [isVirtualKeyboardOpen]);
};

/**
 * Toggles the pointer-events CSS style to avoid access issues while the search is hidden.
 */
export const togglePointerEventsProp: React.TransitionEventHandler<HTMLElement> = (e) => {
  const target = e.target as HTMLDivElement;
  const isVisible = target.style.opacity === '1';
  target.style.pointerEvents = !isVisible ? 'none' : '';
};
