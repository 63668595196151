// The most commonly used values for margins and paddings are listed here
// Values in comments are sizes in pixels assuming 1rem = 16px

export default {
  xxxl: '2.5rem', // 40px
  xxl: '2rem', // 32px
  xl: '1.5rem', // 24px
  lg: '1.25rem', // 20px
  md: '1rem', // 16px
  sm: '0.875rem', // 14px
  xs: '0.75rem', // 12px
  xxs: '0.5rem', // 8px
  xxxs: '0.25rem', // 4px
  xxxxs: '0.125rem', // 2px
  mobileHeaderHeight: '3rem', // 48px
} as const;
