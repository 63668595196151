/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DisplayPreferencesRetrieve,
  DisplayPreferencesRetrieveFromJSON,
  DisplayPreferencesRetrieveToJSON,
  DisplayPreferencesUpdate,
  DisplayPreferencesUpdateFromJSON,
  DisplayPreferencesUpdateToJSON,
  DisplayPreferencesUpdateRequest,
  DisplayPreferencesUpdateRequestFromJSON,
  DisplayPreferencesUpdateRequestToJSON,
  LabelConfig,
  LabelConfigFromJSON,
  LabelConfigToJSON,
  LabelConfigRequest,
  LabelConfigRequestFromJSON,
  LabelConfigRequestToJSON,
  PaginatedLabelConfigList,
  PaginatedLabelConfigListFromJSON,
  PaginatedLabelConfigListToJSON,
  PatchedDisplayPreferencesUpdateRequest,
  PatchedDisplayPreferencesUpdateRequestFromJSON,
  PatchedDisplayPreferencesUpdateRequestToJSON,
  PatchedLabelConfigRequest,
  PatchedLabelConfigRequestFromJSON,
  PatchedLabelConfigRequestToJSON,
} from "../models";

export interface PreferencesDisplayPartialUpdateRequest {
  /** @deprecated Use request instead. **/
  patchedDisplayPreferencesUpdateRequest?: PatchedDisplayPreferencesUpdateRequest;
  request?: PatchedDisplayPreferencesUpdateRequest;
}

export interface PreferencesDisplayUpdateRequest {
  /** @deprecated Use request instead. **/
  displayPreferencesUpdateRequest?: DisplayPreferencesUpdateRequest;
  request: DisplayPreferencesUpdateRequest;
}

export interface PreferencesLabelConfigsCreateRequest {
  /** @deprecated Use request instead. **/
  labelConfigRequest?: LabelConfigRequest;
  request: LabelConfigRequest;
}

export interface PreferencesLabelConfigsDestroyRequest {
  id: number;
}

export interface PreferencesLabelConfigsListRequest {
  limit?: number;
  offset?: number;
}

export interface PreferencesLabelConfigsPartialUpdateRequest {
  id: number;
  /** @deprecated Use request instead. **/
  patchedLabelConfigRequest?: PatchedLabelConfigRequest;
  request?: PatchedLabelConfigRequest;
}

export interface PreferencesLabelConfigsRetrieveRequest {
  id: number;
}

export interface PreferencesLabelConfigsSampleRetrieveRequest {
  id: number;
}

export interface PreferencesLabelConfigsUpdateRequest {
  id: number;
  /** @deprecated Use request instead. **/
  labelConfigRequest?: LabelConfigRequest;
  request: LabelConfigRequest;
}

/**
 *
 */
export class PreferencesApi extends runtime.BaseAPI {
  /**
   */
  async preferencesDisplayPartialUpdateRaw(
    requestParameters: PreferencesDisplayPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DisplayPreferencesUpdate>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/display/`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedDisplayPreferencesUpdateRequestToJSON(requestParameters.request || requestParameters.patchedDisplayPreferencesUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DisplayPreferencesUpdateFromJSON(jsonValue));
  }

  /**
   */
  async preferencesDisplayPartialUpdate(
    requestParameters: PreferencesDisplayPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<DisplayPreferencesUpdate> {
    const response = await this.preferencesDisplayPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesDisplayRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayPreferencesRetrieve>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/display/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DisplayPreferencesRetrieveFromJSON(jsonValue));
  }

  /**
   */
  async preferencesDisplayRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayPreferencesRetrieve> {
    const response = await this.preferencesDisplayRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesDisplayUpdateRaw(
    requestParameters: PreferencesDisplayUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DisplayPreferencesUpdate>> {
    if (
      (requestParameters.request || requestParameters.displayPreferencesUpdateRequest) === null ||
      (requestParameters.request || requestParameters.displayPreferencesUpdateRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "displayPreferencesUpdateRequest",
        "Required parameter (requestParameters.request || requestParameters.displayPreferencesUpdateRequest) was null or undefined when calling preferencesDisplayUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/display/`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: DisplayPreferencesUpdateRequestToJSON(requestParameters.request || requestParameters.displayPreferencesUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DisplayPreferencesUpdateFromJSON(jsonValue));
  }

  /**
   */
  async preferencesDisplayUpdate(requestParameters: PreferencesDisplayUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayPreferencesUpdate> {
    const response = await this.preferencesDisplayUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This is overriden so we can force the overwriting of an existing LabelConfig for a user, in such a way that we can enforce that only one LabelConfig exists, while being ready for the moment when we WILL support more than one LabelConfig / user.
   */
  async preferencesLabelConfigsCreateRaw(
    requestParameters: PreferencesLabelConfigsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LabelConfig>> {
    if ((requestParameters.request || requestParameters.labelConfigRequest) === null || (requestParameters.request || requestParameters.labelConfigRequest) === undefined) {
      throw new runtime.RequiredError(
        "labelConfigRequest",
        "Required parameter (requestParameters.request || requestParameters.labelConfigRequest) was null or undefined when calling preferencesLabelConfigsCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LabelConfigRequestToJSON(requestParameters.request || requestParameters.labelConfigRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LabelConfigFromJSON(jsonValue));
  }

  /**
   * This is overriden so we can force the overwriting of an existing LabelConfig for a user, in such a way that we can enforce that only one LabelConfig exists, while being ready for the moment when we WILL support more than one LabelConfig / user.
   */
  async preferencesLabelConfigsCreate(requestParameters: PreferencesLabelConfigsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LabelConfig> {
    const response = await this.preferencesLabelConfigsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesLabelConfigsDestroyRaw(
    requestParameters: PreferencesLabelConfigsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling preferencesLabelConfigsDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async preferencesLabelConfigsDestroy(requestParameters: PreferencesLabelConfigsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.preferencesLabelConfigsDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async preferencesLabelConfigsListRaw(
    requestParameters: PreferencesLabelConfigsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedLabelConfigList>> {
    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedLabelConfigListFromJSON(jsonValue));
  }

  /**
   */
  async preferencesLabelConfigsList(
    requestParameters: PreferencesLabelConfigsListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedLabelConfigList> {
    const response = await this.preferencesLabelConfigsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesLabelConfigsPartialUpdateRaw(
    requestParameters: PreferencesLabelConfigsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LabelConfig>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling preferencesLabelConfigsPartialUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedLabelConfigRequestToJSON(requestParameters.request || requestParameters.patchedLabelConfigRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LabelConfigFromJSON(jsonValue));
  }

  /**
   */
  async preferencesLabelConfigsPartialUpdate(
    requestParameters: PreferencesLabelConfigsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LabelConfig> {
    const response = await this.preferencesLabelConfigsPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesLabelConfigsRetrieveRaw(
    requestParameters: PreferencesLabelConfigsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LabelConfig>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling preferencesLabelConfigsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LabelConfigFromJSON(jsonValue));
  }

  /**
   */
  async preferencesLabelConfigsRetrieve(
    requestParameters: PreferencesLabelConfigsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LabelConfig> {
    const response = await this.preferencesLabelConfigsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesLabelConfigsSampleRetrieveRaw(
    requestParameters: PreferencesLabelConfigsSampleRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling preferencesLabelConfigsSampleRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/{id}/sample/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async preferencesLabelConfigsSampleRetrieve(
    requestParameters: PreferencesLabelConfigsSampleRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Blob> {
    const response = await this.preferencesLabelConfigsSampleRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async preferencesLabelConfigsUpdateRaw(
    requestParameters: PreferencesLabelConfigsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LabelConfig>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling preferencesLabelConfigsUpdate.");
    }

    if ((requestParameters.request || requestParameters.labelConfigRequest) === null || (requestParameters.request || requestParameters.labelConfigRequest) === undefined) {
      throw new runtime.RequiredError(
        "labelConfigRequest",
        "Required parameter (requestParameters.request || requestParameters.labelConfigRequest) was null or undefined when calling preferencesLabelConfigsUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/preferences/label_configs/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: LabelConfigRequestToJSON(requestParameters.request || requestParameters.labelConfigRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LabelConfigFromJSON(jsonValue));
  }

  /**
   */
  async preferencesLabelConfigsUpdate(requestParameters: PreferencesLabelConfigsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LabelConfig> {
    const response = await this.preferencesLabelConfigsUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
