import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { CLICKS_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip';
import { Animal } from 'types';

export const CLICKS_COLUMN = Symbol('CLICKS_COLUMN');

const clicksColumn = {
  id: CLICKS_COLUMN,
  ordering: CLICKS_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Clicks',
  hideable: true,
  className: style.countColumn,
  labelHtml: () => (
    <ColumnTooltip
      text="The information displayed here is refreshed once every 24 hours. Keep in mind that the data you see may not reflect real-time changes."
      icon={faInfoCircle}
    />
  ),
  render: ({ click_count }: Animal) => click_count,
};

export default clicksColumn;
