export const AVAILABLE_FOR_ORDER_COLUMN = Symbol('AVAILABLE_FOR_ORDER_COLUMN');
import { Checkbox } from '@mui/material';
import { AVAILABLE_FOR_ORDER_ORDERING } from 'api/inventory/ordering';
import { enableBuyNowText } from 'services/phrase';

const availableForOrderColumn = {
  id: AVAILABLE_FOR_ORDER_COLUMN,
  ordering: AVAILABLE_FOR_ORDER_ORDERING,
  label: enableBuyNowText,
  hideable: true,
  render: ({ available_for_order }: { available_for_order: boolean }) => {
    return <Checkbox disabled checked={available_for_order} />;
  },
};

export default availableForOrderColumn;
