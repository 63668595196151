import { useMemo } from 'react';
import { EnumsBucket } from 'constants/enums';
import { useAuth } from 'hooks';
import { useCategoryDrawerOptions } from 'hooks/utils/useCategoryDrawerOptions';
import { MobileGuestLinks, NavbarLinks } from '../../../components';
const { main_calculators_menu } = EnumsBucket;

/**
 * Generates an array of options for the mobile navigation menu.
 */
export const useNavigationOptions = () => {
  const { isLoggedIn } = useAuth();
  const categories = useCategoryDrawerOptions();
  const links = NavbarLinks(categories, main_calculators_menu?.links);
  const options = useMemo(
    () => [...(isLoggedIn ? [] : MobileGuestLinks), ...links],
    [categories, isLoggedIn, links]
  );

  return options;
};
