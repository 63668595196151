import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { useBackButton } from 'hooks/useBackButton/useBackButton';
import styles from './NavigationBackButton.module.scss';

export const NavigationBackButton: React.FC = () => {
  const { canGoBack, goBack } = useBackButton();

  return (
    <IconButton
      aria-label="Go Back to Previous Page"
      onClick={() => {
        if (canGoBack) {
          goBack();
        }
      }}
      className={classNames(styles.root, !canGoBack && styles.disabled)}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </IconButton>
  );
};
