/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BreedingPairingDetailsVisibility,
    BreedingPairingDetailsVisibilityFromJSON,
    BreedingPairingDetailsVisibilityFromJSONTyped,
    BreedingPairingDetailsVisibilityToJSON,
} from './BreedingPairingDetailsVisibility';

/**
 * 
 * @export
 * @interface BreedingPairingDetailsRequest
 */
export interface BreedingPairingDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsRequest
     */
    season: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsRequest
     */
    sireId: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsRequest
     */
    damId: number;
    /**
     * 
     * @type {BreedingPairingDetailsVisibility}
     * @memberof BreedingPairingDetailsRequest
     */
    visibility?: BreedingPairingDetailsVisibility | null;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsRequest
     */
    offspringGroupId?: number | null;
}

export function BreedingPairingDetailsRequestFromJSON(json: any): BreedingPairingDetailsRequest {
    return BreedingPairingDetailsRequestFromJSONTyped(json, false);
}

export function BreedingPairingDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingDetailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'season': json['season'],
        'sireId': json['sire_id'],
        'damId': json['dam_id'],
        'visibility': !exists(json, 'visibility') ? undefined : BreedingPairingDetailsVisibilityFromJSON(json['visibility']),
        'offspringGroupId': !exists(json, 'offspring_group_id') ? undefined : json['offspring_group_id'],
    };
}

export function BreedingPairingDetailsRequestToJSON(value?: BreedingPairingDetailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'season': value.season,
        'sire_id': value.sireId,
        'dam_id': value.damId,
        'visibility': BreedingPairingDetailsVisibilityToJSON(value.visibility),
        'offspring_group_id': value.offspringGroupId,
    };
}

