/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `unspecified` - Unspecified
 * * `yes` - Will Consider
 * * `no` - No Trades
 * @export
 */
export const AcceptTradesEnum = {
    Unspecified: 'unspecified',
    Yes: 'yes',
    No: 'no'
} as const;
export type AcceptTradesEnum = typeof AcceptTradesEnum[keyof typeof AcceptTradesEnum];


export function AcceptTradesEnumFromJSON(json: any): AcceptTradesEnum {
    return AcceptTradesEnumFromJSONTyped(json, false);
}

export function AcceptTradesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptTradesEnum {
    return json as AcceptTradesEnum;
}

export function AcceptTradesEnumToJSON(value?: AcceptTradesEnum | null): any {
    return value as any;
}

