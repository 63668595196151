import classNames from 'classnames';
import style from './DiodeIcon.module.scss';

export type DiodeIconProps = {
  isOn: boolean;
  className?: string;
  role?: string;
  tabIndex?: number;
};
const DiodeIcon: React.FC<DiodeIconProps> = ({ isOn, className, ...props }) => (
  <div
    className={classNames(
      style.icon,
      {
        [style.on]: isOn,
        [style.off]: !isOn,
      },
      className
    )}
    {...props}
  />
);

export default DiodeIcon;
