import { ActiveEnum } from 'api/sdk';
import { SymbolIndex } from '../../../../types';

export const IS_ACTIVE_FILTER = Symbol('IS_ACTIVE_FILTER');

export const IS_ACTIVE_ACTIVE = Symbol('IS_ACTIVE_ACTIVE');
export const IS_ACTIVE_INACTIVE = Symbol('IS_ACTIVE_INACTIVE');
export const IS_ACTIVE_EXPIRED = Symbol('IS_ACTIVE_EXPIRED');

export const isActiveNames: SymbolIndex = {
  [IS_ACTIVE_ACTIVE]: 'Active',
  [IS_ACTIVE_EXPIRED]: 'Expired',
  [IS_ACTIVE_INACTIVE]: 'Inactive',
};

// Ordered options
export const isActiveOptions = [IS_ACTIVE_ACTIVE, IS_ACTIVE_INACTIVE, IS_ACTIVE_EXPIRED];

export const activeEnumToSymbol = {
  [ActiveEnum.Active]: IS_ACTIVE_ACTIVE,
  [ActiveEnum.Inactive]: IS_ACTIVE_INACTIVE,
  [ActiveEnum.Expired]: IS_ACTIVE_EXPIRED,
};

export const activeSymbolToEnum: Record<symbol, ActiveEnum> = Object.fromEntries(
  Object.entries(activeEnumToSymbol).map(([key, value]) => [value, key])
);

export const ANY_ACTIVE = 'any';
