/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { AccountApi } from "../sdk/apis/AccountApi";
import { configuration } from "./configuration";

export const useAccount = () => {
  const instance = new AccountApi(configuration);

  const createAuctionConsent = useAPIHook(instance.accountAuctionConsentCreate.bind(instance));
  const getMe = useAPIHook(instance.accountMeRetrieve.bind(instance));
  const getPaymentInfo = useAPIHook(instance.accountPaymentInfoRetrieve.bind(instance));
  const updatePreferencesPartial = useAPIHook(instance.accountPreferencesPartialUpdate.bind(instance));

  return {
    error: createAuctionConsent.error || getMe.error || getPaymentInfo.error || updatePreferencesPartial.error,
    isLoading: createAuctionConsent.isLoading || getMe.isLoading || getPaymentInfo.isLoading || updatePreferencesPartial.isLoading,

    createAuctionConsent: createAuctionConsent.callback,
    getMe: getMe.callback,
    getPaymentInfo: getPaymentInfo.callback,
    updatePreferencesPartial: updatePreferencesPartial.callback,
  };
};
