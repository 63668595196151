import { ReactElement } from 'react';

export type WhenWrapperProps = {
  condition: boolean;
  children: ReactElement;
};

export const WhenWrapper: React.FC<WhenWrapperProps> = ({ children, condition }) => {
  if (condition) return children;
  return null;
};
