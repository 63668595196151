/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `unknown` - Unsexed
 * * `male` - Male
 * * `female` - Female
 * * `mixed` - Mixed
 * @export
 */
export const SexEnum = {
    Unknown: 'unknown',
    Male: 'male',
    Female: 'female',
    Mixed: 'mixed'
} as const;
export type SexEnum = typeof SexEnum[keyof typeof SexEnum];


export function SexEnumFromJSON(json: any): SexEnum {
    return SexEnumFromJSONTyped(json, false);
}

export function SexEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexEnum {
    return json as SexEnum;
}

export function SexEnumToJSON(value?: SexEnum | null): any {
    return value as any;
}

