import { useState } from 'react';
import { MiscInventoryDrawerName } from 'pages/Inventory/components/FiltersDrawer/enum';
import { DrawerAnchor } from 'components/experimental/Drawer/helper';
import { generateSingletonHook } from 'context/SingletonContext';
import { FilterDrawerController, useFilterDrawer } from 'hooks/useFilterDrawer';
import { InventoryTagsProvider } from './useTagFilter';

export const OPEN_INVENTORY_FILTERS_DRAWER = 'openInventoryFiltersDrawer';

type InventoryFilterDrawerController = FilterDrawerController & {
  otherMiscDrawer: MiscInventoryDrawerName | null;
  setOtherMiscDrawer: React.Dispatch<React.SetStateAction<MiscInventoryDrawerName | null>>;
};

const _useInventoryFilterDrawerInternal = () => {
  const { masterDrawer, dedicatedDrawer } = useFilterDrawer();
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [otherMiscDrawer, setOtherMiscDrawer] = useState<MiscInventoryDrawerName | null>(null);

  const onNavigateToMasterDrawer = () => {
    masterDrawer.navigateToSelf();
    setSelectedFilter(null);
    setOtherMiscDrawer(null);
  };

  const onNavigateToDedicateDrawer = (sections: string, anchor: DrawerAnchor = 'right') => {
    dedicatedDrawer.navigateToSelf(sections, anchor);
    setSelectedFilter(sections);
  };

  const closeAllDrawer = () => {
    masterDrawer.close();
    dedicatedDrawer.close();
    setOtherMiscDrawer(null);
  };

  return {
    otherMiscDrawer,
    setOtherMiscDrawer,
    selectedFilter,
    onSetSelectedFilter: setSelectedFilter,
    masterDrawer: {
      ...masterDrawer,
      navigateToSelf: onNavigateToMasterDrawer,
    },
    dedicatedDrawer: {
      ...dedicatedDrawer,
      navigateToSelf: onNavigateToDedicateDrawer,
    },
    closeAllDrawer,
  } as InventoryFilterDrawerController;
};

export const [_InventoryFilterDrawerProvider, useInventoryFilterDrawer] = generateSingletonHook(
  _useInventoryFilterDrawerInternal
);

export const InventoryFilterDrawerProvider: React.FC<{ children: object }> = ({ children }) => {
  return (
    <_InventoryFilterDrawerProvider>
      <InventoryTagsProvider>{children}</InventoryTagsProvider>
    </_InventoryFilterDrawerProvider>
  );
};

export const openInventoryFiltersDrawer = () =>
  document.dispatchEvent(new CustomEvent(OPEN_INVENTORY_FILTERS_DRAWER));
