/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnseenThreadCounts,
    UnseenThreadCountsFromJSON,
    UnseenThreadCountsFromJSONTyped,
    UnseenThreadCountsToJSON,
} from './UnseenThreadCounts';

/**
 * 
 * @export
 * @interface MarkSeenResponseUnseenThreadCounts
 */
export interface MarkSeenResponseUnseenThreadCounts {
    /**
     * 
     * @type {number}
     * @memberof MarkSeenResponseUnseenThreadCounts
     */
    readonly collection: number;
    /**
     * 
     * @type {number}
     * @memberof MarkSeenResponseUnseenThreadCounts
     */
    readonly offers: number;
    /**
     * 
     * @type {number}
     * @memberof MarkSeenResponseUnseenThreadCounts
     */
    readonly needsReply: number;
}

export function MarkSeenResponseUnseenThreadCountsFromJSON(json: any): MarkSeenResponseUnseenThreadCounts {
    return MarkSeenResponseUnseenThreadCountsFromJSONTyped(json, false);
}

export function MarkSeenResponseUnseenThreadCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkSeenResponseUnseenThreadCounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'collection': json['collection'],
        'offers': json['offers'],
        'needsReply': json['needs_reply'],
    };
}

export function MarkSeenResponseUnseenThreadCountsToJSON(value?: MarkSeenResponseUnseenThreadCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

