// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper--dF4v5{text-align:left}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Inventory/Columns/MaturityColumn/MaturityColumn.module.scss"],"names":[],"mappings":"AAAA,gBACE,eAAA","sourcesContent":[".wrapper {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper--dF4v5`
};
export default ___CSS_LOADER_EXPORT___;
