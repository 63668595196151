export const ORDERING_FILTER = Symbol('ORDERING_FILTER');

// Directions
export const ASCENDING = Symbol('ASCENDING');
export const DESCENDING = Symbol('DESCENDING');

export const orderingToParam = (variants, mode, direction) => {
  const variant = variants.find(
    (variant) => variant.mode === mode && variant.direction === direction
  );

  return variant?.param ?? null;
};

export const paramToOrdering = (variants, param) => {
  const variant = variants.find((variant) => variant.param === param);

  if (!variant) {
    return null;
  }

  const { mode, direction } = variant;

  return { mode, direction };
};
