import { Components, Theme } from '@mui/material';
import { colors, sizes } from '../constants';
const { gray000, gray100, white } = colors;
const { xxxs, xxs, xs, md, xxl } = sizes;
import typo from 'style/typo.scss';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedRounded: true;
    outlinedRounded: true;
  }
}

// Ready to use
export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: xxs,
      textTransform: 'none',
      boxShadow: 'none',
      textDecoration: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    endIcon: {
      fontSize: typo.md,
      svg: {
        fontSize: 'inherit !important', // override the default strong selector
      },
    },
    startIcon: {
      fontSize: typo.md,
      svg: {
        fontSize: 'inherit !important', // override the default strong selector
      },
    },
    sizeSmall: {
      padding: `${xxxs} ${xxs}`,
      height: 30,
      fontSize: typo.xs,
    },
    sizeMedium: {
      padding: `${xxs} ${xs}`,
      height: 35,
      fontSize: typo.sm,
    },
    sizeLarge: {
      padding: `${xxs} ${md}`,
      height: 40,
      fontSize: typo.md,
    },
    iconSizeSmall: {
      fontSize: typo.xxs,
    },
    iconSizeMedium: {
      fontSize: typo.sm,
    },
    iconSizeLarge: {
      fontSize: typo.md,
    },
    containedPrimary: {
      backgroundColor: gray000,
      color: white,
      '&:hover': {
        backgroundColor: gray100,
      },
    },
    outlinedPrimary: {
      borderColor: gray000,
      color: gray000,
      '&:hover': {
        borderColor: gray100,
        backgroundColor: white,
      },
    },
    textPrimary: {
      color: gray000,
      '&:hover': {
        backgroundColor: white,
      },
    },
  },
  variants: [
    {
      props: { variant: 'containedRounded' },
      style: {
        backgroundColor: gray000,
        color: white,
        '&:hover': {
          backgroundColor: gray100,
        },
        fontSize: typo.md,
        padding: `${md} ${xxl}`,
        borderRadius: 30,
        height: 48,
      },
    },
    {
      props: { variant: 'outlinedRounded' },
      style: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: gray000,
        color: gray000,
        '&:hover': {
          borderColor: gray100,
          backgroundColor: white,
        },
        fontSize: typo.md,
        padding: `${md} ${xxl}`,
        borderRadius: 30,
        height: 48,
      },
    },
  ],
};
