/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Unknown` - Unknown
 * * `Rat` - Rat
 * * `Mouse` - Mouse
 * * `Soft-furred Rat` - Soft-furred Rat
 * * `Mealworm` - Mealworm
 * * `Cricket` - Cricket
 * * `Roach` - Roach
 * * `Meal Replacement` - Meal Replacement
 * * `Dry Feed` - Dry Feed
 * * `Vegetables` - Vegetables
 * * `Quail` - Quail
 * * `Rabbit` - Rabbit
 * * `Chicken` - Chicken
 * * `Guinea Pig` - Guinea Pig
 * * `Pig` - Pig
 * * `Lamb` - Lamb
 * * `Goat` - Goat
 * * `Other` - Other
 * @export
 */
export const PreyFoodDisplayEnum = {
    Unknown: 'Unknown',
    Rat: 'Rat',
    Mouse: 'Mouse',
    SoftFurredRat: 'Soft-furred Rat',
    Mealworm: 'Mealworm',
    Cricket: 'Cricket',
    Roach: 'Roach',
    MealReplacement: 'Meal Replacement',
    DryFeed: 'Dry Feed',
    Vegetables: 'Vegetables',
    Quail: 'Quail',
    Rabbit: 'Rabbit',
    Chicken: 'Chicken',
    GuineaPig: 'Guinea Pig',
    Pig: 'Pig',
    Lamb: 'Lamb',
    Goat: 'Goat',
    Other: 'Other'
} as const;
export type PreyFoodDisplayEnum = typeof PreyFoodDisplayEnum[keyof typeof PreyFoodDisplayEnum];


export function PreyFoodDisplayEnumFromJSON(json: any): PreyFoodDisplayEnum {
    return PreyFoodDisplayEnumFromJSONTyped(json, false);
}

export function PreyFoodDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreyFoodDisplayEnum {
    return json as PreyFoodDisplayEnum;
}

export function PreyFoodDisplayEnumToJSON(value?: PreyFoodDisplayEnum | null): any {
    return value as any;
}

