import React, { useContext, useState } from 'react';
import { Modal } from 'components/experimental';
import { WhenWrapper } from 'components/experimental/Wrappers';
import { NativeAppReviewContext } from 'context/NativeAppReviewModalContext';
import { isAndroid } from '../../../../src/js/native-app/app-utils';
import { ModalBody } from './components/ModalBody';
import { ModalControl } from './components/ModalControl';
import { useHandleAppReviewForm } from './hooks/useHandleAppReviewForm';
import { useHandleAppReviewSubmit } from './hooks/useHandleAppReviewSubmit';
import style from './NativeAppReviewModal.module.scss';

export const ReviewModal: React.FC = () => {
  const title = 'Rate your experience';
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    isRated,
    feedback,
    userRating,
    errorMessage,
    disableSubmit,
    showControlButton,
    showRateInAppStore,
    labels: { cancelLabel, submitLabel },
    actions: {
      setUserRating,
      setFeedback,
      setTouched,
      setIsRated,
      onModalClose,
      handleDisableSubmit,
    },
  } = useHandleAppReviewForm({ submitted });

  const { handleSubmit } = useHandleAppReviewSubmit({
    isRated,
    review: feedback,
    rating: userRating,
    platform: isAndroid() ? 'android' : 'ios',
    showRateInAppStore,
    actions: {
      setSubmitted,
      setTouched,
      closeModal: onModalClose,
      handleDisableSubmit,
      setIsRated,
    },
  });

  const modalControlActions = {
    onClose: onModalClose,
    onSubmit: handleSubmit,
  };

  return (
    <Modal className={style.ratingModal} title={title} onClose={onModalClose}>
      <div className={style.ratingModalContent}>
        <div className={style.body}>
          <ModalBody
            isRated={isRated}
            submitted={submitted}
            errorMessage={errorMessage}
            showRateInAppStore={showRateInAppStore}
            actions={{ onUserRatingChange: setUserRating, onFeedbackChange: setFeedback }}
          />
        </div>
      </div>
      <ModalControl
        closeLabel={cancelLabel}
        submitLabel={submitLabel}
        disableSubmit={disableSubmit}
        actions={modalControlActions}
        showCloseOnly={!showControlButton}
      />
    </Modal>
  );
};

export const NativeAppReviewModal: React.FC = () => {
  const { showNativeAppReviewModal } = useContext(NativeAppReviewContext);
  return (
    <WhenWrapper condition={showNativeAppReviewModal}>
      <ReviewModal />
    </WhenWrapper>
  );
};
