import { EnumsBucket } from 'constants/enums';
import {
  CATEGORY_FILTER,
  allCategories,
  rootCategory,
} from 'components/Common/Filters/CategoryFilter/definitions';

import {
  PK_FILTER,
  ALERT_MATCH_FILTER,
  GROUP_MATCHES_BY_FILTER,
  SEARCH_FILTER,
} from 'components/Common/Filters/SearchFilter/definitions';
import {
  STATE_FILTER,
  STATE_FOR_SALE,
  STATE_SOLD,
  STATE_ANY,
  STATE_ON_HOLD,
  STATE_FOR_SALE_OR_ON_HOLD,
  STATE_HOLDBACKS,
  STATE_BREEDERS,
  STATE_PETS,
} from 'components/Common/Filters/StatusFilter/definitions';
import {
  INCLUDING_TRAITS_FILTER,
  EXCLUDING_TRAITS_FILTER,
} from 'components/Common/Filters/TraitsFilter/definitions';
import {
  DIET_FOOD_FILTER,
  DIET_FORM_FILTER,
  foodOptions as dietFoodOptions,
  formOptions as dietFormOptions,
} from 'components/Inventory/Filters/DietFilter/definitions';
import { MATURITY_FILTER } from 'components/Inventory/Filters/MaturityFilter/definitions';
import {
  PRICE_MIN_FILTER,
  PRICE_MAX_FILTER,
  ON_SALE_FILTER,
  WHOLESALE_FILTER,
} from 'components/Inventory/Filters/PriceFilter/definitions';
import {
  SEX_FILTER,
  SEX_MALE,
  SEX_FEMALE,
  SEX_UNKNOWN,
  SEX_MIXED,
} from 'components/Inventory/Filters/SexFilter/definitions';
import {
  TRAIT_COUNT_MIN_FILTER,
  TRAIT_COUNT_MAX_FILTER,
} from 'components/Inventory/Filters/TraitCountFilter/definitions';
import {
  WEIGHT_MIN_FILTER,
  WEIGHT_MAX_FILTER,
} from 'components/Inventory/Filters/WeightFilter/definitions';
import { PAGE_FILTER, PER_PAGE_FILTER } from 'components/ResultsCounter/definitions';
import {
  INDIVIDUALITY_FILTER,
  INDIVIDUALITY_GROUP,
  INDIVIDUALITY_INDIVIDUAL,
  ORIGIN_FILTER,
  ORIGIN_SELF_PRODUCED,
  ORIGIN_WILD_CAUGHT,
  ORIGIN_CAPTIVE_BRED,
  ORIGIN_CAPTIVE_HATCHED_OR_BORN,
} from 'components/Search/Filters/AnimalOptionsFilter/definitions';
import { EXCLUDE_CATEGORY_FILTER } from 'components/Search/Filters/CategoryFilter/definitions';
import { EXPO_FILTER } from 'components/Search/Filters/ExpoFilter/definitions';
import { EXPORTING_TO_FILTER } from 'components/Search/Filters/ExportingToFilter/definitions';
import {
  ANY_TRAIT_FORM_FILTER,
  HET_REC_FILTER,
  NO_ADDITIONAL_TRAITS_FILTER,
} from 'components/Search/Filters/GenesAndTraitsFilter/definitions';
import { LIKED_FILTER } from 'components/Search/Filters/LikedFilter/definition';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import {
  NEAR_TO_COORDS_FILTER,
  NEAR_TO_DISTANCE_FILTER,
} from 'components/Search/Filters/NearToFilter/definitions';

import {
  ACTUAL_PHOTO_FILTER,
  IS_AVAILABLE_FOR_ORDER_FILTER,
  ACCEPTS_OFFERS_FILTER,
  HAS_LINEAGE_FILTER,
  HAS_VIDEO_FILTER,
  INQUIRE_FOR_PRICE_FILTER,
  PROVEN_BREEDER_FILTER,
  USARK_MEMBERSHIP_FILTER,
  WILL_TRADE_FILTER,
  AUCTION_FILTER,
  EXCLUDE_BLOCKED_STORES_FILTER,
  MY_BIDS_ONLY_FILTER,
  PET_ONLY_FILTER,
} from 'components/Search/Filters/OtherOptionsFilter/definitions';
import {
  PAYMENT_METHOD_FILTER,
  PAYMENT_PLAN_FILTER,
} from 'components/Search/Filters/PaymentOptionsFilter/definitions';
import { SAVED_FILTER } from 'components/Search/Filters/SavedFilter/definition';
import {
  SELLER_FILTER,
  FOLLOWED_STORES_FILTER,
} from 'components/Search/Filters/SellerFilter/definitions';
import {
  FREE_SHIPPING_FILTER,
  LOCAL_PICKUP_ONLY_FILTER,
} from 'components/Search/Filters/ShippingFilter/definitions';
import {
  POSTAL_CODE_FILTER,
  POSTAL_RADIUS_FILTER,
} from 'components/Stores/Filters/LocationWithGeolocationFilter/PostalCode/definitions';
import { US_STATE_FILTER } from 'components/Stores/Filters/LocationWithStateFilter/definitions';
import { CARD_VIEW, GRID_VIEW } from 'components/ViewMenu/definitions';
import { ORDERING_FILTER } from 'api/ordering';
import searchOrdering from 'api/search/ordering';
import preferences, { SEARCH_VIEW_PREFERENCE } from 'services/preferences';
import { DEFAULT_RESULTS_PER_PAGE } from 'config';

const paymentMethods = EnumsBucket.payment_methods;

const searchFilters = {
  // Ordering
  [ORDERING_FILTER]: {
    apiParam: 'ordering',
    values: searchOrdering.map(({ param }) => param),
  },

  // Pagination
  [PAGE_FILTER]: {
    apiParam: 'page',
    unserialize: Number,
    defaultValue: () => 1,
  },
  [PER_PAGE_FILTER]: {
    apiParam: 'page_size',
    unserialize: Number,
    defaultValue: () => DEFAULT_RESULTS_PER_PAGE,
  },

  // Category
  [CATEGORY_FILTER]: {
    apiParam: 'category',
    defaultValue: () => rootCategory.id,
    values: new Map(allCategories.map(({ id, value }) => [id, value])),
  },

  // Exclude Category
  [EXCLUDE_CATEGORY_FILTER]: {
    apiParam: 'exclude_category',
    values: new Map(allCategories.map(({ id, value }) => [id, value])),
  },

  // Location
  [LOCATION_FILTER]: {
    apiParam: 'region',
  },

  // States
  [US_STATE_FILTER]: {
    apiParam: 'location_state',
  },

  // Genes and Traits
  [INCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__in',
  },
  [EXCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__not__in',
  },
  [ANY_TRAIT_FORM_FILTER]: {
    apiParam: 'any_trait_form',
    unserialize: Boolean,
  },
  [NO_ADDITIONAL_TRAITS_FILTER]: {
    apiParam: 'no_additional_traits',
    unserialize: Boolean,
  },
  [TRAIT_COUNT_MIN_FILTER]: {
    apiParam: 'trait_count__min',
    unserialize: Number,
  },
  [TRAIT_COUNT_MAX_FILTER]: {
    apiParam: 'trait_count__max',
    unserialize: Number,
  },

  // Price
  [PRICE_MIN_FILTER]: {
    apiParam: 'price__min',
    unserialize: Number,
  },
  [PRICE_MAX_FILTER]: {
    apiParam: 'price__max',
    unserialize: Number,
  },
  // On Sale: differnt than STATE_FOR_SALE because on sale are ads which are giving a discount, so more aligned to price
  [ON_SALE_FILTER]: {
    apiParam: 'on_sale',
  },
  [WHOLESALE_FILTER]: {
    apiParam: 'wholesale',
  },

  // Shipping
  [LOCAL_PICKUP_ONLY_FILTER]: {
    apiParam: 'local_pickup_only',
    unserialize: Boolean,
  },
  [FREE_SHIPPING_FILTER]: {
    apiParam: 'free_shipping',
    unserialize: Boolean,
  },

  // Sex
  [SEX_FILTER]: {
    apiParam: 'sex',
    values: new Map([
      [SEX_FEMALE, 'female'],
      [SEX_MALE, 'male'],
      [SEX_UNKNOWN, 'unknown'],
      [SEX_MIXED, 'mixed'],
    ]),
  },

  // Maturity
  [MATURITY_FILTER]: {
    apiParam: 'maturity__in',
  },

  // Weight
  [WEIGHT_MIN_FILTER]: {
    apiParam: 'weight__min',
    unserialize: Number,
  },
  [WEIGHT_MAX_FILTER]: {
    apiParam: 'weight__max',
    unserialize: Number,
  },

  // Diet
  [DIET_FOOD_FILTER]: {
    apiParam: 'prey_food',
    values: dietFoodOptions.map(([value]) => value),
  },
  [DIET_FORM_FILTER]: {
    apiParam: 'prey_status',
    values: dietFormOptions.map(([value]) => value),
  },

  // Seller
  [SELLER_FILTER]: {
    apiParam: 'seller',
  },

  // Followed stores only filter
  [FOLLOWED_STORES_FILTER]: {
    apiParam: 'followed_stores',
  },

  [LIKED_FILTER]: {
    apiParam: 'liked',
  },

  [SAVED_FILTER]: {
    apiParam: 'saved',
  },

  // State
  [STATE_FILTER]: {
    apiParam: 'state',
    values: new Map([
      [STATE_FOR_SALE, 'for_sale'],
      [STATE_ON_HOLD, 'on_hold'],
      [STATE_FOR_SALE_OR_ON_HOLD, 'for_sale_or_on_hold'],
      [STATE_SOLD, 'sold'],
      [STATE_HOLDBACKS, 'holdback'],
      [STATE_BREEDERS, 'breeder'],
      [STATE_PETS, 'pet'],
      [STATE_ANY, 'any'],
    ]),
    defaultValue: () => STATE_FOR_SALE,
  },

  // Also Available at
  [EXPO_FILTER]: {
    apiParam: 'expo_id',
    unserialize: Number,
  },

  // Animal Options
  [ORIGIN_FILTER]: {
    apiParam: 'origin',
    values: new Map([
      [ORIGIN_SELF_PRODUCED, 'self_produced'],
      [ORIGIN_CAPTIVE_BRED, 'captive_bred'],
      [ORIGIN_CAPTIVE_HATCHED_OR_BORN, 'captive_hatched_or_born'],
      [ORIGIN_WILD_CAUGHT, 'wild_caught'],
    ]),
  },
  [INDIVIDUALITY_FILTER]: {
    apiParam: 'individuality',
    values: new Map([
      [INDIVIDUALITY_GROUP, 'group'],
      [INDIVIDUALITY_INDIVIDUAL, 'individual'],
    ]),
  },

  // Payment
  [PAYMENT_METHOD_FILTER]: {
    apiParam: 'payment_method',
    unserialize: (val) => paymentMethods.find(({ value }) => value === val)?.label,
    serialize: (value) => paymentMethods.find(({ label }) => value === label)?.value,
    values: new Map(paymentMethods.map(({ value, label }) => [value, label])),
  },
  [PAYMENT_PLAN_FILTER]: {
    apiParam: 'payment_plan',
    unserialize: Number,
  },

  // Near To
  [NEAR_TO_COORDS_FILTER]: {
    apiParam: 'coords',
    serialize: ({ latitude, longitude }) => `${latitude} ${longitude}`,
    unserialize: (value) => {
      const [latitude, longitude] = value.split(' ').map(Number);

      return { latitude, longitude };
    },
  },
  [NEAR_TO_DISTANCE_FILTER]: {
    apiParam: 'distance',
    unserialize: Number,
  },
  [POSTAL_CODE_FILTER]: {
    apiParam: 'postal_code',
  },
  [POSTAL_RADIUS_FILTER]: {
    apiParam: 'postal_radius',
    unserialize: Number,
  },

  // Exporting To
  [EXPORTING_TO_FILTER]: {
    apiParam: 'exporting_to',
  },

  // Search
  [SEARCH_FILTER]: {
    apiParam: 'search',
  },

  // Other Options
  [ACTUAL_PHOTO_FILTER]: {
    apiParam: 'actual_photo',
    unserialize: Boolean,
  },
  [HAS_VIDEO_FILTER]: {
    apiParam: 'has_video',
    unserialize: Boolean,
  },
  [HAS_LINEAGE_FILTER]: {
    apiParam: 'has_lineage',
    unserialize: Boolean,
  },
  [IS_AVAILABLE_FOR_ORDER_FILTER]: {
    apiParam: 'is_available_for_order',
    unserialize: Boolean,
  },
  [ACCEPTS_OFFERS_FILTER]: {
    apiParam: 'accepts_offers',
    unserialize: Boolean,
  },
  [INQUIRE_FOR_PRICE_FILTER]: {
    apiParam: 'inquire_for_price',
    unserialize: Boolean,
  },
  [AUCTION_FILTER]: {
    apiParam: 'auction',
  },
  [MY_BIDS_ONLY_FILTER]: {
    apiParam: 'my_bids',
  },
  [EXCLUDE_BLOCKED_STORES_FILTER]: {
    apiParam: 'exclude_blocked_stores',
  },
  [PET_ONLY_FILTER]: {
    apiParam: 'pet_only',
    unserialize: Boolean,
  },
  [PROVEN_BREEDER_FILTER]: {
    apiParam: 'proven_breeder',
    unserialize: Boolean,
  },
  [USARK_MEMBERSHIP_FILTER]: {
    apiParam: 'usark_membership',
    unserialize: Boolean,
  },
  [WILL_TRADE_FILTER]: {
    apiParam: 'will_trade',
    unserialize: Boolean,
  },
  [PK_FILTER]: {
    apiParam: 'pk',
  },
  [ALERT_MATCH_FILTER]: {
    apiParam: 'alert_match',
  },
  [GROUP_MATCHES_BY_FILTER]: {
    apiParam: 'group_matches_by',
  },
  [HET_REC_FILTER]: {
    apiParam: 'trait_count_type',
  },
  [SEARCH_VIEW_PREFERENCE]: {
    apiParam: 'view',
    values: new Map([
      [CARD_VIEW, 'card'],
      [GRID_VIEW, 'grid'],
    ]),
    defaultValue: () => {
      const view = preferences.get(SEARCH_VIEW_PREFERENCE) || GRID_VIEW;
      return view;
    },
  },

  // Filter for testing API calls on the BE without caching
  'cache-mode': {
    apiParam: 'cache-mode',
  },
};

export type SearchFilterSymbol = keyof typeof searchFilters;

export default searchFilters;
