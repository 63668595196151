import React from 'react';
import { faVenusMars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './SexMixed.module.scss';

interface SexMixedProps {
  className?: string;
}
const SexMixed: React.FC<SexMixedProps> = ({ className, ...props }) => (
  <FontAwesomeIcon icon={faVenusMars} className={classNames(style.icon, className)} {...props} />
);

export default SexMixed;
