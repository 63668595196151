/* eslint-disable no-unused-vars */
import { action, computed, makeObservable, observable } from 'mobx';

export type Unwrap<T> = T extends Promise<infer U>
  ? U
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends (...args: any) => Promise<infer U>
  ? U
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends (...args: any) => infer U
  ? U
  : T;

export type AsyncOperationWithStatusOptions = {
  notifyIfError?: boolean;
};

const DEFAULT_OPTIONS: AsyncOperationWithStatusOptions = {
  notifyIfError: true,
};
export class AsyncOperationWithStatus<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  F extends (...args: any) => Promise<any>
> {
  @observable isLoading = false;
  @observable error?: any;
  @observable isLoaded = false;
  @observable data?: Unwrap<F>;
  @observable countLoads = 0;
  @observable options: AsyncOperationWithStatusOptions;

  @computed get hasData() {
    return Boolean(this.data);
  }

  @computed get hasError() {
    return Boolean(this.error);
  }

  constructor(private fn: F, options?: AsyncOperationWithStatusOptions) {
    this.options = { ...DEFAULT_OPTIONS, ...options };
    makeObservable(this);
  }

  @action private setData(data: Unwrap<F>) {
    this.data = data;
  }

  @action private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action private setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  @action async run(...params: Parameters<F>) {
    try {
      this.setIsLoading(true);
      this.error = undefined;

      const data = (await this.fn.apply(undefined, params)) as Unwrap<F>;

      this.setData(data);

      return data;
    } catch (error) {
      console.warn(error);
      this.error = error;
      this.setIsLoaded(false);
    } finally {
      this.countLoads++;
      this.setIsLoading(false);
    }
  }

  @action reset() {
    this.error = undefined;
    this.data = undefined;
  }
}
