import { Components, Theme } from '@mui/material';
import { colors } from '../constants';

const { gray200, white } = colors;

export const MuiOutlinedInput: Components<Omit<Theme, 'components'>>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      padding: '0 0 0 9px',
      backgroundColor: white,
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${gray200}`,
        },
      },
    },
    notchedOutline: {
      border: `1px solid  ${gray200}`,
    },
  },
};
