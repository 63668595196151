import { useCallback, useContext, useEffect } from 'react';
import ToastContext from 'context/ToastContext/ToastContext';

// See Toast.jsx
type ToastExtraProps = {
  isHtml?: boolean;
  lifetimeMs?: number;
  onDelete?: () => void;
};

/**
 * Hook for displaying all toaster messages.
 */
export const useToaster = () => {
  const { toast } = useContext(ToastContext);
  const showError = useCallback(
    (message: string, toastProps: ToastExtraProps = {}) => toast(message, 'error', toastProps),
    [toast]
  );
  const showWarning = useCallback(
    (message: string, toastProps: ToastExtraProps = {}) => toast(message, 'warning', toastProps),
    [toast]
  );
  const showSuccess = useCallback(
    (message: string, toastProps: ToastExtraProps = {}) => toast(message, 'success', toastProps),
    [toast]
  );
  const showInfo = useCallback(
    (message: string, toastProps: ToastExtraProps = {}) => toast(message, 'info', toastProps),
    [toast]
  );

  return {
    showError,
    showWarning,
    showSuccess,
    showInfo,
  };
};

/**
 * Convenience hook for showing toaster error messages.
 */
export const useToasterError = (message?: string | false | null | 0) => {
  const { showError } = useToaster();

  useEffect(() => {
    if (message) {
      showError(message);
    }
  }, [message, showError]);
};

/**
 * Convenience hook for showing toaster success messages.
 */
export const useToasterSuccess = (message?: string | false | null | 0) => {
  const { showSuccess } = useToaster();

  useEffect(() => {
    if (message) {
      showSuccess(message);
    }
  }, [message, showSuccess]);
};

/**
 * Convenience hook for showing toaster warning messages.
 */
export const useToasterWarning = (message?: string | false | null | 0) => {
  const { showWarning } = useToaster();

  useEffect(() => {
    if (message) {
      showWarning(message);
    }
  }, [message, showWarning]);
};

/**
 * Convenience hook for showing toaster info messages.
 */
export const useToasterInfo = (message?: string | false | null | 0) => {
  const { showInfo } = useToaster();

  useEffect(() => {
    if (message) {
      showInfo(message);
    }
  }, [message, showInfo]);
};
