import { NAMRegionId, USACountryId } from 'pages/Events/constants';
import { PurchaseOrder } from 'api/sdk';
import { patch, post } from 'api/utilities/api';
import { DESCENDING } from '../api/ordering';
import { OffspringGroup, OffspringGroupCreateModel, OffspringGroupUpdateModel } from '../types';
import { BaseApi } from './BaseApi';
import { eventTypes } from './constants';
import { clearQueryParams } from './helpers';
import {
  User,
  MorphmarketEventListItem,
  Pageable,
  SearchEventsFilters,
  MorphmarketEvent,
  MorphmarketEventListSort,
  RegisterToEventWithStoreRequest,
  Dashboard,
  Blog,
  Participant,
  EventsTabStatus,
  MorphmarketEventStatus,
  Animal,
} from './types';

const api = new BaseApi();

export const getEvents = (
  request: SearchEventsFilters,
  page: number,
  itemsPerPage: number,
  statusTab: EventsTabStatus,
  sortField?: string | null,
  sortDirection?: MorphmarketEventListSort
): Promise<Pageable<MorphmarketEventListItem>> => {
  const queryParams: any = {
    name: request.searchQuery,
    min_date: request.dateFrom,
    max_date: request.dateTo,
    event_type: eventTypes?.find((eventType) => eventType.value === request.eventType)?.id,
    page,
    page_size: itemsPerPage,
    order: sortField ? `${sortDirection === DESCENDING ? '-' : ''}${sortField}` : null,
    state: [NAMRegionId, USACountryId].includes(request.countryId ?? '')
      ? request.state
      : undefined,
    region: request.countryId,
  };

  const today = new Date();

  let startDate = null;
  let endDate = null;
  if (!!request.dateFrom) {
    startDate = new Date(request.dateFrom as any);
  }
  if (!!request.dateTo) {
    endDate = new Date(request.dateTo as any);
  }

  queryParams.max_date = null;
  queryParams.min_date = null;

  if (statusTab === EventsTabStatus.UPCOMING) {
    queryParams.status = [
      MorphmarketEventStatus.UPCOMING,
      MorphmarketEventStatus.CANCELLED,
      MorphmarketEventStatus.ONGOING,
    ];
    if (endDate) {
      queryParams.max_date = endDate.toISOString().slice(0, 10);
    }

    queryParams.min_date = today.toISOString().slice(0, 10);
    if (startDate && +startDate > +today) {
      queryParams.min_date = startDate.toISOString().slice(0, 10);
    }
  } else {
    queryParams.max_date = today.toISOString().slice(0, 10);
    if (endDate && +endDate < +today) {
      queryParams.max_date = endDate.toISOString().slice(0, 10);
    }

    if (startDate) {
      queryParams.min_date = startDate.toISOString().slice(0, 10);
    }
    queryParams.status = [MorphmarketEventStatus.CANCELLED, MorphmarketEventStatus.FINISHED];
  }

  return api.get('/events/', clearQueryParams(queryParams));
};

export const getEvent = (id: number): Promise<MorphmarketEvent> => {
  return api.get(`/events/${id}/`);
};

export const getAccountInfo = (): Promise<User> => {
  return api.get('/account/me/');
};

export const registerToEventWithStore = (
  eventId: number,
  request: RegisterToEventWithStoreRequest
) => {
  return api.post(`/events/${eventId}/vendor_registration/`, {
    position: request.tableNumber,
    categories: request.categories,
    description: request.description,
  });
};

export const registerToEventWithStoreBySuperUser = (
  eventId: number,
  request: RegisterToEventWithStoreRequest,
  vendorId: number
) => {
  return api.put(`/events/${eventId}/vendors/${vendorId}/`, {
    position: request.tableNumber,
    categories: request.categories,
    description: request.description,
  });
};

export const unregisterVendorFromEvent = (eventId: number) => {
  return api.delete(`/events/${eventId}/vendor_registration/`);
};

export const getDashboard = () => {
  return api.get<Dashboard>('/dashboard/');
};

export const getBlogs = (limit: number) => {
  return api.get<Pageable<Blog>>(`/blog?format=json&limit=${limit}`);
};

export const getUserProfile = (): Promise<Participant> => {
  return api.get('/users/me/');
};

export const getStoreProfile = (): Promise<Participant> => {
  return api.get('/stores/_own/');
};

export const renewAnimal = (animalId: string): Promise<Animal> => {
  return api.patch(`/animals/${animalId}/renew/`);
};

export const getAnimalInfo = (animalId: string): Promise<Animal> => {
  return api.get(`/animals/${animalId}/`);
};

export const updateAnimalInfo = (animalId: string, body: Partial<Animal>) => {
  return patch(`/animals/${animalId}/`, { body });
};

export const addAnimal = (body: Partial<Animal>) => {
  return post(`/animals/`, { body });
};

export const getPotentialParents = (
  category: string,
  search?: string
): Promise<{ results: Animal[] }> => {
  const params = new URLSearchParams({
    category,
    maturity: 'adult',
    ordering: 'title',
    page_size: '50',
  });
  !!search && params.set('search', search);
  return api.get(`/animals/?${params.toString()}`);
};

export const getPotentialBuyers = (animalId: string) => {
  return api.get(`/animals/${animalId}/buyers/`);
};

export const getAnimalsInfoByAnimalId = (
  animalId: string
): Promise<{ keys: number[]; results: Animal[] }> => {
  return api.get(`/animals/?animal_id=${animalId}`);
};

export const getGroupInfoPublicDetails = (
  storeUsername: string,
  offspringId: string
): Promise<OffspringGroup> => {
  return api.get(`/stores/${storeUsername}/offspring_groups/${offspringId}/`);
};

export const listPrivateGroup = (): Promise<{ results: OffspringGroup[] }> => {
  return api.get(`/offspring_groups/`);
};

export const getGroupInfo = (offspringId: string): Promise<OffspringGroup> => {
  return api.get(`/offspring_groups/${offspringId}/`);
};

export const addGroupInfo = (group: OffspringGroupCreateModel): Promise<OffspringGroup> => {
  return api.post(`/offspring_groups/`, group);
};

export const updateGroupInfo = (
  group: OffspringGroupUpdateModel
): Promise<{ details: OffspringGroup; message: string }> => {
  return api.put(`/offspring_groups/${group.key}/`, group);
};

export const updateGroupInfoPartially = (
  id: string,
  group: Partial<OffspringGroupUpdateModel>
): Promise<{ details: OffspringGroup; message: string }> => {
  return api.patch(`/offspring_groups/${id}/`, group);
};

export const deleteGroupInfo = (offspringId: string): Promise<void> => {
  return api.delete(`/offspring_groups/${offspringId}/`);
};

export const getOrderPurchase = (orderId: string): Promise<PurchaseOrder> => {
  return api.get(`/orders/purchases/${orderId}/`);
};
