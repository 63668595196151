/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `self_produced` - Self Produced
 * * `captive_bred` - Captive Bred
 * * `captive_hatched_or_born` - Captive Hatched/Born
 * * `wild_caught` - Wild Caught
 * @export
 */
export const ItemOriginEnum = {
    SelfProduced: 'self_produced',
    CaptiveBred: 'captive_bred',
    CaptiveHatchedOrBorn: 'captive_hatched_or_born',
    WildCaught: 'wild_caught'
} as const;
export type ItemOriginEnum = typeof ItemOriginEnum[keyof typeof ItemOriginEnum];


export function ItemOriginEnumFromJSON(json: any): ItemOriginEnum {
    return ItemOriginEnumFromJSONTyped(json, false);
}

export function ItemOriginEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemOriginEnum {
    return json as ItemOriginEnum;
}

export function ItemOriginEnumToJSON(value?: ItemOriginEnum | null): any {
    return value as any;
}

