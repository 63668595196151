import { memo } from 'react';

import Spinner from '../Spinner/Spinner';

import styles from './PreloaderSpinner.module.scss';

/**
 * @deprecated. Use https://mui.com/material-ui/react-progress/ instead.
 */
export const PreloaderSpinner = memo(() => (
  <div className={styles.spinnerWrapper}>
    <Spinner className={styles.spinnerIcon} />
    <h3 className={styles.loading}>Loading...</h3>
  </div>
));
