import { RouteObject } from 'react-router-dom';

const NotificationsManager = () => import('pages/NotificationsManager');
const Followers = () => import('pages/Followed/Followers');
const Following = () => import('pages/Followed/Following');
const BlockedStores = () => import('pages/Followed/Blocked');
const UserGroups = () => import('pages/UserGroups/UserGroups');

export const accountsRoutes: RouteObject[] = [
  {
    path: 'accounts',
    children: [
      { path: 'settings/notifications', lazy: NotificationsManager },
      { path: 'following', lazy: Following },
      { path: 'followers', lazy: Followers },
      { path: 'blocked-stores', lazy: BlockedStores },
      { path: 'user-groups', lazy: UserGroups },
    ],
  },
];
