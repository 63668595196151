import { MouseEvent } from 'react';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { ANIMAL_ID_ORDERING } from 'api/inventory/ordering';

export const ANIMAL_ID_COLUMN = Symbol('ANIMAL_ID_COLUMN');

interface AnimalIdColumnRenderProps {
  serial: string;
  view_url: string;
}

const handleClick = (e: MouseEvent<HTMLAnchorElement>) => e.stopPropagation();

const animalIdColumn = {
  id: ANIMAL_ID_COLUMN,
  ordering: ANIMAL_ID_ORDERING,
  label: 'Animal ID',
  hideable: true,
  className: style.animalIdColumn,

  render: ({ serial, view_url }: AnimalIdColumnRenderProps, readonly: boolean) => {
    const isLink = Boolean(view_url) && readonly;

    if (isLink) {
      return (
        <a onClickCapture={handleClick} href={view_url} className={style.animalIdLink}>
          {serial}
        </a>
      );
    }

    return <span>{serial}</span>;
  },
};

export default animalIdColumn;
