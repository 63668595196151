import React from 'react';
import MuiButton from 'components/Button/MUI/MuiButton';
import { Controls } from 'components/experimental';
import { WhenWrapper } from 'components/experimental/Wrappers';
import style from '../NativeAppReviewModal.module.scss';

type ModalControlProps = {
  closeLabel: string;
  submitLabel: string;
  showCloseOnly: boolean;
  disableSubmit: boolean;
  actions: {
    onClose: () => void;
    onSubmit: () => void;
  };
};

export const ModalControl: React.FC<ModalControlProps> = ({
  closeLabel,
  submitLabel,
  disableSubmit,
  showCloseOnly,
  actions: { onClose, onSubmit },
}) => {
  return (
    <>
      <Controls withShadow rightAligned>
        <MuiButton
          onClick={onClose}
          variant="outlined"
          label={closeLabel}
          className={style.secondary}
        />
        <WhenWrapper condition={!showCloseOnly}>
          <MuiButton
            onClick={onSubmit}
            label={submitLabel}
            disabled={disableSubmit}
            className={style.primary}
          />
        </WhenWrapper>
      </Controls>
    </>
  );
};
