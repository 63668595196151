import { safeGetLocalStorageItem, safeSetLocalStorageItem } from 'services/localStorage';
/**
 * Temporary solution for storing the user's sidebar preferences.
 */
export const useSidebarPreferences = () => {
  const isOpen = safeGetLocalStorageItem('isSidebarOpen', 'yes') === 'yes';
  const toggle = (value?: boolean) =>
    safeSetLocalStorageItem('isSidebarOpen', value ? 'yes' : 'no');

  return {
    isOpen,
    toggle,
  };
};
