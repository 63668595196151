import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import TagsList from 'components/Inventory/TagsList/TagsList';
import { TAG_ORDERING } from 'api/inventory/ordering';

export const TAG_COLUMN = Symbol('TAG_COLUMN');

interface TagColumnProps {
  tags: { id: number; name: string }[];
}

const tagColumn = {
  id: TAG_COLUMN,
  ordering: TAG_ORDERING,
  label: 'Tags',
  hideable: true,
  className: style.tagsColumn,

  render: ({ tags }: TagColumnProps) => <TagsList tags={tags} />,
};

export default tagColumn;
