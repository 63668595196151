import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { CHILDREN_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const CHILDREN_COLUMN = Symbol('CHILDREN_COLUMN');

const childrenColumn = {
  id: CHILDREN_COLUMN,
  ordering: CHILDREN_ORDERING,
  label: 'Offspring',
  hideable: true,
  className: style.countColumn,
  defaultDirection: DESCENDING,

  render: ({ children_count_display }) => children_count_display,
};

export default childrenColumn;
