import kebabCase from 'lodash/kebabCase';
import { categories } from 'constants/enums';

export const findCategory = (match: any, matchOnly?: 'id' | 'label' | 'value' | 'path') => {
  if (!match) {
    return categories.find((category) => category.path === 'all');
  }

  return categories.find((category) => {
    if (matchOnly) {
      switch (matchOnly) {
        case 'label':
          return kebabCase(category[matchOnly]) === kebabCase(match);
        case 'id':
          return parseInt(match, 10) === category.id;
        default:
          return category[matchOnly] === match;
      }
    } else {
      const { id, value, label, path } = category;
      return (
        parseInt(match, 10) === id ||
        (typeof match === 'string' && kebabCase(match) === kebabCase(label)) ||
        match === value ||
        match === path
      );
    }
  });
};

export const getFamilyTree = (match: any) => {
  const leaf = !match?.ancestors ? findCategory(match) : match;
  const { ancestors, label } = leaf;
  const [, ...rest] = ancestors.map(({ id }: { id: number }) => findCategory(id, 'id')?.label);
  return [...rest, label].map(kebabCase).join('/');
};
