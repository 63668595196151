import { useContext, useEffect } from 'react';
import {
  NEAR_TO_COORDS_FILTER,
  NEAR_TO_DISTANCE_FILTER,
} from 'components/Search/Filters/NearToFilter/definitions';
import { TabsContext } from 'context/TabsContext';
import { ORDERING_FILTER } from 'api/ordering';
import { FiltersObject } from 'api/types';
import { useGeolocation } from 'hooks/utils/useGeolocation';

export const LOCAL_RADIUS = 50;

export const usePopulateParamsForLocalSearch = (
  filters: FiltersObject,
  applyFilters: (filters: FiltersObject) => void
) => {
  const { status, getLocation } = useGeolocation();
  const { actions: tabsContextActions } = useContext(TabsContext);

  useEffect(() => {
    const hasDistanceWithNoCoordFilter =
      filters?.get(NEAR_TO_DISTANCE_FILTER) === LOCAL_RADIUS &&
      !filters?.has(NEAR_TO_COORDS_FILTER);
    if (hasDistanceWithNoCoordFilter && status == 'granted') {
      (async () => {
        const { coords } = await getLocation();
        if (!!coords) {
          applyFilters(
            filters
              .set(NEAR_TO_COORDS_FILTER, coords)
              .set(NEAR_TO_DISTANCE_FILTER, LOCAL_RADIUS)
              .set(ORDERING_FILTER, 'distance')
          );
        }
      })();
    } else if (hasDistanceWithNoCoordFilter && status != 'granted') {
      tabsContextActions.setActiveTabPath(window.location.toString());
    }
  }, [filters, status]);
};
