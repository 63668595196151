import classnames from 'classnames';
import styles from './Typography.module.scss';

// Headings
export type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>;

// This code must not make the assumption that h1's are necessarily large.
// H1's are mainly used to indicate semantic importance (e.g. to search engines).
// It should be possible to make a visually small h1.

/**
 * @deprecated. Use https://mui.com/material-ui/react-typography/ instead.
 */
export const H1: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
  ...props
}) => (
  <h1 {...props} className={classnames(styles.h1, className)}>
    {children}
  </h1>
);

/**
 * @deprecated
 */
export const H2: React.FC<HeadingProps> = ({ children, ...props }) => (
  <h2 {...props} className={styles.h2}>
    {children}
  </h2>
);

/**
 * @deprecated
 */
export const H3: React.FC<HeadingProps> = ({ children, ...props }) => (
  <h3 {...props} className={styles.h3}>
    {children}
  </h3>
);

/**
 * @deprecated
 */
export const H4: React.FC<HeadingProps> = ({ children, ...props }) => (
  <h4 {...props} className={styles.h4}>
    {children}
  </h4>
);

// Paragraph
type SM<T> = T & {
  lg?: never;
  sm?: boolean;
  xs?: never;
};

type XS<T> = T & {
  lg?: never;
  sm?: never;
  xs?: boolean;
};

type LG<T> = T & {
  lg?: boolean;
  sm?: never;
  xs?: never;
};

type Bold = {
  bold?: boolean;
};

type ParaProps = React.HTMLAttributes<HTMLParagraphElement> & Bold;
export type ParagraphProps = XS<ParaProps> | SM<ParaProps>;

/**
 * @deprecated
 */
export const P: React.FC<ParagraphProps> = ({ children, xs, sm, bold, ...props }) => (
  <p
    {...props}
    className={classnames(
      styles.p,
      bold && styles.bold,
      sm && styles.small,
      xs && styles.extraSmall
    )}
  >
    {children}
  </p>
);

/**
 * @deprecated
 */
export const Label: React.FC<React.HTMLAttributes<HTMLLabelElement>> = ({ children, ...props }) => (
  <label {...props} className={classnames(styles.label, styles.small)}>
    {children}
  </label>
);

type SpProps = React.HTMLAttributes<HTMLSpanElement> & Bold;
export type SpanProps = XS<SpProps> | SM<SpProps> | LG<SpProps>;

/**
 * @deprecated
 */
export const Span: React.FC<SpanProps> = ({ children, bold, xs, sm, lg, className, ...props }) => (
  <span
    {...props}
    className={classnames(
      styles.span,
      bold && styles.bold,
      lg && styles.large,
      sm && styles.small,
      xs && styles.extraSmall,
      className
    )}
  >
    {children}
  </span>
);
