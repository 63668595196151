// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxxxxl": `6.5rem`,
	"xxxxl": `4rem`,
	"xxxl": `2.5rem`,
	"xxl": `2rem`,
	"xl": `1.5rem`,
	"lg": `1.25rem`,
	"md": `1rem`,
	"sm": `0.875rem`,
	"xs": `0.75rem`,
	"xxs": `0.5rem`,
	"xxxs": `0.25rem`,
	"xxxxs": `0.125rem`,
	"borderRadius": `0.375rem`
};
export default ___CSS_LOADER_EXPORT___;
