import {
  faGlobeAmericas,
  faLock,
  faExternalLinkSquareAlt,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibilityEnum } from 'api/sdk';

const icons: Record<VisibilityEnum, [string, IconDefinition]> = {
  [VisibilityEnum.Public]: ['Public', faGlobeAmericas],
  [VisibilityEnum.Private]: ['Private', faLock],
  [VisibilityEnum.Unlisted]: ['Unlisted', faExternalLinkSquareAlt],
};

export type VisibilityIconProps = { visibility: VisibilityEnum };
const VisibilityIcon: React.FC<VisibilityIconProps> = ({ visibility, ...props }) => {
  const [label, icon] = icons[visibility];

  return (
    <FontAwesomeIcon
      icon={icon}
      data-tip={`Visibility: <b>${label}</b>`}
      aria-label={`Visibility: ${label}`}
      aria-hidden="false"
      tabIndex={0}
      data-html
      {...props}
    />
  );
};

export default VisibilityIcon;
