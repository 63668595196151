import { Divider } from '@mui/material';
import { NestedHelpers, NestedItem } from 'components/experimental/MUI/NestedMenu';
import { SettingsItem } from '../Lists';
import { AccordionMenu } from '../Menus/Accordion';
import { Anchor } from './Anchor';
import { BackButton } from './BackButton';
import { ForwardButton } from './ForwardButton';
import { PrefixedAnchor } from './PrefixedAnchor';
import { PrefixedButton } from './PrefixedButton';
import { RecentlyVisitedAnchor } from './RecentlyVisitedAnchor';

export type NestedMenuItemProps = NestedItem &
  NestedHelpers & {
    variant?: 'close' | 'back';
    links?: NestedItem[];
    name?: string;
    href?: string;
    recentlyVisited?: boolean;
    divider?: boolean;
    CustomComponent?: React.FC;
  };

export const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  goTo,
  goBack,
  links,
  divider,
  variant,
  recentlyVisited,
  CustomComponent,
  ...props
}) => {
  const goBackToPreviousMenu = goBack || undefined;
  const linksWithBackButton = [{ variant: 'back', ...props }, ...(links || [])];
  const goToSubmenu = (!!links && goTo && (() => goTo(linksWithBackButton))) || undefined;

  if (!!CustomComponent) {
    return CustomComponent;
  }

  if (!!divider) {
    return <Divider />;
  }

  if (variant === 'back') {
    return <BackButton {...props} onClick={goBackToPreviousMenu} />;
  }

  if (variant === 'close') {
    return <BackButton {...props} closeIcon />;
  }

  if (!!links && links.length) {
    return <ForwardButton {...props} onClick={goToSubmenu} />;
  }

  if (props.href) {
    if (!!recentlyVisited) {
      return <RecentlyVisitedAnchor {...props} />;
    } else {
      return <Anchor {...props} />;
    }
  }

  return null;
};

export const SettingsMenuItem: React.FC<SettingsItem> = ({
  children,
  divider,
  icon,
  links,
  href,
  onClick,
  CustomComponent,
  ...props
}) => {
  if (!!CustomComponent) {
    return CustomComponent;
  }

  if (!!links && links.length) {
    return (
      <AccordionMenu children={children} icon={icon} links={links} {...props} onClick={onClick} />
    );
  }

  if (!!divider) {
    return <Divider />;
  }

  if (!!icon) {
    if (!!onClick) {
      return <PrefixedButton icon={icon} children={children} onClick={onClick} {...props} />;
    }

    if (!!href) {
      return <PrefixedAnchor icon={icon} children={children} href={href} {...props} />;
    }
  } else {
    if (!!onClick) {
      return <button children={children} onClick={onClick} {...props} />;
    }

    if (!!href) {
      return <Anchor children={children} href={href} {...props} />;
    }
  }

  return null;
};
