export const PAGINATION_PER_PAGE_OPTIONS = [12, 24, 48];

export const STORES_PAGINATION_PER_PAGE_OPTIONS = PAGINATION_PER_PAGE_OPTIONS.slice(0, -1);

export const DEBOUNCE_INPUT_MS = 750;

export const DEBOUNCE_SCROLL_MS = 100;

export const TABLET_LARGE_WIDTH = 820;

export const DEFAULT_RESULTS_PER_PAGE = 24;

/**
 * The default number of results on the Search and related pages - but only for mobile.
 */
export const DEFAULT_RESULT_PER_PAGE_MOBILE_SEARCH = 24;

export const DEFAULT_RESULTS_PER_PAGE_INVENTORY = 48;
export const DEFAULT_RESULTS_PER_PAGE_INVENTORY_GRID = 24;
