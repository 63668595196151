/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `unknown` - Unknown
 * * `live` - Live
 * * `frozen` - Frozen/Thawed
 * * `prekilled` - Pre-killed
 * @export
 */
export const PreyStatusEnum = {
    Unknown: 'unknown',
    Live: 'live',
    Frozen: 'frozen',
    Prekilled: 'prekilled'
} as const;
export type PreyStatusEnum = typeof PreyStatusEnum[keyof typeof PreyStatusEnum];


export function PreyStatusEnumFromJSON(json: any): PreyStatusEnum {
    return PreyStatusEnumFromJSONTyped(json, false);
}

export function PreyStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreyStatusEnum {
    return json as PreyStatusEnum;
}

export function PreyStatusEnumToJSON(value?: PreyStatusEnum | null): any {
    return value as any;
}

