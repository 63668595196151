import { findCategory } from 'services/CustomURLs/helpers/categories';
import { isActiveUrl } from 'services/helpers';
import { isListingsPath } from 'services/urls';

declare let $: any; // Remove after replacing legacy advanced search

/**
 * Toggles the advanced search form from the old jQuery-powered Navigation menu.
 */
export const openLegacyAdvancedSearch = () => !!$ && $('#advanced-search-bar').collapse('toggle');

/**
 * Injects the active user's username into links. This was previously handled by Django on the BE.
 */
export const injectUserName = (array: any[], username?: string): any[] =>
  JSON.parse(JSON.stringify(array).replace(/\$user/g, username || ''));

interface RecursivelyMapObject {
  <O extends Record<string, any>>(obj: O, prop: string, cb: (obj: O) => O, inPlace?: boolean): O;
}

export const recursivelyMapObject: RecursivelyMapObject = (obj, prop, cb, inPlace) => {
  let tree = inPlace ? obj : JSON.parse(JSON.stringify(obj));
  tree = cb(tree);

  if (tree[prop]) {
    tree[prop] = tree[prop].map((value: typeof obj) => recursivelyMapObject(value, prop, cb));
  }

  return tree;
};

export const replaceRegionInMenu = (menu: any, region: string) => {
  return JSON.parse(JSON.stringify(menu).replace(/REGION/g, region));
};

export const appendActiveTag = (item: any) => {
  // Record would be better here but Typescript doesn't recognize filter() removes empty values
  return !!item.href && isActiveUrl(window.location, item.href, isListingsPath(item.href) || false)
    ? { ...item, active: true }
    : item;
};

type Category = NonNullable<ReturnType<typeof findCategory>>;
export const recentCategoriesToLinks = (categories: Category[], region: string) => {
  const LIMIT = 3; // Show only up to X most recent categories
  const links = categories?.map(({ label, path, id }) => ({
    id,
    recentlyVisited: true,
    children: label,
    name: `recently-visited-${label}`,
    href: `/${region}/c/${path}`,
  }));
  const slice = links.slice(0, Math.min(LIMIT, links.length));
  const sliceWithUniqueCategory = slice.filter(
    (link, index, self) => index === self.findIndex((l) => l.id === link.id)
  );
  return !!sliceWithUniqueCategory?.length ? [...sliceWithUniqueCategory, { divider: true }] : [];
};

export const recentCategoriesToCalcLinks = (categories: Category[]) => {
  const LIMIT = 3; // Show only up to X most recent categories
  const links = categories
    ?.filter(({ has_wizard }) => !!has_wizard)
    .map(({ label, path, id }) => ({
      id,
      recentlyVisited: true,
      children: label,
      name: `recently-visited-${label}`,
      href: `/c/${path}/genetic-calculator`,
    }));
  const slice = links.slice(0, Math.min(LIMIT, links.length));
  const sliceWithUniqueCategory = slice.filter(
    (link, index, self) => index === self.findIndex((l) => l.id === link.id)
  );
  return !!sliceWithUniqueCategory?.length ? [...sliceWithUniqueCategory, { divider: true }] : [];
};

export const injectIntoLinks = (links: any[], categories: Category[], region: string) => {
  return links.map(({ showRecentlyVisitedCategories, showRecentlyVisitedCalculators, ...link }) => {
    if (showRecentlyVisitedCategories) {
      const links = [...recentCategoriesToLinks(categories, region), ...link.links];
      return { ...link, links };
    } else if (showRecentlyVisitedCalculators) {
      const links = [...recentCategoriesToCalcLinks(categories), ...link.links];
      return { ...link, links };
    } else {
      return link;
    }
  });
};
