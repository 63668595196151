/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InquiredAnimal
 */
export interface InquiredAnimal {
    /**
     * 
     * @type {string}
     * @memberof InquiredAnimal
     */
    readonly key: string;
    /**
     * 
     * @type {string}
     * @memberof InquiredAnimal
     */
    readonly name: string;
}

export function InquiredAnimalFromJSON(json: any): InquiredAnimal {
    return InquiredAnimalFromJSONTyped(json, false);
}

export function InquiredAnimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): InquiredAnimal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
    };
}

export function InquiredAnimalToJSON(value?: InquiredAnimal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

