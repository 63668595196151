// import * as Sentry from '@sentry/browser';
import HumanReadableError from 'errors/HumanReadableError';
import { ERROR_5XX_MESSAGE, UNKNOWN_API_ERROR_MESSAGE } from 'api/messages/generic';
import { useToaster } from 'hooks/utils/useToaster';
import { ApiError } from 'services/BaseApi';
import { parseApiError } from './api';
import { APIErrorResponse, lookFor5XX, parseAPIErrorResponse } from './helpers';

interface UseAPIErrorHandlerProps {
  apiCallbackName?: string;
  callbackDefinedErrorMessage?: string;
  actions: {
    setError: React.Dispatch<any>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const useAPIErrorHandler = ({
  callbackDefinedErrorMessage,
  apiCallbackName,
  actions: { setLoading, setError },
}: UseAPIErrorHandlerProps) => {
  const { showError } = useToaster();

  const handleUndefinedError = () => {
    setError(UNKNOWN_API_ERROR_MESSAGE);
    showError(UNKNOWN_API_ERROR_MESSAGE);
    setLoading(false);
    // Disabling for now because:
    // 1. It's too noisy and is gonna trigger #ops-emergencies until we figure why we have so many of these.
    // 2. It's missing key info about the status code for the failed network request
    //
    // Sentry.captureException(
    //   new Error(`Tried calling ${apiCallbackName} but got undefined in response`)
    // );
    return true;
  };

  const handle5XXError = (error: ApiError) => {
    const { status, isHTML } = lookFor5XX(error);
    if (status && status >= 500 && status < 600 && isHTML) {
      setError(ERROR_5XX_MESSAGE);
      showError(ERROR_5XX_MESSAGE);
      setLoading(false);
      return true;
    }
  };

  const handleHumanReadableError = (error: HumanReadableError) => {
    const { message } = error;
    setError(message);
    showError(message);
    setLoading(false);
  };
  // To handle the issue where <a> tags are coming from the backend in uppercase (<A>)
  const fixLinksUppercase = (text: string) => text.replace(/<A/g, '<a').replace(/A>/g, 'a>');

  const handleErrorResponse = async (error: APIErrorResponse) => {
    const errorDetails = await parseAPIErrorResponse(error);

    if (errorDetails) {
      setError(errorDetails);
      if (Array.isArray(errorDetails)) {
        errorDetails.forEach((error) => showError(fixLinksUppercase(error), { isHtml: true }));
      } else {
        showError(fixLinksUppercase(errorDetails), { isHtml: true });
      }
    } else {
      // This is a fallback for when the error is not a HumanReadableError
      const parsedAPIError = await parseApiError(error as any);
      setError(error);
      showError(parsedAPIError || callbackDefinedErrorMessage, { isHtml: true });
    }

    setLoading(false);
    return true;
  };

  return { handle5XXError, handleErrorResponse, handleUndefinedError, handleHumanReadableError };
};
