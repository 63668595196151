import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import { SettingsItem } from '../Lists';
import { AccordionMenuButton } from '../MenuItems/AccordionMenuButton';
import { SettingsMenuItem } from '../MenuItems/Item';

export type AccordionMenuProps = SettingsItem;

export const AccordionMenu: React.FC<AccordionMenuProps> = ({
  links,
  icon,
  children,
  isOpenSidebar,
  tooltipText,
  ...props
}) => {
  const { utils, toggle, isOpen } = useDropdownMenu({ id: 'desktop-account-settings' });
  const { button } = utils;

  return (
    <>
      <AccordionMenuButton
        isOpen={isOpen}
        isOpenSidebar={isOpenSidebar}
        icon={icon as IconDefinition}
        tooltipText={tooltipText}
        {...props}
        {...button}
        children={children}
        onClick={toggle}
      />
      <>
        {!!isOpen &&
          links?.map((link) => (
            <SettingsMenuItem
              key={link.children as string}
              role="menuitem"
              tabIndex={0}
              style={{ paddingLeft: '2.8rem' }}
              {...link}
            />
          ))}
      </>
    </>
  );
};
