// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--JAGJn{width:-moz-max-content;width:max-content;height:-moz-max-content;height:max-content;position:relative;display:flex;align-items:center;justify-content:center}.clickable--Idt48{cursor:pointer}.imagesCount--mj0XG{--size: 14px;align-items:center;background-color:var(--color-hardcoded-333333);border-radius:9999px;bottom:1px;box-sizing:border-box;color:var(--color-var-white);display:flex;height:var(--size);justify-content:center;min-width:var(--size);padding:0 .3em;position:absolute;right:1px}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/UploadableImage/UploadableImage.module.scss"],"names":[],"mappings":"AAEA,kBACE,sBAAA,CAAA,iBAAA,CACA,uBAAA,CAAA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,kBACE,cAAA,CAGF,oBACE,YAAA,CACA,kBAAA,CACA,8CAAA,CACA,oBAAA,CACA,UAAA,CACA,qBAAA,CACA,4BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,cAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":["@use 'style/colors' as *;\n\n.container {\n  width: max-content;\n  height: max-content;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.imagesCount {\n  --size: 14px;\n  align-items: center;\n  background-color: var(--color-hardcoded-333333);\n  border-radius: 9999px;\n  bottom: 1px;\n  box-sizing: border-box;\n  color: var(--color-var-white);\n  display: flex;\n  height: var(--size);\n  justify-content: center;\n  min-width: var(--size);\n  padding: 0 0.3em;\n  position: absolute;\n  right: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gold": `#ffa41c`,
	"male": `#468cc8`,
	"female": `#fd798a`,
	"mixed": `#926cdb`,
	"unknown": `#828282`,
	"gray000": `#333`,
	"gray100": `#4a4b4d`,
	"gray200": `#8b8b8b`,
	"gray300": `#b4b4b4`,
	"gray400": `#d9d9d9`,
	"gray500": `#ced4da`,
	"gray600": `#e9ecef`,
	"gray700": `#f9f9f9`,
	"gray800": `#ffffff`,
	"white": `#ffffff`,
	"whiteSmoke": `#f7f7f7`,
	"black": `#000`,
	"container": `container--JAGJn`,
	"clickable": `clickable--Idt48`,
	"imagesCount": `imagesCount--mj0XG`
};
export default ___CSS_LOADER_EXPORT___;
