/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { AppApi } from "../sdk/apis/AppApi";
import { configuration } from "./configuration";

export const useApp = () => {
  const instance = new AppApi(configuration);

  const getSetting = useAPIHook(instance.appSettingRetrieve.bind(instance));

  return {
    error: getSetting.error,
    isLoading: getSetting.isLoading,

    getSetting: getSetting.callback,
  };
};
