import { ASCENDING, DESCENDING } from 'api/ordering';

// Modes
export const OrderingModes = {
  ANIMAL_ID_ORDERING: Symbol('ANIMAL_ID_ORDERING'),
  STORE_NAME_ORDERING: Symbol('STORE_NAME_ORDERING'),
  BIRTH_DATE_ORDERING: Symbol('BIRTH_DATE_ORDERING'),
  LAST_UPDATED_ORDERING: Symbol('LAST_UPDATED_ORDERING'),
  DATE_POSTED: Symbol('DATE_POSTED'),
  PRICE_ORDERING: Symbol('PRICE_ORDERING'),
  SALE_START_TIME: Symbol('SALE_START_TIME'),
  AUCTION_END_TIME: Symbol('AUCTION_END_TIME'),
  AUCTION_CREATED: Symbol('AUCTION_CREATED'),
  TITLE_ORDERING: Symbol('TITLE_ORDERING'),
  TRAIT_COUNT_ORDERING: Symbol('TRAIT_COUNT_ORDERING'),
  DISTANCE_ORDERING: Symbol('DISTANCE_ORDERING'),
  TRAITS_AND_PRICE: Symbol('TRAITS_AND_PRICE'),
  POPULARITY_ORDERING: Symbol('POPULARITY_ORDERING'),
  BID_ORDERING: Symbol('BID_ORDERING'),
};
type OrderingMode = (typeof OrderingModes)[keyof typeof OrderingModes];

const params: [SearchOrdering['mode'], SearchOrdering['param']][] = [
  [OrderingModes.PRICE_ORDERING, 'price'],
  [OrderingModes.SALE_START_TIME, 'sale_start_time'],
  [OrderingModes.AUCTION_END_TIME, 'auction_end_time'],
  [OrderingModes.AUCTION_CREATED, 'auction_created'],
  [OrderingModes.BID_ORDERING, 'bid'],
  [OrderingModes.TRAIT_COUNT_ORDERING, 'traits'],
  [OrderingModes.TRAITS_AND_PRICE, 'traits_and_price'],
  [OrderingModes.BIRTH_DATE_ORDERING, 'birth_date'],
  [OrderingModes.DATE_POSTED, 'first_posted'],
  [OrderingModes.LAST_UPDATED_ORDERING, 'last_updated'],
  [OrderingModes.POPULARITY_ORDERING, 'popularity'],
  [OrderingModes.DISTANCE_ORDERING, 'distance'],
  [OrderingModes.TITLE_ORDERING, 'title'],
  [OrderingModes.ANIMAL_ID_ORDERING, 'animal_id'],
  [OrderingModes.STORE_NAME_ORDERING, 'store_name'],
];

type SearchOrdering = {
  mode: OrderingMode;
  direction: typeof ASCENDING | typeof DESCENDING;
  param: string;
};
const searchOrdering: SearchOrdering[] = params.reduce(
  (result, [mode, param]) => [
    ...result,
    {
      mode,
      direction: ASCENDING,
      param,
    },
    {
      mode,
      direction: DESCENDING,
      param: `-${param}`,
    },
  ],
  [] as SearchOrdering[]
);

export default searchOrdering;

export const excludeModeLabelOptions = ['Price', 'Date Posted', 'Last Updated'];

export const searchOrderingLabels: { [key: OrderingMode]: string[] } = {
  [OrderingModes.ANIMAL_ID_ORDERING]: ['Animal ID'],
  [OrderingModes.STORE_NAME_ORDERING]: ['Seller Name'],
  [OrderingModes.BIRTH_DATE_ORDERING]: ['Birth Date', 'Oldest', 'Youngest'],
  [OrderingModes.TRAIT_COUNT_ORDERING]: ['Trait Count', 'Lowest', 'Highest'],
  [OrderingModes.LAST_UPDATED_ORDERING]: ['Last Updated', 'Oldest Updated', 'Recently Updated'],
  [OrderingModes.DATE_POSTED]: ['Date Posted', 'Oldest Posted', 'Recently Posted'],
  [OrderingModes.PRICE_ORDERING]: ['Price', 'Lowest Price', 'Highest Price'],
  [OrderingModes.SALE_START_TIME]: ['Sale: Ending Soon'],
  [OrderingModes.AUCTION_END_TIME]: ['Auction: Ending Soon'],
  [OrderingModes.AUCTION_CREATED]: ['Auction', 'Oldest', 'Recently Started'],
  [OrderingModes.BID_ORDERING]: ['Auction', 'Lowest Bid', 'Highest Bid'],
  [OrderingModes.TITLE_ORDERING]: ['Title'],
  [OrderingModes.POPULARITY_ORDERING]: ['Popularity'],
  [OrderingModes.DISTANCE_ORDERING]: ['Distance'],
  [OrderingModes.TRAITS_AND_PRICE]: ['Trait Count & Price: Lowest'],
};
