import breakpoints from 'components/experimental/Breakpoint/Breakpoint.module.scss';

interface Breakpoints {
  MOBILE: number;
  MOBILE_MEDIUM: number;
  MOBILE_MEDIUM_MINUS: number;
  MOBILE_LARGE: number;
  TABLET: number;
  TABLET_LARGE: number;
  DESKTOP: number;
  DESKTOP_LARGE: number;
}

const _fromBreakpointModuleSCSS = (breakpoints: Record<string, string>): Breakpoints => {
  const convertedBreakpoints: Breakpoints = {} as Breakpoints;

  Object.entries(breakpoints).forEach(([key, value]) => {
    convertedBreakpoints[key as keyof Breakpoints] = parseInt(value, 10);
  });

  return convertedBreakpoints;
};

export const {
  MOBILE_MEDIUM,
  MOBILE_MEDIUM_MINUS,
  MOBILE_LARGE,
  TABLET,
  TABLET_LARGE,
  DESKTOP,
  DESKTOP_LARGE,
} = _fromBreakpointModuleSCSS(breakpoints);
