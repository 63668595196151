import { Components, Theme, tablePaginationClasses } from '@mui/material';

export const MuiDataGrid: Components<Omit<Theme, 'components'>>['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      [`& .${tablePaginationClasses.displayedRows}`]: { margin: 0 },
      overflowX: 'hidden',
      overscrollBehavior: 'contain',
      '--DataGrid-overlayHeight': '400px',
    },
    columnHeaders: ({ theme }) => ({
      backgroundColor: theme.palette.common.white,
    }),
    columnHeader: {
      '&:focus, &:focus-within': { outline: 'none' },
    },
    columnSeparator: {
      display: 'none',
    },
    row: ({ theme }) => ({
      '&.Mui-even, &.Mui-even.Mui-selected': {
        backgroundColor: theme.palette.common.white,
      },
      '&.Mui-odd, &.Mui-odd.Mui-selected': {
        backgroundColor: theme.palette.grey[50],
      },
    }),
    cell: {
      border: 0,
      '&:focus, &:focus-within': { outline: 'none' },
    },
    overlay: {
      height: '400px',
    },
  },
};
