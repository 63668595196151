import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { SIRE_ORDERING } from 'api/common/ordering';

export const SIRE_COLUMN = Symbol('SIRE_COLUMN');

const sireColumn = {
  id: SIRE_COLUMN,
  ordering: SIRE_ORDERING,
  label: 'Sire',
  hideable: true,
  className: style.parentColumn,

  render: ({ sires }) =>
    `${sires[0]?.animal_id ?? ''}${sires.length >= 2 ? ` +${sires.length - 1}` : ''}`,
};

export default sireColumn;
