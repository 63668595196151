/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `no_preference` - No Preference
 * * `husbandry` - Husbandry
 * * `edit_animal` - Edit Animal
 * @export
 */
export const AppQrCodeRedirectModeEnum = {
    NoPreference: 'no_preference',
    Husbandry: 'husbandry',
    EditAnimal: 'edit_animal'
} as const;
export type AppQrCodeRedirectModeEnum = typeof AppQrCodeRedirectModeEnum[keyof typeof AppQrCodeRedirectModeEnum];


export function AppQrCodeRedirectModeEnumFromJSON(json: any): AppQrCodeRedirectModeEnum {
    return AppQrCodeRedirectModeEnumFromJSONTyped(json, false);
}

export function AppQrCodeRedirectModeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppQrCodeRedirectModeEnum {
    return json as AppQrCodeRedirectModeEnum;
}

export function AppQrCodeRedirectModeEnumToJSON(value?: AppQrCodeRedirectModeEnum | null): any {
    return value as any;
}

