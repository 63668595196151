/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldData,
    FieldDataFromJSON,
    FieldDataFromJSONTyped,
    FieldDataToJSON,
} from './FieldData';

/**
 * 
 * @export
 * @interface UserMembershipBadge
 */
export interface UserMembershipBadge {
    /**
     * 
     * @type {string}
     * @memberof UserMembershipBadge
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UserMembershipBadge
     */
    subtitle: string;
    /**
     * 
     * @type {string}
     * @memberof UserMembershipBadge
     */
    color: string;
}

export function UserMembershipBadgeFromJSON(json: any): UserMembershipBadge {
    return UserMembershipBadgeFromJSONTyped(json, false);
}

export function UserMembershipBadgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMembershipBadge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'subtitle': json['subtitle'],
        'color': json['color'],
    };
}

export function UserMembershipBadgeToJSON(value?: UserMembershipBadge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'subtitle': value.subtitle,
        'color': value.color,
    };
}

