import {
  faChartLine,
  faCog,
  faSearch,
  faSignOutAlt,
  faStar,
  faStore,
  faTachometerAlt,
  faUsers,
  faFlask,
  faHeart,
  faClock,
  IconDefinition,
  faBookmark,
  faBagShopping,
  faPlus,
  faQrcode,
  faUserTag,
  faFileWaveform,
  faAddressBook,
  faLineColumns,
  faGavel,
  faHeartPulse,
} from '@fortawesome/pro-solid-svg-icons';
import { PairingSeason } from 'pages/BreedingManager/Private/types';
import { mmMessages, mmAnimal, mmEgg } from 'components/experimental/IconDefinitions';
import { NestedItem } from 'components/experimental/MUI/NestedMenu';
import { getAnimalStatusParm } from 'components/experimental/Sidebar/Lists';
import { useRegion } from 'hooks';
import { useShowExtendedContent } from 'hooks/utils/useShowExtendedContent';
import { navigationMenuText } from 'services/phrase';
import {
  generateLikedListingsPath,
  generateMyBidsListingsPath,
  generateSavedListingsPath,
} from 'services/urls';
import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../../../../src/js/app/rn-messages';
import { isIos, isAndroid } from '../../../../../../src/js/native-app/app-utils'; // TODO: maybe add js as an alias path to avoid these?
import { appendActiveTag } from '../helpers';

/**
 * Returns a link to the mobile app, if appropriate.
 */
export const getAppLink = () => {
  if (isIos()) {
    return {
      children: 'Get Apple App',
      href: 'https://apps.apple.com/us/app/morphmarket/id1585228835',
    };
  }

  if (isAndroid()) {
    return {
      children: 'Get Android App',
      href: 'https://play.google.com/store/apps/details?id=com.morphmarket.marketapp',
    };
  }

  return undefined;
};

/**
 * A list of links that goes into the main (horizontal) navbar menu.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NavbarLinks = (categories: any[], calculators: any[]) => {
  const region = useRegion();
  const { showPricingToUser } = useShowExtendedContent();

  return [
    { children: 'Categories', links: categories, showRecentlyVisitedCategories: true },
    {
      children: 'Auctions',
      href: `/${region}/c/all?auction=true&ordering=auction_end_time`,
    },
    { children: 'Stores', href: `/${region}/stores` },
    { children: 'Expos', href: `/${region}/reptile-expos` },
    { children: 'Morphpedia', href: '/morphpedia' },
    { children: 'Calculators', links: calculators, showRecentlyVisitedCalculators: true },
    { children: 'News', href: '/blog' },
    { children: 'Community', href: 'https://community.morphmarket.com' },
    { children: 'Genetic Testing', href: '/genetic-tests' },
    { children: 'Shipping', href: 'https://support.morphmarket.com/category/181-shipping' },
    { children: 'Merchandise', href: 'https://shop.morphmarket.com/' },
    { children: 'About Us', href: '/about' },
    showPricingToUser && {
      children: 'Pricing',
      href: '/pricing',
    },
    {
      children: 'Ethics',
      href: 'https://support.morphmarket.com/article/43-position-on-ethics-of-animal-keeping-business',
    },
    getAppLink(),
    { children: 'Get Help', href: 'https://support.morphmarket.com' },
  ]
    .filter((value) => !!value)
    .map(appendActiveTag);
};

export type SettingsItem = NestedItem & {
  href?: string;
  links?: SettingsItem[];
  icon?: IconDefinition;
  divider?: boolean;
  active?: boolean;
  onClick?: () => void;
  isOpenSidebar?: boolean;
  CustomComponent?: React.FC;
};

/**
 * A list of links that goes into the Settings menu in the Navbar.
 */
export const UserLinks = (props: {
  region: string;
  userName: string;
  logoutCallback: () => void;
  navigateToInventoryCallback: (href: string) => void;
  breedingSeason: PairingSeason;
  accessPermissions: {
    isSeller: boolean;
    hasOrganization: boolean;
    showPricingToUser: boolean;
  };
}) => {
  const { region, userName, logoutCallback, navigateToInventoryCallback, breedingSeason } = props;
  const { isSeller, hasOrganization, showPricingToUser } = props.accessPermissions;
  const animalHref = `/animals/${getAnimalStatusParm(!!isSeller)}`;

  return [
    isSeller && {
      children: navigationMenuText.dashboard,
      href: '/dashboard',
      icon: faTachometerAlt,
    },
    { children: 'Add Animal', href: '/inventory/add#add-item', icon: faPlus },
    {
      children: navigationMenuText.myAnimals,
      href: animalHref,
      icon: mmAnimal,
      onClick: () => navigateToInventoryCallback(animalHref),
    },
    {
      children: navigationMenuText.husbandry,
      href: '/animals/husbandry',
      icon: faHeartPulse,
    },
    {
      children: navigationMenuText.breeding,
      href: `/breeding?season=${breedingSeason}`,
      icon: faFlask,
    },
    { children: navigationMenuText.offspring, href: '/offspring/?status=current', icon: mmEgg },
    {
      children: navigationMenuText.groupsRacks,
      href: '/husbandry/groups',
      icon: faLineColumns,
    },
    {
      children: navigationMenuText.activityLog,
      href: '/husbandry/activities',
      icon: faFileWaveform,
    },
    {
      children: navigationMenuText.orders,
      href: `/orders/`,
      icon: faBagShopping,
    },
    isSeller && {
      children: 'Customers',
      href: '/customers',
      icon: faAddressBook,
    },
    isSeller && {
      children: navigationMenuText.myStore,
      href: `/stores/${userName}`,
      icon: faStore,
    },
    window.IS_APP && {
      children: 'Scan QR',
      icon: faQrcode,
      onClick: () => sendMessageToReactNative(MESSAGE_TYPES.OPEN_QR_CODE_SCANNER),
    },
    { divider: true },
    { children: navigationMenuText.messages, href: `/messages/${userName}`, icon: mmMessages },
    { children: navigationMenuText.waitlists, href: '/waitlists', icon: faClock },
    { children: navigationMenuText.savedSearches, href: '/animals/searches/', icon: faSearch },
    {
      children: navigationMenuText.myBids,
      href: generateMyBidsListingsPath(region),
      icon: faGavel,
    },
    {
      children: navigationMenuText.likedAnimals,
      href: generateLikedListingsPath(region),
      icon: faHeart,
    },
    {
      children: navigationMenuText.savedAnimals,
      href: generateSavedListingsPath(region),
      icon: faBookmark,
    },
    { children: navigationMenuText.following, href: '/accounts/following', icon: faUsers },
    isSeller && {
      children: navigationMenuText.userGroups,
      href: '/accounts/user-groups',
      icon: faUserTag,
    },
    { divider: true },
    {
      children: navigationMenuText.accountSettings,
      icon: faCog,
      links: [
        { children: navigationMenuText.general, href: '/accounts/settings' },
        hasOrganization && {
          children: navigationMenuText.orgProfile,
          href: `/stores/${userName}/edit`,
        },
        isSeller && {
          children: navigationMenuText.sellerProfile,
          href: `/stores/${userName}/edit`,
        },
        !hasOrganization &&
          !isSeller && {
            children: navigationMenuText.startSelling,
            href: `/stores/${userName}/create`,
          },
        { children: navigationMenuText.userProfile, href: `/users/${userName}/edit` },
        { children: navigationMenuText.labelSettings, href: `/users/${userName}/label_settings` },
        { children: navigationMenuText.notifications, href: `/accounts/settings/notifications/` },
        showPricingToUser && {
          children: navigationMenuText.subscriptions,
          href: '/accounts/subscriptions',
        },
      ]
        .filter((value) => !!value)
        .map(appendActiveTag),
    },
    isSeller && {
      children: navigationMenuText.sellerRatings,
      href: `/stores/${userName}/ratings`,
      icon: faChartLine,
    },
    { children: navigationMenuText.buyerRatings, href: `/users/${userName}/`, icon: faStar },
    { divider: true },
    { children: 'Social', href: 'https://coldblooded.com/', icon: faStar },
    !!userName && { children: 'Logout', onClick: logoutCallback, icon: faSignOutAlt },
  ]
    .filter((value) => !!value)
    .map(appendActiveTag);
};

export const MobileGuestLinks = [
  { children: 'Log In', href: '/accounts/login' },
  { children: 'Sign Up', href: '/accounts/signup' },
  { divider: true },
];
