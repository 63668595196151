/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This serializer is used to deserialize thread tags.
 * @export
 * @interface ThreadTagWrite
 */
export interface ThreadTagWrite {
    /**
     * 
     * @type {number}
     * @memberof ThreadTagWrite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreadTagWrite
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTagWrite
     */
    color?: string;
}

export function ThreadTagWriteFromJSON(json: any): ThreadTagWrite {
    return ThreadTagWriteFromJSONTyped(json, false);
}

export function ThreadTagWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThreadTagWrite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function ThreadTagWriteToJSON(value?: ThreadTagWrite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'color': value.color,
    };
}

