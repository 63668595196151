import { RouteObject } from 'react-router-dom';

const ShipmentsList = () => import('pages/Shipping/Shipments/List/ListShipments');
const ShipmentsAdd = () => import('pages/Shipping/Shipments/Add/AddShipments');

export const shipmentsRoutes: RouteObject[] = [
  {
    path: 'ship/',
    children: [
      {
        path: 'shipments/',
        children: [
          {
            path: '',
            lazy: ShipmentsList,
          },
          {
            path: 'add/',
            lazy: ShipmentsAdd,
          },
        ],
      },
    ],
  },
];
