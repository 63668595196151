import { useMemo } from 'react';
import { faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { TextFieldProps } from '@mui/material';
import { EnumsBucket } from 'constants/enums';
import { useToggleFormOrRedirect } from 'pages/Search/components/services/useAdvancedSearchRedirect';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import { generateNestedMenu, NestedMenu } from 'components/experimental/MUI/NestedMenu';
import { FilterIcon } from 'components/Icons/FilterIcon/FilterIcon';
import { useRegion } from 'hooks';
import { useRecentlyVisitedCategories } from 'hooks/useRecentlyVisited';
import { recentCategoriesToLinks, replaceRegionInMenu } from '../../helpers';
import { appendActiveTag } from '../../helpers';
import { IconButton } from '../Buttons';
import { NestedMenuItem } from '../MenuItems/Item';
import { GenericMenu } from '../Menus/GenericMenu';
import { InputField } from './SearchInput';
import styles from './MobileSearchField.module.scss';
import iconButtonStyles from '../Buttons/IconButton.module.scss';

export type MobileSearchFieldProps = TextFieldProps & {
  isOpen?: boolean;
  close: () => void;
};

export const MobileSearchField: React.FC<MobileSearchFieldProps> = ({
  isOpen,
  close,
  ...props
}) => {
  const linkProps = useToggleFormOrRedirect();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.input}>
          <InputField {...props} autoFocus={isOpen} />
        </div>
        <div className={styles.buttons}>
          <a className={iconButtonStyles.link} {...linkProps} onClick={() => close()}>
            <FilterIcon />
          </a>
          <InlineCategoriesMenu />
        </div>
      </div>
    </>
  );
};

const InlineCategoriesMenu: React.FC = () => {
  const { main_category_menu } = EnumsBucket;
  const region = useRegion();
  const { recentCategories, trackVisit } = useRecentlyVisitedCategories();
  const categories = useMemo(
    () => replaceRegionInMenu(main_category_menu?.links, region),
    [main_category_menu, region]
  );
  const name = 'Categories';
  const { utils, close } = useDropdownMenu({ id: `${name}-menu` });
  const { button, menu } = utils;
  const linksWithRecentlyVisitedCategories = useMemo(() => {
    return [
      { variant: 'close', name, onClick: close },
      ...recentCategoriesToLinks(recentCategories, region),
      ...(categories || []),
    ];
  }, [categories, region, recentCategories]);
  const onClickCapture = (e: any) => {
    const isAnchor = e?.target?.tagName?.toLowerCase() === 'a';
    const categoryId = e?.target.getAttribute('id');
    !!isAnchor && trackVisit(categoryId);
  };

  return (
    <>
      <IconButton icon={faSitemap} {...button} />
      <NestedMenu>
        {({ goTo, goBack, resetMenu }, subMenu) => (
          <GenericMenu
            {...menu}
            onClose={() => {
              close();
              resetMenu(300);
            }}
          >
            {generateNestedMenu({
              items: (subMenu || linksWithRecentlyVisitedCategories).map(appendActiveTag),
              keyProps: ['name', 'children'],
              Component: NestedMenuItem,
              optionalProps: {
                goTo: goTo(subMenu || linksWithRecentlyVisitedCategories),
                goBack,
                onClickCapture,
              },
            })}
          </GenericMenu>
        )}
      </NestedMenu>
    </>
  );
};
