import { Link } from 'react-router-dom';
import { isReactRoute } from 'routes/utils/routeUtils';
import styles from './Logo.module.scss';

declare global {
  interface Window {
    LOGO_URL: string;
  }
}

export const TopLeftLogo = ({ isDesktop }: { isDesktop: boolean }) => {
  const isReact = isReactRoute(window.location.pathname);
  const style = isDesktop ? styles.desktopLogo : styles.mobileLogo;

  if (isReact) {
    return (
      <Link to="/" className={style}>
        <img src={window.LOGO_URL} alt="MorphMarket Logo" />
      </Link>
    );
  }
  return (
    <a href="/" className={style}>
      <img src={window.LOGO_URL} alt="MorphMarket Logo" />
    </a>
  );
};
