import { useSearchFilters } from 'pages/Search/components/services/useSearchFilters';
import { ContextTabs } from 'components/ContextTabs';

const ListingsMenu = () => {
  const { filters } = useSearchFilters();

  return <ContextTabs filters={filters} />;
};

export default ListingsMenu;
