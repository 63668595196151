/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `unspecified` - Unspecified
 * * `firm` - Firm Price
 * * `negotiable` - Will Consider
 * @export
 */
export const PriceFlexibilityEnum = {
    Unspecified: 'unspecified',
    Firm: 'firm',
    Negotiable: 'negotiable'
} as const;
export type PriceFlexibilityEnum = typeof PriceFlexibilityEnum[keyof typeof PriceFlexibilityEnum];


export function PriceFlexibilityEnumFromJSON(json: any): PriceFlexibilityEnum {
    return PriceFlexibilityEnumFromJSONTyped(json, false);
}

export function PriceFlexibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceFlexibilityEnum {
    return json as PriceFlexibilityEnum;
}

export function PriceFlexibilityEnumToJSON(value?: PriceFlexibilityEnum | null): any {
    return value as any;
}

