import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import UploadableImage from 'components/UploadableImage/UploadableImage';
import { IMAGE_ORDERING } from 'api/common/ordering';
import { DESCENDING } from 'api/ordering';

export const IMAGE_COLUMN = Symbol('IMAGE_COLUMN');

const imageColumn = {
  id: IMAGE_COLUMN,
  ordering: IMAGE_ORDERING,
  label: 'Image',
  hideable: true,
  className: style.center,
  defaultDirection: DESCENDING,
  disableOrdering: true,

  // TODO remove images coalescing
  render: ({ thumb_image, title, images, image_count }) => (
    <UploadableImage
      title={title}
      srcset={thumb_image}
      images={images ?? []}
      imageCount={image_count}
    />
  ),
};

export default imageColumn;
