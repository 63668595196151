import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gray300, gray000 } = colors;

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      color: gray300,
      '&.Mui-checked': {
        color: gray000,
      },
      '&.MuiCheckbox-indeterminate': {
        color: gray000,
      },
    },
  },
};
