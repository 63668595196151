import { KeyboardEventHandler, useRef, useState } from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, Link, MenuItem, Popper } from '@mui/material';
import Autocomplete, {
  AutocompleteClasses,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Highlighted } from 'components/experimental/Highlighted';
import { useRegion } from 'hooks';
import { useCategory } from 'hooks/useCategory';
import { useTextSearch } from 'hooks/useTextSearch';
import { useSafariMobileScrollFix } from '../../mobile/components/QuickSearch/helpers';
import styles from './Search.module.scss';

type Option = {
  href: string;
  label: string;
  type:
    | 'categorySearch'
    | 'category'
    | 'traitInCategory'
    | 'storeSearch'
    | 'store'
    | 'storeInCategory'
    | 'disabled';
};

type SearchFieldProps = Partial<AutocompleteProps<Option, undefined, undefined, undefined>>;

const classes: Partial<AutocompleteClasses> = {
  root: styles.root,
  endAdornment: styles.hidden,
  listbox: styles.listbox,
  option: styles.option,
  noOptions: styles.noOption,
  inputRoot: styles.inputRoot,
  popper: styles.popper,
};

export const SearchField: React.FC<SearchFieldProps> = (props) => {
  const [value, setValue] = useState<string>('');
  const { data, search } = useTextSearch();
  const { categories, stores, traits } = data;
  const region = useRegion();
  const category = useCategory();
  const notice = getTraitsNotice(traits?.length > 8);
  const options = [...categories, ...traits, ...(notice || []), ...stores] as Option[];
  const runDefaultSearchIfEmpty: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && !options?.length) {
      window.location.href = `/${region}/c/${category?.path}`;
    }
  };

  return (
    <Autocomplete
      autoHighlight
      classes={classes}
      inputValue={value}
      PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
      clearOnBlur={true}
      onKeyDown={runDefaultSearchIfEmpty}
      onChange={(_e, value) => {
        if (!!value) {
          window.location.href = value?.href;
        }
      }}
      onInputChange={(e, value, reason) => {
        if (reason === 'reset') {
          setValue('');
          search('');
        } else if (e && e?.type !== 'keydown') {
          search(value);
          setValue(value);
        }
      }}
      filterOptions={(all) => all}
      getOptionDisabled={({ type }) => type === 'disabled'}
      options={options}
      noOptionsText="Enter a few more letters..."
      renderOption={renderOption}
      renderInput={InputField}
      {...props}
    />
  );
};

export const InputField: React.FC<TextFieldProps> = (params) => {
  const { placeholder: customPlaceholder, ...otherProps } = params;
  const placeholder = customPlaceholder || `Search MorphMarket`;
  const ref = useRef<HTMLInputElement>();
  useSafariMobileScrollFix(ref.current);

  return (
    <TextField
      {...otherProps}
      placeholder={placeholder}
      InputProps={{
        classes: {
          root: styles.customTextField,
        },
        ...otherProps.InputProps,
        inputRef: ref,
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
    />
  );
};

/**
 * A notice should be displayed if the number of traits is greater than a fixed number. This lets the user know that there are more traits than are shown in the results and tells him to narrow his search (move to a subcategory) in order to see more of them.
 */
export const getTraitsNotice = (condition: boolean) =>
  condition && [
    { label: 'Too many traits to display, navigate to a subcategory...', type: 'disabled' },
  ];

export const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  state: AutocompleteRenderOptionState
) => {
  const addDivider = option.type === 'storeSearch';
  const dividerProps = addDivider
    ? { divider: true, classes: { divider: styles.listDivider } }
    : {};

  // TODO: Once the site has been fully migrated to React, replace <a> with <Link />
  return (
    <MenuItem
      {...(props as any)}
      {...dividerProps}
      onClick={(e) => e.stopPropagation()}
      component={Link}
      href={option.href}
    >
      <Highlighted highlight={state.inputValue}>{option.label}</Highlighted>
    </MenuItem>
  );
};
