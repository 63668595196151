import { fetchApi } from 'api/utils';
import { settings } from './settings';

const url = window.API_ENDPOINTS.USER_PREFERENCES;

const preferences = {
  _items: {} as typeof settings,
  async fetch() {
    const response = await fetchApi(url);
    if (!response.ok) {
      return null;
    }
    const apiPreferencesResponse = await response.json();
    return this.unserialize(apiPreferencesResponse);
  },
  unserialize(apiPreferencesResponse) {
    return Reflect.ownKeys(settings).reduce((result, preferenceId) => {
      const { apiParam } = settings[preferenceId];
      const apiValue = apiPreferencesResponse[apiParam] ?? null;
      return {
        ...result,
        [preferenceId]: this.parse(preferenceId, apiValue),
      };
    }, {});
  },

  setFromApi(apiPreferencesResponse) {
    this._items = this.unserialize(apiPreferencesResponse);
  },

  parse(key, value) {
    if (!settings.hasOwnProperty(key)) {
      return null;
    }
    const item = settings[key];
    let result = value;
    if (result == null) {
      result = item.defaultValue ?? null;
    }
    if (item.unserialize) {
      result = item.unserialize(result);
    }
    return result;
  },

  get(key) {
    return this._items[key];
  },

  getAll() {
    return this._items;
  },

  async set(key, value) {
    if (settings[key]?.serialize) {
      const k = settings[key].apiParam;
      const v = settings[key]?.serialize(value);
      window.dispatchEvent(new CustomEvent('DISPATCH_PREFERENCES_SET', { detail: { [k]: v } }));
    }
  },
};

export default preferences;
