/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Public` - Public
 * * `Unlisted` - Unlisted
 * * `Private` - Private
 * @export
 */
export const VisibilityDisplayEnum = {
    Public: 'Public',
    Unlisted: 'Unlisted',
    Private: 'Private'
} as const;
export type VisibilityDisplayEnum = typeof VisibilityDisplayEnum[keyof typeof VisibilityDisplayEnum];


export function VisibilityDisplayEnumFromJSON(json: any): VisibilityDisplayEnum {
    return VisibilityDisplayEnumFromJSONTyped(json, false);
}

export function VisibilityDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilityDisplayEnum {
    return json as VisibilityDisplayEnum;
}

export function VisibilityDisplayEnumToJSON(value?: VisibilityDisplayEnum | null): any {
    return value as any;
}

