import { useNavigate } from 'react-router-dom';

export enum InventoryNavigationInformation {
  navigatedViaReactRouter = 'navigatedViaReactRouter',
}

/**
 * This hook manages the navigation state for the inventory page,
 * ensuring that internal navigation occurs through this hook.
 * It distinguishes between internal navigation via React Router actions and direct user navigation.
 * When restoring the last visited inventory URL, it only applies if the navigation was internal.
 * If the user navigates directly with their own filters applied, it is treated as a new visit,
 * and the URL is not restored from the previous session.
 * Use this hook for all internal navigation to the inventory page to preserve the correct last visited URL.
 */
export const useInventoryNavigation = () => {
  const navigate = useNavigate();

  const navigateToInventory = (inventoryUrl: string) => {
    navigate(inventoryUrl, {
      state: { message: InventoryNavigationInformation.navigatedViaReactRouter },
    });
  };

  return navigateToInventory;
};
