import { RouteObject } from 'react-router-dom';

const EditInventory = () => import('pages/EditInventory/EditInventory');

export const inventoryRoutes: RouteObject[] = [
  {
    path: 'inventory',
    children: [
      {
        path: 'edit/:id',
        async lazy() {
          const { Component } = await EditInventory();
          return { element: <Component key="edit" /> };
        },
      },
      {
        path: 'import-item',
        async lazy() {
          const { Component } = await EditInventory();
          return { element: <Component key="import" operation="create" /> };
        },
      },
      {
        path: 'add',
        async lazy() {
          const { Component } = await EditInventory();
          return { element: <Component key="create" operation="create" /> };
        },
      },
      {
        path: 'clone/:id',
        async lazy() {
          const { Component } = await EditInventory();
          return { element: <Component key="clone" operation="clone" /> };
        },
      },
    ],
  },
];
