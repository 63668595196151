/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StoreSearchStoreRatingDetails,
    StoreSearchStoreRatingDetailsFromJSON,
    StoreSearchStoreRatingDetailsFromJSONTyped,
    StoreSearchStoreRatingDetailsToJSON,
} from './StoreSearchStoreRatingDetails';

/**
 * Serializer, which is used to return the store info for the store list/search page.
 * @export
 * @interface StoreSearch
 */
export interface StoreSearch {
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly displayName: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly displayLabel: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly owner: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly logoUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly organizationType: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreSearch
     */
    readonly adsCount: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly storeSearchUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearch
     */
    readonly brandedMembership: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly formattedAddress: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearch
     */
    readonly hasWaitlists: boolean | null;
    /**
     * 
     * @type {StoreSearchStoreRatingDetails}
     * @memberof StoreSearch
     */
    storeRatingDetails: StoreSearchStoreRatingDetails | null;
    /**
     * 
     * @type {string}
     * @memberof StoreSearch
     */
    readonly storePath: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearch
     */
    readonly isFollowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearch
     */
    readonly isBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearch
     */
    unauthVisibilityOwnerDetails?: boolean;
}

export function StoreSearchFromJSON(json: any): StoreSearch {
    return StoreSearchFromJSONTyped(json, false);
}

export function StoreSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['display_name'],
        'displayLabel': json['display_label'],
        'owner': json['owner'],
        'logoUrl': json['logo_url'],
        'organizationType': json['organization_type'],
        'adsCount': json['ads_count'],
        'storeSearchUrl': json['store_search_url'],
        'brandedMembership': json['branded_membership'],
        'formattedAddress': json['formatted_address'],
        'hasWaitlists': json['has_waitlists'],
        'storeRatingDetails': StoreSearchStoreRatingDetailsFromJSON(json['store_rating_details']),
        'storePath': json['store_path'],
        'isFollowed': json['is_followed'],
        'isBlocked': json['is_blocked'],
        'unauthVisibilityOwnerDetails': !exists(json, 'unauth_visibility_owner_details') ? undefined : json['unauth_visibility_owner_details'],
    };
}

export function StoreSearchToJSON(value?: StoreSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'store_rating_details': StoreSearchStoreRatingDetailsToJSON(value.storeRatingDetails),
        'unauth_visibility_owner_details': value.unauthVisibilityOwnerDetails,
    };
}

