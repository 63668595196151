import { STORES_DATA_URL } from 'constants/index';
import { MINIMAL_STORE } from 'hooks/useTextSearch/types';
import { uFetch } from 'services/fetch';

export const fetchStores = async (): Promise<MINIMAL_STORE[]> => {
  const response = await uFetch(STORES_DATA_URL);
  try {
    const data = await response.clone().json();
    return data;
    // An exception can occur, for example, when CloudFront gives a 404 for that JSON file.
    // It's unclear when this happens -- perhaps when a file is out of date and kicked from the cache?
  } catch (error) {
    console.warn(error); // eslint-disable-line
    return [];
  }
};
