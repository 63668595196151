import * as Sentry from '@sentry/browser';
import { useRouteError } from 'react-router-dom';
import Error500 from 'components/Errors/Error500/Error500';
import ErrorLocalStorage from 'components/Errors/ErrorLocalStorage/ErrorLocalStorage';

declare global {
  interface Window {
    Sentry: typeof Sentry;
  }
}

export const ErrorBoundary = () => {
  const error = useRouteError() as { name?: string; message?: string };

  if (!error) return;

  if (window.Sentry) {
    window.Sentry.withScope((scope) => {
      Object.entries(error).forEach(([key, value]) => {
        scope.setExtra(key, value);
      });
      window.Sentry.captureException(error);
    });
  }

  // See issue #5170
  if (
    error?.name === 'SecurityError' &&
    (error?.message?.includes('localStorage') || error?.message?.includes('sessionStorage'))
  ) {
    return <ErrorLocalStorage errorName={error?.name} errorMessage={error?.message} />;
  }

  return <Error500 errorName={error?.name} errorMessage={error?.message} />;
};
