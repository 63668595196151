import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { IMPRESSIONS_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';

export const IMPRESSIONS_COLUMN = Symbol('IMPRESSIONS_COLUMN');

const impressionsColumn = {
  id: IMPRESSIONS_COLUMN,
  ordering: IMPRESSIONS_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Impressions',
  shortLabel: 'Impr.',
  hideable: true,
  className: style.countColumn,

  render: ({ impression_count }: { impression_count: number }) => impression_count,
};

export default impressionsColumn;
