/**
 * IMPORTANT!
 * Do not use any fancy Javascript language functionality here as this file is directly imported by the browser
 * and needs to work in old versions of Safari (11-13) where many modern features just aren't supported.
 * When in doubt, check caniuse.com to see if that language feature is supported.
 */

import compact from 'lodash/compact';
import { EnumsBucket } from './enums';
const { categories: rawCategories } = EnumsBucket;

const buildFamilyTree = (parent_id) => {
  const familyTree = [];
  let ancestor_id = parent_id;

  while (ancestor_id !== undefined) {
    const ancestor = rawCategories.find(({ id }) => id === ancestor_id);
    ancestor_id = ancestor && ancestor.parent_id;
    familyTree.unshift(ancestor);
  }

  return [...new Set(compact(familyTree))];
};

export const categories = rawCategories.map(({ id, label, value, path, parent_id, has_wizard }) => {
  return {
    id,
    value,
    label,
    path,
    has_wizard,
    ancestors: buildFamilyTree(parent_id),
    children: rawCategories.filter(({ parent_id }) => parent_id === id),
  };
});
