import { KeyboardEventHandler, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteClasses, Popper } from '@mui/material';
import { DesktopAdvancedSearch } from 'components/experimental/Navigation/components/AdvancedSearch/AdvancedSearch';
import {
  getTraitsNotice,
  InputField,
  renderOption,
} from 'components/experimental/Navigation/components/Search/SearchInput';
import { useRegion } from 'hooks';
import { useCategory } from 'hooks/useCategory';
import { useTextSearch } from 'hooks/useTextSearch';
import { useOpenState } from 'hooks/utils/useOpenState';
import { useLastScrollDirection } from './useLastScrollDirection';
import styles from './QuickSearch.module.scss';

type Option = {
  href: string;
  label: string;
  type:
    | 'categorySearch'
    | 'category'
    | 'traitInCategory'
    | 'storeSearch'
    | 'store'
    | 'storeInCategory'
    | 'disabled';
};

export const QuickSearch = () => {
  const classes: Partial<AutocompleteClasses> = {
    root: styles.root,
    endAdornment: styles.hidden,
    listbox: styles.listbox,
    option: styles.option,
    noOptions: styles.noOption,
    inputRoot: styles.inputRoot,
  };
  const { isOpen, open, close } = useOpenState();
  const [value, setValue] = useState('');
  const { data, search } = useTextSearch();
  const { categories, stores, traits } = data;
  const notice = getTraitsNotice(traits?.length > 8);
  const region = useRegion();
  const category = useCategory();
  const direction = useLastScrollDirection();
  const options = [...categories, ...traits, ...(notice || []), ...stores] as Option[];
  const runDefaultSearchIfEmpty: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && !options?.length) {
      window.location.href = `/${region}/c/${category?.path}`;
    }
  };

  useEffect(() => {
    if (direction === 'down') {
      close();
    }
  }, [direction]);

  return (
    <div className={styles.searchBar}>
      <div className={styles.innerContainer}>
        <Autocomplete
          classes={classes}
          open={isOpen}
          onOpen={open}
          onClose={close}
          inputValue={value}
          clearOnBlur={true}
          onKeyDown={runDefaultSearchIfEmpty}
          slotProps={{ paper: { className: styles.paper } }}
          PopperComponent={({ ...props }) => {
            const anchorEl = props.anchorEl as HTMLElement;

            if (!anchorEl) {
              console.warn(
                'Missing hoisting element, the search suggestions might not be positioned properly'
              );
            }

            return (
              <Popper
                {...props}
                placement="bottom-start"
                anchorEl={anchorEl}
                slotProps={{ root: { style: { width: '100%' } } }}
              />
            );
          }}
          onChange={(_e, value) => {
            if (!!value) {
              window.location.href = value?.href;
            }
          }}
          onInputChange={(e, value, reason) => {
            if (reason === 'reset') {
              setValue('');
              search('');
            } else if (e && e?.type !== 'keydown') {
              search(value);
              setValue(value);
            }
          }}
          filterOptions={(all) => all}
          getOptionDisabled={({ type }) => type === 'disabled'}
          options={options}
          noOptionsText="Enter a few more letters..."
          renderOption={renderOption}
          renderInput={InputField}
        />
        <DesktopAdvancedSearch />
      </div>
    </div>
  );
};
