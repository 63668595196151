import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { DAM_ORDERING } from 'api/common/ordering';

export const DAM_COLUMN = Symbol('DAM_COLUMN');

const damColumn = {
  id: DAM_COLUMN,
  ordering: DAM_ORDERING,
  label: 'Dam',
  hideable: true,
  className: style.parentColumn,

  render: ({ dams }) =>
    `${dams[0]?.animal_id ?? ''}${dams.length >= 2 ? ` +${dams.length - 1}` : ''}`,
};

export default damColumn;
