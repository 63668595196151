/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `desktop` - LoginClientType.DESKTOP
 * * `tablet` - LoginClientType.TABLET
 * * `mobile` - LoginClientType.MOBILE
 * @export
 */
export const DisplayPreferencesUpdateDeviceTypeEnum = {
    Desktop: 'desktop',
    Tablet: 'tablet',
    Mobile: 'mobile'
} as const;
export type DisplayPreferencesUpdateDeviceTypeEnum = typeof DisplayPreferencesUpdateDeviceTypeEnum[keyof typeof DisplayPreferencesUpdateDeviceTypeEnum];


export function DisplayPreferencesUpdateDeviceTypeEnumFromJSON(json: any): DisplayPreferencesUpdateDeviceTypeEnum {
    return DisplayPreferencesUpdateDeviceTypeEnumFromJSONTyped(json, false);
}

export function DisplayPreferencesUpdateDeviceTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesUpdateDeviceTypeEnum {
    return json as DisplayPreferencesUpdateDeviceTypeEnum;
}

export function DisplayPreferencesUpdateDeviceTypeEnumToJSON(value?: DisplayPreferencesUpdateDeviceTypeEnum | null): any {
    return value as any;
}

