import { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks';
import { useIsApp } from 'hooks/utils/useIsApp';
import styles from './Breakpoint.module.scss';

export const BREAKPOINT_SIZES = {
  mobile: [0, 320],
  mobileLarge: [321, 576],
  tablet: [577, 768],
  tabletLarge: [769, 820],
  desktop: [821, 991],
  desktopLarge: [992, 9999],
};

export type GenericBreakpoints =
  | 'mobile'
  | 'mobileLarge'
  | 'tablet'
  | 'tabletLarge'
  | 'desktop'
  | 'desktopLarge';

export type BreakpointProps = {
  children: React.ReactNode;
  hidden?: GenericBreakpoints[];
  /**
   * Whether the component should not even be rendered if it's not visible.
   */
  hard?: boolean;
  style?: CSSProperties;
  /**
   * Hide if window size is within range. For fine-grained control.
   * Only works with the "hard" prop for now.
   */
  customDimensions?: [number, number];
};

/**
 * A container that is shown/hidden depending on the width of the viewport.
 */
export const Breakpoint: React.FC<BreakpointProps> = ({
  children,
  style,
  hard,
  customDimensions,
  hidden = [],
}) => {
  const className = classNames([
    styles.container,
    ...hidden.map((breakpoint) => styles[breakpoint]),
  ]);

  if (hard) {
    return (
      <HardWrapper hidden={hidden} customDimensions={customDimensions}>
        {children}
      </HardWrapper>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

/**
 * Convenience component of Breakpoint - only visible on desktop resolutions.
 */
export const Desktop: React.FC<{
  children: React.ReactNode;
  style?: CSSProperties;
  hard?: boolean;
}> = ({ children, style, hard }) => {
  return (
    <Breakpoint hidden={['mobile', 'mobileLarge', 'tablet']} style={style} hard={hard}>
      {children}
    </Breakpoint>
  );
};

/**
 * Convenience component of Breakpoint - only visible on tablet and larger resolutions.
 */
export const Tablet: React.FC<{
  children: React.ReactNode;
  style?: CSSProperties;
  hard?: boolean;
}> = ({ children, style, hard }) => {
  return (
    <Breakpoint hidden={['mobile', 'mobileLarge']} style={style} hard={hard}>
      {children}
    </Breakpoint>
  );
};

/**
 * Convenience component of Breakpoint - only visible on mobile resolutions.
 */
export const Mobile: React.FC<{
  children: React.ReactNode;
  style?: CSSProperties;
  hard?: boolean;
}> = ({ children, style, hard }) => {
  return (
    <Breakpoint hidden={['tabletLarge', 'desktop', 'desktopLarge']} style={style} hard={hard}>
      {children}
    </Breakpoint>
  );
};

const HardWrapper: React.FC<{
  children: React.ReactNode;
  hidden: GenericBreakpoints[];
  customDimensions?: [number, number];
}> = ({ children, customDimensions, hidden }) => {
  const { width } = useWindowSize();
  const isHidden = useMemo(() => {
    if (width === undefined) {
      return true;
    }

    if (!!customDimensions) {
      const [min, max] = customDimensions;
      return width >= min && width <= max;
    }

    return hidden.some((breakpoint) => {
      const [min, max] = BREAKPOINT_SIZES[breakpoint];
      return width >= min && width <= max;
    });
  }, [hidden, width, customDimensions]);

  return <>{!isHidden && children}</>;
};

/**
 * Renders children only on mobile devices, regardless of viewport dimensions.
 */
export const MobileDeviceOnly: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isApp = useIsApp();
  const isMobileDevice = /Android|webOS|iPhone|iPad/i.test(navigator.userAgent);

  if ((!!isMobileDevice || isApp) && !!children) {
    return children as React.ReactElement;
  }

  return null;
};
