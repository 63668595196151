// NB: This must match the user-agent being sent via the WebView of the app
const APP_USER_AGENT = 'morphmarket-web-view';

const _isAppUserAgent = () => navigator.userAgent.includes(APP_USER_AGENT);

// PURPOSE: Tell us whether or not the code is executing in the context of the Android/iOS app.
// Will return false if only executing within a web browser (e.g. Chrome, Safari) on one of these devices.
//
// USAGE: Within a React component:
// () =>
//     const isApp = useIsApp();
export const useIsApp = (): boolean => {
  return _isAppUserAgent();
};
