/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ItemOriginEnum,
    ItemOriginEnumFromJSON,
    ItemOriginEnumFromJSONTyped,
    ItemOriginEnumToJSON,
} from './ItemOriginEnum';
import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';

/**
 * @type AnimalItemOrigin
 * 
 * @export
 */
export type AnimalItemOrigin = ItemOriginEnum | NullEnum;

export function AnimalItemOriginFromJSON(json: any): AnimalItemOrigin {
    return AnimalItemOriginFromJSONTyped(json, false);
}

export function AnimalItemOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalItemOrigin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return ItemOriginEnumFromJSONTyped(json, true);
}

export function AnimalItemOriginToJSON(value?: AnimalItemOrigin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return value;
}

