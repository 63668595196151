/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * @type DisplayPreferencesUpdateTheme
 * 
 * @export
 */
export type DisplayPreferencesUpdateTheme = BlankEnum | ThemeEnum;

export function DisplayPreferencesUpdateThemeFromJSON(json: any): DisplayPreferencesUpdateTheme {
    return DisplayPreferencesUpdateThemeFromJSONTyped(json, false);
}

export function DisplayPreferencesUpdateThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesUpdateTheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...BlankEnumFromJSONTyped(json, true), ...ThemeEnumFromJSONTyped(json, true) };
}

export function DisplayPreferencesUpdateThemeToJSON(value?: DisplayPreferencesUpdateTheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return value;
}

