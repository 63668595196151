import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { categories } from 'constants/enums/generated/categories';
import { generateSingletonHook } from 'context/SingletonContext';

// Utility function for checking if a category is a descendant of the live-plants category
export const isPlantCategory = (categoryId: number, includeSelf = false) => {
  const livePlants = 'live-plants';
  const category = categories.find((category) => category.id === categoryId);

  if (includeSelf && category?.value === livePlants) {
    return true;
  }

  const categoryAncestors = category?.ancestors.map(({ value }) => value) ?? [];
  return categoryAncestors.includes(livePlants);
};

// Use this hook inside of Formik context to determine if the current category is a plant
const useHook = () => {
  const [{ value }] = useField('categoryId');

  const [isPlant, setIsPlant] = useState(false);
  useEffect(() => {
    const isPlant = isPlantCategory(value);
    setIsPlant(isPlant);
  }, [categories, value]);

  return isPlant;
};

export const [IsPlantProvider, useIsPlant] = generateSingletonHook(useHook);
