/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnimalTrait,
    AnimalTraitFromJSON,
    AnimalTraitFromJSONTyped,
    AnimalTraitToJSON,
} from './AnimalTrait';
import {
    ApproximateDate,
    ApproximateDateFromJSON,
    ApproximateDateFromJSONTyped,
    ApproximateDateToJSON,
} from './ApproximateDate';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';
import {
    OffspringGroupListDamsInner,
    OffspringGroupListDamsInnerFromJSON,
    OffspringGroupListDamsInnerFromJSONTyped,
    OffspringGroupListDamsInnerToJSON,
} from './OffspringGroupListDamsInner';
import {
    VisibilityDisplayEnum,
    VisibilityDisplayEnumFromJSON,
    VisibilityDisplayEnumFromJSONTyped,
    VisibilityDisplayEnumToJSON,
} from './VisibilityDisplayEnum';
import {
    VisibilityEnum,
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * 
 * @export
 * @interface OffspringGroupList
 */
export interface OffspringGroupList {
    /**
     * 
     * @type {number}
     * @memberof OffspringGroupList
     */
    readonly key: number;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly url: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly path: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly viewUrl: string | null;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof OffspringGroupList
     */
    readonly thumbImage: Array<ImageVariants> | null;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroupList
     */
    readonly imageCount: number;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly offspringGroupType: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly categoryDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly category: string;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroupList
     */
    readonly categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly layDateDisplay: string | null;
    /**
     * 
     * @type {Array<OffspringGroupListDamsInner>}
     * @memberof OffspringGroupList
     */
    readonly dams: Array<OffspringGroupListDamsInner>;
    /**
     * 
     * @type {Array<OffspringGroupListDamsInner>}
     * @memberof OffspringGroupList
     */
    readonly sires: Array<OffspringGroupListDamsInner>;
    /**
     * 
     * @type {Array<AnimalTrait>}
     * @memberof OffspringGroupList
     */
    readonly traits: Array<AnimalTrait>;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    publicNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    privateNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly viableEggsCountDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly nonViableEggsCountDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly eggsCountDisplay: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OffspringGroupList
     */
    readonly isEggLaying: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly birthDateDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly childrenCountDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly modifiedDisplay: string | null;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof OffspringGroupList
     */
    visibility: VisibilityEnum;
    /**
     * 
     * @type {VisibilityDisplayEnum}
     * @memberof OffspringGroupList
     */
    readonly visibilityDisplay: VisibilityDisplayEnum | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly damCrossSireOrChildrenTraits: string;
    /**
     * 
     * @type {ApproximateDate}
     * @memberof OffspringGroupList
     */
    birthDate?: ApproximateDate;
    /**
     * 
     * @type {Array<number>}
     * @memberof OffspringGroupList
     */
    readonly offsprings: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly title: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly parents: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroupList
     */
    readonly eggsOrOffspringAndBirthDate: string;
}

export function OffspringGroupListFromJSON(json: any): OffspringGroupList {
    return OffspringGroupListFromJSONTyped(json, false);
}

export function OffspringGroupListFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffspringGroupList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'url': json['url'],
        'path': json['path'],
        'viewUrl': json['view_url'],
        'thumbImage': (json['thumb_image'] === null ? null : (json['thumb_image'] as Array<any>).map(ImageVariantsFromJSON)),
        'imageCount': json['image_count'],
        'groupId': json['group_id'],
        'offspringGroupType': json['offspring_group_type'],
        'categoryDisplay': json['category_display'],
        'category': json['category'],
        'categoryId': json['category_id'],
        'layDateDisplay': json['lay_date_display'],
        'dams': ((json['dams'] as Array<any>).map(OffspringGroupListDamsInnerFromJSON)),
        'sires': ((json['sires'] as Array<any>).map(OffspringGroupListDamsInnerFromJSON)),
        'traits': ((json['traits'] as Array<any>).map(AnimalTraitFromJSON)),
        'publicNotes': !exists(json, 'public_notes') ? undefined : json['public_notes'],
        'privateNotes': !exists(json, 'private_notes') ? undefined : json['private_notes'],
        'viableEggsCountDisplay': json['viable_eggs_count_display'],
        'nonViableEggsCountDisplay': json['non_viable_eggs_count_display'],
        'eggsCountDisplay': json['eggs_count_display'],
        'isEggLaying': json['is_egg_laying'],
        'birthDateDisplay': json['birth_date_display'],
        'childrenCountDisplay': json['children_count_display'],
        'modifiedDisplay': json['modified_display'],
        'visibility': VisibilityEnumFromJSON(json['visibility']),
        'visibilityDisplay': VisibilityDisplayEnumFromJSON(json['visibility_display']),
        'damCrossSireOrChildrenTraits': json['dam_cross_sire_or_children_traits'],
        'birthDate': !exists(json, 'birth_date') ? undefined : ApproximateDateFromJSON(json['birth_date']),
        'offsprings': json['offsprings'],
        'title': json['title'],
        'parents': json['parents'],
        'eggsOrOffspringAndBirthDate': json['eggs_or_offspring_and_birth_date'],
    };
}

export function OffspringGroupListToJSON(value?: OffspringGroupList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.groupId,
        'public_notes': value.publicNotes,
        'private_notes': value.privateNotes,
        'visibility': VisibilityEnumToJSON(value.visibility),
        'birth_date': ApproximateDateToJSON(value.birthDate),
    };
}

