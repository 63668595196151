import { RouteObject } from 'react-router-dom';

const GeneticTestsHomePage = () => import('pages/GeneticTests/HomePage/HomePage');
const OrderedTests = () => import('pages/GeneticTests/OrderedTests/OrderedTests');
const CreateOrder = () => import('pages/GeneticTests/CreateOrder/CreateOrder');

export const geneticTestsRoutes: RouteObject[] = [
  {
    path: 'genetic-tests',
    children: [
      { path: '', lazy: GeneticTestsHomePage },
      { path: 'orders', lazy: OrderedTests },
      { path: 'create', lazy: CreateOrder },
    ],
  },
];
