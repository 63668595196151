import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gold000, gold200 } = colors;

export const MuiLink: Components<Omit<Theme, 'components'>>['MuiLink'] = {
  styleOverrides: {
    root: {
      color: gold200,
      '&:hover': {
        color: gold000,
      },
    },
  },
};
