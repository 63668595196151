// This service is used to expose the native APIs to the React app.
// So that no need of long path to import the native APIs.

import {
  TRIGGER_RATING_PROMPT_EVENT,
  TriggerRatingPromptEvent,
  sendHandleRatingPromptMessageToReactNative,
  tryGetIsLastAppRatingPromptClosedByUserFromLocalStorage,
  trySaveIsLastAppRatingPromptClosedByUserInLocalStorage,
} from '../../../src/js/app/ratingPromptUtils';

import {
  MESSAGE_TYPES,
  MM_APP_RATING_EVENTS,
  sendMessageToReactNative,
} from '../../../src/js/app/rn-messages';
import { isApp } from '../../../src/js/native-app/app-utils';

export const reactNativeAPIs = {
  MESSAGE_TYPES,
  MM_APP_RATING_EVENTS,
  TRIGGER_RATING_PROMPT_EVENT,
  sendMessageToReactNative,
  sendHandleRatingPromptMessageToReactNative,
  tryGetIsLastAppRatingPromptClosedByUserFromLocalStorage,
  trySaveIsLastAppRatingPromptClosedByUserInLocalStorage,
  isApp,
};

export type { TriggerRatingPromptEvent };
