import { Breakpoint, MobileDeviceOnly } from 'components/experimental/Breakpoint';
import { NavigationBackButton } from 'components/NavigationBackButton';
import { useAuth } from 'hooks';
import {
  Messages,
  DesktopSearch,
  HorizontalMenu,
  AnimalManager,
  DesktopGuestMenu,
  MobileSearch,
  TopLeftLogo,
} from '../components';
import { DesktopAdvancedSearch } from '../components/AdvancedSearch/AdvancedSearch';
import { DesktopSettingsButton } from '../components/Buttons/DesktopSettingsButton';
import { EllipsisMenu } from '../components/Menus/Ellipsis';
import { Settings } from '../components/Menus/Settings';
import { NavigationProps } from '../types';
import { usePartitionLinks } from './hooks';
import styles from './DesktopNavigation.module.scss';

export const DesktopNavigation: React.FC<NavigationProps> = ({ links = [], unseenMessages }) => {
  const { userName } = useAuth();
  const { primaryLinks, secondaryLinks } = usePartitionLinks(links, 115, links[links.length - 1]);

  return (
    <nav className={styles.container}>
      <div id="navbarMenuContainer" />
      <div className={styles.top}>
        <div className={styles.backButtonWrapper}>
          <MobileDeviceOnly>
            <NavigationBackButton />
          </MobileDeviceOnly>
          <TopLeftLogo isDesktop={true} />
        </div>
        <Breakpoint hard customDimensions={[0, 650]}>
          <div className={styles.searchBar}>
            <div className={styles.innerContainer}>
              <DesktopSearch />
              <DesktopAdvancedSearch />
            </div>
          </div>
        </Breakpoint>
        <div className={styles.controls}>
          <Breakpoint hard customDimensions={[651, 9999]}>
            <MobileSearch />
          </Breakpoint>
          {!userName && <DesktopGuestMenu />}
          {!!userName && (
            <>
              <AnimalManager href={`/animals/?status=for_sale`} />
              <Messages href={`/messages/${userName}`} unseenMessages={unseenMessages} />
              <Settings ButtonComponent={DesktopSettingsButton} />
            </>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <HorizontalMenu links={primaryLinks} />
        {!!secondaryLinks.length && (
          <div className={styles.ellipsisMenuContainer}>
            <EllipsisMenu links={secondaryLinks} />
          </div>
        )}
      </div>
    </nav>
  );
};
