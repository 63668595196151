import { A } from 'components/experimental/Links';
import styles from './DesktopGuestMenu.module.scss';

export const DesktopGuestMenu: React.FC = () => {
  return (
    <div className={styles.container}>
      <A href="/accounts/login" variant="outline">
        Log In
      </A>
      <A href="/accounts/signup" variant="button">
        Sign Up
      </A>
    </div>
  );
};
