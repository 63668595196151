import PropTypes from 'prop-types';
import SexFemale from 'components/Icons/SexFemale/SexFemale';
import SexMale from 'components/Icons/SexMale/SexMale';
import SexMixed from 'components/Icons/SexMixed/SexMixed';
import SexUnknown from 'components/Icons/SexUnknown/SexUnknown';
import {
  SEX_MALE,
  SEX_FEMALE,
  SEX_UNKNOWN,
  SEX_MIXED,
} from 'components/Inventory/Filters/SexFilter/definitions';


const icons = {
  [SEX_MALE]: [SexMale, 'Male'],
  [SEX_FEMALE]: [SexFemale, 'Female'],
  [SEX_MIXED]: [SexMixed, 'Mixed'],
  [SEX_UNKNOWN]: [SexUnknown, 'unknown']
};

const SexIcon = ({ sex, ...props }) => {
  const [Icon, name] = icons[sex] ?? [];

  if (!Icon) {
    return null;
  }

  return <Icon data-tip={`Sex: <b>${name}</b>`} aria-label={`Sex: ${name}`} data-html {...props} />;
};

SexIcon.propTypes = {
  sex: PropTypes.oneOf([SEX_MALE, SEX_FEMALE, SEX_UNKNOWN, SEX_MIXED]),
};

export default SexIcon;
