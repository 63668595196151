import { EnumsBucket } from 'constants/enums';
import { Category } from 'components/Autocomplete/variants/CategoryPicker/useCategoryPicker';
import { alphabetizeItems, NavbarCategory } from './alphabetizeItems';

const { category_menu_choices } = EnumsBucket;

const buildCategoryTree = (category: Category): NavbarCategory => {
  const tree: NavbarCategory = {
    id: category.id,
    name: category.label,
    href: `/REGION/c/${category.path}`,
    parent_id: category.parent_id,
    has_wizard: category.has_wizard,
    links: category_menu_choices
      .filter(({ parent_id }) => parent_id === category.id)
      .map((child) => buildCategoryTree(child)),
  };

  // Prepend "All X"
  if (tree?.links?.length) tree.links.unshift({ name: `All ${tree.name}`, href: tree.href });

  return tree;
};

const getAlphabetizedCategory = (category: NavbarCategory): NavbarCategory => {
  return {
    ...category,
    ...(category.links?.length && {
      links: alphabetizeItems(
        category.links.map((subcategory) => getAlphabetizedCategory(subcategory))
      ),
    }),
  };
};

const allAnimals = { name: 'All Animals', href: '/REGION/c/all' };
const topLevelCategories = category_menu_choices.filter(({ parent_id }) => parent_id === null);

export const categoryMenu = [
  allAnimals,
  ...topLevelCategories.map((c) => buildCategoryTree(c)).map((c) => getAlphabetizedCategory(c)),
];
