import { HTMLAttributes } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import styles from './MenuItems.module.scss';

export type ForwardButtonProps = HTMLAttributes<HTMLButtonElement> & {
  name?: string;
  has_wizard?: boolean;
};

export const ForwardButton: React.FC<ForwardButtonProps> = ({
  children,
  name,
  has_wizard,
  ...props
}) => {
  return (
    <button tabIndex={0} role="menuitem" className={styles.forwardButton} {...props}>
      <span>{children || name}</span> <Icon icon={faChevronRight} />
    </button>
  );
};
