import { Divider } from '@mui/material';
import {
  AccordionMenu,
  Anchor,
  PrefixedAnchor,
  PrefixedButton,
  SettingsItem,
} from '../Navigation/components';

export type SidebarItem = SettingsItem & {
  children: React.ReactNode;
  isOpen?: boolean;
};

export const SidebarMenuItem: React.FC<SidebarItem> = ({
  children,
  divider,
  icon,
  links,
  href,
  onClick,
  isOpen,
  tooltipText,
  ...props
}) => {
  if (!!links && links.length) {
    const linksWithStyle = links.map((link) => ({ ...link, style: { paddingLeft: '2.5rem' } }));
    return (
      <AccordionMenu
        children={children}
        icon={icon}
        links={linksWithStyle}
        {...props}
        onClick={onClick}
        tooltipText={tooltipText}
        isOpenSidebar={isOpen}
      />
    );
  }

  if (!!divider) {
    return <Divider />;
  }

  if (!!icon) {
    if (!!onClick) {
      return <PrefixedButton icon={icon} children={children} onClick={onClick} {...props} />;
    }

    if (!!href) {
      return (
        <PrefixedAnchor
          icon={icon}
          children={children}
          href={href}
          {...props}
          sidebar
          isOpen={isOpen}
          tooltipText={tooltipText}
        />
      );
    }
  } else {
    if (!!onClick) {
      return <button children={children} onClick={onClick} {...props} />;
    }

    if (!!href) {
      return <Anchor children={children} href={href} {...props} />;
    }
  }

  return null;
};
