import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../src/js/app/rn-messages';
import { IS_APP } from '../constants';

export function setCookie(name: string, value: string, days: number) {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`;
  if (!IS_APP) return;
  sendMessageToReactNative(MESSAGE_TYPES.SEND_COOKIE_TO_RN, {
    key: name,
    value: `${value || ''}${expires}; path=/`,
  });
}

export function getCookie(name: string) {
  const nameEQ = `${name}=`;

  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
}
