import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { green000, gray200, gold200 } = colors;

export const MuiIconButton: Components<Omit<Theme, 'components'>>['MuiIconButton'] = {
  styleOverrides: {
    colorPrimary: {
      color: gray200,
    },
    colorSecondary: {
      color: green000,
    },
    colorSuccess: {
      color: gold200,
    },
    sizeMedium: {
      fontSize: '1.5em',
    },
    root: {
      transition: 'color 0.2s',
      '&:hover': {
        color: gold200,
        backgroundColor: 'transparent',
      },
    },
  },
};
