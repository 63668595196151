import { useMemo, useState } from 'react';
import { useRegion } from 'hooks';
import { useCategory } from 'hooks/useCategory';
import { useAboutMe } from '../../context/UserContext/UserContext';
import { getTotal } from './helpers/data';
import {
  adsWithTrait,
  adsInCategory,
  storesInCategory,
  storesWithName,
  defaultCategoryOption,
  defaultStoreOption,
} from './helpers/formatters';
import { useFlexSearch } from './useFlexSearch';

/**
 * useTextSearch is used to search through the list of ads and stores and (for now)
 * redirect the user to a HTML page with the results. All the data is embedded into the HTML by
 * the Django backend so there are no API calls to the server.
 */
export const useTextSearch = () => {
  const [query, setQuery] = useState('');
  const { categories, traits, stores, length } = useFlexSearch(query);
  const region = useRegion();
  const category = useCategory();
  const { isLoggedIn } = useAboutMe();

  const data = useMemo(() => {
    if (length === 0) {
      return { categories, traits, stores, length };
    }

    return {
      length: getTotal([categories, traits, stores, categories]) + 2, // 2 for the two default results
      categories: [
        defaultCategoryOption(query, region, category?.path),
        ...adsInCategory(categories, query, region),
      ],
      traits: [...adsWithTrait(traits, query, region)],
      stores: [
        defaultStoreOption(query, region, category?.value),
        ...storesInCategory(categories, query, region),
        ...storesWithName(stores, isLoggedIn),
      ],
    };
  }, [query]);

  return {
    data,
    isLoading: false, // Always false since there's no call to the API
    search: (query: string) => setQuery(encodeURIComponent(query.toLowerCase().trim())),
  };
};
