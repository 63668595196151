import { faDash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './DrawerDash.module.scss';

export const DrawerDash = () => (
  <div className={styles.wrapper}>
    <FontAwesomeIcon icon={faDash} />
  </div>
);
