import { RouteObject } from 'react-router-dom';
import { mainRouteComponents } from '../utils/routeComponents';

const { PushNotificationsList } = mainRouteComponents;
const Search = () => import('../../pages/Project/Search');
const Index = () => import('../../pages/Project/Index/Index');
const SavedSearches = () => import('../../pages/Project/SavedSearches');

const categoryRoutes: RouteObject[] = [
  { path: 'traits', lazy: Index },
  { path: '', lazy: Search },
  { path: 'gene/:trait', lazy: Search },
  { path: 'trait/:trait', lazy: Search },
  { path: 'multigene/:trait', lazy: Search },
];

const canonicalRoutes: RouteObject[] = [
  {
    path: ':category',
    children: categoryRoutes,
  },
  {
    path: ':parent/:category',
    children: categoryRoutes,
  },
  {
    path: ':ancestor/:parent/:category',
    children: categoryRoutes,
  },
  {
    path: ':anotherAncestor/:ancestor/:parent/:category',
    children: categoryRoutes,
  },
];

export const projectsRoutes: RouteObject[] = [
  {
    path: ':region/p/*',
    children: [{ path: '', children: canonicalRoutes }],
  },
  {
    path: 'projects',
    children: [
      { path: 'searches', lazy: SavedSearches },
      { path: 'search/matches', lazy: PushNotificationsList },
    ],
  },
];
