import { useEffect } from 'react';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import originalPreferencesObject from 'services/preferences';

/**
 * Custom hook for handling legacy preferences. Remove after fully migrating to useUserData.
 */
export const useLegacyPreferences = <P extends Record<string, any>>(
  set: (key: string, value: any) => void,
  preferences?: P
) => {
  const onPreferencesChange: EventListener = (event) => {
    const preference = (event as CustomEvent)?.detail;

    if (preference) {
      const [[key, value]] = Object.entries(preference);
      set(camelCase(key), value);
    }
  };

  useEffect(() => {
    if (preferences) {
      const preferencesInSnakeCase = Object.fromEntries(
        Object.entries(preferences).map(([key, value]) => [snakeCase(key), value])
      );
      originalPreferencesObject?.setFromApi(preferencesInSnakeCase);
    }
  }, [preferences]);

  useEffect(() => {
    window.addEventListener('DISPATCH_PREFERENCES_SET', onPreferencesChange);
    return () => window.removeEventListener('DISPATCH_PREFERENCES_SET', onPreferencesChange);
  });
};
