import { generatePath } from 'react-router-dom';
import { CATEGORY_FILTER } from 'components/Common/Filters/CategoryFilter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import { generateCanonicalParams, generateCanonicalPath } from 'services/CustomURLs/canonical';
import { findCategory } from 'services/CustomURLs/helpers/categories';

export const filtersToSearchUrl = (filters) => {
  const base = '/:region/c';
  const destinationPath = generateCanonicalPath(filters, base);
  const queryString = generateCanonicalParams(filters);
  return `${destinationPath}${queryString}`.toLowerCase();
};

/**
 * Returns the default search URL (without filters) as a string.
 */
export const toDefaultSearchUrl = (filters) => {
  const destinationPath = generatePath('/:region/c/:category', {
    region: filters.get(LOCATION_FILTER),
    category: findCategory(filters.get(CATEGORY_FILTER))?.path,
  });
  return destinationPath;
};
