/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `card` - card
 * * `grid` - grid
 * @export
 */
export const CardGridViewChoices = {
    Card: 'card',
    Grid: 'grid'
} as const;
export type CardGridViewChoices = typeof CardGridViewChoices[keyof typeof CardGridViewChoices];


export function CardGridViewChoicesFromJSON(json: any): CardGridViewChoices {
    return CardGridViewChoicesFromJSONTyped(json, false);
}

export function CardGridViewChoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardGridViewChoices {
    return json as CardGridViewChoices;
}

export function CardGridViewChoicesToJSON(value?: CardGridViewChoices | null): any {
    return value as any;
}

