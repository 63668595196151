import { EnumsBucket, traits } from 'constants/enums';
import { allCategories as categories } from 'services/constants';

export const getCategoryObject = (category: string) => {
  return categories?.find((c) => c.value === category);
};

export const categoryMenuChoice = EnumsBucket.category_menu_choices.filter(
  ({ fine_grained_subcategory }) => !fine_grained_subcategory
);

// The functions below are replacements for functions previously hoisted on the window object.
// As such, they are mostly copied from the original code with traits being directly imported instead of hoisted.

export const traitsForCategory = (category: number, query?: string) => {
  const categoryData = traits?.[category];

  if (!categoryData) {
    return {};
  }

  const categoryTraits = traits[categoryData.trait_category];

  if (!categoryTraits || !categoryTraits.has_traits) {
    return {};
  }

  if (!query) {
    return categoryTraits.traits_with_aliases_list;
  }

  const needle = query.toLowerCase();

  return Object.fromEntries(
    Object.entries(categoryTraits.traits_with_aliases_list || {}).filter(([, trait_name]) =>
      trait_name.toLowerCase().includes(needle)
    )
  );
};

export const traitsWithoutAliasesForCategory = (category: number, query: string) => {
  const categoryData = traits[category];

  if (!categoryData) {
    return {};
  }

  const categoryTraits = traits[categoryData.trait_category];

  if (!categoryTraits || !categoryTraits.has_traits) {
    return {};
  }

  if (!query) {
    return categoryTraits.traits_list;
  }

  const needle = query.toLowerCase();

  return Object.fromEntries(
    Object.entries(categoryTraits.traits_list || {}).filter(([, trait_name]) =>
      trait_name.toLowerCase().includes(needle)
    )
  );
};

export const traitById = (category: number, id: number) => {
  const categoryData = traits[category];
  if (categoryData) {
    const categoryTraits = traits[categoryData.trait_category];
    if (categoryTraits && categoryTraits.has_traits) {
      return categoryTraits?.traits_with_aliases_list?.[id];
    }
  }
  return null;
};
