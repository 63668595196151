/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `40` - 40%
 * * `50` - 50%
 * * `60` - 60%
 * * `70` - 70%
 * @export
 */
export const MinimumOfferEnum = {
    _40: '40',
    _50: '50',
    _60: '60',
    _70: '70'
} as const;
export type MinimumOfferEnum = typeof MinimumOfferEnum[keyof typeof MinimumOfferEnum];


export function MinimumOfferEnumFromJSON(json: any): MinimumOfferEnum {
    return MinimumOfferEnumFromJSONTyped(json, false);
}

export function MinimumOfferEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinimumOfferEnum {
    return json as MinimumOfferEnum;
}

export function MinimumOfferEnumToJSON(value?: MinimumOfferEnum | null): any {
    return value as any;
}

