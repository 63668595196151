import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { CATEGORY_ORDERING } from 'api/common/ordering';

export const CATEGORY_COLUMN = Symbol('CATEGORY_COLUMN');

const categoryColumn = {
  id: CATEGORY_COLUMN,
  ordering: CATEGORY_ORDERING,
  label: 'Category',
  hideable: true,
  className: style.categoryColumn,

  render: ({ category_display }) => category_display,
};

export default categoryColumn;
