import Badge, {
  BADGE_BLUE,
  BADGE_DARK_PURPLE,
  BADGE_GOLD,
  BADGE_GRAY,
  BADGE_GREEN,
  BADGE_RED,
} from 'components/Badge/Badge';
import { StateDisplayEnum, StateEnum } from 'api/sdk';

const BADGES: Record<StateEnum, { color: symbol; label?: string; ariaLabel?: string }> = {
  [StateEnum.Archived]: {
    color: BADGE_GRAY,
    ariaLabel: 'Archived',
  },
  [StateEnum.ForSale]: {
    color: BADGE_GREEN,
    ariaLabel: 'For Sale',
  },
  [StateEnum.Loaned]: {
    color: BADGE_DARK_PURPLE,
    ariaLabel: 'Loaned',
  },
  [StateEnum.Nfs]: {
    color: BADGE_BLUE,
    label: 'NFS',
    ariaLabel: 'Not For Sale',
  },
  [StateEnum.OnHold]: {
    color: BADGE_GOLD,
    ariaLabel: 'On Hold',
  },
  [StateEnum.Sold]: {
    color: BADGE_RED,
    ariaLabel: 'Sold',
  },
  [StateEnum.OnHoldAuction]: {
    color: BADGE_GOLD,
    ariaLabel: 'On Hold, Being Auctioned',
  },
  [StateEnum.SoldAuction]: {
    color: BADGE_RED,
    ariaLabel: 'Sold, Via Auction',
  },
  // TODO: confirm the color for these states, i.e Breeders, Holdbacks, Pets
  [StateEnum.Breeder]: {
    color: BADGE_BLUE,
    ariaLabel: 'Breeder',
  },
  [StateEnum.Holdback]: {
    color: BADGE_BLUE,
    ariaLabel: 'Holdback',
  },
  [StateEnum.Pet]: {
    color: BADGE_BLUE,
    ariaLabel: 'Pet',
  },
};

const STATE_LABELS = Object.entries(StateEnum).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: StateDisplayEnum[key as keyof typeof StateDisplayEnum],
  }),
  {} as Record<StateEnum, string>
);

type StateBadgeProps = {
  state: StateEnum;
  className?: string;
};
const StateBadge: React.FC<StateBadgeProps> = ({ state, className }) => {
  return (
    <Badge
      type={BADGES[state].color}
      className={className}
      role="img"
      aria-label={`Status: ${BADGES[state].ariaLabel}`}
      tabIndex={0}
    >
      {BADGES[state]?.label ?? STATE_LABELS[state]}
    </Badge>
  );
};

export default StateBadge;
