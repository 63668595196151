import React from 'react';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import notFFoundImg from './404.svg';

export const NotFoundPage: React.FC = () => {
  return (
    <ErrorPage
      title="Page Not Found"
      text="That page seems to have crawled away."
      img={notFFoundImg}
      imgAlt="Page Not Found"
      buttonText="Go Back"
      buttonAction={() => window.history.back()}
    />
  );
};
