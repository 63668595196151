import { EnumsBucket } from 'constants/enums';
import {
  CATEGORY_FILTER,
  allCategories,
  rootCategory,
} from 'components/Common/Filters/CategoryFilter/definitions';
import {
  PK_FILTER,
  OG_ALERT_MATCH_FILTER,
  PAIRING_ALERT_MATCH_FILTER,
  GROUP_MATCHES_BY_FILTER,
  SEARCH_FILTER,
} from 'components/Common/Filters/SearchFilter/definitions';
import {
  INCLUDING_TRAITS_FILTER,
  EXCLUDING_TRAITS_FILTER,
} from 'components/Common/Filters/TraitsFilter/definitions';
import { PAGE_FILTER, PER_PAGE_FILTER } from 'components/ResultsCounter/definitions';
import { EXPORTING_TO_FILTER } from 'components/Search/Filters/ExportingToFilter/definitions';
import { ANY_TRAIT_FORM_FILTER } from 'components/Search/Filters/GenesAndTraitsFilter/definitions';
import { LIKED_FILTER } from 'components/Search/Filters/LikedFilter/definition';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import {
  NEAR_TO_COORDS_FILTER,
  NEAR_TO_DISTANCE_FILTER,
} from 'components/Search/Filters/NearToFilter/definitions';
import { USARK_MEMBERSHIP_FILTER } from 'components/Search/Filters/OtherOptionsFilter/definitions';
import {
  PAYMENT_METHOD_FILTER,
  PAYMENT_PLAN_FILTER,
} from 'components/Search/Filters/PaymentOptionsFilter/definitions';
import { SAVED_FILTER } from 'components/Search/Filters/SavedFilter/definition';
import {
  SELLER_FILTER,
  FOLLOWED_STORES_FILTER,
} from 'components/Search/Filters/SellerFilter/definitions';
import { US_STATE_FILTER } from 'components/Stores/Filters/LocationWithStateFilter/definitions';
import { CARD_VIEW, GRID_VIEW, LIST_VIEW } from 'components/ViewMenu/definitions';

import { ORDERING_FILTER } from 'api/ordering';
import searchOrdering from 'api/search/ordering';
import preferences, { SEARCH_VIEW_PREFERENCE } from 'services/preferences';

const paymentMethods = EnumsBucket.payment_methods;

export const definitions = {
  // Ordering
  [ORDERING_FILTER]: {
    apiParam: 'ordering',
    values: searchOrdering.map(({ param }) => param),
  },

  // Pagination
  [PAGE_FILTER]: {
    apiParam: 'page',
    unserialize: Number,
    defaultValue: () => 1,
  },
  [PER_PAGE_FILTER]: {
    apiParam: 'page_size',
    unserialize: Number,
    defaultValue: () => 24,
  },

  // Category
  [CATEGORY_FILTER]: {
    apiParam: 'category',
    defaultValue: () => rootCategory.id,
    values: new Map(allCategories.map(({ id, value }) => [id, value])),
  },

  // Location
  [LOCATION_FILTER]: {
    apiParam: 'region',
  },

  // States
  [US_STATE_FILTER]: {
    apiParam: 'location_state',
  },

  // Genes and Traits
  [INCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__in',
  },
  [EXCLUDING_TRAITS_FILTER]: {
    apiParam: 'traits__not__in',
  },
  [ANY_TRAIT_FORM_FILTER]: {
    apiParam: 'any_trait_form',
    unserialize: Boolean,
  },

  // Seller
  [SELLER_FILTER]: {
    apiParam: 'seller',
  },

  // Followed stores only filter
  [FOLLOWED_STORES_FILTER]: {
    apiParam: 'followed_stores',
  },
  [LIKED_FILTER]: {
    apiParam: 'liked',
  },
  [SAVED_FILTER]: {
    apiParam: 'saved',
  },
  // Payment
  [PAYMENT_METHOD_FILTER]: {
    apiParam: 'payment_method',
    unserialize: (val) => paymentMethods.find(({ value }) => value === val)?.label,
    serialize: (value) => paymentMethods.find(({ label }) => value === label)?.value,
    values: new Map(paymentMethods.map(({ value, label }) => [value, label])),
  },
  [PAYMENT_PLAN_FILTER]: {
    apiParam: 'payment_plan',
    unserialize: Number,
  },

  // Near To
  [NEAR_TO_COORDS_FILTER]: {
    apiParam: 'coords',
    serialize: ({ latitude, longitude }) => `${latitude} ${longitude}`,
    unserialize: (value) => {
      const [latitude, longitude] = value.split(' ').map(Number);
      return { latitude, longitude };
    },
  },
  [NEAR_TO_DISTANCE_FILTER]: {
    apiParam: 'distance',
    unserialize: Number,
  },

  // Exporting To
  [EXPORTING_TO_FILTER]: {
    apiParam: 'exporting_to',
  },

  // Search
  [SEARCH_FILTER]: {
    apiParam: 'search',
  },

  // Other Options
  [USARK_MEMBERSHIP_FILTER]: {
    apiParam: 'usark_membership',
    unserialize: Boolean,
  },
  [PK_FILTER]: {
    apiParam: 'pk',
  },
  [OG_ALERT_MATCH_FILTER]: {
    apiParam: 'og_alert_match',
  },
  [PAIRING_ALERT_MATCH_FILTER]: {
    apiParam: 'pairing_alert_match',
  },
  [GROUP_MATCHES_BY_FILTER]: {
    apiParam: 'group_matches_by',
  },
  [SEARCH_VIEW_PREFERENCE]: {
    apiParam: 'view',
    values: new Map([
      [CARD_VIEW, 'card'],
      [GRID_VIEW, 'grid'],
    ]),
    defaultValue: () => {
      const view = preferences.get(SEARCH_VIEW_PREFERENCE) || GRID_VIEW;
      return view;
    },
  },
  // Filter for testing API calls on the BE without caching
  'cache-mode': {
    apiParam: 'cache-mode',
  },
};

export const lockedFilters = [
  CATEGORY_FILTER,
  LOCATION_FILTER,
  ORDERING_FILTER,
  PER_PAGE_FILTER,
  PAGE_FILTER,
  SEARCH_VIEW_PREFERENCE,
  NEAR_TO_COORDS_FILTER,
];
