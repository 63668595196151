import { useState, useCallback, useMemo, memo } from 'react';
import classNames, { Argument } from 'classnames';
import sortBy from 'lodash/sortBy';
import placeholderImageGray from '../../images/icons/no-image-gray.svg';
import placeholderImage from './placeholder.svg';
import style from './Image.module.scss';

export type Source = {
  src: string;
  from: number;
};

export type ImageSrcSet = Source[] | null;

const Sources: React.FC<{ sources: Source[] }> = ({ sources = [] }) => {
  const sorted = useMemo(() => sortBy(sources, 'from').reverse(), [sources]);

  return (
    <>
      {sorted.map(({ src, from }) => (
        <source key={src} srcSet={src} media={`(min-width: ${from}px)`} />
      ))}
    </>
  );
};

export type ImageProps = React.HTMLAttributes<HTMLImageElement> & {
  src?: string;
  alt?: string | null;
  srcset?: Source[] | null;
  className?: Argument;
  useGrayPlaceholder?: boolean;
};

export const Image: React.FC<ImageProps> = memo(
  ({ src, srcset, alt, className, useGrayPlaceholder, ...props }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const finishLoading = useCallback(() => setIsLoaded(true), []);

    return (
      <picture>
        {!!srcset && <Sources sources={srcset} />}
        <img
          src={src ?? (useGrayPlaceholder ? placeholderImageGray : placeholderImage)}
          alt={alt ?? ''}
          onLoad={finishLoading}
          className={classNames(
            {
              [style.loaded]: isLoaded,
            },
            style.fadedImage,
            className
          )}
          {...props}
        />
      </picture>
    );
  }
);

export default Image;
