/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedRatingByBuyerRequest,
    DetailedRatingByBuyerRequestFromJSON,
    DetailedRatingByBuyerRequestFromJSONTyped,
    DetailedRatingByBuyerRequestToJSON,
} from './DetailedRatingByBuyerRequest';
import {
    OverallExperienceEnum,
    OverallExperienceEnumFromJSON,
    OverallExperienceEnumFromJSONTyped,
    OverallExperienceEnumToJSON,
} from './OverallExperienceEnum';
import {
    PurchaseResultEnum,
    PurchaseResultEnumFromJSON,
    PurchaseResultEnumFromJSONTyped,
    PurchaseResultEnumToJSON,
} from './PurchaseResultEnum';

/**
 * 
 * @export
 * @interface BuyerReviewRequest
 */
export interface BuyerReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyerReviewRequest
     */
    actionType?: string;
    /**
     * 
     * @type {PurchaseResultEnum}
     * @memberof BuyerReviewRequest
     */
    purchaseResult: PurchaseResultEnum;
    /**
     * 
     * @type {OverallExperienceEnum}
     * @memberof BuyerReviewRequest
     */
    overallExperience: OverallExperienceEnum;
    /**
     * 
     * @type {DetailedRatingByBuyerRequest}
     * @memberof BuyerReviewRequest
     */
    detailedRatings: DetailedRatingByBuyerRequest;
    /**
     * 
     * @type {string}
     * @memberof BuyerReviewRequest
     */
    feedback: string;
}

export function BuyerReviewRequestFromJSON(json: any): BuyerReviewRequest {
    return BuyerReviewRequestFromJSONTyped(json, false);
}

export function BuyerReviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerReviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': !exists(json, 'action_type') ? undefined : json['action_type'],
        'purchaseResult': PurchaseResultEnumFromJSON(json['purchase_result']),
        'overallExperience': OverallExperienceEnumFromJSON(json['overall_experience']),
        'detailedRatings': DetailedRatingByBuyerRequestFromJSON(json['detailed_ratings']),
        'feedback': json['feedback'],
    };
}

export function BuyerReviewRequestToJSON(value?: BuyerReviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.actionType,
        'purchase_result': PurchaseResultEnumToJSON(value.purchaseResult),
        'overall_experience': OverallExperienceEnumToJSON(value.overallExperience),
        'detailed_ratings': DetailedRatingByBuyerRequestToJSON(value.detailedRatings),
        'feedback': value.feedback,
    };
}

