/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreOwner
 */
export interface StoreOwner {
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof StoreOwner
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwner
     */
    readonly firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwner
     */
    readonly lastName: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreOwner
     */
    dateJoined?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StoreOwner
     */
    readonly created: Date;
}

export function StoreOwnerFromJSON(json: any): StoreOwner {
    return StoreOwnerFromJSONTyped(json, false);
}

export function StoreOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreOwner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'dateJoined': !exists(json, 'date_joined') ? undefined : (new Date(json['date_joined'])),
        'created': (new Date(json['created'])),
    };
}

export function StoreOwnerToJSON(value?: StoreOwner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'date_joined': value.dateJoined === undefined ? undefined : (value.dateJoined.toISOString()),
    };
}

