import React from 'react';
import { WhenWrapper } from 'components/experimental/Wrappers';
import { PostRating } from './PostRating';
import { StarRating } from './StarRating';
import type { fiveStarRating } from './StarRating';
import style from '../NativeAppReviewModal.module.scss';

type ModalBodyProps = {
  isRated: boolean;
  submitted: boolean;
  errorMessage: string;
  showRateInAppStore: boolean;
  actions: {
    onFeedbackChange: (feedback: string) => void;
    onUserRatingChange: (rating: fiveStarRating) => void;
  };
};

export const ModalBody: React.FC<ModalBodyProps> = ({
  isRated,
  submitted,
  errorMessage,
  showRateInAppStore,
  actions: { onUserRatingChange, onFeedbackChange },
}) => {
  return (
    <>
      <WhenWrapper condition={!isRated}>
        <>
          <div className={style.rating}>
            <label>How happy are you with our app?</label>
            <StarRating onChange={onUserRatingChange} />
          </div>
        </>
      </WhenWrapper>
      <WhenWrapper condition={isRated}>
        <PostRating
          submitted={submitted}
          isError={!!errorMessage}
          onFeedbackChange={onFeedbackChange}
          showRateInAppStore={showRateInAppStore}
        />
      </WhenWrapper>
      <WhenWrapper condition={!!errorMessage}>
        <div className={style.errorContainer}>
          <span>{errorMessage}</span>
        </div>
      </WhenWrapper>
    </>
  );
};
