import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { GROUP_ID_ORDERING } from 'api/inventory/ordering';

export const GROUP_ID_COLUMN = Symbol('GROUP_ID_COLUMN');

const groupIdColumn = {
  id: GROUP_ID_COLUMN,
  ordering: GROUP_ID_ORDERING,
  label: 'Group ID',
  hideable: true,
  className: style.groupIdColumn,

  render: ({ clutch_number }: { clutch_number: string }) => clutch_number,
};

export default groupIdColumn;
