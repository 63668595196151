import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { INQUIRIES_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip';

export const INQUIRIES_COLUMN = Symbol('INQUIRIES_COLUMN');

const inquiriesColumn = {
  id: INQUIRIES_COLUMN,
  ordering: INQUIRIES_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Inquiries',
  shortLabel: 'Inq.',
  hideable: true,
  className: style.countColumn,
  labelHtml: () => (
    <ColumnTooltip
      text="The information displayed here is refreshed once every 24 hours. Keep in mind that the data you see may not reflect real-time changes."
      icon={faInfoCircle}
    />
  ),
  render: ({ inquiries_count }: { inquiries_count: number }) => inquiries_count,
};

export default inquiriesColumn;
