import { useState, useCallback, createContext } from 'react';

const ToastContext = createContext();

export default ToastContext;

const maxItems = 3;

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (message, type, props) =>
      setToasts((toasts) =>
        [
          ...toasts,
          {
            id: Math.max(-1, ...toasts.map(({ id }) => id)) + 1,
            message,
            type,
            ...props,
          },
        ].slice(-maxItems)
      ),
    []
  );

  const deleteToast = useCallback(
    (id) => setToasts((toasts) => toasts.filter((toast) => toast.id !== id)),
    []
  );

  return (
    <ToastContext.Provider
      value={{
        toasts,
        toast: addToast,
        deleteToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
