import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import { generateNestedMenu, NestedMenu } from 'components/experimental/MUI/NestedMenu';
import { IconButton } from '../Buttons/IconButton';
import { NestedMenuItem } from '../MenuItems/Item';
import { GenericMenu } from './GenericMenu';

export type EllipsisMenuProps = {
  links: any[];
};

export const EllipsisMenu: React.FC<EllipsisMenuProps> = ({ links }) => {
  const { utils, toggle, close } = useDropdownMenu({ id: 'nav-menu' });
  const { button, menu } = utils;

  return (
    <>
      <IconButton
        {...button}
        onClick={toggle}
        icon={faEllipsisV}
        aria-label="Additional Menu Options"
      />
      <NestedMenu>
        {({ goTo, goBack, resetMenu }, subMenu) => (
          <GenericMenu
            {...menu}
            onClose={() => {
              close();
              resetMenu(300);
            }}
          >
            {generateNestedMenu({
              items: subMenu || links,
              keyProps: ['children', 'name'],
              Component: NestedMenuItem,
              optionalProps: { goTo: goTo(subMenu || links), goBack },
            })}
          </GenericMenu>
        )}
      </NestedMenu>
    </>
  );
};
