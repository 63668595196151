import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';

export const GRAVID_COLUMN = Symbol('GRAVID_COLUMN');

interface GravidColumnRenderProps {
  is_gravid: boolean;
}

const GravidColumn = {
  id: GRAVID_COLUMN,
  disableOrdering: true,
  label: 'Gravid',
  className: style.center,

  render: ({ is_gravid }: GravidColumnRenderProps) => <span>{is_gravid ? 'Yes' : 'No'}</span>,
};

export default GravidColumn;
