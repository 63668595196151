import {
  faChartLine,
  faCog,
  faBookmark,
  faSearch,
  faStar,
  faStore,
  faTachometerAlt,
  faUsers,
  faFlask,
  faClock,
  faHeart,
  faBagShopping,
  faUserTag,
  faFileWaveform,
  faLineColumns,
  faGavel,
  faHeartPulse,
} from '@fortawesome/pro-solid-svg-icons';
import { PairingSeason } from 'pages/BreedingManager/Private/types';
import { useInventoryNavigation } from 'pages/Inventory/hooks/useInventoryNavigation';
import { mmMessages, mmAnimal, mmEgg } from 'components/experimental/IconDefinitions';
import { useAboutMe } from 'context/UserContext/UserContext';
import { isRtoR } from 'services/isRtoR';
import { navigationMenuText } from 'services/phrase';
import {
  generateLikedListingsPath,
  generateSavedListingsPath,
  generateMyBidsListingsPath,
} from 'services/urls';

import { useShowExtendedContent } from '../../../hooks/utils/useShowExtendedContent';
import { appendActiveTag } from '../Navigation/helpers';

export const getAnimalStatusParm = (isSeller: boolean) => {
  if (!isSeller) return '';
  return '?state=for_sale';
};

/**
 * A list of links that goes into the Sidebar menu.
 */
export const SidebarLinks = (props: { region: string; breedingSeason: PairingSeason }) => {
  const { region, breedingSeason = 'any' } = props;
  const { showPricingToUser } = useShowExtendedContent();
  const navigateToInventory = useInventoryNavigation();

  const { about } = useAboutMe();
  const userName = about?.username;
  const isSeller = !!about?.hasStore;
  const hasOrganization = !!about?.hasOrganization;

  const inventoryHref = `/animals/${getAnimalStatusParm(!!isSeller)}`;
  const isReactRoute = isRtoR(inventoryHref);

  return [
    isSeller && {
      children: navigationMenuText.dashboard,
      href: '/dashboard',
      icon: faTachometerAlt,
      tooltipText: navigationMenuText.dashboard,
    },
    {
      children: navigationMenuText.myAnimals,
      href: inventoryHref,
      icon: mmAnimal,
      tooltipText: navigationMenuText.myAnimals,
      ...(isReactRoute && { onClick: () => navigateToInventory(inventoryHref) }),
    },
    {
      children: navigationMenuText.husbandry,
      href: '/animals/husbandry/',
      icon: faHeartPulse,
      tooltipText: navigationMenuText.husbandry,
    },
    {
      children: navigationMenuText.breeding,
      href: `/breeding?season=${breedingSeason}`,
      icon: faFlask,
      tooltipText: navigationMenuText.breeding,
    },
    {
      children: navigationMenuText.offspring,
      href: '/offspring/?status=current',
      icon: mmEgg,
      tooltipText: navigationMenuText.offspring,
    },
    {
      children: navigationMenuText.groupsRacks,
      href: '/husbandry/groups/',
      icon: faLineColumns,
      tooltipText: navigationMenuText.groupsRacks,
    },
    {
      children: navigationMenuText.activityLog,
      href: '/husbandry/activities/',
      icon: faFileWaveform,
      tooltipText: navigationMenuText.activityLog,
    },
    {
      children: navigationMenuText.orders,
      href: `/orders/`,
      icon: faBagShopping,
      tooltipText: navigationMenuText.orders,
    },
    isSeller && {
      children: navigationMenuText.myStore,
      href: `/stores/${userName}`,
      icon: faStore,
      tooltipText: navigationMenuText.myStore,
    },
    { divider: true },
    {
      children: navigationMenuText.messages,
      href: `/messages/${userName}`,
      icon: mmMessages,
      tooltipText: navigationMenuText.messages,
    },
    {
      children: navigationMenuText.waitlists,
      href: '/waitlists',
      icon: faClock,
      tooltipText: navigationMenuText.waitlists,
    },
    {
      children: navigationMenuText.savedSearches,
      href: '/animals/searches/',
      icon: faSearch,
      tooltipText: navigationMenuText.savedSearches,
    },
    {
      children: navigationMenuText.myBids,
      href: generateMyBidsListingsPath(region),
      icon: faGavel,
      tooltipText: navigationMenuText.myBids,
    },
    {
      children: navigationMenuText.likedAnimals,
      href: generateLikedListingsPath(region),
      icon: faHeart,
      tooltipText: navigationMenuText.likedAnimals,
    },
    {
      children: navigationMenuText.savedAnimals,
      href: generateSavedListingsPath(region),
      icon: faBookmark,
      tooltipText: navigationMenuText.savedAnimals,
    },
    {
      children: navigationMenuText.following,
      href: '/accounts/following',
      icon: faUsers,
      tooltipText: navigationMenuText.following,
    },
    isSeller && {
      children: navigationMenuText.userGroups,
      href: '/accounts/user-groups',
      icon: faUserTag,
      tooltipText: navigationMenuText.userGroups,
    },
    { divider: true },
    {
      children: navigationMenuText.accountSettings,
      icon: faCog,
      links: [
        { children: navigationMenuText.general, href: '/accounts/settings' },
        isSeller && {
          children: hasOrganization
            ? navigationMenuText.orgProfile
            : navigationMenuText.sellerProfile,
          href: `/stores/${userName}/edit`,
        },
        !isSeller && {
          children: navigationMenuText.startSelling,
          href: `/stores/${userName}/create`,
        },
        { children: navigationMenuText.userProfile, href: `/users/${userName}/edit` },
        { children: navigationMenuText.labelSettings, href: `/users/${userName}/label_settings` },
        { children: navigationMenuText.notifications, href: `/accounts/settings/notifications/` },
        showPricingToUser && {
          children: navigationMenuText.subscriptions,
          href: '/accounts/subscriptions',
        },
      ]
        .filter((value) => !!value)
        .map(appendActiveTag),
      tooltipText: navigationMenuText.accountSettings,
    },
    isSeller && {
      children: navigationMenuText.sellerRatings,
      href: `/stores/${userName}/ratings`,
      icon: faChartLine,
      tooltipText: 'Seller Ratings',
    },
    {
      children: navigationMenuText.buyerRatings,
      href: `/users/${userName}`,
      icon: faStar,
      tooltipText: 'Buyer Ratings',
    },
  ]
    .filter((value) => !!value)
    .map(appendActiveTag);
};
