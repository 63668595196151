import { useEffect, useState } from 'react';

export type UseDropdownMenu = {
  id: string;
  isOpenByDefault?: boolean;
  customAnchor?: HTMLElement | null;
};

/**
 * Hook for handling repetitive functionality of the Menu component. Handles, open and close state and the menu anchor.
 */
export const useDropdownMenu = ({ customAnchor, isOpenByDefault, id }: UseDropdownMenu) => {
  const [anchor, setAnchorEl] = useState<null | HTMLElement>(customAnchor || null);
  const [isOpen, setIsOpen] = useState(Boolean(isOpenByDefault));
  const open = (event?: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(customAnchor || (event as any)?.currentTarget);
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };
  const toggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
    !!isOpen ? close() : open(event);
  };

  useEffect(() => {
    !isOpen && setAnchorEl(customAnchor || null);
  }, [isOpen]);

  const utils = {
    button: {
      id,
      'aria-controls': isOpen ? `dropdown-menu-for-${id}` : undefined,
      'aria-haspopup': true,
      'aria-expanded': isOpen ? true : undefined,
      onClick: open,
    },
    menu: {
      id: `dropdown-menu-for-${id}`,
      anchorEl: anchor,
      open: isOpen,
      MenuListProps: {
        'aria-labelledby': id,
      },
      onClose: close,
    },
  };

  return {
    isOpen,
    anchor,
    utils,
    open,
    close,
    toggle,
  };
};
