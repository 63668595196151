import { useEffect, useContext } from 'react';
import { MenuList } from '@mui/material';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { PairingSeason } from 'pages/BreedingManager/Private/types';
import { SidebarContext } from 'context/SideBarContext/SidebarContext';
import { useRegion } from 'hooks';
import preferences, { BREEDING_PAIRING_SEASON } from 'services/preferences';
import { useAboutMe } from '../../../context/UserContext/UserContext';
import { useDropdownMenu } from '../MUI/DropdownMenu';
import { closeSidebar, onEscape, openSidebar } from './helpers';
import { SidebarLinks } from './Lists';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useSidebarPreferences } from './useSidebarPreferences';
import styles from './Sidebar.module.scss';
import menuStyles from './SidebarMenu.module.scss';

/**
 * This is the global sidebar that is the vertical counterpart to the Navbar.
 */

export const Sidebar: React.FC = () => {
  const { isLoggedIn } = useAboutMe();
  const region = useRegion();

  const breedingSeason: PairingSeason = preferences.get(BREEDING_PAIRING_SEASON) ?? 'any';

  const links = SidebarLinks({
    region,
    breedingSeason,
  });
  const { isOpen: isOpenByDefault, toggle: togglePreference } = useSidebarPreferences();
  const { utils, close, open, isOpen } = useDropdownMenu({
    id: 'sidebar',
    isOpenByDefault,
  });

  const { disableSidebar } = useContext(SidebarContext);

  const {
    menu: { MenuListProps, anchorEl: _, ...menu },
  } = utils;
  const classes = { root: menuStyles.list };

  useEffect(() => {
    togglePreference(isOpen);
  }, [isOpen]);

  if (!isLoggedIn || window.IS_APP) {
    // This should be an empty div rather than null so that
    // "#react-standalone-sidebar:not(:empty) ~ div" selector is applied correctly
    return <div />;
  }

  return createPortal(
    <div className={classNames(styles.container, isOpen && !disableSidebar && styles.expanded)}>
      <ReactTooltip />
      <MenuList
        onKeyDown={onEscape(close)}
        onBlur={closeSidebar}
        component="div"
        classes={classes}
        {...MenuListProps}
        {...menu}
      >
        {links.map(({ ...link }, i) => (
          <SidebarMenuItem
            {...link}
            onMouseDown={openSidebar(open)}
            key={(link.children as string) || i}
            isOpen={isOpen}
          />
        ))}
      </MenuList>
    </div>,
    document.getElementById('react-standalone-sidebar') as Element
  );
};
