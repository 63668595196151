import { PAGE_FILTER } from 'components/ResultsCounter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';

import FiltersJar from 'api/FiltersJar';
import { filterDefinitionsToDefaults, queryParamsToFilters } from 'api/url';
import { CategoryObject } from 'services/constants';
import {
  generateCanonicalParams,
  generateCanonicalPath,
  mergeSearchParams,
} from 'services/CustomURLs/canonical';

import { definitions } from './definitions';

const filtersToSearchUrlBase = (searchType: 'projects' | 'listings', filters: FiltersJar) => {
  let base = '';
  if (searchType === 'projects') {
    base = '/:region/p';
  } else if (searchType === 'listings') {
    base = '/:region/c';
  }
  const destinationPath = generateCanonicalPath(filters, base);
  const queryString = generateCanonicalParams(filters);
  return `${destinationPath}${queryString}`.toLowerCase();
};

export const filtersToProjectSearchUrl = (filters: InstanceType<typeof FiltersJar>) => {
  return filtersToSearchUrlBase('projects', filters);
};

export const filtersToListingsSearchUrl = (filters: InstanceType<typeof FiltersJar>) => {
  return filtersToSearchUrlBase('listings', filters);
};

export const projectSearchUrlToFilters = (
  definitions: any,
  searchParams: URLSearchParams,
  params: any
) => {
  const filters = queryParamsToFilters(definitions, mergeSearchParams(searchParams, params));
  const defaults = filterDefinitionsToDefaults(definitions);
  return filters.set(LOCATION_FILTER, params.region).setDefaults(defaults);
};

/**
 * This helper function is meant to be used specifically on Animal Search.
 * It will generate an URL that only contains filters supported by PS.
 */
export const listingsUrlToProjectUrl = (searchParams: URLSearchParams, canonicalParams: any) => {
  const filters = projectSearchUrlToFilters(definitions, searchParams, canonicalParams);
  const filtersWithoutCurrentPage = filters.remove(PAGE_FILTER);
  return filtersToProjectSearchUrl(filtersWithoutCurrentPage);
};

/**
 * This helper function is meant to be used specifically on Projects Search.
 * It will generate an URL that only contains filters supported by PS.
 */
export const projectsPathToListingsPath = (searchParams: URLSearchParams, canonicalParams: any) => {
  const filters = projectSearchUrlToFilters(definitions, searchParams, canonicalParams);
  return filtersToListingsSearchUrl(filters);
};
