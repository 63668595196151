import { Components, Theme } from '@mui/material';
import { colors, zIndexes } from '../constants';

const { white, gold400, gray700 } = colors;

export const MuiAutocomplete: Components<Omit<Theme, 'components'>>['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      
    },
    endAdornment: {
      marginRight: '0.5rem',
    },
    inputRoot: {
      padding: '0 0 0 9px',
    },
    popper: {
      zIndex: zIndexes.popOver,
    },
    listbox: {
      '.MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
        {
          backgroundColor: gray700,
        },
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: gold400,
      },
    },
  },
};
