import { useEffect, useRef } from 'react';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem } from '@mui/material';
import {
  appendActiveTag,
  recentCategoriesToCalcLinks,
  recentCategoriesToLinks,
} from 'components/experimental/Navigation/helpers';
import { useRegion } from 'hooks';
import { useRecentlyVisitedCategories } from 'hooks/useRecentlyVisited';
import { useNestedMenu } from './Nested';

export type CategoryBranchProps = {
  children?: React.ReactNode;
  showRecentlyVisitedCategories?: boolean;
  showRecentlyVisitedCalculators?: boolean;
  name?: string;
  links?: any[];
  recentlyVisited?: boolean;
  has_wizard?: boolean;
};

export const CategoryBranch: React.FC<CategoryBranchProps> = ({
  showRecentlyVisitedCategories,
  showRecentlyVisitedCalculators,
  children,
  name,
  links,
  has_wizard: _, // We don't use this prop here, and removing it from rest.
  ...rest
}) => {
  const region = useRegion();
  const { recentCategories } = useRecentlyVisitedCategories();
  const { changeBranch } = useNestedMenu();

  const options = [
    { isBackButton: true, name: `${children || name}` },
    { divider: true },
    ...(showRecentlyVisitedCategories ? recentCategoriesToLinks(recentCategories, region) : []),
    ...(showRecentlyVisitedCalculators ? recentCategoriesToCalcLinks(recentCategories) : []),
    ...(links || []).map(appendActiveTag),
  ];

  return (
    <MenuItem onClick={() => changeBranch(options)} disableRipple {...rest}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <span>{children || name}</span>
        <FontAwesomeIcon icon={faAngleRight} />
      </Box>
    </MenuItem>
  );
};

export const BackButton: React.FC<{ children?: React.ReactNode; name?: string }> = (props) => {
  const ref = useRef<any>(null);
  const { goBack } = useNestedMenu();
  const { name, children, ...rest } = props;

  // This is a hack to preserve focus while changing branches
  useEffect(() => {
    ref.current?.focus();
  }, [ref.current]);

  return (
    <MenuItem {...rest} onClick={goBack} disableRipple ref={ref}>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        <span>{name || children}</span>
      </Box>
    </MenuItem>
  );
};
