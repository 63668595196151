import { matchRoutes } from 'react-router-dom';
import { LOCAL_RADIUS } from 'pages/Search/components/services/usePopulateParamsForLocalSearch';
import { CategoryObject, rootCategory } from './constants';
import { geneticCalculatorRoutes } from 'routes/features/geneticCalculator';
/**
 * Converts an URL containing search parameters into a dictionary object of key/values.
 */
export const searchParamsToDict = (params: string) => {
  return Object.fromEntries(new URLSearchParams(params));
};

/**
 * Converts an array of entries into a search params string, taking into account some peculiarities such as:
 * [value, ""] should become "?value", not "?value=""
 * "?" should be prepended if the searchParams is not empty
 */
export const searchParamsToString = (params: [string, string][]) => {
  const searchParams = new URLSearchParams(params).toString().replace(/=&/g, '&').replace(/=$/, '');
  return !!searchParams ? `?${searchParams}` : '';
};

export const getReturnFromLoginUrl = () => {
  const RETURN_TO = window.location.href.slice(window.location.origin.length);
  return `/accounts/login/?next=${RETURN_TO}`;
};

export const generateSearchPathBase = (
  searchType: 'projects' | 'listings',
  region: string,
  category?: CategoryObject
) => {
  let urlCategoryComponent;

  if (!category) {
    urlCategoryComponent = 'all';
  } else {
    urlCategoryComponent = category.path;
  }

  if (searchType === 'projects') {
    return `/${region}/p/${urlCategoryComponent}`;
  } else if (searchType === 'listings') {
    return `/${region}/c/${urlCategoryComponent}`;
  }
};

// Note that this does not preserve all filters like the other helpers (projectsPathToListingsPath etc.)
// purport to do.
export const generateProjectsPath = (region: string, category: CategoryObject) => {
  return generateSearchPathBase('projects', region, category);
};

// Note that this does not preserve all filters like the other helpers (projectsPathToListingsPath etc.)
// purport to do.
export const generateListingsPath = (region: string, category: CategoryObject) => {
  return generateSearchPathBase('listings', region, category);
};

export const generateLocalListingsPath = (
  region: string,
  category: CategoryObject,
  postalCode: string,
  distance: string
) => {
  const path = generateSearchPathBase('listings', region, category);
  const fragment = postalCode
    ? `?postal_code=${postalCode}&postal_radius=${distance ?? LOCAL_RADIUS}`
    : `?distance=${distance ?? LOCAL_RADIUS}`;
  return `${path}${fragment}`;
};

export const generateForYouListingsPath = (region: string, category: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?followed_stores=true`;
};
export const generateLikedListingsPath = (
  region: string,
  category: CategoryObject = rootCategory as CategoryObject
) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?liked=true&state=any`;
};

export const generateSavedListingsPath = (
  region: string,
  category: CategoryObject = rootCategory as CategoryObject
) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?saved=true&state=any`;
};

export const generateDealsListingsPath = (region: string, category: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?on_sale=true&ordering=sale_start_time`;
};

export const generateWholesaleListingsPath = (region: string, category: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?wholesale=true`;
};

export const generateAuctionListingsPath = (region: string, category: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?auction=true&ordering=auction_end_time`;
};

export const generateMyBidsListingsPath = (region: string, category?: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?my_bids=true`;
};

export const generatePopularListingsPath = (region: string, category: CategoryObject) => {
  const path = generateSearchPathBase('listings', region, category);
  return `${path}?ordering=popularity`;
};

export const generateExpoListingPath = (expoId: number, region: string) => {
  const path = generateSearchPathBase('listings', region);
  return `${path}?expo_id=${expoId}`;
};

export const generateStoresPath = (region: string, category?: CategoryObject) =>
  `/${region}/stores/?category=${category?.value || 'all'}`;

export const generateSavedSearchesPath = (region: string, category: CategoryObject) =>
  `/animals/searches/?region=${region}&category=${category?.value}`;

export const generateCalculatorPath = (region: string, category?: CategoryObject) =>
  category && category.has_wizard && `/c/${category.path}/genetic-calculator/?region=${region}`;

export const isIndexPath = (pathname: string): boolean => {
  return pathname.split('/')?.slice(-1)[0] === 'traits';
};

export const isCalculatorPath = (pathname: string): boolean => {
  const matches = matchRoutes(geneticCalculatorRoutes, pathname);
  return !!matches && matches.length > 0;
};

export const isProjectsPath = (pathname: string): boolean => {
  return pathname.split('/')?.[2] == 'p' && !isIndexPath(pathname);
};

export const isProjectsIndexPath = (pathname: string): boolean => {
  return pathname.split('/')?.[2] == 'p' && !!isIndexPath(pathname);
};

// TODO: A lot of these should use react-router utils

// Listings list
export const isListingsPath = (pathname: string): boolean => {
  // The index and the listings path differ only by the last segment.
  return (
    pathname.split('/')?.[2] == 'c' && !isIndexPath(pathname) && !isListingDetailPath(pathname)
  );
};

export const isListingDetailPath = (pathname: string): boolean => {
  // Anchors in regex to avoid query params like ?keyword=123 on listings search page
  return Boolean(
    pathname.split('/')?.[2] == 'c' && pathname.split('/').slice(-1)?.[0]?.match(/^\d+$/)
  );
};

export const isLocalListingsPath = (pathname: string): boolean => {
  // The index and the listings path differ only by the last segment.
  return (
    pathname.includes('/c/') &&
    !isIndexPath(pathname) &&
    (pathname.includes(`distance=`) || pathname.includes('postal_code='))
  );
};

export const isSavedSearchesPath = (pathname: string): boolean => {
  return pathname.split('/')?.[2] == 'searches';
};

export const isStoresPath = (pathname: string): boolean => {
  return pathname.split('/')?.[2] == 'stores';
};

export const isForYouPath = (pathname: string): boolean => {
  return (
    pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`followed_stores=true`)
  );
};
export const isLikedPath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`liked=true`);
};
export const isSavedPath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`saved=true`);
};
export const isPopularPath = (pathname: string): boolean => {
  return (
    pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes('ordering=popularity')
  );
};
export const isDealsPath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`on_sale=true`);
};
export const isWholesalePath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`wholesale=true`);
};
export const isAuctionsPath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`auction=true`);
};

export const isMyBidsPath = (pathname: string): boolean => {
  return pathname.includes('/c/') && !isIndexPath(pathname) && pathname.includes(`my_bids=true`);
};

export const isEditAnimalPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/inventory\/edit/);
};
export const isAddEditAnimalPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/inventory\/(add|edit|clone)/);
};
export const isAnimalActivityListPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/activities\/\d+/);
};
export const isAnimalActivitiesOverviewPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/activities\/overview\/\d+/);
};
export const isHusbandryPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\//);
};
export const isPublicHusbandryPath = (pathname: string): boolean => {
  return isListingDetailPath(pathname);
};
export const isAddEditActivityPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/activities\/(add|edit)/);
};

export const isDashboardPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/dashboard\/?/);
};
export const isInventoryPath = (pathname: string): boolean => {
  return (
    !!pathname.match(/^\/animals\/?/) &&
    !pathname.match(/^\/animals\/searches\/?/) &&
    !pathname.match(/^\/animals\/husbandry\/?/)
  );
};
export const isHusbandryInventory = (pathname: string): boolean => {
  return !!pathname.match(/^\/animals\/husbandry\/?/);
};
export const isActivityListPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/activities\/?$/);
};
export const isAnimalGroupsPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/groups\/?$/);
};
export const isAnimalGroupDetailPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/husbandry\/groups\/\d+\/?$/);
};
export const isOrdersPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/orders\/?/);
};
export const isBreedingPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/breeding\/?/);
};
export const isOffspringPath = (pathname: string): boolean => {
  return !!pathname.match(/^\/offspring\/?/);
};
export const isHomePath = (pathname: string): boolean => {
  return pathname === '/';
};
export const isMessengerPath = (pathname: string): boolean => {
  return pathname.startsWith('/messages');
};

export const BREEDING_URLS = {
  outcomes: '/breeding/outcomes/',
};
