import { FC } from 'react';
import { faGenderless, faMars, faVenus } from '@fortawesome/pro-solid-svg-icons';
import { Stack, SxProps } from '@mui/material';
import { blue, grey, pink } from '@mui/material/colors';
import { isHatchedActivity } from 'api/generated/activityTypes';
import { BornActivity, HatchedActivity } from 'api/sdk';
import { IconFieldBox } from './FieldBox';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const HatchedDataCell: FC<{ row: HatchedActivity | BornActivity; sx?: SxProps }> = ({
  row,
  sx,
}) => {
  return (
    <Stack gap={1} direction="row" flexWrap="wrap" sx={sx}>
      <IconFieldBox icon={faMars} color={blue[200]}>
        {isHatchedActivity(row) ? row.maleCountHatched : row.maleCountBorn}
      </IconFieldBox>
      <IconFieldBox icon={faVenus} color={pink[200]}>
        {isHatchedActivity(row) ? row.femaleCountHatched : row.femaleCountBorn}
      </IconFieldBox>
      <IconFieldBox icon={faGenderless} color={grey[400]}>
        {isHatchedActivity(row) ? row.unsexedCountHatched : row.unsexedCountBorn}
      </IconFieldBox>
    </Stack>
  );
};
