// From Dan Abramov
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
const monkeyPatchNode = () => {
  if (typeof Node === 'function' && Node.prototype && !Node.patched) {
    Node.patched = true;

    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

const handleClassChange = () => {
  if (document.querySelector('html').className.includes('translated')) {
    monkeyPatchNode();
  }
};

/**
 * Using Chrome's built-in Translate led to exceptions for certain components.
 * We're only patching when we're sure the page has been translated for perf reasons.
 *
 * Examples of Sentry issues:
 * - https://morphmarket.sentry.io/issues/3773906752
 * - https://morphmarket.sentry.io/issues/4400771620
 *
 * We can remove this if/when this gets resolved:
 * - https://bugs.chromium.org/p/chromium/issues/detail?id=872770
 */
export const patchGoogleTranslate = () => {
  const observer = new MutationObserver(handleClassChange);
  observer.observe(document.querySelector('html'), {
    attributes: true,
    attributeFilter: ['class'],
  });
};
