/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponsivenessRating,
    ResponsivenessRatingFromJSON,
    ResponsivenessRatingFromJSONTyped,
    ResponsivenessRatingToJSON,
} from './ResponsivenessRating';
import {
    ResponsivenessRatingRating,
    ResponsivenessRatingRatingFromJSON,
    ResponsivenessRatingRatingFromJSONTyped,
    ResponsivenessRatingRatingToJSON,
} from './ResponsivenessRatingRating';

/**
 * 
 * @export
 * @interface AnimalOwnerSellerResponsiveness
 */
export interface AnimalOwnerSellerResponsiveness {
    /**
     * 
     * @type {number}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    readonly score: number | null;
    /**
     * 
     * @type {ResponsivenessRatingRating}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    rating: ResponsivenessRatingRating;
    /**
     * 
     * @type {string}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    shortMessage: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    color: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalOwnerSellerResponsiveness
     */
    isKnown: boolean;
}

export function AnimalOwnerSellerResponsivenessFromJSON(json: any): AnimalOwnerSellerResponsiveness {
    return AnimalOwnerSellerResponsivenessFromJSONTyped(json, false);
}

export function AnimalOwnerSellerResponsivenessFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalOwnerSellerResponsiveness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'rating': ResponsivenessRatingRatingFromJSON(json['rating']),
        'message': json['message'],
        'shortMessage': json['short_message'],
        'color': json['color'],
        'isKnown': json['is_known'],
    };
}

export function AnimalOwnerSellerResponsivenessToJSON(value?: AnimalOwnerSellerResponsiveness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': ResponsivenessRatingRatingToJSON(value.rating),
        'message': value.message,
        'short_message': value.shortMessage,
        'color': value.color,
        'is_known': value.isKnown,
    };
}

