/**
 * Conditionally returns an instance of a custom hook or undefined otherwise.
 * This function is an anti-pattern and should only be used when the circumstances require it.
 * Any usage should be scrutinized during code review to see if a better solution could be found.
 * Main purpose is to elegantly handle custom hooks that throw errors on instantiation, such as those that depend on a specific
 * ReactContext (for example ReactRouter et all).
 */
export const useConditionalHook = <H extends () => ReturnType<H>>(
  customHook: H
): undefined | ReturnType<H> => {
  try {
    return customHook();
  } catch (e) {
    return undefined;
  }
};
