import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const mmMessages: IconDefinition = {
  prefix: 'fab',
  iconName: 'accessible-icon', // We need to fake the icon name here
  icon: [
    24,
    19,
    [],
    '',
    `M15.6002 9.50622V2.34313C15.6002 1.06759 14.524 0 13.2002 0H2.40024C1.07837 0 0.000244141 1.06759 0.000244141 2.34313V9.4728C0.000244141 10.817 1.07837 11.8828 2.40024 11.8828L3.60024 11.8832V13.8137C3.60024 14.0774 3.81774 14.2593 4.05024 14.2593C4.13931 14.2593 4.23306 14.2315 4.31743 14.1711L8.40024 11.8828L13.2002 11.88C14.524 11.8791 15.6002 10.817 15.6002 9.50622ZM7.92774 10.1004L5.40024 11.5152V10.1004H2.40024C2.07212 10.1004 1.80024 9.833 1.80024 9.50622V2.37655C1.80024 2.05164 2.07212 1.78242 2.40024 1.78242H13.2002C13.5284 1.78242 13.8002 2.05164 13.8002 2.37655V9.50622C13.8002 9.83114 13.5284 10.1004 13.2002 10.1004H7.92774ZM21.6002 4.75311H16.8002V9.50622C16.8002 11.4721 15.1855 13.0707 13.2002 13.0707L9.60024 13.0707V14.2583C9.60024 15.5672 10.6784 16.6014 11.9665 16.6014L16.684 16.6011L19.7871 18.9079C20.059 19.1164 20.4002 18.9456 20.4002 18.6485V16.6344H21.6002C22.9221 16.6344 24.0002 15.5668 24.0002 14.2913V7.09624C24.0002 5.81885 22.924 4.75311 21.6002 4.75311Z`,
  ],
};
