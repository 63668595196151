import { generateSingletonHook } from 'context/SingletonContext';
import { useStores as useStoresAPI } from 'api/generated/useStores';

const useDecoratedHook = () => {
  const unwrapped = useStoresAPI();
  return {
    unwrapped,
    isLoading: unwrapped.isLoading,
    stores: unwrapped.featuredStores,
  };
};

const [FeaturedStoresProvider, useFeaturedStores] = generateSingletonHook(useDecoratedHook);
export { FeaturedStoresProvider, useFeaturedStores };
