import NotesIcon from 'components/Offspring/NotesIcon/NotesIcon';
import style from 'components/Offspring/OffspringTable/OffspringTable.module.scss';
import { NOTES_ORDERING } from 'api/offspring/ordering';

export const NOTES_COLUMN = Symbol('NOTES_COLUMN');

const notesColumn = {
  id: NOTES_COLUMN,
  ordering: NOTES_ORDERING,
  label: 'Notes',
  hideable: true,
  className: style.center,

  render: ({ public_notes }) => <NotesIcon notes={public_notes} />,
};

export default notesColumn;
