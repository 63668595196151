import dayjs from 'dayjs';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { LAST_RENEWED_ORDERING } from 'api/inventory/ordering';
import { SHORT_DATE_FORMAT } from 'services/constants';

export const LAST_RENEWED_COLUMN = Symbol('LAST_RENEWED_COLUMN');

const lastRenewedColumn = {
  id: LAST_RENEWED_COLUMN,
  ordering: LAST_RENEWED_ORDERING,
  label: 'Last Renewed',
  hideable: true,
  className: style.lastUpdatedColumn,

  render: ({ last_renewal }: { last_renewal: string | null }) =>
    last_renewal === null ? '—' : dayjs(last_renewal).format(SHORT_DATE_FORMAT),
};

export default lastRenewedColumn;
