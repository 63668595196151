import { ClickAwayListener, Menu, MenuClasses, MenuListProps, MenuProps } from '@mui/material';
import styles from './Menu.module.scss';

/**
 * The Generic Menu is just a collection of repetitive properties and settings that are used for most Navbar menus.
 */
export const GenericMenu: React.FC<MenuProps> = ({
  children,
  classes,
  MenuListProps,
  ...props
}) => {
  const shiftFocus = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.getAttribute('role') === 'menuitem') {
      target?.focus();
    }
  };
  const onClickAway = (e: MouseEvent | TouchEvent) => {
    const element = (e.target as any)?.closest('button');

    if (`dropdown-menu-for-${element?.id}` !== props.id) {
      props?.onClose?.(e, 'backdropClick');
    }
  };

  const defaultClasses: Partial<MenuClasses> = {
    root: styles.rootSansPointerEvents,
    paper: styles.paperWithPointerEvents,
    list: styles.list,
  };

  const defaultMenuListProps = {
    component: 'div',
  } as MenuListProps;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Menu
        autoFocus
        transitionDuration={0}
        onMouseOver={shiftFocus}
        MenuListProps={{ ...defaultMenuListProps, ...MenuListProps }}
        classes={{ ...defaultClasses, ...classes }}
        {...props}
      >
        {children}
      </Menu>
    </ClickAwayListener>
  );
};
