/**
 * This call is not part of the API - it's an ad-hoc page for logging the user out.
 */
export const logout = () => {
  const url = '/accounts/logout/';
  const body = JSON.stringify({ content: 'xxx', csrfmiddlewaretoken: window.CSRF_TOKEN });

  return fetch(url, {
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': window.CSRF_TOKEN,
    },
  });
};
