import classNames from 'classnames';
import styles from './Components.module.scss';

export const Body: React.FC<{
  children: React.ReactNode;
  widthAuto?: boolean;
  isNarrow?: boolean;
}> = ({ widthAuto = false, isNarrow = false, children }) => {
  return (
    <div
      className={classNames(styles.body, {
        [styles.widthAuto]: widthAuto,
        [styles.narrow]: isNarrow,
      })}
    >
      {children}
    </div>
  );
};

export const Controls: React.FC<{
  children: React.ReactNode;
  withShadow?: boolean;
  rightAligned?: boolean;
  fixed?: boolean;
  classname?: string;
}> = ({ children, withShadow, fixed, rightAligned = false, classname }) => {
  return (
    <>
      <div
        className={classNames([
          styles.controls,
          withShadow && styles.withShadow,
          rightAligned && styles.rightAligned,
          fixed && styles.fixed,
          classname,
        ])}
      >
        {children}
      </div>
      <div className={styles.spacer}></div>
    </>
  );
};
