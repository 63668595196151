const requestsInProgress: Record<string, ReturnType<typeof fetch>> = {};

/**
 * uFetch, short for unique fetch is a thin wrapper around fetch. Its purpose is to prevent identical requests to the same URL simultaneously by storing the first fetch request and returning it instead of creating new requests. The URL is used as a unique key - if you want to make multiple simultaneous requests to the same URL with different parameters, _do not_ use this function.
 */
export function uFetch(...args: Parameters<typeof fetch>): ReturnType<typeof fetch> {
  const [request] = args;
  const key: string = (request as Request)?.url || (request as string);

  if (!requestsInProgress[key]) {
    requestsInProgress[key] = fetch(...args);
    requestsInProgress[key].finally(() => {
      delete requestsInProgress[key];
    });
  }

  return requestsInProgress[key];
}
