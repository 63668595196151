import { Components, Theme } from '@mui/material';
import { colors } from '../constants';

const { gray200, gray000 } = colors;

export const MuiInputLabel: Components<Omit<Theme, 'components'>>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'transparent',
      color: gray200,
      transform: 'translate(14px, 8px) scale(1)',
      '&.Mui-focused': {
        color: gray200,
      },

      // Custom styles for Select
      // This is a workaround for select placeholder
      [`&[id^="state-field"]`]: {
        // Custom color for placeholder in select to match MUI's default placeholder color
        color: 'rgb(172, 172, 172)',
        fontSize: theme.typography.sm.fontSize,
        transform: 'translate(23px, 12px) scale(1)',
        '&.MuiFormLabel-filled': {
          opacity: 0,
        },
      },
      [`&[id^="select-label"]`]: {
        color: gray000,
        fontSize: theme.typography.sm.fontSize,
        '&.Mui-error': {
          color: `${theme.palette.error.main}`,
        },
      },
    }),
    shrink: {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
};
