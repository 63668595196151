import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { BIRTH_DATE_ORDERING } from 'api/inventory/ordering';
import { DESCENDING } from 'api/ordering';
import { Animal } from 'types';

export const BIRTH_DATE_COLUMN = Symbol('BIRTH_DATE_COLUMN');

const birthDateColumn = {
  id: BIRTH_DATE_COLUMN,
  ordering: BIRTH_DATE_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Date of Birth',
  shortLabel: 'Birth',
  hideable: true,
  className: style.birthDateColumn,

  render: ({ birth_date_display }: Animal) => birth_date_display,
};

export default birthDateColumn;
