import { forwardRef, ReactNode } from 'react';
import { IconDefinition, faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Link, MenuItem, MenuItemProps } from '@mui/material';
import { colors } from 'context/ThemeContext/Theme/constants';
import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../../../../src/js/app/rn-messages';
import { BackButton, CategoryBranch } from './CategoryTree';
import { useHandleLinkClick } from './useHandleLinkClick';
import styles from './styles.module.scss';

const { gray300 } = colors;

export type OptionProps = MenuItemProps<
  'li',
  {
    children?: ReactNode;
    CustomComponent?: React.FC;
    href?: string;
    links?: OptionProps[];
    divider?: boolean;
    icon?: IconDefinition;
    active?: boolean;
    name?: string;
    isBackButton?: boolean;
    recentlyVisited?: boolean;
    has_wizard?: boolean;
    appVersion?: string;
    closeMenu?: () => void;
    onClick?: () => void;
  }
>;

export const MenuOption = forwardRef<OptionProps, any>(
  (
    {
      id,
      children,
      href,
      links,
      divider,
      name,
      active,
      isBackButton,
      recentlyVisited,
      icon,
      appVersion,
      closeMenu,
      onClick: propsOnClick,
      CustomComponent,
      has_wizard: _, // We don't use this prop here, and removing it from rest.
      ...rest
    },
    ref
  ) => {
    const MenuItemProps = { ...rest, selected: !!active, ref };
    const onClick = useHandleLinkClick({ recentlyVisited, href, id, closeMenu, ...MenuItemProps });

    if (!!CustomComponent) {
      return CustomComponent;
    }

    if (divider) {
      return <Divider style={{ margin: '0 0 0.5rem 0' }} />;
    }

    if (links?.length) {
      return <CategoryBranch {...MenuItemProps} links={links} children={children} name={name} />;
    }

    if (href) {
      return (
        <MenuItem {...MenuItemProps} component={Link} href={href} disableRipple onClick={onClick}>
          <div>
            {!!icon && <FontAwesomeIcon icon={icon} style={{ width: '1em', marginRight: '1em' }} />}
            <span>{children || name}</span>
            {!!recentlyVisited && (
              <Box sx={{ color: gray300, fontSize: '0.75rem' }}>(Recently viewed)</Box>
            )}
          </div>
        </MenuItem>
      );
    }

    if (isBackButton) {
      return <BackButton {...MenuItemProps} name={name} children={children} />;
    }

    if (propsOnClick) {
      return (
        <MenuItem {...MenuItemProps} onClick={propsOnClick}>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%' }}>
            {!!icon && <FontAwesomeIcon icon={icon} style={{ width: '1em' }} />}
            <span>{children}</span>
          </Box>
        </MenuItem>
      );
    }

    if (appVersion) {
      return (
        <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            onClick={() => {
              window.location.reload();
              sendMessageToReactNative(MESSAGE_TYPES.APP_UPDATE_BUTTON_PRESSED);
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            <span style={{ marginLeft: '0.5rem' }}>Reload App</span>
          </div>
          <div className={styles.version}>App version: {appVersion}</div>
        </MenuItem>
      );
    }

    return null;
  }
);
