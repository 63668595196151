import { isInventoryAnimalNavPath } from 'components/experimental/Navigation/mobile/Services';
import { InventoryAnimalMenu } from '../../components/InventoryAnimalMenu/InventoryAnimalMenu';

import styles from './UtilityNavDesktop.module.scss';

const UtilityNavDesktop = () => {
  const isInventoryAnimalPage = isInventoryAnimalNavPath();

  return (
    <>
      {isInventoryAnimalPage && (
        <div className={styles.additionalNavbar}>
          <InventoryAnimalMenu />
        </div>
      )}
    </>
  );
};

export default UtilityNavDesktop;
