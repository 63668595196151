/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThreadTagUpdateRequest,
    ThreadTagUpdateRequestFromJSON,
    ThreadTagUpdateRequestFromJSONTyped,
    ThreadTagUpdateRequestToJSON,
} from './ThreadTagUpdateRequest';

/**
 * 
 * @export
 * @interface TagActionRequest
 */
export interface TagActionRequest {
    /**
     * 
     * @type {Array<ThreadTagUpdateRequest>}
     * @memberof TagActionRequest
     */
    add?: Array<ThreadTagUpdateRequest>;
    /**
     * 
     * @type {Array<ThreadTagUpdateRequest>}
     * @memberof TagActionRequest
     */
    remove?: Array<ThreadTagUpdateRequest>;
}

export function TagActionRequestFromJSON(json: any): TagActionRequest {
    return TagActionRequestFromJSONTyped(json, false);
}

export function TagActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add': !exists(json, 'add') ? undefined : ((json['add'] as Array<any>).map(ThreadTagUpdateRequestFromJSON)),
        'remove': !exists(json, 'remove') ? undefined : ((json['remove'] as Array<any>).map(ThreadTagUpdateRequestFromJSON)),
    };
}

export function TagActionRequestToJSON(value?: TagActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add': value.add === undefined ? undefined : ((value.add as Array<any>).map(ThreadTagUpdateRequestToJSON)),
        'remove': value.remove === undefined ? undefined : ((value.remove as Array<any>).map(ThreadTagUpdateRequestToJSON)),
    };
}

