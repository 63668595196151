declare global {
  interface Window {
    USERNAME: string;
    MESSAGE_COUNT_URL: string;
    DJANGO_MESSAGES_SS_KEY: string;
    IS_AUTHENTICATED: boolean;
    IS_WEBSOCKETS_ENABLED: boolean;
    CATEGORY_CONTEXT: number;
  }
}

export const { MESSAGE_COUNT_URL } = window;
export const { DJANGO_MESSAGES_SS_KEY } = window;
export const { IS_AUTHENTICATED } = window;
export const { IS_WEBSOCKETS_ENABLED } = window;
export const { USERNAME } = window;
export const { CATEGORY_CONTEXT } = window;
