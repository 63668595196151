import { MenuProps } from 'components/experimental/FilterMenus';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import {
  generateNestedMenu,
  NestedItem,
  NestedMenu,
  NestedMenuProps,
} from 'components/experimental/MUI/NestedMenu';
import { appendActiveTag } from '../../helpers';
import { CaretButton, CaretButtonProps } from '../Buttons/CaretButton';
import { NestedMenuItem, NestedMenuItemProps } from '../MenuItems/Item';
import { GenericMenu } from './GenericMenu';

export type TreeMenuProps = {
  name: string;
  links: any[];
  props?: {
    CaretButtonProps?: CaretButtonProps;
    NestedMenuProps?: NestedMenuProps;
    GenericMenuProps?: MenuProps;
    NestedMenuItemProps?: NestedMenuItemProps;
  };
};

/**
 * A menu with submenus that can be navigated through.
 */

const calculateAlphabetizeSubMenu = (name: string, subMenu: NestedItem[] | undefined) => {
  // We do not need to change the order of the Tier 1 Levels (Pythons, Colubrids, etc), only the Tier 2 (ball pythons, reticulated pythons, etc.) and Tier 3 options. #5800
  // undefined subMenu is Tier 1
  if (name !== 'Categories' || !subMenu) {
    return subMenu;
  }

  // If we have back button it should always come first in order
  let backButtonOption: NestedMenuItemProps = {} as NestedMenuItemProps;
  // The "All X" option should still be at the top, and the "Other X" option should be last.
  let allOption: NestedMenuItemProps = {} as NestedMenuItemProps;
  let otherOption: NestedMenuItemProps = {} as NestedMenuItemProps;
  // In the instance of Tier 3 categories, if there is a "More X" option, that should be last.
  let moreOption: NestedMenuItemProps = {} as NestedMenuItemProps;

  const currentMenu = [...subMenu] as NestedMenuItemProps[];
  if (currentMenu.some((item) => item.variant === 'close')) {
    return subMenu; // Tier 1 level
  }
  // isBackButton needs to come first if exists
  if (currentMenu[0].variant === 'back') {
    [backButtonOption] = currentMenu;
    currentMenu.shift();
  }
  // allOption needs to come second if exists
  if (currentMenu[0].name?.slice(0, 4) === 'All ') {
    [allOption] = currentMenu;
    currentMenu.shift();
  }
  // "Other X" option needs to come last if exists
  const otherOptionIndex = currentMenu.findIndex((item) => item.name?.slice(0, 6) === 'Other ');
  if (otherOptionIndex !== -1) {
    [otherOption] = currentMenu.splice(otherOptionIndex, 1);
  }
  // "More X" option needs to come last if exists
  const moreXIndex = currentMenu.findIndex((item) => item.name?.slice(0, 5) === 'More ');
  if (moreXIndex !== -1) {
    [moreOption] = currentMenu.splice(moreXIndex, 1);
  }
  // this is the middle of the copy of current branch needs to be sorted
  currentMenu.sort((a, b) => {
    const labelA = a.name?.toLowerCase() ?? '';
    const labelB = b.name?.toLowerCase() ?? '';

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
  const result = [
    ...(backButtonOption.hasOwnProperty('name') ? [backButtonOption] : []),
    ...(allOption.hasOwnProperty('name') ? [allOption] : []),
    ...currentMenu,
    ...(otherOption.hasOwnProperty('name') ? [otherOption] : []),
    ...(moreOption.hasOwnProperty('name') ? [moreOption] : []),
  ];

  return result;
};

export const TreeMenu: React.FC<TreeMenuProps> = ({ name, links, props }) => {
  const { utils, toggle, close } = useDropdownMenu({ id: `${name}-menu` });
  const linksWithCloseButton = [{ variant: 'close', name, onClick: close }, ...(links || [])];
  const { button, menu } = utils;

  return (
    <>
      <CaretButton {...button} {...props?.CaretButtonProps} onClick={toggle}>
        {name}
      </CaretButton>
      <NestedMenu {...props?.NestedMenuProps}>
        {({ goTo, goBack, resetMenu }, subMenu) => {
          const orderedSubMenu = calculateAlphabetizeSubMenu(name, subMenu);
          return (
            <GenericMenu
              {...menu}
              {...props?.GenericMenuProps}
              onClose={() => {
                close();
                resetMenu(300);
              }}
            >
              {generateNestedMenu({
                items: (orderedSubMenu || linksWithCloseButton).map(appendActiveTag),
                keyProps: ['name', 'children'],
                Component: NestedMenuItem,
                optionalProps: {
                  goTo: goTo(subMenu || linksWithCloseButton),
                  goBack,
                  ...props?.NestedMenuItemProps,
                },
              })}
            </GenericMenu>
          );
        }}
      </NestedMenu>
    </>
  );
};
