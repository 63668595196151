/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    AnimalOwnerSellerResponsiveness,
    AnimalOwnerSellerResponsivenessFromJSON,
    AnimalOwnerSellerResponsivenessFromJSONTyped,
    AnimalOwnerSellerResponsivenessToJSON,
} from './AnimalOwnerSellerResponsiveness';
import {
    BaseStoreUrls,
    BaseStoreUrlsFromJSON,
    BaseStoreUrlsFromJSONTyped,
    BaseStoreUrlsToJSON,
} from './BaseStoreUrls';
import {
    MembershipBadge,
    MembershipBadgeFromJSON,
    MembershipBadgeFromJSONTyped,
    MembershipBadgeToJSON,
} from './MembershipBadge';
import {
    OrganizationTypeEnum,
    OrganizationTypeEnumFromJSON,
    OrganizationTypeEnumFromJSONTyped,
    OrganizationTypeEnumToJSON,
} from './OrganizationTypeEnum';
import {
    StoreAuctionStatus,
    StoreAuctionStatusFromJSON,
    StoreAuctionStatusFromJSONTyped,
    StoreAuctionStatusToJSON,
} from './StoreAuctionStatus';
import {
    StoreFeatures,
    StoreFeaturesFromJSON,
    StoreFeaturesFromJSONTyped,
    StoreFeaturesToJSON,
} from './StoreFeatures';
import {
    StoreOwner,
    StoreOwnerFromJSON,
    StoreOwnerFromJSONTyped,
    StoreOwnerToJSON,
} from './StoreOwner';
import {
    StoreRatings,
    StoreRatingsFromJSON,
    StoreRatingsFromJSONTyped,
    StoreRatingsToJSON,
} from './StoreRatings';

/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    storeName: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    storeLabel?: string;
    /**
     * 
     * @type {StoreOwner}
     * @memberof Store
     */
    owner: StoreOwner;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    sellerRatingScore?: number | null;
    /**
     * 
     * @type {MembershipBadge}
     * @memberof Store
     */
    membershipBadge: MembershipBadge;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    homeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    twitterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    threadsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    tiktokUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    coldbloodedUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    readonly logoUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    readonly imageUrl: string | null;
    /**
     * display_name is used to here to provide a common interface with OrganizationSerializer
     * @type {string}
     * @memberof Store
     */
    readonly displayName: string;
    /**
     * 
     * @type {Address}
     * @memberof Store
     */
    address: Address;
    /**
     * 
     * @type {AnimalOwnerSellerResponsiveness}
     * @memberof Store
     */
    responsivenessRating: AnimalOwnerSellerResponsiveness;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    readonly averageResponseTimeMessage: string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    approved?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof Store
     */
    readonly created: Date;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    readonly followerCount: number;
    /**
     * 
     * @type {StoreRatings}
     * @memberof Store
     */
    ratings: StoreRatings;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    readonly currencySymbol: string;
    /**
     * 
     * @type {BaseStoreUrls}
     * @memberof Store
     */
    urls: BaseStoreUrls;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    readonly listingsCount: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    personName: string;
    /**
     * 
     * @type {OrganizationTypeEnum}
     * @memberof Store
     */
    organizationType?: OrganizationTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    readonly isAnimalSeller: boolean;
    /**
     * 
     * @type {StoreFeatures}
     * @memberof Store
     */
    features: StoreFeatures;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    readonly hasWaitlists: boolean;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    readonly auctionsPerMonth: number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    readonly canCreateAuction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    readonly canEnablePayment: boolean;
    /**
     * 
     * @type {StoreAuctionStatus}
     * @memberof Store
     */
    auctionStatus: StoreAuctionStatus;
}

export function StoreFromJSON(json: any): Store {
    return StoreFromJSONTyped(json, false);
}

export function StoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Store {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeName': json['store_name'],
        'storeLabel': !exists(json, 'store_label') ? undefined : json['store_label'],
        'owner': StoreOwnerFromJSON(json['owner']),
        'sellerRatingScore': !exists(json, 'seller_rating_score') ? undefined : json['seller_rating_score'],
        'membershipBadge': MembershipBadgeFromJSON(json['membership_badge']),
        'homeUrl': !exists(json, 'home_url') ? undefined : json['home_url'],
        'facebookUrl': !exists(json, 'facebook_url') ? undefined : json['facebook_url'],
        'instagramUrl': !exists(json, 'instagram_url') ? undefined : json['instagram_url'],
        'twitterUrl': !exists(json, 'twitter_url') ? undefined : json['twitter_url'],
        'youtubeUrl': !exists(json, 'youtube_url') ? undefined : json['youtube_url'],
        'threadsUrl': !exists(json, 'threads_url') ? undefined : json['threads_url'],
        'tiktokUrl': !exists(json, 'tiktok_url') ? undefined : json['tiktok_url'],
        'coldbloodedUrl': !exists(json, 'coldblooded_url') ? undefined : json['coldblooded_url'],
        'logoUrl': json['logo_url'],
        'imageUrl': json['image_url'],
        'displayName': json['display_name'],
        'address': AddressFromJSON(json['address']),
        'responsivenessRating': AnimalOwnerSellerResponsivenessFromJSON(json['responsiveness_rating']),
        'averageResponseTimeMessage': json['average_response_time_message'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'created': (new Date(json['created'])),
        'followerCount': json['follower_count'],
        'ratings': StoreRatingsFromJSON(json['ratings']),
        'currencyCode': !exists(json, 'currency_code') ? undefined : json['currency_code'],
        'currencySymbol': json['currency_symbol'],
        'urls': BaseStoreUrlsFromJSON(json['urls']),
        'listingsCount': json['listings_count'],
        'personName': json['person_name'],
        'organizationType': !exists(json, 'organization_type') ? undefined : OrganizationTypeEnumFromJSON(json['organization_type']),
        'isAnimalSeller': json['is_animal_seller'],
        'features': StoreFeaturesFromJSON(json['features']),
        'hasWaitlists': json['has_waitlists'],
        'auctionsPerMonth': json['auctions_per_month'],
        'canCreateAuction': json['can_create_auction'],
        'canEnablePayment': json['can_enable_payment'],
        'auctionStatus': StoreAuctionStatusFromJSON(json['auction_status']),
    };
}

export function StoreToJSON(value?: Store | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'store_name': value.storeName,
        'store_label': value.storeLabel,
        'owner': StoreOwnerToJSON(value.owner),
        'seller_rating_score': value.sellerRatingScore,
        'membership_badge': MembershipBadgeToJSON(value.membershipBadge),
        'home_url': value.homeUrl,
        'facebook_url': value.facebookUrl,
        'instagram_url': value.instagramUrl,
        'twitter_url': value.twitterUrl,
        'youtube_url': value.youtubeUrl,
        'threads_url': value.threadsUrl,
        'tiktok_url': value.tiktokUrl,
        'coldblooded_url': value.coldbloodedUrl,
        'address': AddressToJSON(value.address),
        'responsiveness_rating': AnimalOwnerSellerResponsivenessToJSON(value.responsivenessRating),
        'approved': value.approved,
        'ratings': StoreRatingsToJSON(value.ratings),
        'currency_code': value.currencyCode,
        'urls': BaseStoreUrlsToJSON(value.urls),
        'person_name': value.personName,
        'organization_type': OrganizationTypeEnumToJSON(value.organizationType),
        'features': StoreFeaturesToJSON(value.features),
        'auction_status': StoreAuctionStatusToJSON(value.auctionStatus),
    };
}

