import VisibilityIcon from 'components/Icons/VisibilityIcon/VisibilityIcon';
import style from 'components/Offspring/OffspringTable/OffspringTable.module.scss';
import { VISIBILITY_ORDERING } from 'api/offspring/ordering';

export const VISIBILITY_COLUMN = Symbol('VISIBILITY_COLUMN');

const visibilityColumn = {
  id: VISIBILITY_COLUMN,
  ordering: VISIBILITY_ORDERING,
  label: 'Visibility',
  hideable: true,
  className: style.visibilityColumn,

  render: ({ visibility }) => <VisibilityIcon visibility={visibility} />,
};

export default visibilityColumn;
