import { HTMLAttributes, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isRtoR } from 'services/isRtoR';

export type AnchorProps = HTMLAttributes<HTMLAnchorElement> & {
  autoFocus?: boolean;
  name?: string;
  has_wizard?: boolean;
  href?: string;
  active?: boolean;
};

export const Anchor: React.FC<AnchorProps> = ({
  children,
  name,
  autoFocus,
  active,
  has_wizard,
  ...props
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);

  const isReactLink = isRtoR(props.href || '');

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  const clearFocus = () => {
    const { body } = document;
    if (body) body.click();
  };

  if (isReactLink && props.href) {
    return (
      <Link
        ref={ref}
        tabIndex={0}
        role="menuitem"
        aria-current={active ? 'page' : undefined}
        to={props.href}
        onClick={clearFocus}
        style={props.style}
      >
        {children || name}
      </Link>
    );
  }

  return (
    <a ref={ref} tabIndex={0} role="menuitem" aria-current={active ? 'page' : undefined} {...props}>
      {children || name}
    </a>
  );
};
