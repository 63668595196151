import { useWindowSize } from 'hooks';

/**
 * Partition links into the main (horizontal) and secondary (ellipsis menu) sets.
 * Slice start is based on available horizontal space.
 */
export const usePartitionLinks = <T>(links: T[], width: number, getHelpLink?: T) => {
  const { width: spaceAvailable } = useWindowSize();
  const withoutGetHelpLink = links.filter((link) => link !== getHelpLink);
  const spaceRequired = (withoutGetHelpLink.length + (!!getHelpLink ? 1 : 0)) * width;
  const spaceLeft = (spaceAvailable || 0) - spaceRequired - width;
  const splitOn =
    spaceLeft > 0
      ? withoutGetHelpLink.length
      : withoutGetHelpLink.length - Math.round(Math.abs(spaceLeft) / width);

  if (spaceAvailable === undefined) {
    return {
      primaryLinks: [getHelpLink],
      secondaryLinks: withoutGetHelpLink.slice(0, withoutGetHelpLink.length),
    };
  } else {
    return {
      primaryLinks: [
        ...withoutGetHelpLink.slice(0, splitOn),
        ...(!!getHelpLink ? [getHelpLink] : []),
      ],
      secondaryLinks: withoutGetHelpLink.slice(splitOn, withoutGetHelpLink.length),
    };
  }
};
