import { useParams, useSearchParams } from 'react-router-dom';
import { EnumsBucket } from 'constants/enums';
import { CATEGORY_CONTEXT } from 'constants/user';
import { useUrlParamsContext } from 'context/UrlParamsContext';
import { CategoryObject } from 'services/constants';
// FIXME: In general, this middleman object (the imports renamed to be beginning with originalX) is not ideal. We should centralize.
import {
  allCategories as originalAllCategories,
  categoryMenuChoices,
  extendedCategoryMenuChoices,
  rootCategory as originalRootCategory,
} from 'services/constants/misc';

// FIXME: This export is named incorrectly, it should be `categoryMenuChoices` but
// we were forced to keep it as it is for backward compatibility reasons.
export const categories = categoryMenuChoices;
// FIXME: This export is named incorrectly, it should be `extendedCategoryMenuChoices` but
// we were forced to keep it as it is for backward compatibility reasons.
export const expandedCategories = extendedCategoryMenuChoices;
export const allCategories = originalAllCategories;
export const rootCategory = originalRootCategory;

export const getCategoryBranch = (categoryId: number, categories: CategoryObject[]) => {
  const result = [];
  let childId: number | null | undefined = categoryId;

  while (childId != null) {
    const category = categories.find(
      ({ id }) => id === childId // eslint-disable-line no-loop-func
    );

    if (category !== undefined) {
      result.push(category);
    }

    childId = category?.parent_id;
  }

  return result;
};

// FIXME: This is NOT all categories. This function name does nothing to describe what it does.
export const getCategories = () => {
  const allId = originalAllCategories?.find((category) => category.parent_id === null)?.id;
  let categories = originalAllCategories?.filter((category) => category.parent_id !== null);
  categories = categories?.map((category) =>
    category.parent_id === allId ? { ...category, parent_id: null } : category
  );

  return categories;
};

export const convertCategoryIdToShortName = (
  categoryId: number,
  // We make the `categoriesList` a parameter because the set of categories you are looking at varies by context on the site.
  categoriesList = categories
): string | null => {
  const category = categoriesList.find((category) => category.id === categoryId);
  // We return `null` instead of `undefined if not found because our PATCH code for calling the endpoints sometimes filters out `undefined`, making it impossible to attempt to unset category. This
  // would skip the useful validation UX.
  return category?.value || null;
};

export const CATEGORY_FILTER = Symbol('CATEGORY_FILTER');

/**
 * Preferentially returns the category based on the URL segment params
 * e.g. `../reptiles/pythons` or `../reptiles/pythons/ball-pythons`
 * or URL search params - e.g. `../?category=reptiles`
 *
 * Can be used in conjunction with `CATEGORY_CONTEXT` to get the category
 * if you pass the `shouldFallbackToCategoryContext` param. However, this param
 * should be used with caution as it can get out of sync if you navigate
 * between React-only pages.
 */
export const useCurrentCategory = ({
  shouldFallBackToCategoryContext,
}: {
  shouldFallBackToCategoryContext: boolean;
}) => {
  const { category: paramsCategory, animal, animalSubType, ...restParams } = useParams();
  const { urlParams } = useUrlParamsContext();
  const { category: urlParamsCategory } = urlParams || {};
  const category = paramsCategory || urlParamsCategory;
  // This is currently needed on the projects listing page when
  // it displays 'all'
  const starParam = restParams['*'];
  const [searchParams] = useSearchParams();

  const _getCategoryMatchingSegmentParams = (valueToMatch: string) => {
    // We need to match on path segment rather than `value` because
    // the URLs (from which this function gets its input)
    // usually don't use the value (short_name) for category.
    return EnumsBucket.categories.find(({ path }) => path.split('/').slice(-1)[0] == valueToMatch);
  };

  const _getCategoryMatchingSearchParams = (valueToMatch: string) => {
    return EnumsBucket.categories.find(({ value }) => value == valueToMatch);
  };

  // Strategy 1: Try the URL segment params
  if (animalSubType) {
    return _getCategoryMatchingSegmentParams(animalSubType);
  } else if (animal) {
    return _getCategoryMatchingSegmentParams(animal);
  } else if (category) {
    return _getCategoryMatchingSegmentParams(category);
  } else if (starParam) {
    return _getCategoryMatchingSegmentParams('all');

    // Strategy 2: Try the search params
  } else if (searchParams.get('category')) {
    return _getCategoryMatchingSearchParams(searchParams.get('category') as string);
  }

  // Strategy 3: Try the CATEGORY_CONTEXT
  if (!shouldFallBackToCategoryContext) {
    return undefined;
  }

  if (CATEGORY_CONTEXT) {
    return EnumsBucket.categories.find(({ id }) => id === CATEGORY_CONTEXT);
  } else {
    console.warn("Attempted to get fallback from CATEGORY_CONTEXT but couldn't");
    return undefined;
  }
};
