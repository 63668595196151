import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import styles from './ColumnTooltip.module.scss';
interface ColumnTooltipProps {
  text: string;
  icon: IconProp;
}

const ColumnTooltip = (props: ColumnTooltipProps) => {
  const { text, icon } = props;
  return (
    <Tooltip arrow title={text}>
      <span className={styles.icon}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </Tooltip>
  );
};

export default ColumnTooltip;
