/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BreedingPairingDetailsOffspringGroup,
    BreedingPairingDetailsOffspringGroupFromJSON,
    BreedingPairingDetailsOffspringGroupFromJSONTyped,
    BreedingPairingDetailsOffspringGroupToJSON,
} from './BreedingPairingDetailsOffspringGroup';
import {
    BreedingPairingDetailsSire,
    BreedingPairingDetailsSireFromJSON,
    BreedingPairingDetailsSireFromJSONTyped,
    BreedingPairingDetailsSireToJSON,
} from './BreedingPairingDetailsSire';
import {
    BreedingPairingDetailsVisibility,
    BreedingPairingDetailsVisibilityFromJSON,
    BreedingPairingDetailsVisibilityFromJSONTyped,
    BreedingPairingDetailsVisibilityToJSON,
} from './BreedingPairingDetailsVisibility';

/**
 * 
 * @export
 * @interface BreedingPairingDetails
 */
export interface BreedingPairingDetails {
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetails
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetails
     */
    season: number;
    /**
     * 
     * @type {BreedingPairingDetailsSire}
     * @memberof BreedingPairingDetails
     */
    sire: BreedingPairingDetailsSire | null;
    /**
     * 
     * @type {BreedingPairingDetailsSire}
     * @memberof BreedingPairingDetails
     */
    dam: BreedingPairingDetailsSire | null;
    /**
     * 
     * @type {BreedingPairingDetailsVisibility}
     * @memberof BreedingPairingDetails
     */
    visibility?: BreedingPairingDetailsVisibility | null;
    /**
     * 
     * @type {BreedingPairingDetailsOffspringGroup}
     * @memberof BreedingPairingDetails
     */
    offspringGroup: BreedingPairingDetailsOffspringGroup;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetails
     */
    readonly sireBreedingCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetails
     */
    readonly damBreedingCount: number | null;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetails
     */
    readonly publicUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetails
     */
    readonly storeName: string;
}

export function BreedingPairingDetailsFromJSON(json: any): BreedingPairingDetails {
    return BreedingPairingDetailsFromJSONTyped(json, false);
}

export function BreedingPairingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'season': json['season'],
        'sire': BreedingPairingDetailsSireFromJSON(json['sire']),
        'dam': BreedingPairingDetailsSireFromJSON(json['dam']),
        'visibility': !exists(json, 'visibility') ? undefined : BreedingPairingDetailsVisibilityFromJSON(json['visibility']),
        'offspringGroup': BreedingPairingDetailsOffspringGroupFromJSON(json['offspring_group']),
        'sireBreedingCount': json['sire_breeding_count'],
        'damBreedingCount': json['dam_breeding_count'],
        'publicUrl': json['public_url'],
        'storeName': json['store_name'],
    };
}

export function BreedingPairingDetailsToJSON(value?: BreedingPairingDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'season': value.season,
        'sire': BreedingPairingDetailsSireToJSON(value.sire),
        'dam': BreedingPairingDetailsSireToJSON(value.dam),
        'visibility': BreedingPairingDetailsVisibilityToJSON(value.visibility),
        'offspring_group': BreedingPairingDetailsOffspringGroupToJSON(value.offspringGroup),
    };
}

