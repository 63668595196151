import style from 'components/Offspring/OffspringTable/OffspringTable.module.scss';
import { LAY_DATE_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const LAY_DATE_COLUMN = Symbol('LAY_DATE_COLUMN');

const layDateColumn = {
  id: LAY_DATE_COLUMN,
  ordering: LAY_DATE_ORDERING,
  label: 'Lay Date',
  hideable: true,
  className: style.dateColumn,
  defaultDirection: DESCENDING,

  render: ({ lay_date_display }) => lay_date_display,
};

export default layDateColumn;
