import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import { findCategory } from 'services/CustomURLs/helpers/categories';
import { useLocalStorage } from './useLocalStorage';

export type UseRecentlyVisitedCategoriesFunc = () => {
  recentCategories: any[];
  trackVisit: (id: string | number) => void;
};

export const useRecentlyVisitedCategories: UseRecentlyVisitedCategoriesFunc = () => {
  const { value, setValue } = useLocalStorage({ name: 'recently-visited-categories' });
  const recentCategories = useMemo(
    () =>
      (value || [])
        .map((value: any) => findCategory(value))
        .filter((category) => !!category && category?.label !== 'All Categories'),
    [value]
  );
  const trackVisit = (id: string | number) => {
    !!id &&
      setValue((value: any) => {
        const newValue = uniq([id, ...(value || [])]);
        return newValue.slice(0, Math.min(20, newValue.length));
      });
  };

  return {
    recentCategories,
    trackVisit,
  };
};
