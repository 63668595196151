import { FC } from 'react';
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons';
import { Stack, SxProps } from '@mui/material';
import { green, red, blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import { mmEgg } from 'components/experimental/IconDefinitions';
import { LaidActivity } from 'api/sdk';
import { DATE_FORMAT_US } from 'services/constants';
import { IconFieldBox } from './FieldBox';

export const LaidDataCell: FC<{ row: LaidActivity; sx?: SxProps }> = ({ row, sx }) => {
  const date = dayjs(row.estimatedHatchDate).format(DATE_FORMAT_US);
  return (
    <Stack gap={1} direction="row" flexWrap="wrap" sx={sx}>
      <IconFieldBox icon={mmEgg} color={green[200]}>
        {row.goodEggCount}
      </IconFieldBox>
      <IconFieldBox icon={mmEgg} color={red[200]}>
        {row.badEggCount}
      </IconFieldBox>
      {row.estimatedHatchDate && (
        <IconFieldBox icon={faCalendarDays} color={blue[200]}>
          {date}
        </IconFieldBox>
      )}
    </Stack>
  );
};
