/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Unspecified` - Unspecified
 * * `Firm Price` - Firm Price
 * * `Will Consider` - Will Consider
 * @export
 */
export const PriceFlexibilityDisplayEnum = {
    Unspecified: 'Unspecified',
    FirmPrice: 'Firm Price',
    WillConsider: 'Will Consider'
} as const;
export type PriceFlexibilityDisplayEnum = typeof PriceFlexibilityDisplayEnum[keyof typeof PriceFlexibilityDisplayEnum];


export function PriceFlexibilityDisplayEnumFromJSON(json: any): PriceFlexibilityDisplayEnum {
    return PriceFlexibilityDisplayEnumFromJSONTyped(json, false);
}

export function PriceFlexibilityDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceFlexibilityDisplayEnum {
    return json as PriceFlexibilityDisplayEnum;
}

export function PriceFlexibilityDisplayEnumToJSON(value?: PriceFlexibilityDisplayEnum | null): any {
    return value as any;
}

