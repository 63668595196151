import { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { NestedMenuComponent } from './types';

type NestedItem = Record<string, any> & {
  children: ReactNode;
};

type GenerateNestedMenu = <T extends NestedItem>(params: {
  items: T[];
  /**
   * The React component to use to render individual list items.
   * Default is MenuItem.
   */
  Component?: NestedMenuComponent;
  /**
   * An array of props to use as the list item's key. Ideally, all items in the list should have the same shape and thus, use the same key.
   * Sometimes this isn't the case though (for example, with mixed items) so this prop comes in handy.
   * The default values are ['key', 'children'].
   */
  keyProps?: string[];
  /**
   * An object containing additional props you want to pass to each menu item.
   */
  optionalProps?: Record<string, any>;
}) => ReactNode;

export const generateNestedMenu: GenerateNestedMenu = ({
  items,
  Component = MenuItem,
  keyProps = ['key', 'children'],
  optionalProps = {},
}) => {
  return items.map((props, index) => (
    <Component
      {...props}
      {...optionalProps}
      key={getKey(keyProps, props) || index}
      children={props.children}
    />
  ));
};

const getKey = (potentialKeys: string[], props: Record<string, any>): string | undefined => {
  const prop = potentialKeys.find((prop) => typeof props[prop] === 'string');
  return prop ? props[prop] : undefined;
};
