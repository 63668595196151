/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnimalGridGroupViewEnum,
    AnimalGridGroupViewEnumFromJSON,
    AnimalGridGroupViewEnumFromJSONTyped,
    AnimalGridGroupViewEnumToJSON,
} from './AnimalGridGroupViewEnum';
import {
    BreedingPairingGroupingEnum,
    BreedingPairingGroupingEnumFromJSON,
    BreedingPairingGroupingEnumFromJSONTyped,
    BreedingPairingGroupingEnumToJSON,
} from './BreedingPairingGroupingEnum';
import {
    CardGridViewChoices,
    CardGridViewChoicesFromJSON,
    CardGridViewChoicesFromJSONTyped,
    CardGridViewChoicesToJSON,
} from './CardGridViewChoices';
import {
    ColumnPreferencesRequest,
    ColumnPreferencesRequestFromJSON,
    ColumnPreferencesRequestFromJSONTyped,
    ColumnPreferencesRequestToJSON,
} from './ColumnPreferencesRequest';
import {
    DisplayPreferencesUpdateDeviceType,
    DisplayPreferencesUpdateDeviceTypeFromJSON,
    DisplayPreferencesUpdateDeviceTypeFromJSONTyped,
    DisplayPreferencesUpdateDeviceTypeToJSON,
} from './DisplayPreferencesUpdateDeviceType';
import {
    DisplayPreferencesUpdateTheme,
    DisplayPreferencesUpdateThemeFromJSON,
    DisplayPreferencesUpdateThemeFromJSONTyped,
    DisplayPreferencesUpdateThemeToJSON,
} from './DisplayPreferencesUpdateTheme';
import {
    ListCardViewChoices,
    ListCardViewChoicesFromJSON,
    ListCardViewChoicesFromJSONTyped,
    ListCardViewChoicesToJSON,
} from './ListCardViewChoices';
import {
    ListGridCardViewChoices,
    ListGridCardViewChoicesFromJSON,
    ListGridCardViewChoicesFromJSONTyped,
    ListGridCardViewChoicesToJSON,
} from './ListGridCardViewChoices';
import {
    ListGridViewChoices,
    ListGridViewChoicesFromJSON,
    ListGridViewChoicesFromJSONTyped,
    ListGridViewChoicesToJSON,
} from './ListGridViewChoices';

/**
 * 
 * @export
 * @interface DisplayPreferencesUpdateRequest
 */
export interface DisplayPreferencesUpdateRequest {
    /**
     * 
     * @type {DisplayPreferencesUpdateDeviceType}
     * @memberof DisplayPreferencesUpdateRequest
     */
    deviceType?: DisplayPreferencesUpdateDeviceType | null;
    /**
     * 
     * @type {ListGridCardViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    inventoryView: ListGridCardViewChoices;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesUpdateRequest
     */
    inventoryNumberOfRows?: number | null;
    /**
     * 
     * @type {Array<ColumnPreferencesRequest>}
     * @memberof DisplayPreferencesUpdateRequest
     */
    inventoryColumnPreferences?: Array<ColumnPreferencesRequest> | null;
    /**
     * 
     * @type {ListGridCardViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    husbandryInventoryView: ListGridCardViewChoices;
    /**
     * 
     * @type {Array<ColumnPreferencesRequest>}
     * @memberof DisplayPreferencesUpdateRequest
     */
    husbandryInventoryColumnPreferences?: Array<ColumnPreferencesRequest> | null;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    offspringView: ListCardViewChoices;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesUpdateRequest
     */
    offspringNumberOfRows?: number | null;
    /**
     * 
     * @type {Array<ColumnPreferencesRequest>}
     * @memberof DisplayPreferencesUpdateRequest
     */
    offspringColumnPreferences?: Array<ColumnPreferencesRequest> | null;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    indexView: ListCardViewChoices;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesUpdateRequest
     */
    searchNumberOfRows?: number | null;
    /**
     * 
     * @type {CardGridViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    searchView: CardGridViewChoices;
    /**
     * 
     * @type {ListCardViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    publicOffspringView: ListCardViewChoices;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesUpdateRequest
     */
    publicOffspringNumberOfRows?: number | null;
    /**
     * 
     * @type {Array<ColumnPreferencesRequest>}
     * @memberof DisplayPreferencesUpdateRequest
     */
    publicOffspringColumnPreferences?: Array<ColumnPreferencesRequest> | null;
    /**
     * 
     * @type {BreedingPairingGroupingEnum}
     * @memberof DisplayPreferencesUpdateRequest
     */
    breedingPairingGrouping: BreedingPairingGroupingEnum;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesUpdateRequest
     */
    breedingPairingSeason?: string;
    /**
     * 
     * @type {Array<ColumnPreferencesRequest>}
     * @memberof DisplayPreferencesUpdateRequest
     */
    siblingsColumnPreferences?: Array<ColumnPreferencesRequest> | null;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    followingStoresView: ListGridViewChoices;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    blockedStoresView: ListGridViewChoices;
    /**
     * 
     * @type {ListGridViewChoices}
     * @memberof DisplayPreferencesUpdateRequest
     */
    snakeOrdersView: ListGridViewChoices;
    /**
     * 
     * @type {number}
     * @memberof DisplayPreferencesUpdateRequest
     */
    radiusPreferences?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayPreferencesUpdateRequest
     */
    showSocialActionsOnListingsDetail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesUpdateRequest
     */
    postalCodeForListingSearch?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesUpdateRequest
     */
    weightDisplayUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesUpdateRequest
     */
    lengthDisplayUnit?: string | null;
    /**
     * 
     * @type {DisplayPreferencesUpdateTheme}
     * @memberof DisplayPreferencesUpdateRequest
     */
    theme: DisplayPreferencesUpdateTheme;
    /**
     * 
     * @type {AnimalGridGroupViewEnum}
     * @memberof DisplayPreferencesUpdateRequest
     */
    animalGridGroupView: AnimalGridGroupViewEnum;
}

export function DisplayPreferencesUpdateRequestFromJSON(json: any): DisplayPreferencesUpdateRequest {
    return DisplayPreferencesUpdateRequestFromJSONTyped(json, false);
}

export function DisplayPreferencesUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': !exists(json, 'device_type') ? undefined : DisplayPreferencesUpdateDeviceTypeFromJSON(json['device_type']),
        'inventoryView': ListGridCardViewChoicesFromJSON(json['inventory_view']),
        'inventoryNumberOfRows': !exists(json, 'inventory_number_of_rows') ? undefined : json['inventory_number_of_rows'],
        'inventoryColumnPreferences': !exists(json, 'inventory_column_preferences') ? undefined : (json['inventory_column_preferences'] === null ? null : (json['inventory_column_preferences'] as Array<any>).map(ColumnPreferencesRequestFromJSON)),
        'husbandryInventoryView': ListGridCardViewChoicesFromJSON(json['husbandry_inventory_view']),
        'husbandryInventoryColumnPreferences': !exists(json, 'husbandry_inventory_column_preferences') ? undefined : (json['husbandry_inventory_column_preferences'] === null ? null : (json['husbandry_inventory_column_preferences'] as Array<any>).map(ColumnPreferencesRequestFromJSON)),
        'offspringView': ListCardViewChoicesFromJSON(json['offspring_view']),
        'offspringNumberOfRows': !exists(json, 'offspring_number_of_rows') ? undefined : json['offspring_number_of_rows'],
        'offspringColumnPreferences': !exists(json, 'offspring_column_preferences') ? undefined : (json['offspring_column_preferences'] === null ? null : (json['offspring_column_preferences'] as Array<any>).map(ColumnPreferencesRequestFromJSON)),
        'indexView': ListCardViewChoicesFromJSON(json['index_view']),
        'searchNumberOfRows': !exists(json, 'search_number_of_rows') ? undefined : json['search_number_of_rows'],
        'searchView': CardGridViewChoicesFromJSON(json['search_view']),
        'publicOffspringView': ListCardViewChoicesFromJSON(json['public_offspring_view']),
        'publicOffspringNumberOfRows': !exists(json, 'public_offspring_number_of_rows') ? undefined : json['public_offspring_number_of_rows'],
        'publicOffspringColumnPreferences': !exists(json, 'public_offspring_column_preferences') ? undefined : (json['public_offspring_column_preferences'] === null ? null : (json['public_offspring_column_preferences'] as Array<any>).map(ColumnPreferencesRequestFromJSON)),
        'breedingPairingGrouping': BreedingPairingGroupingEnumFromJSON(json['breeding_pairing_grouping']),
        'breedingPairingSeason': !exists(json, 'breeding_pairing_season') ? undefined : json['breeding_pairing_season'],
        'siblingsColumnPreferences': !exists(json, 'siblings_column_preferences') ? undefined : (json['siblings_column_preferences'] === null ? null : (json['siblings_column_preferences'] as Array<any>).map(ColumnPreferencesRequestFromJSON)),
        'followingStoresView': ListGridViewChoicesFromJSON(json['following_stores_view']),
        'blockedStoresView': ListGridViewChoicesFromJSON(json['blocked_stores_view']),
        'snakeOrdersView': ListGridViewChoicesFromJSON(json['snake_orders_view']),
        'radiusPreferences': !exists(json, 'radius_preferences') ? undefined : json['radius_preferences'],
        'showSocialActionsOnListingsDetail': !exists(json, 'show_social_actions_on_listings_detail') ? undefined : json['show_social_actions_on_listings_detail'],
        'postalCodeForListingSearch': !exists(json, 'postal_code_for_listing_search') ? undefined : json['postal_code_for_listing_search'],
        'weightDisplayUnit': !exists(json, 'weight_display_unit') ? undefined : json['weight_display_unit'],
        'lengthDisplayUnit': !exists(json, 'length_display_unit') ? undefined : json['length_display_unit'],
        'theme': DisplayPreferencesUpdateThemeFromJSON(json['theme']),
        'animalGridGroupView': AnimalGridGroupViewEnumFromJSON(json['animal_grid_group_view']),
    };
}

export function DisplayPreferencesUpdateRequestToJSON(value?: DisplayPreferencesUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_type': DisplayPreferencesUpdateDeviceTypeToJSON(value.deviceType),
        'inventory_view': ListGridCardViewChoicesToJSON(value.inventoryView),
        'inventory_number_of_rows': value.inventoryNumberOfRows,
        'inventory_column_preferences': value.inventoryColumnPreferences === undefined ? undefined : (value.inventoryColumnPreferences === null ? null : (value.inventoryColumnPreferences as Array<any>).map(ColumnPreferencesRequestToJSON)),
        'husbandry_inventory_view': ListGridCardViewChoicesToJSON(value.husbandryInventoryView),
        'husbandry_inventory_column_preferences': value.husbandryInventoryColumnPreferences === undefined ? undefined : (value.husbandryInventoryColumnPreferences === null ? null : (value.husbandryInventoryColumnPreferences as Array<any>).map(ColumnPreferencesRequestToJSON)),
        'offspring_view': ListCardViewChoicesToJSON(value.offspringView),
        'offspring_number_of_rows': value.offspringNumberOfRows,
        'offspring_column_preferences': value.offspringColumnPreferences === undefined ? undefined : (value.offspringColumnPreferences === null ? null : (value.offspringColumnPreferences as Array<any>).map(ColumnPreferencesRequestToJSON)),
        'index_view': ListCardViewChoicesToJSON(value.indexView),
        'search_number_of_rows': value.searchNumberOfRows,
        'search_view': CardGridViewChoicesToJSON(value.searchView),
        'public_offspring_view': ListCardViewChoicesToJSON(value.publicOffspringView),
        'public_offspring_number_of_rows': value.publicOffspringNumberOfRows,
        'public_offspring_column_preferences': value.publicOffspringColumnPreferences === undefined ? undefined : (value.publicOffspringColumnPreferences === null ? null : (value.publicOffspringColumnPreferences as Array<any>).map(ColumnPreferencesRequestToJSON)),
        'breeding_pairing_grouping': BreedingPairingGroupingEnumToJSON(value.breedingPairingGrouping),
        'breeding_pairing_season': value.breedingPairingSeason,
        'siblings_column_preferences': value.siblingsColumnPreferences === undefined ? undefined : (value.siblingsColumnPreferences === null ? null : (value.siblingsColumnPreferences as Array<any>).map(ColumnPreferencesRequestToJSON)),
        'following_stores_view': ListGridViewChoicesToJSON(value.followingStoresView),
        'blocked_stores_view': ListGridViewChoicesToJSON(value.blockedStoresView),
        'snake_orders_view': ListGridViewChoicesToJSON(value.snakeOrdersView),
        'radius_preferences': value.radiusPreferences,
        'show_social_actions_on_listings_detail': value.showSocialActionsOnListingsDetail,
        'postal_code_for_listing_search': value.postalCodeForListingSearch,
        'weight_display_unit': value.weightDisplayUnit,
        'length_display_unit': value.lengthDisplayUnit,
        'theme': DisplayPreferencesUpdateThemeToJSON(value.theme),
        'animal_grid_group_view': AnimalGridGroupViewEnumToJSON(value.animalGridGroupView),
    };
}

