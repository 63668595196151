// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon--UXw51{border-radius:50%;height:.65em;width:.65em}.on--CS_RX{background-color:var(--color-hardcoded-7ab800)}.off--Z_5fk{background-color:var(--color-var-light-gray)}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Icons/DiodeIcon/DiodeIcon.module.scss"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,YAAA,CACA,WAAA,CAGF,WACE,8CAAA,CAGF,YACE,4CAAA","sourcesContent":["@use 'style/colors' as *;\n\n.icon {\n  border-radius: 50%;\n  height: 0.65em;\n  width: 0.65em;\n}\n\n.on {\n  background-color: var(--color-hardcoded-7ab800);\n}\n\n.off {\n  background-color: var(--color-var-light-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gold": `#ffa41c`,
	"male": `#468cc8`,
	"female": `#fd798a`,
	"mixed": `#926cdb`,
	"unknown": `#828282`,
	"gray000": `#333`,
	"gray100": `#4a4b4d`,
	"gray200": `#8b8b8b`,
	"gray300": `#b4b4b4`,
	"gray400": `#d9d9d9`,
	"gray500": `#ced4da`,
	"gray600": `#e9ecef`,
	"gray700": `#f9f9f9`,
	"gray800": `#ffffff`,
	"white": `#ffffff`,
	"whiteSmoke": `#f7f7f7`,
	"black": `#000`,
	"icon": `icon--UXw51`,
	"on": `on--CS_RX`,
	"off": `off--Z_5fk`
};
export default ___CSS_LOADER_EXPORT___;
