export const MATURITY_FILTER = Symbol('MATURITY_FILTER');

export const MATURITY_NOT_READY = Symbol('MATURITY_NOT_READY');
export const MATURITY_BABY = Symbol('MATURITY_BABY');
export const MATURITY_JUVENILE = Symbol('MATURITY_JUVENILE');
export const MATURITY_SUBADULT = Symbol('MATURITY_SUBADULT');
export const MATURITY_ADULT = Symbol('MATURITY_ADULT');
export const MATURITY_UNKNOWN = Symbol('MATURITY_UNKNOWN');

export const maturityOptions = [
  [MATURITY_NOT_READY, 'Not Ready'],
  [MATURITY_BABY, 'Baby'],
  [MATURITY_JUVENILE, 'Juvenile'],
  [MATURITY_SUBADULT, 'Subadult'],
  [MATURITY_ADULT, 'Adult'],
  [MATURITY_UNKNOWN, 'Unknown'],
];
