import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { ANIMAL_ID_ORDERING } from 'api/inventory/ordering';
import { Animal } from 'types';
export const ANIMAL_ID_COLUMN = Symbol('ANIMAL_ID_COLUMN');

const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation();
};

interface AnimalIdColumn {
  id: typeof ANIMAL_ID_COLUMN;
  ordering: symbol;
  label: string;
  hideable: boolean;
  className: string;
  render: (animal: Animal, readonly: boolean) => JSX.Element;
}

const animalIdColumn: AnimalIdColumn = {
  id: ANIMAL_ID_COLUMN,
  ordering: ANIMAL_ID_ORDERING,
  label: 'Animal ID',
  hideable: true,
  className: style.animalIdColumn,

  render: ({ animal_id, view_url }, readonly) => {
    const isLink = Boolean(view_url) && readonly;

    if (isLink) {
      return (
        <a onClickCapture={handleClick} href={view_url} className={style.animalIdLink}>
          {animal_id}
        </a>
      );
    }

    return <span>{animal_id}</span>;
  },
};

export default animalIdColumn;
