import { useCallback, useEffect, useState } from 'react';
import { IS_AUTHENTICATED } from 'constants/index';
import {
  MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME,
  MESSAGE_MARKED_SEEN,
} from 'hooks/messages/messages';
import { WEBSOCKETS_MESSAGE_TYPES } from '../../../../src/js/user-messages-web-sockets';

interface MessagesCount {
  detail: {
    unseenThreads: number;
  };
}

interface UnseenMessagesCount {
  detail: {
    collection: number;
  };
}

/**
 * A hook that returns the number of unread user messages.
 * Pools the backend for new messages at regular intervals.
 */
export const useMessagesCount = (initialUnseenCount: number) => {
  const [unseenCount, setUnseenCount] = useState(initialUnseenCount);

  const onMessageSeenCounts = useCallback((event: unknown) => {
    const evt = event as MessagesCount;
    const { unseenThreads } = evt.detail;
    setUnseenCount(unseenThreads);
  }, []);

  const onUnseenCounts = useCallback((event: unknown) => {
    const evt = event as UnseenMessagesCount;
    const { collection } = evt.detail;
    setUnseenCount(collection);
  }, []);

  const onMessageMarkedSeen = useCallback((event: unknown) => {
    const evt = event as MessagesCount;
    const { unseenThreads } = evt.detail;
    setUnseenCount(unseenThreads);
  }, []);

  useEffect(() => {
    if (IS_AUTHENTICATED) {
      document.addEventListener(MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME, onMessageSeenCounts);
      document.addEventListener(MESSAGE_MARKED_SEEN, onMessageMarkedSeen);
      document.addEventListener(WEBSOCKETS_MESSAGE_TYPES.UNSEEN_COUNTS, onUnseenCounts);
    }
  }, [onMessageSeenCounts]);

  useEffect(() => {
    setUnseenCount(initialUnseenCount);
  }, [initialUnseenCount]);

  return {
    unseenCount,
  };
};
