import { Link } from 'react-router-dom';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { TITLE_ORDERING } from 'api/inventory/ordering';
import { calculateInventoryColor } from 'services/inventory/calculateInventoryColor';
import { Animal } from 'types';
export const TITLE_COLUMN = Symbol('TITLE_COLUMN');

interface TitleColumn extends Animal {
  needs_update: boolean;
}

const TitleColumn = {
  id: TITLE_COLUMN,
  ordering: TITLE_ORDERING,
  label: 'Title & Category',
  hideable: true,
  className: style.titleColumn,

  render: (
    { title_display, category_display, edit_url, active, can_renew, needs_update }: TitleColumn,
    _readonly: boolean,
    link_title: string
  ) => {
    const title = (
      <div lang="en" className={style.itemTitle}>
        {title_display}
      </div>
    );
    const color = calculateInventoryColor(active, can_renew, needs_update);
    let colorClass = style.editingLink;
    if (color === 'GREEN') {
      colorClass = style.green;
    } else if (color === 'GRAY') {
      colorClass = style.gray;
    } else if (color == 'RED') {
      colorClass = style.red;
    }
    return (
      <div>
        {link_title ? (
          <Link className={`${colorClass}`} to={edit_url}>
            {title}
          </Link>
        ) : (
          title
        )}
        <div className={style.itemSubtitle}>{category_display}</div>
      </div>
    );
  },
};

export default TitleColumn;
