import { Components, Theme } from '@mui/material';
import colors from '../constants/colors';

export const MuiTypography: Components<Omit<Theme, 'components'>>['MuiTypography'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: colors.gray100,
      '&.smallThin': {
        fontWeight: theme.typography.light,
        fontSize: theme.typography.xs.fontSize,
      },
    }),
  },
};
