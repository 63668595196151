import { useEffect } from 'react';
import camelCase from 'lodash/camelCase';
import { useAccount } from 'api/generated/useAccount';
import { usePreferences } from 'api/generated/usePreferences';
import { AccountPreferencesPartialUpdateRequest } from 'api/sdk';
import { DisplayPreferencesRetrieve, User } from 'api/sdk/models';
import { logout as logoutCall } from 'api/user/session';
import { useAPIHook } from 'api/utilities/useAPIHook';
import { useAPIList } from 'api/utilities/useAPIList';
import { snakesEventService } from '../../../../src/js/snakes-event-service';
import { useLocalStorage } from '../useRecentlyVisited';
import { useLegacyPreferences } from './useLegacyPreferences';

// eslint-disable-next-line
// @ts-ignore
const defaultPreferences = window.display_preferences;

/**
 * Custom hook for fetching information about the user and his preferences.
 * NOTE: This custom hook has an unusual way of handling preferences. For more information, see here: https://github.com/morphmarket/morphmarket/issues/3172#issuecomment-1537447839
 * In short: you will receive/update different sets of preferences depending on the user's user agent string. This is silent and implicit - it cannot be controlled by the FE or turned off.
 */
export const useUserData = () => {
  const accountAPIHook = useAccount();
  const preferencesAPIHook = usePreferences();
  const aboutList = useAPIList(accountAPIHook.getMe, false);
  // eslint-disable-next-line
  // @ts-ignore
  const preferencesList = aboutList?.data?.preferences;
  const isLoggedIn = !!preferencesList;
  const { callback: logoutCB } = useAPIHook(logoutCall);
  const { value: about, setValue: setAbout } = useLocalStorage<User>({ name: 'user' });
  const { value: preferences, setValue: setPreferences } = useLocalStorage<
    Partial<DisplayPreferencesRetrieve>
  >({ name: 'preferences' });

  const refetch = () => {
    aboutList.refetch();
  };

  const set = (key: string, value: any) => {
    if (!!about?.hasOwnProperty(key)) {
      setAbout({ ...about, ...{ [key]: value } });
    } else if (!!preferences?.hasOwnProperty(key)) {
      setPreferences({ ...preferences, ...{ [key]: value } });

      if (isLoggedIn) {
        preferencesAPIHook.updateDisplayPartial({
          patchedDisplayPreferencesUpdateRequest: { [key]: value },
        });
      }
    } else {
      console.debug(`Could not find key "${key}" in preferences or user data.`);
    }
  };

  const clear = () => {
    setAbout(undefined);
    setPreferences(undefined);
  };

  const setModelPreferences = (data: AccountPreferencesPartialUpdateRequest) => {
    return accountAPIHook.updatePreferencesPartial(data).catch((e) => console.error(e));
  };

  useEffect(() => {
    // Fetch the initial data about the user on page load
    refetch();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    aboutList.data && setAbout(aboutList.data);

    if (preferencesList) {
      setPreferences(preferencesList);
    } else if (!preferences) {
      const preferencesToCamelCase = Object.fromEntries(
        Object.entries(defaultPreferences || {}).map(([key, value]) => [camelCase(key), value])
      );
      setPreferences(preferencesToCamelCase);
    }
  }, [aboutList.data, preferencesList]);

  useLegacyPreferences(set, preferences);

  const logout = () =>
    logoutCB().then(() => {
      // We need to preserve the original functionality of log out -> redirect to login page
      window.location.href = '/accounts/login';
    });

  useEffect(() => {
    snakesEventService.setUpListenerThatListensToReloadMeEvents({
      callback: refetch,
    });
    return () => snakesEventService.removeListenerThatListensToReloadMeEvents();
  }, [refetch]);

  return {
    isLoading: accountAPIHook.isLoading || accountAPIHook.isLoading,
    error: accountAPIHook.error || preferencesAPIHook.error,
    preferences,
    about,
    refetch,
    set,
    clear,
    logout,
    isLoggedIn,
    setModelPreferences,
  };
};
