import { createContext, useContext, useEffect, useState } from 'react';
import { ReviewModal } from 'components/NativeAppReviewModal/NativeAppReviewModal';
import { useIsApp } from 'hooks';
import { reactNativeAPIs } from 'services/mmAppAPIs';
import ModalContext from './ModalContext/ModalContext';

interface NativeAppReviewContextType {
  showNativeAppReviewModal: boolean;
  setShowNativeAppReviewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NativeAppReviewContext = createContext<NativeAppReviewContextType>({
  showNativeAppReviewModal: false,
  setShowNativeAppReviewModal: () => {},
});

export const NativeAppReviewModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    TRIGGER_RATING_PROMPT_EVENT,
    trySaveIsLastAppRatingPromptClosedByUserInLocalStorage,
    tryGetIsLastAppRatingPromptClosedByUserFromLocalStorage,
  } = reactNativeAPIs;

  const { modal } = useContext(ModalContext);
  const isApp = useIsApp();
  const [showNativeAppReviewModal, setShowNativeAppReviewModal] = useState<boolean>(
    tryGetIsLastAppRatingPromptClosedByUserFromLocalStorage() ?? false
  );

  useEffect(function handleAppRatingPrompt() {
    if (!isApp) return;

    const handleShowAppRatingPrompt = () => {
      modal(<ReviewModal />);
      trySaveIsLastAppRatingPromptClosedByUserInLocalStorage(true);
    };

    document.addEventListener(TRIGGER_RATING_PROMPT_EVENT, handleShowAppRatingPrompt);

    const cleanup = () => {
      document.removeEventListener(TRIGGER_RATING_PROMPT_EVENT, handleShowAppRatingPrompt);
    };

    return cleanup;
  }, []);

  return (
    <NativeAppReviewContext.Provider
      value={{ showNativeAppReviewModal, setShowNativeAppReviewModal }}
    >
      {children}
    </NativeAppReviewContext.Provider>
  );
};
