import { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Toast from 'components/Toast/Toast';
import ToastContext from 'context/ToastContext/ToastContext';
import styles from './Toaster.module.scss';

const Toaster = ({ toasts, className }) => {
  const { deleteToast } = useContext(ToastContext);

  return (
    <div className={classNames(className, styles.container)}>
      {toasts.map(({ id, message, type, ...props }) => (
        <Toast key={id} id={id} message={message} type={type} onDelete={deleteToast} {...props} />
      ))}
    </div>
  );
};

Toaster.propTypes = {
  toasts: PropTypes.array,
  className: PropTypes.string,
};

export default Toaster;
