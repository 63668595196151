// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin--XwDuU{from{transform:rotate(0)}to{transform:rotate(360deg)}}.spinner--ddGB7{animation:spin--XwDuU 1.3s linear infinite}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,uBACE,KACE,mBAAA,CAGF,GACE,wBAAA,CAAA,CAIJ,gBACE,0CAAA","sourcesContent":["@keyframes spin {\n  from {\n    transform: rotate(0);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner {\n  animation: spin 1.3s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `spinner--ddGB7`,
	"spin": `spin--XwDuU`
};
export default ___CSS_LOADER_EXPORT___;
