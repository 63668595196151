import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { PRICE_ORDERING } from 'api/inventory/ordering';
export const PRICE_COLUMN = Symbol('PRICE_COLUMN');

interface PriceColumnProps {
  price_display: string;
  sale_price_display?: string;
}

const priceColumn = {
  id: PRICE_COLUMN,
  ordering: PRICE_ORDERING,
  label: 'Price',
  hideable: true,
  className: style.priceColumn,

  render: ({ price_display, sale_price_display }: PriceColumnProps) => {
    if (sale_price_display) return <span className={style.oldPrice}>{sale_price_display}</span>;
    return price_display;
  },
};

export default priceColumn;
