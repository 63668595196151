/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';
import {
    VisibilityEnum,
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * @type BreedingPairingDetailsVisibility
 * 
 * @export
 */
export type BreedingPairingDetailsVisibility = BlankEnum | NullEnum | VisibilityEnum;

export function BreedingPairingDetailsVisibilityFromJSON(json: any): BreedingPairingDetailsVisibility {
    return BreedingPairingDetailsVisibilityFromJSONTyped(json, false);
}

export function BreedingPairingDetailsVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingDetailsVisibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return json;
}

export function BreedingPairingDetailsVisibilityToJSON(value?: BreedingPairingDetailsVisibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...BlankEnumToJSON(value), ...NullEnumToJSON(value), ...VisibilityEnumToJSON(value) };
}

