import { createTheme } from '@mui/material';
import { base } from './base';

const light = createTheme({
  ...base,
  palette: {
    error: {
      main: '#eb5757',
    },
  },
});

export { light };
