/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DisplayPreferencesRetrieveDeviceTypeEnum = {
    Mobile: 'mobile',
    Tablet: 'tablet',
    Desktop: 'desktop',
    MobileAppIos: 'mobile-app-ios',
    TabletAppIos: 'tablet-app-ios',
    MobileAppAndroid: 'mobile-app-android',
    TabletAppAndroid: 'tablet-app-android'
} as const;
export type DisplayPreferencesRetrieveDeviceTypeEnum = typeof DisplayPreferencesRetrieveDeviceTypeEnum[keyof typeof DisplayPreferencesRetrieveDeviceTypeEnum];


export function DisplayPreferencesRetrieveDeviceTypeEnumFromJSON(json: any): DisplayPreferencesRetrieveDeviceTypeEnum {
    return DisplayPreferencesRetrieveDeviceTypeEnumFromJSONTyped(json, false);
}

export function DisplayPreferencesRetrieveDeviceTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesRetrieveDeviceTypeEnum {
    return json as DisplayPreferencesRetrieveDeviceTypeEnum;
}

export function DisplayPreferencesRetrieveDeviceTypeEnumToJSON(value?: DisplayPreferencesRetrieveDeviceTypeEnum | null): any {
    return value as any;
}

