/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AppReviewResponse,
  AppReviewResponseFromJSON,
  AppReviewResponseToJSON,
  NativeAppReviewRequest,
  NativeAppReviewRequestFromJSON,
  NativeAppReviewRequestToJSON,
} from "../models";

export interface MmNativeAppReviewsAppReviewCreateRequest {
  /** @deprecated Use request instead. **/
  nativeAppReviewRequest?: NativeAppReviewRequest;
  request: NativeAppReviewRequest;
}

/**
 *
 */
export class MmNativeAppApi extends runtime.BaseAPI {
  /**
   * A ViewSet for storing NativeAppReview instances.
   */
  async mmNativeAppReviewsAppReviewCreateRaw(
    requestParameters: MmNativeAppReviewsAppReviewCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AppReviewResponse>> {
    if ((requestParameters.request || requestParameters.nativeAppReviewRequest) === null || (requestParameters.request || requestParameters.nativeAppReviewRequest) === undefined) {
      throw new runtime.RequiredError(
        "nativeAppReviewRequest",
        "Required parameter (requestParameters.request || requestParameters.nativeAppReviewRequest) was null or undefined when calling mmNativeAppReviewsAppReviewCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/mm_native_app/reviews/app_review/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: NativeAppReviewRequestToJSON(requestParameters.request || requestParameters.nativeAppReviewRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AppReviewResponseFromJSON(jsonValue));
  }

  /**
   * A ViewSet for storing NativeAppReview instances.
   */
  async mmNativeAppReviewsAppReviewCreate(
    requestParameters: MmNativeAppReviewsAppReviewCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AppReviewResponse> {
    const response = await this.mmNativeAppReviewsAppReviewCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
