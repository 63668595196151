import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Link, ThemeProvider } from '@mui/material';
import { NavigationBackButton } from 'components/NavigationBackButton';
import { theme } from 'context/ThemeContext/Coldblooded/Theme';
import { useAuth } from 'hooks';

import { TopLeftLogo } from '../components';
import { NavigationProps } from '../types';

import { MessengerButton } from './components/MessengerButton';
import { NavigationMenu } from './components/NavigationMenu/NavigationMenu';
import { ProfileMenu } from './components/ProfileMenu/ProfileMenu';

import { Services } from './Services';
import UtilityNavMobile from './UtilityNavMobile';
import { isReactRoute } from 'routes/utils/routeUtils';
import styles from './MobileNavigation.module.scss';

export const MobileNavigation: React.FC<NavigationProps> = () => {
  const { isLoggedIn } = useAuth();
  const isReactPath = isReactRoute(window.location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <Services />
      <nav className={styles.container}>
        <div className={styles.inner}>
          <div>
            <NavigationBackButton />
            <TopLeftLogo isDesktop={false} />
          </div>
          <div className={styles.menu}>
            {!isLoggedIn && (
              <Button className={styles.signupLink} LinkComponent={Link} href="/accounts/signup">
                Sign Up
              </Button>
            )}
            {!isLoggedIn && (
              <IconButton
                LinkComponent="a"
                href="/accounts/login"
                sx={{ fontSize: 24, padding: 0 }}
              >
                <FontAwesomeIcon icon={faCircleUser} />
              </IconButton>
            )}
            {!!isLoggedIn && <MessengerButton />}
            {!!isLoggedIn && <ProfileMenu />}
            <NavigationMenu />
          </div>
        </div>
        {!isReactPath && <UtilityNavMobile />}
      </nav>
    </ThemeProvider>
  );
};
