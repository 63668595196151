/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_ready` - Not Ready
 * * `baby` - Baby
 * * `juvenile` - Juvenile
 * * `subadult` - Subadult
 * * `adult` - Adult
 * @export
 */
export const MaturityEnum = {
    NotReady: 'not_ready',
    Baby: 'baby',
    Juvenile: 'juvenile',
    Subadult: 'subadult',
    Adult: 'adult'
} as const;
export type MaturityEnum = typeof MaturityEnum[keyof typeof MaturityEnum];


export function MaturityEnumFromJSON(json: any): MaturityEnum {
    return MaturityEnumFromJSONTyped(json, false);
}

export function MaturityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaturityEnum {
    return json as MaturityEnum;
}

export function MaturityEnumToJSON(value?: MaturityEnum | null): any {
    return value as any;
}

