import { HTMLAttributes, useEffect, useRef } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isRtoR } from 'services/isRtoR';
import styles from './SettingsMenuItems.module.scss';

export type PrefixedAnchorProps = HTMLAttributes<HTMLAnchorElement> & {
  autoFocus?: boolean;
  name?: string;
  icon: IconDefinition;
  href: string;
  active?: boolean;
  sidebar?: boolean;
  isOpen?: boolean;
  tooltipText?: string;
};

export const PrefixedAnchor: React.FC<PrefixedAnchorProps> = ({
  children,
  name,
  icon,
  autoFocus,
  active,
  sidebar,
  isOpen,
  tooltipText,
  ...props
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  const isReactLink = isRtoR(props.href || '');

  const clearFocus = () => {
    const { body } = document;
    if (body) body.click();
  };

  if (isReactLink && props.href) {
    return (
      <>
        <ReactTooltip />
        <Link
          ref={ref}
          tabIndex={0}
          role="menuitem"
          aria-current={active ? 'page' : undefined}
          className={styles.root}
          to={props.href}
          data-tip={sidebar && !isOpen && tooltipText ? tooltipText : null}
          onClick={clearFocus}
          data-ddd={'XD'}
        >
          <span className={styles.prefix}>
            <Icon icon={icon} />
          </span>
          <span className={styles.label}>{children || name}</span>
        </Link>
      </>
    );
  }

  return (
    <>
      <ReactTooltip />
      <a
        ref={ref}
        tabIndex={0}
        role="menuitem"
        aria-current={active ? 'page' : undefined}
        className={styles.root}
        {...props}
        data-tip={sidebar && !isOpen && tooltipText ? tooltipText : null}
      >
        <span className={styles.prefix}>
          <Icon icon={icon} />
        </span>
        <span className={styles.label}>{children || name}</span>
      </a>
    </>
  );
};
