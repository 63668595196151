export type NavbarCategory = {
  name: string;
  href: string;
  id?: number;
  parent_id?: number | null;
  has_wizard?: boolean;
  links?: NavbarCategory[];
};

export const alphabetizeItems = (items: NavbarCategory[]): NavbarCategory[] => {
  // The "All X" option should still be at the top, and the "Other X" option should be last.
  let allOption: Partial<NavbarCategory> = {};
  let otherOption: Partial<NavbarCategory> = {};
  // In the instance of Tier 3 categories, if there is a "More X" option, that should be last.
  let moreOption: Partial<NavbarCategory> = {};

  const currentMenu = [...items];
  // allOption needs to come second if exists
  if (currentMenu[0].name?.slice(0, 4) === 'All ') {
    [allOption] = currentMenu;
    currentMenu.shift();
  }
  // "Other X" option needs to come last if exists
  const otherOptionIndex = currentMenu.findIndex((item) => item.name?.slice(0, 6) === 'Other ');
  if (otherOptionIndex !== -1) {
    [otherOption] = currentMenu.splice(otherOptionIndex, 1);
  }
  // "More X" option needs to come last if exists
  const moreXIndex = currentMenu.findIndex((item) => item.name?.slice(0, 5) === 'More ');
  if (moreXIndex !== -1) {
    [moreOption] = currentMenu.splice(moreXIndex, 1);
  }
  // this is the middle of the copy of current branch needs to be sorted
  currentMenu.sort((a, b) => {
    const labelA = a.name?.toLowerCase() ?? '';
    const labelB = b.name?.toLowerCase() ?? '';

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
  const result = [
    ...(allOption.hasOwnProperty('name') ? [allOption] : []),
    ...currentMenu,
    ...(otherOption.hasOwnProperty('name') ? [otherOption] : []),
    ...(moreOption.hasOwnProperty('name') ? [moreOption] : []),
  ];
  return result;
};
