import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { EGGS_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const EGGS_COLUMN = Symbol('EGGS_COLUMN');

const eggsColumn = {
  id: EGGS_COLUMN,
  ordering: EGGS_ORDERING,
  label: 'Eggs',
  hideable: true,
  className: style.countColumn,
  defaultDirection: DESCENDING,
  attributes: ({ is_egg_laying, view_url }) => ({
    'data-tip':
      is_egg_laying && view_url
        ? 'Please click on Viable/Non-Viable columns to update counts'
        : null,
  }),

  render: ({ eggs_count_display, view_url }) => eggs_count_display,
};

export default eggsColumn;
