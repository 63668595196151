import React from 'react';
import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
  title: string;
  text: string;
  detail?: string;
  buttonText: string;
  img: string;
  imgAlt?: string;
  buttonAction: () => void;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  buttonText,
  text,
  detail,
  title,
  img,
  imgAlt,
  buttonAction,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>

        <p className={styles.text}>{text}</p>

        {detail && <p className={styles.text}>{detail}</p>}

        <img className={styles.img} src={img} alt={imgAlt} />

        <div className="d-flex justify-content-center">
          <button className={styles.button} onClick={buttonAction}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
