import IsActiveIcon from 'components/Icons/IsActiveIcon/IsActiveIcon';
import StateBadge from 'components/Icons/StateBadge/StateBadge';
import VisibilityIcon from 'components/Icons/VisibilityIcon/VisibilityIcon';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { STATUS_ORDERING } from 'api/inventory/ordering';

export const STATUS_COLUMN = Symbol('STATUS_COLUMN');

const statusColumn = {
  id: STATUS_COLUMN,
  ordering: STATUS_ORDERING,
  label: 'Status',
  hideable: true,
  className: style.statusColumn,

  render: ({ state, visibility, active }) => (
    <div className={style.statusContainer}>
      <StateBadge state={state} className={style.stateBadge} />
      <VisibilityIcon visibility={visibility} />
      <IsActiveIcon isActive={active} />
    </div>
  ),
};

export default statusColumn;
