import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MessengerTabs from 'components/experimental/Navigation/mobile/components/MessenerTabs/MessegerTabs';
import { QuickSearchMessenger } from 'components/experimental/Navigation/mobile/components/QuickSearch/QuickSearchMessenger';
import {
  isAnimalsNavPath,
  isInventoryAnimalNavPath,
  isListingsNavPath,
  isMessengerNavPath,
  hasNavMenu,
  isPageWhereWeHideQuickSearch,
} from 'components/experimental/Navigation/mobile/Services';
import { UnseenThreadCounts } from 'api/sdk';
import { isHomePath } from 'services/urls';
import { WEBSOCKETS_MESSAGE_TYPES } from '../../../../../../src/js/user-messages-web-sockets';
import { InventoryAnimalMenu } from '../components/InventoryAnimalMenu/InventoryAnimalMenu';
import { AnimalsMenu } from './components/AnimalsMenu/AnimalsMenu';
import ListingsMenu from './components/ListingsMenu/ListingsMenu';
import { QuickSearch } from './components/QuickSearch/QuickSearch';

import { QuickSearchInventory } from './components/QuickSearch/QuickSearchInventory';
import { isReactRoute } from 'routes/utils/routeUtils';
import styles from './MobileNavigation.module.scss';

const UtilityNavMobile = () => {
  const { pathname } = useLocation();
  const hideQuickSearch = isPageWhereWeHideQuickSearch();
  const isReactPath = isReactRoute(window.location.pathname);

  const isListingPage = isListingsNavPath();
  const isHomePage = isHomePath(window.location.pathname);
  const isInventoryAnimalPage = isInventoryAnimalNavPath();
  const isAnimalsPage = isAnimalsNavPath();
  const isMessengerPage = isMessengerNavPath();

  const mobileNavHeaderHeightPX = 50;

  const [unseenCounts, setUnseenCounts] = useState<UnseenThreadCounts>();

  useEffect(function handleUnseenCountsEvent() {
    const handleEvents = (ev: Event) => {
      const { type, detail } = ev as unknown as CustomEvent;
      if (type === WEBSOCKETS_MESSAGE_TYPES.UNSEEN_COUNTS) {
        setUnseenCounts(detail);
      }
    };
    document.addEventListener(WEBSOCKETS_MESSAGE_TYPES.UNSEEN_COUNTS, handleEvents);
  }, []);

  return (
    <>
      {(!hideQuickSearch || hasNavMenu()) && (
        <div
          className={styles.additionalNavbar}
          style={{ top: isReactPath ? 0 : mobileNavHeaderHeightPX }}
          id="mobileUtilityContainer"
        >
          {!hideQuickSearch && !isMessengerPage && <QuickSearch key={pathname} />}
          {(isListingPage || isHomePage) && (
            <div className={styles.fixed}>
              <ListingsMenu />
            </div>
          )}
          {isInventoryAnimalPage && <InventoryAnimalMenu />}
          {isAnimalsPage && (
            <>
              <QuickSearchInventory />
              <div className={styles.fixed}>
                <AnimalsMenu />
              </div>
            </>
          )}
          {isMessengerPage && (
            <>
              <QuickSearchMessenger />
              <div className={styles.fixed}>
                <MessengerTabs unseenCounts={unseenCounts} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UtilityNavMobile;
