/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BreedingPairingList,
    BreedingPairingListFromJSON,
    BreedingPairingListFromJSONTyped,
    BreedingPairingListToJSON,
} from './BreedingPairingList';

/**
 * 
 * @export
 * @interface PaginatedBreedingPairingListList
 */
export interface PaginatedBreedingPairingListList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedBreedingPairingListList
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBreedingPairingListList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBreedingPairingListList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BreedingPairingList>}
     * @memberof PaginatedBreedingPairingListList
     */
    results?: Array<BreedingPairingList>;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBreedingPairingListList
     */
    publicStoreBreedingUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedBreedingPairingListList
     */
    totalOffspringGroupsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBreedingPairingListList
     */
    storeName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedBreedingPairingListList
     */
    totalFemalesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedBreedingPairingListList
     */
    totalMalesCount?: number;
}

export function PaginatedBreedingPairingListListFromJSON(json: any): PaginatedBreedingPairingListList {
    return PaginatedBreedingPairingListListFromJSONTyped(json, false);
}

export function PaginatedBreedingPairingListListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedBreedingPairingListList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(BreedingPairingListFromJSON)),
        'publicStoreBreedingUrl': !exists(json, 'public_store_breeding_url') ? undefined : json['public_store_breeding_url'],
        'totalOffspringGroupsCount': !exists(json, 'total_offspring_groups_count') ? undefined : json['total_offspring_groups_count'],
        'storeName': !exists(json, 'store_name') ? undefined : json['store_name'],
        'totalFemalesCount': !exists(json, 'total_females_count') ? undefined : json['total_females_count'],
        'totalMalesCount': !exists(json, 'total_males_count') ? undefined : json['total_males_count'],
    };
}

export function PaginatedBreedingPairingListListToJSON(value?: PaginatedBreedingPairingListList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(BreedingPairingListToJSON)),
        'public_store_breeding_url': value.publicStoreBreedingUrl,
        'total_offspring_groups_count': value.totalOffspringGroupsCount,
        'store_name': value.storeName,
        'total_females_count': value.totalFemalesCount,
        'total_males_count': value.totalMalesCount,
    };
}

