import { EnumsBucket, traits } from 'constants/enums';
import { TYPES } from '../constants';
import { MINIMAL_STORE, STORE_MATCH, TRAIT_MATCH } from '../types';

type Traits = typeof traits;
type Categories = typeof EnumsBucket.category_menu_choices_with_root;
type Category = Categories[0];
type CategoryBranch = Category & { children: CategoryBranch[] };

type FlexSearchEntry = {
  id: string | number;
  name: string;
  type: string;
};

// Due to the way FlexSearch works, all datasets need to have the same format.
// To separate them later, we add a type property to each list item.
// That is all the format* functions do; format the datasets to a single format and append a type

export const formatTraits = (traits: Traits, categories: Categories): FlexSearchEntry[] => {
  const dataset: TRAIT_MATCH[] = [];
  const indexedCategories: Record<string, { label: string; path: string; value: string }> =
    categories.reduce(
      (acc, { id, label, path, value }) => ({ ...acc, [id]: { label, path, value } }),
      {}
    );

  // TODO: Temporary solution, remove after we settle on a better approach (see issue #4242)
  const categoryIDs = Object.keys(indexedCategories).map((id) => parseInt(id, 10));
  const ignoredTraits: string[] = [];

  Object.entries(traits).forEach(([, { traits_with_aliases_data, trait_category }]) => {
    Object.entries(traits_with_aliases_data || {}).forEach(([id, name]) => {
      // Related to #4242
      if (!categoryIDs.includes(trait_category)) {
        ignoredTraits.push(
          `omitted: ${name as string} (${id}) in ${
            indexedCategories?.[`${trait_category}`]?.label || 'unknown category'
          } (${trait_category})`
        );
        return;
      }

      // Unrelated to #4242
      dataset.push({
        id: `${trait_category}-${id}`,
        name: name as string,
        cat: trait_category,
        categoryPath: indexedCategories[trait_category].path,
        category: indexedCategories[trait_category].label,
        type: TYPES.TRAIT,
      });
    });
  });

  // Related to #4242
  if (!!ignoredTraits.length) {
    // eslint-disable-next-line no-console
    if (!!console.groupCollapsed) {
      // eslint-disable-next-line no-console
      console.groupCollapsed('Ignored traits (see issue #4242)');
    }
    // eslint-disable-next-line no-console
    ignoredTraits.forEach((message) => console.debug(message));
    // eslint-disable-next-line no-console
    console.debug(`Omitted ${ignoredTraits.length} traits`);
    // eslint-disable-next-line no-console
    if (!!console.groupCollapsed && console.groupEnd) {
      // eslint-disable-next-line no-console
      console.groupEnd();
    }
  }

  return dataset;
};

export const formatStores = (stores: MINIMAL_STORE[]): STORE_MATCH[] => {
  return stores.map(([stub, name, , owner, , , , , visibility]) => {
    return {
      id: stub,
      name,
      stub,
      owner: owner,
      type: TYPES.STORE,
      unauthOwnerVisibility: visibility,
    };
  });
};

export const formatCategories = (categories: Categories): FlexSearchEntry[] => {
  return categories.map(
    ({ label, path, id, value, fine_grained_subcategory, parent_id, scientific_name }) => {
      const parent = fine_grained_subcategory
        ? categories.find(({ id }) => id === parent_id)
        : undefined;

      return {
        id,
        name: label,
        path,
        value,
        cat: id,
        parent_label: parent?.label,
        parent_value: parent?.value,
        scientific_name,
        type: TYPES.CATEGORY,
      };
    }
  );
};

/**
 * Filter results by type.
 */
export const filterByType = (data: any, match: string) => {
  return (data as any[]).filter(({ type }) => type === match);
};

/**
 * Filters results by category - only showing the current category and any descendants.
 */
export const filterByCategory = (data: any, valid: number[]) => {
  if (!valid || !valid.length) return data;
  return (data as any[]).filter(({ cat }) => {
    if (valid === undefined) {
      console.error(
        `To make sure it is handled but meanwhile force sentry to report it,
         and remind us that there is category issue that we need to fix.
         Handling this error while searching at top-level category: ${cat}.
        `
      );
    } else {
      return valid.includes(parseInt(cat, 10));
    }
  });
};

/**
 * Calculates the total of an array of arrays.
 */
export const getTotal = (results: any[][]) => {
  return results.reduce((acc, array) => acc + array.length, 0);
};

export const buildCategoryTree = (categories: Categories): CategoryBranch => {
  const isParent = (parent: Category) => (current: Category) => {
    return current.parent_id === parent.id;
  };
  const attachBranch = (current: Category): CategoryBranch => {
    return {
      ...current,
      children: categories.filter(isParent(current)).map(attachBranch),
    };
  };

  const root = categories.find(({ parent_id }) => parent_id === null);

  return {
    ...root,
    children: categories.filter(isParent(root)).map(attachBranch),
  };
};

export const flattenTree = (tree: CategoryBranch): CategoryBranch[] => {
  const branches: CategoryBranch[] = [];
  const sliceBranch = (branch: CategoryBranch) => {
    branches.push(branch);
    branch.children.forEach(sliceBranch);
  };

  sliceBranch(tree);

  return branches;
};

export const branchesToId = (branches: CategoryBranch[]): Record<number, number[]> => {
  const ids: Record<number, number[]> = {};
  const reduceBranches = (children: CategoryBranch[]): number[] => {
    return children
      .map(({ id, children }) => {
        return [id, ...reduceBranches(children)];
      })
      .reduce((acc, child) => [...acc, ...child], []);
  };

  branches.forEach(({ id, children }) => {
    ids[id] = [id, ...reduceBranches(children)];
  });

  return ids;
};

export const sortStores = (query: string) => {
  return (a: STORE_MATCH, b: STORE_MATCH) => {
    const aNameStartsWithKeyword = a.name.toLowerCase().startsWith(query);
    const bNameStartsWithKeyword = b.name.toLowerCase().startsWith(query);
    const aNameContainsKeyword = a.name.toLowerCase().includes(query);
    const bNameContainsKeyword = b.name.toLowerCase().includes(query);
    const aOwnerContainsKeyword = a.owner.toLowerCase().includes(query);
    const bOwnerContainsKeyword = b.owner.toLowerCase().includes(query);

    if (aNameStartsWithKeyword && !bNameStartsWithKeyword) return -1;
    if (!aNameStartsWithKeyword && bNameStartsWithKeyword) return 1;

    if (aNameStartsWithKeyword && bNameStartsWithKeyword) {
      return a.name.localeCompare(b.name);
    }

    if (aNameContainsKeyword && !bNameContainsKeyword) return -1;
    if (!aNameContainsKeyword && bNameContainsKeyword) return 1;

    if (aOwnerContainsKeyword && !bOwnerContainsKeyword) return -1;
    if (!aOwnerContainsKeyword && bOwnerContainsKeyword) return 1;

    return 0;
  };
};
