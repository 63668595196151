import { useCallback, useEffect, useState } from 'react';
import { generateSingletonHook } from 'context/SingletonContext';
import { MESSAGE_TYPES, sendMessageToReactNative } from '../../../src/js/app/rn-messages';
import { IS_APP } from '../constants';
import { useGlobalContext } from './GlobalContext';

// This hook is used to enable or disable certain gestures (actions) in React Native based on certain conditions.
const useDecoratedHook = () => {
  // isBackDisabledInReactJs is a boolean that determines whether the back button should be disabled in React JS.
  // isBackDisabledInRn is a boolean that determines whether the back button should be disabled in React Native.
  // These two attributes are used in different contexts (RN vs ReactJS) but with this hook, they are kept in sync.
  const [isBackDisabledInReactJs, setIsBackDisabledInReactJs] = useState<boolean>(false);
  const { pageHasUnsavedChanges } = useGlobalContext();

  // Inform React Native that going back is disabled
  const handleSendMessageToReactNative = useCallback((): void => {
    if (!IS_APP) return;

    sendMessageToReactNative(MESSAGE_TYPES.IS_BACK_DISABLED_IN_RN, {
      isDisabled: isBackDisabledInReactJs,
    });
  }, [isBackDisabledInReactJs]);

  // Here should be the logic to determine if back should be disabled in App.
  // Currently, the only criteria for disabling back button is `pageHasUnsavedChanges`.
  useEffect(() => {
    const isDisabled = IS_APP && pageHasUnsavedChanges;
    setIsBackDisabledInReactJs(isDisabled);
  }, [pageHasUnsavedChanges]);

  // This effect manages sending of message to RN whenever isBackDisabledInReactJs changes. See messageHandler.tsx.
  useEffect(() => {
    handleSendMessageToReactNative();
  }, [isBackDisabledInReactJs, handleSendMessageToReactNative]);

  return { isBackDisabledInReactJs };
};

const [NativeAppGesturesContext, useNativeAppGesturesContext] =
  generateSingletonHook(useDecoratedHook);
export { NativeAppGesturesContext, useNativeAppGesturesContext };
