import { HTMLAttributes } from 'react';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './CaretButton.module.scss';

export type CaretButtonProps = HTMLAttributes<HTMLButtonElement> & {
  initialDirection?: 'left' | 'right' | 'top' | 'bottom';
  expandedDirection?: 'left' | 'right' | 'top' | 'bottom';
};

export const CaretButton: React.FC<CaretButtonProps> = ({
  children,
  initialDirection = 'bottom',
  expandedDirection = 'bottom',
  ...props
}) => {
  return (
    <button {...props} className={classNames(styles.button)}>
      <span>
        <span>{children}</span>
        <Icon
          icon={faCaretRight}
          className={props['aria-expanded'] ? styles[expandedDirection] : styles[initialDirection]}
        />
      </span>
    </button>
  );
};
