/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `unknown` - Unknown
 * * `rat` - Rat
 * * `mouse` - Mouse
 * * `soft-furred-rat` - Soft-furred Rat
 * * `mealworm` - Mealworm
 * * `cricket` - Cricket
 * * `roach` - Roach
 * * `meal-replacement` - Meal Replacement
 * * `dry-feed` - Dry Feed
 * * `vegetables` - Vegetables
 * * `quail` - Quail
 * * `rabbit` - Rabbit
 * * `chicken` - Chicken
 * * `guinea-pig` - Guinea Pig
 * * `pig` - Pig
 * * `lamb` - Lamb
 * * `goat` - Goat
 * * `other` - Other
 * @export
 */
export const PreyFoodEnum = {
    Unknown: 'unknown',
    Rat: 'rat',
    Mouse: 'mouse',
    SoftFurredRat: 'soft-furred-rat',
    Mealworm: 'mealworm',
    Cricket: 'cricket',
    Roach: 'roach',
    MealReplacement: 'meal-replacement',
    DryFeed: 'dry-feed',
    Vegetables: 'vegetables',
    Quail: 'quail',
    Rabbit: 'rabbit',
    Chicken: 'chicken',
    GuineaPig: 'guinea-pig',
    Pig: 'pig',
    Lamb: 'lamb',
    Goat: 'goat',
    Other: 'other'
} as const;
export type PreyFoodEnum = typeof PreyFoodEnum[keyof typeof PreyFoodEnum];


export function PreyFoodEnumFromJSON(json: any): PreyFoodEnum {
    return PreyFoodEnumFromJSONTyped(json, false);
}

export function PreyFoodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreyFoodEnum {
    return json as PreyFoodEnum;
}

export function PreyFoodEnumToJSON(value?: PreyFoodEnum | null): any {
    return value as any;
}

