import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const mmAnimal: IconDefinition = {
  prefix: 'fab',
  iconName: 'accessible-icon', // We need to fake the icon name here
  icon: [
    18,
    20,
    [],
    '',
    `M9.41495 6.41038C9.28759 4.45755 10.9857 3.0566 10.9857 3.0566C10.9857 3.0566 11.0142 2.03177 11.3678 1.5283C11.667 1.10227 11.95 0.925266 12.4291 0.721698C13.192 0.397551 14.5517 0.721698 14.5517 0.721698L15.231 0L15.9102 0.721698L15.231 1.5283C15.231 1.5283 15.3633 2.47462 15.231 3.0566C15.0962 3.64949 15.0016 4.04856 14.5517 4.45755C14.0159 4.94467 12.7687 4.96698 12.7687 4.96698C12.7687 4.96698 12.1954 5.37562 12.0895 5.77358C12.0255 6.01391 12.0624 6.16317 12.0895 6.41038C12.2241 7.64069 13.4075 7.83675 14.0848 8.87264C14.8064 9.97642 15.018 11.5292 14.0848 12.6934C13.1411 13.8706 11.5801 14.1368 10.3065 13.5C9.03284 12.8632 4.10828 6.79245 3.38661 6.66509C2.66495 6.53774 2.49514 6.79245 2.49514 7.42925C2.49514 8.06604 7.67432 10.9953 7.6319 13.5C7.58948 16.0047 6.51217 17.0236 4.99985 17.0236C4.02341 17.0236 3.51714 16.0254 2.49514 16.1745C1.3627 16.3397 0.20268 18 0.20268 18C0.20268 18 0.160197 14.2642 1.77341 13.6698C3.38661 13.0755 3.89608 14.1368 4.36306 14.3066C4.83004 14.4764 5.21209 14.0094 4.99985 13.5C4.78762 12.9906 0.584726 9.50943 0.20268 8.32076C-0.179365 7.13208 -0.057784 5.91709 0.881926 4.96698C1.92891 3.90841 3.38668 3.73585 4.66023 4.45755C5.93378 5.17925 10.6461 10.8221 11.3678 10.9953C12.0895 11.1685 12.4291 10.6557 12.0895 10.2311C11.7499 9.8066 9.54231 8.36321 9.41495 6.41038Z`,
  ],
};
