import React from 'react';
import { faVenus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './SexFemale.module.scss';

interface SexFemaleProps {
  className?: string;
}
const SexFemale: React.FC<SexFemaleProps> = ({ className, ...props }) => (
  <FontAwesomeIcon icon={faVenus} className={classNames(style.icon, className)} {...props} />
);

export default SexFemale;
