import { RouteObject } from 'react-router-dom';

const HusbandryContainer = () => import('../../pages/Husbandry/HusbandryContainer');
const ActivityList = () => import('../../pages/Husbandry/pages/ActivityList');
const AnimalActivityList = () => import('../../pages/Husbandry/pages/AnimalActivityList');
const ActivitiesOverview = () => import('../../pages/Husbandry/pages/ActivitiesOverview');

const ActivityReminderList = () => import('../../pages/Husbandry/pages/ActivityReminderList');
const AddEditActivityReminder = () => import('../../pages/Husbandry/pages/AddEditActivityReminder');

const AnimalGroupDetail = () => import('../../pages/Husbandry/pages/AnimalGroupDetail');
const AnimalGroupList = () => import('../../pages/Husbandry/pages/AnimalGroupList');

export const husbandryRoutes: RouteObject[] = [
  {
    path: 'husbandry',
    lazy: HusbandryContainer,
    children: [
      {
        path: 'activities',
        children: [
          {
            path: '',
            lazy: ActivityList,
            handle: { title: 'Husbandry Activities' },
          },
          {
            path: ':animalId',
            lazy: AnimalActivityList,
            handle: { title: 'Animal Activities' },
          },
          {
            path: 'overview/:animalId',
            lazy: ActivitiesOverview,
            handle: {
              title: 'Husbandry',
              maxWidth: 1024,
            },
          },
        ],
      },
      {
        path: 'reminders',
        children: [
          {
            path: '',
            lazy: ActivityReminderList,
            handle: { title: 'Activity Reminders' },
          },
          {
            path: 'add',
            async lazy() {
              const { Component } = await AddEditActivityReminder();
              return { element: <Component isAdd /> };
            },
            handle: {
              title: 'Add Activity Reminder',
              maxWidth: 1024,
            },
          },
          {
            path: 'edit/:reminderId',
            lazy: AddEditActivityReminder,
            handle: {
              title: 'Edit Activity Reminder',
              maxWidth: 1024,
            },
          },
        ],
      },
      {
        path: 'groups',
        children: [
          {
            path: '',
            lazy: AnimalGroupList,
            handle: { title: 'Groups / Racks' },
          },
          {
            path: ':groupId',
            lazy: AnimalGroupDetail,
            handle: { title: 'Animal Group' },
          },
        ],
      },
    ],
  },
];
