import { Components, Theme } from '@mui/material';
import { colors } from '../../Theme/constants';
const { gray200 } = colors;

export const MuiIconButton: Components<Omit<Theme, 'components'>>['MuiIconButton'] = {
  styleOverrides: {
    colorPrimary: {
      color: gray200,
    },
    colorSecondary: {
      color: 'inherit',
      fontWeight: 'bold',
    },
    colorSuccess: {
      color: 'inherit',
      fontWeight: 'bold',
    },
    sizeMedium: {
      fontSize: '1.5em',
    },
    root: {
      '&:hover': {
        color: 'inherit',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
      },
    },
  },
};
