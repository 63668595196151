import classNames from 'classnames';
import tableStyle from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { UploadableImageOverrideStyle } from './UploadableImage';
import style from './UploadableImage.module.scss';

export const useCustomStyle = (
  styleConfig: UploadableImageOverrideStyle,
  clickableRef: unknown
) => {
  const { thumbnail, mainWrapper, imageContainer, applyDefaultStyle } = styleConfig;
  const containerClass = classNames(imageContainer, {
    [style.clickable]: clickableRef,
    [style.container]: applyDefaultStyle,
  });

  const thumbnailClass = classNames(thumbnail, {
    [tableStyle.thumbnail]: applyDefaultStyle,
  });

  return { classes: { thumbnail: thumbnailClass, container: containerClass, mainWrapper } };
};
