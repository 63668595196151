import { useEffect, useState } from 'react';

type LocalStorageEntry = Record<string, any>;

export const safeGetItem = <T extends LocalStorageEntry>(name: string): T | undefined => {
  try {
    const value = localStorage.getItem(name);
    return !!value && JSON.parse(value);
  } catch (e) {
    console.warn(`Failed to load ${name} data from localStorage`);
    return undefined;
  }
};

export const safeSetItem = (name: string, value: any) => {
  try {
    value === undefined
      ? localStorage.removeItem(name)
      : localStorage.setItem(name, JSON.stringify(value));
    return true;
  } catch (e) {
    console.warn(`Failed to store ${name} data to localStorage`);
    return false;
  }
};

/**
 * Safely merges an object with another in localStorage.
 */
export const safeMergeItem = <T extends LocalStorageEntry>(
  name: string,
  value: Record<string, any>
) => {
  const oldValue = safeGetItem<T>(name);
  return safeSetItem(name, { ...(oldValue || {}), ...value });
};

export type UseLocalStorageProps = {
  name: string;
  defaultValue?: string;
};

/**
 * A safe way to read and write data to localStorage. It includes a reactive state via useState().
 * This hook should not be used directly - rather, it should be used internally by other, more specific hooks.
 */
export const useLocalStorage = <T extends Record<string, any>>({ name }: UseLocalStorageProps) => {
  const [value, setValue] = useState<T | undefined>(safeGetItem<T>(name));

  useEffect(() => {
    safeSetItem(name, value);
  }, [value]);

  return { value, setValue };
};
