/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressLocality,
    AddressLocalityFromJSON,
    AddressLocalityFromJSONTyped,
    AddressLocalityToJSON,
} from './AddressLocality';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    readonly id: number;
    /**
     * 
     * @type {AddressLocality}
     * @memberof Address
     */
    locality: AddressLocality | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    raw: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    formatted?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    longitude?: number | null;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'locality': AddressLocalityFromJSON(json['locality']),
        'streetNumber': !exists(json, 'street_number') ? undefined : json['street_number'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'raw': json['raw'],
        'formatted': !exists(json, 'formatted') ? undefined : json['formatted'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality': AddressLocalityToJSON(value.locality),
        'street_number': value.streetNumber,
        'route': value.route,
        'raw': value.raw,
        'formatted': value.formatted,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

