import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { useDarkMode } from 'context/DarkMode/DarkModeContext';
import { dark } from './Theme/dark';
import { light } from './Theme/light';

const ThemeContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme } = useDarkMode();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme === 'dark' ? dark : light}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeContext.displayName = 'ThemeContext';

export { ThemeContext };
