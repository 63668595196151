import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { REGION_COOKIE } from 'constants/cookie';
import { getCookie, setCookie } from 'services/cookie';
import { fetchWithCSRFToken } from '../../../../src/js/utils';

const findRegionCookie = () => {
  return getCookie(REGION_COOKIE) || 'us';
};

// The idea is that drop-downs for region should switch region in a persistent way.
// See region_middleware.py for background comments
export const switchRegion = (region: string) => {
  // eslint-disable-next-line no-console
  console.debug('switching region to', region);
  const days = 365 * 10;
  const regionChanged = region !== findRegionCookie();
  setCookie(REGION_COOKIE, region, days);
  if (regionChanged) {
    void fetchWithCSRFToken(`/switch-region/${region}/`, { method: 'POST' });
  }
};

/**
 * Gets the currently active region for the user.
 *
 * It reads from either the URL segment or the region cookie.
 */
export const useRegion = () => {
  const { region } = useParams();
  const location = useLocation();
  const [currentRegion, setCurrentRegion] = useState(region || findRegionCookie());

  useEffect(() => {
    setCurrentRegion(region || findRegionCookie());
  }, [location.pathname, region]);

  return currentRegion;
};
