// !! NB: This needs to be kept in sync with `stacking.scss`
export default {
  sticky: 1100, // For elements with display: sticky
  fixed: 1200, // For elements with display: fixed
  modalBg: 1300, // For modal backgrounds
  modal: 1301, // For modal containers
  dropDown: 1400, // For dropdown menus
  popOver: 1500, // For various popovers & tooltips

  // For local z-indexes within a component
  // DO NOT USE THESE FOR GLOBAL Z-INDEXES
  'local-4': 4,
  'local-3': 3,
  'local-2': 2,
  'local-1': 1,
  'local-0': 0,
  'local--1': -1,
  'local--2': -2,
  'local--3': -3,
  'local--4': -4,
};
