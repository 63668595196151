import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'pages/Dashboard/components/Tabs/Tabs';
import { useInventoryNavigation } from 'pages/Inventory/hooks/useInventoryNavigation';
import { getAnimalStatusParm } from 'components/experimental/Sidebar/Lists';
import { useAboutMe } from 'context/UserContext/UserContext';
import { useSettingsPermission } from 'hooks/useSettingsPermission';
import { navigationMenuText } from 'services/phrase';
import {
  isDashboardPath,
  isInventoryPath,
  isActivityListPath,
  isOrdersPath,
  isBreedingPath,
  isOffspringPath,
  isAddEditActivityPath,
  isAnimalGroupsPath,
  isHusbandryInventory,
} from 'services/urls';
import style from './AnimalsMenu.module.scss';

enum AnimalsPage {
  Dashboard = 'dashboard',
  Inventory = 'inventory',
  Husbandry = 'husbandry',
  ActivityList = 'activityList',
  Orders = 'orders',
  Breeding = 'breeding',
  Offspring = 'offspring',
  AnimalGroups = 'animalGroups',
}

export const AnimalsMenu: FC = () => {
  const { pathname } = window.location;
  const accessPermissions = useSettingsPermission();

  let value;
  if (isDashboardPath(pathname)) value = AnimalsPage.Dashboard;
  if (isInventoryPath(pathname)) value = AnimalsPage.Inventory;
  if (isHusbandryInventory(pathname)) value = AnimalsPage.Husbandry;
  if (isActivityListPath(pathname) || isAddEditActivityPath(pathname))
    value = AnimalsPage.ActivityList;
  if (isAnimalGroupsPath(pathname)) value = AnimalsPage.AnimalGroups;
  if (isOrdersPath(pathname)) value = AnimalsPage.Orders;
  if (isBreedingPath(pathname)) value = AnimalsPage.Breeding;
  if (isOffspringPath(pathname)) value = AnimalsPage.Offspring;

  const navigate = useNavigate();
  const navigateToInventory = useInventoryNavigation();
  const handleChange = (value: AnimalsPage) => {
    if (value === AnimalsPage.Dashboard) navigate('/dashboard');
    if (value === AnimalsPage.Inventory)
      navigateToInventory(`/animals/${getAnimalStatusParm(accessPermissions.isSeller)}`);
    if (value === AnimalsPage.Husbandry) navigateToInventory('/animals/husbandry');
    if (value === AnimalsPage.ActivityList) navigate('/husbandry/activities');
    if (value === AnimalsPage.AnimalGroups) navigate('/husbandry/groups');
    if (value === AnimalsPage.Orders) navigate('/orders');
    if (value === AnimalsPage.Breeding) navigate('/breeding?season=2024');
    if (value === AnimalsPage.Offspring) navigate('/offspring');
  };

  const { about } = useAboutMe();
  const hasStore = !!about?.hasStore;

  const animalsTabs = [
    hasStore && { title: navigationMenuText.dashboard, value: AnimalsPage.Dashboard },
    { title: navigationMenuText.myAnimals, value: AnimalsPage.Inventory },
    { title: navigationMenuText.husbandry, value: AnimalsPage.Husbandry },
    { title: navigationMenuText.breeding, value: AnimalsPage.Breeding },
    { title: navigationMenuText.offspring, value: AnimalsPage.Offspring },
    { title: navigationMenuText.groupsRacks, value: AnimalsPage.AnimalGroups },
    { title: navigationMenuText.activityLog, value: AnimalsPage.ActivityList },
    { title: navigationMenuText.orders, value: AnimalsPage.Orders },
  ].filter(Boolean) as { title: string; value: string }[];

  return (
    <div className={style.tabsWrapper}>
      <Tabs onChange={handleChange} value={value as string} tabs={animalsTabs} />
    </div>
  );
};
