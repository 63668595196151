import searchStyles from 'pages/Search/Search.module.scss';
import { FilterComponent } from './Sidebar';
import { Section } from './Sidebar';
import style from './Sidebar.module.scss';
/**
 * This function is essentially a hack to get AS filters toggled from closed to expanded and vice-versa.
 * It should probably be replaced with a more React-like solution in the future.
 */
export const openCollapsibleSidebars = () => {
  const buttonClass = style.collapsibleButton;
  const elements = document.querySelectorAll(`.${buttonClass}`);
  const filterEligible = (element: Element) => {
    const hasValues = element.nextElementSibling?.lastChild?.textContent === 'Clear';
    const alwaysOpenSections = ['Category', 'Location'];
    const shouldAlwaysOpen = alwaysOpenSections.includes(
      element.nextElementSibling?.firstChild?.textContent || ''
    );
    return hasValues || shouldAlwaysOpen;
  };
  const filterClosed = (element: Element) => {
    return !element.classList.contains(style.isExpanded);
  };
  const filterOpen = (element: Element) => {
    return element.classList.contains(style.isExpanded);
  };
  const dispatchClick = (element: any) => element.click();
  const targets = [...elements].filter(filterEligible);
  const eligibleCount = targets.length;
  const openCount = targets.filter(filterOpen).length;
  const closedCount = targets.filter(filterOpen).length;

  if (eligibleCount === closedCount) {
    targets.forEach(dispatchClick);
  } else if (eligibleCount === openCount) {
    targets.forEach(dispatchClick);
  } else {
    targets.filter(filterClosed).forEach(dispatchClick);
  }
};

/**
 * This function is like openCollapsibleSidebars, except it open the mobile f
 */
export const toggleSearchModal = () => {
  document.querySelector(`.${searchStyles.drawerIcon}`)?.click();
  document.querySelector(`.${searchStyles.sidebarButton}`)?.click();
};

export const makeSidebarSections = (filtersComponentList: FilterComponent[]): Section[] => {
  return filtersComponentList.map((FilterComponent) => [FilterComponent, false]);
};
