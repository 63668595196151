/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface PaginatedMessageList
 */
export interface PaginatedMessageList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessageList
     */
    total?: number;
    /**
     * 
     * @type {Array<Message>}
     * @memberof PaginatedMessageList
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedMessageList
     */
    isCustomer?: boolean;
}

export function PaginatedMessageListFromJSON(json: any): PaginatedMessageList {
    return PaginatedMessageListFromJSONTyped(json, false);
}

export function PaginatedMessageListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMessageList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'isCustomer': !exists(json, 'is_customer') ? undefined : json['is_customer'],
    };
}

export function PaginatedMessageListToJSON(value?: PaginatedMessageList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageToJSON)),
        'is_customer': value.isCustomer,
    };
}

