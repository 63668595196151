/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `weight` - weight
 * * `length` - length
 * * `feeding` - feeding
 * * `shed` - shed
 * * `defecation` - defecation
 * * `cleaning` - cleaning
 * * `water_change` - water change
 * * `brumation` - brumation
 * * `ovulation` - ovulation
 * * `follicle` - follicle
 * * `pairing` - pairing
 * * `lock` - lock
 * * `copulation` - copulation
 * * `gravid` - gravid
 * * `laid` - laid
 * * `hatched` - hatched
 * * `born` - born
 * @export
 */
export const ActivityTypeEnum = {
    Weight: 'weight',
    Length: 'length',
    Feeding: 'feeding',
    Shed: 'shed',
    Defecation: 'defecation',
    Cleaning: 'cleaning',
    WaterChange: 'water_change',
    Brumation: 'brumation',
    Ovulation: 'ovulation',
    Follicle: 'follicle',
    Pairing: 'pairing',
    Lock: 'lock',
    Copulation: 'copulation',
    Gravid: 'gravid',
    Laid: 'laid',
    Hatched: 'hatched',
    Born: 'born'
} as const;
export type ActivityTypeEnum = typeof ActivityTypeEnum[keyof typeof ActivityTypeEnum];


export function ActivityTypeEnumFromJSON(json: any): ActivityTypeEnum {
    return ActivityTypeEnumFromJSONTyped(json, false);
}

export function ActivityTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityTypeEnum {
    return json as ActivityTypeEnum;
}

export function ActivityTypeEnumToJSON(value?: ActivityTypeEnum | null): any {
    return value as any;
}

