/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseStoreUrls
 */
export interface BaseStoreUrls {
    /**
     * 
     * @type {string}
     * @memberof BaseStoreUrls
     */
    ratings: string;
    /**
     * 
     * @type {string}
     * @memberof BaseStoreUrls
     */
    storePublic: string;
    /**
     * 
     * @type {string}
     * @memberof BaseStoreUrls
     */
    storeAdmin: string;
    /**
     * 
     * @type {string}
     * @memberof BaseStoreUrls
     */
    listings: string;
}

export function BaseStoreUrlsFromJSON(json: any): BaseStoreUrls {
    return BaseStoreUrlsFromJSONTyped(json, false);
}

export function BaseStoreUrlsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseStoreUrls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ratings': json['ratings'],
        'storePublic': json['store_public'],
        'storeAdmin': json['store_admin'],
        'listings': json['listings'],
    };
}

export function BaseStoreUrlsToJSON(value?: BaseStoreUrls | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ratings': value.ratings,
        'store_public': value.storePublic,
        'store_admin': value.storeAdmin,
        'listings': value.listings,
    };
}

