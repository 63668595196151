/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUnseenMessages
 */
export interface UserUnseenMessages {
    /**
     * 
     * @type {number}
     * @memberof UserUnseenMessages
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof UserUnseenMessages
     */
    url: string;
}

export function UserUnseenMessagesFromJSON(json: any): UserUnseenMessages {
    return UserUnseenMessagesFromJSONTyped(json, false);
}

export function UserUnseenMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUnseenMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'url': json['url'],
    };
}

export function UserUnseenMessagesToJSON(value?: UserUnseenMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'url': value.url,
    };
}

