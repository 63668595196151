import { Span } from 'components/experimental/Typography';
import { SettingsProps } from '../Menus/Settings';
import { CaretButton } from './CaretButton';

export const DesktopSettingsButton: React.FC<
  React.ComponentProps<SettingsProps['ButtonComponent']>
> = ({ userName, toggle, button }) => {
  return (
    <CaretButton {...button} onClick={toggle} aria-label="User Settings Menu">
      <Span bold lg>
        {userName}
      </Span>
    </CaretButton>
  );
};
