/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarkSeenResponseUnseenThreadCounts,
    MarkSeenResponseUnseenThreadCountsFromJSON,
    MarkSeenResponseUnseenThreadCountsFromJSONTyped,
    MarkSeenResponseUnseenThreadCountsToJSON,
} from './MarkSeenResponseUnseenThreadCounts';
import {
    MessageAttachment,
    MessageAttachmentFromJSON,
    MessageAttachmentFromJSONTyped,
    MessageAttachmentToJSON,
} from './MessageAttachment';
import {
    MessageByEnum,
    MessageByEnumFromJSON,
    MessageByEnumFromJSONTyped,
    MessageByEnumToJSON,
} from './MessageByEnum';

/**
 * 
 * @export
 * @interface NewMessageResponse
 */
export interface NewMessageResponse {
    /**
     * 
     * @type {MessageByEnum}
     * @memberof NewMessageResponse
     */
    readonly messageBy: MessageByEnum | null;
    /**
     * 
     * @type {string}
     * @memberof NewMessageResponse
     */
    readonly messageTime: string;
    /**
     * 
     * @type {string}
     * @memberof NewMessageResponse
     */
    readonly messageTimeDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof NewMessageResponse
     */
    readonly messageText: string;
    /**
     * 
     * @type {Array<MessageAttachment>}
     * @memberof NewMessageResponse
     */
    readonly attachments: Array<MessageAttachment>;
    /**
     * 
     * @type {MarkSeenResponseUnseenThreadCounts}
     * @memberof NewMessageResponse
     */
    unseenThreadCounts: MarkSeenResponseUnseenThreadCounts;
}

export function NewMessageResponseFromJSON(json: any): NewMessageResponse {
    return NewMessageResponseFromJSONTyped(json, false);
}

export function NewMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageBy': MessageByEnumFromJSON(json['message_by']),
        'messageTime': json['message_time'],
        'messageTimeDisplay': json['message_time_display'],
        'messageText': json['message_text'],
        'attachments': ((json['attachments'] as Array<any>).map(MessageAttachmentFromJSON)),
        'unseenThreadCounts': MarkSeenResponseUnseenThreadCountsFromJSON(json['unseen_thread_counts']),
    };
}

export function NewMessageResponseToJSON(value?: NewMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unseen_thread_counts': MarkSeenResponseUnseenThreadCountsToJSON(value.unseenThreadCounts),
    };
}

