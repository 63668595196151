import categoryColumn from 'components/Common/Columns/CategoryColumn/CategoryColumn';
import damColumn from 'components/Common/Columns/DamColumn/DamColumn';
import imageColumn from 'components/Common/Columns/ImageColumn/ImageColumn';
import sireColumn from 'components/Common/Columns/SireColumn/SireColumn';
import traitsColumn from 'components/Common/Columns/TraitsColumn/TraitsColumn';
import acceptOffersColumn from 'components/Inventory/Columns/AcceptOffersColumn/AcceptOffersColumn';
import animalIdColumn from 'components/Inventory/Columns/AnimalIdColumn/AnimalIdColumn';
import availableForOrderColumn from 'components/Inventory/Columns/AvailableForOrderColumn/AvailableForOrderColumn';
import birthDateColumn from 'components/Inventory/Columns/BirthDateColumn/BirthDateColumn';
import clicksColumn from 'components/Inventory/Columns/ClicksColumn/ClicksColumn';
import expoColumn from 'components/Inventory/Columns/ExpoColumn/ExpoColumn';
import firstListedColumn from 'components/Inventory/Columns/FirstListedColumn/FirstListedColumn';
import groupIdColumn from 'components/Inventory/Columns/GroupIdColumn/GroupIdColumn';
import impressionsColumn from 'components/Inventory/Columns/ImpressionsColumn/ImpressionsColumn';
import inquiriesColumn from 'components/Inventory/Columns/InquiriesColumn/InquiriesColumn';
import lastRenewedColumn from 'components/Inventory/Columns/LastRenewedColumn/LastRenewedColumn';
import lastUpdatedColumn from 'components/Inventory/Columns/LastUpdatedColumn/LastUpdatedColumn';
import maturityColumn from 'components/Inventory/Columns/MaturityColumn/MaturityColumn';
import priceColumn from 'components/Inventory/Columns/PriceColumn/PriceColumn';
import quantityColumn from 'components/Inventory/Columns/QuantityColumn/QuantityColumn';
import sexColumn from 'components/Inventory/Columns/SexColumn/SexColumn';
import statusColumn from 'components/Inventory/Columns/StatusColumn/StatusColumn';
import tagColumn from 'components/Inventory/Columns/TagColumn/TagColumn';
import titleColumn from 'components/Inventory/Columns/TitleColumn/TitleColumn';
import titleIdColumn from 'components/Inventory/Columns/TitleIdColumn/TitleIdColumn';
import weightColumn from 'components/Inventory/Columns/WeightColumn/WeightColumn';
import wholesalePriceColumn from 'components/Inventory/Columns/WholesalePriceColumn/WholesalePriceColumn';
import transferredFromColumn from '../../../../components/Inventory/Columns/TransferredFromColumn/TransferredFromColumn';
import { IWindow } from 'types/window';

declare global {
  interface Window extends IWindow {}
}

const hasStore = window?.display_preferences?.has_store;

const defaultColumns = [
  [imageColumn, true],
  [titleColumn, true],
  [animalIdColumn, true],
  [priceColumn, true],
  [wholesalePriceColumn, false],
  [statusColumn, true],
  [birthDateColumn, true],
  [sexColumn, true],
  [maturityColumn, true],
  [weightColumn, true],
  [firstListedColumn, false],
  [lastUpdatedColumn, true],
  [lastRenewedColumn, false],
  [quantityColumn, true],
  [traitsColumn, true],
  [damColumn, true],
  [sireColumn, true],
  [groupIdColumn, true],
  [tagColumn, true],
  [expoColumn, true],
  [impressionsColumn, hasStore],
  [clicksColumn, hasStore],
  [inquiriesColumn, hasStore],
  [categoryColumn, false],
  [titleIdColumn, false],
  [availableForOrderColumn, false],
  [acceptOffersColumn, false],
  [transferredFromColumn, true],
];

export default defaultColumns;
