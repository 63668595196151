import {
  Components,
  formLabelClasses,
  inputLabelClasses,
  outlinedInputClasses,
  Theme,
} from '@mui/material';
import colors from '../constants/colors';
import heights from '../constants/heights';

export const MuiTextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 6,
      color: colors.gray100,
      fontSize: theme.typography.sm.fontSize,
      [`& .${outlinedInputClasses.root}`]: {
        height: heights.input,
      },
      [`& .${outlinedInputClasses.input}`]: {
        fontSize: theme.typography.sm.fontSize,
      },
      [`& .${formLabelClasses.root}`]: {
        fontSize: theme.typography.sm.fontSize,
        color: colors.gray100,
        '&.Mui-focused': {
          fontSize: 'initial',
          color: colors.gray100,
        },
        [`&:not(.${inputLabelClasses.shrink})`]: {
          transform: 'translate(14px, 12px) scale(1)',
          height: heights.input,
        },
      },
    }),
  },
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
  },
};
