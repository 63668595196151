import BornColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/BornColumn';
import BrumationColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/BrumationColumn';
import CleaningColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/CleaningColumn';
import CopulationColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/CopulationColumn';
import DefecationColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/DefecationColumn';
import FeedingColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/FeedingColumn';
import FollicleColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/FollicleColumn';
import HatchedColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/HatchedColumn';
import LaidColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/LaidColumn';
import LengthColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/LengthColumn';
import LockColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/LockColumn';
import OvulationColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/OvulationColumn';
import PairingColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/PairingColumn';
import ShedColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/ShedColumn';
import WaterChangeColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/WaterChangeColumn';
import WeightColumn from 'components/HusbandryInventory/Columns/ActivitiesColumns/WeightColumn';
import animalIdColumn from 'components/HusbandryInventory/Columns/AnimalIdColumn/AnimalIdColumn';
import GravidColumn from 'components/HusbandryInventory/Columns/GravidColumn/GravidCoumn';
import imageColumn from 'components/HusbandryInventory/Columns/ImageColumn/ImageColumn';
import sexColumn from 'components/HusbandryInventory/Columns/SexColumn/SexColumn';
import titleColumn from 'components/HusbandryInventory/Columns/TitleColumn/TitleColumn';

const husbandryInventoryDefaultColumns = [
  [imageColumn, true],
  [titleColumn, true],
  [animalIdColumn, true],
  [sexColumn, true],
  [WeightColumn, true],
  [LengthColumn, true],
  [FeedingColumn, true],
  [ShedColumn, true],
  [DefecationColumn, true],
  [CleaningColumn, true],
  [WaterChangeColumn, true],
  [BrumationColumn, true],
  [OvulationColumn, true],
  [FollicleColumn, true],
  [PairingColumn, true],
  [LockColumn, true],
  [CopulationColumn, true],
  [GravidColumn, true],
  [LaidColumn, true],
  [HatchedColumn, true],
  [BornColumn, true],
];

export default husbandryInventoryDefaultColumns;
