import { SymbolIndex } from './../../../../types';

export const STATUS_FILTER = Symbol('STATUS_FILTER');

export const STATUS_ACTIVE_COLLECTIONS = Symbol('STATUS_ACTIVE_COLLECTIONS');
export const STATUS_COLLECTION = Symbol('STATUS_COLLECTION');
export const STATUS_EXPIRED = Symbol('STATUS_EXPIRED');
export const STATUS_FOR_SALE = Symbol('STATUS_FOR_SALE');
export const STATUS_INACTIVE = Symbol('STATUS_INACTIVE');
export const STATUS_LIVE_LISTINGS = Symbol('STATUS_LIVE_LISTINGS');
export const STATUS_LOANED = Symbol('STATUS_LOANED');
export const STATUS_NOT_ACTIVE_COLLECTIONS = Symbol('STATUS_NOT_ACTIVE_COLLECTIONS');
export const STATUS_NOT_FOR_SALE = Symbol('STATUS_NOT_FOR_SALE');
export const STATUS_NOT_LIVE_LISTINGS = Symbol('STATUS_NOT_LIVE_LISTINGS');
export const STATUS_ON_HOLD = Symbol('STATUS_ON_HOLD');
export const STATUS_SOLD = Symbol('STATUS_SOLD');
export const STATUS_NEEDS_UPDATE = Symbol('STATUS_NEED_UPDATE');
export const STATUS_PURCHASED = Symbol('STATUS_PURCHASED');
export const STATUS_BREEDERS = Symbol('STATUS_BREEDERS');
export const STATUS_HOLDBACKS = Symbol('STATUS_HOLDBACKS');
export const STATUS_PETS = Symbol('STATUS_PETS');

export const statusNames: SymbolIndex = {
  [STATUS_ACTIVE_COLLECTIONS]: 'Collection (Active)',
  [STATUS_COLLECTION]: 'Collection',
  [STATUS_EXPIRED]: 'Expired',
  [STATUS_FOR_SALE]: 'For Sale',
  [STATUS_INACTIVE]: 'Inactive',
  [STATUS_LIVE_LISTINGS]: 'Ads (Listed)',
  [STATUS_LOANED]: 'Loaned',
  [STATUS_NOT_ACTIVE_COLLECTIONS]: 'Collection (Not Active)',
  [STATUS_NOT_FOR_SALE]: 'NFS',
  [STATUS_NOT_LIVE_LISTINGS]: 'Ads (Unlisted)',
  [STATUS_ON_HOLD]: 'On Hold',
  [STATUS_NEEDS_UPDATE]: 'Needs Update',
  [STATUS_PURCHASED]: 'Purchased',
  [STATUS_SOLD]: 'Sold',
  [STATUS_BREEDERS]: 'Breeder',
  [STATUS_HOLDBACKS]: 'Holdback',
  [STATUS_PETS]: 'Pet',
};
