import { FC, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, SxProps } from '@mui/material';

type FieldLabelProps = {
  children: ReactNode;
  position: 'start' | 'end';
  color?: string;
  icon?: IconDefinition;
};

export const FieldLabel: FC<FieldLabelProps> = ({ children, position, color, icon }) => (
  <Box
    sx={{
      px: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 1,
      borderLeft: position === 'start' ? 1 : 0,
      borderRight: position === 'end' ? 1 : 0,
      borderColor: 'fieldBox.border',
      borderRadius: 1,
      ...(position === 'start' && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }),
      ...(position === 'end' && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
      backgroundColor: 'fieldBox.labelBackground',
      ...(!!icon && { color: 'fieldBox.labelText' }),
      ...(!!color && { backgroundColor: color, color: 'white' }),
    }}
  >
    {children}
  </Box>
);

type FieldBoxProps = {
  children: ReactNode;
  hasStart?: boolean;
  hasEnd?: boolean;
  sx?: SxProps;
};

export const FieldBox: FC<FieldBoxProps> = ({ children, hasStart, hasEnd, sx }) => (
  <Box
    sx={{
      backgroundColor: 'fieldBox.background',
      border: 1,
      borderColor: 'fieldBox.border',
      borderRadius: 1,
      ...(hasStart && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
      ...(hasEnd && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }),
      px: 1.5,
      py: 0.5,
      fontWeight: 500,
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const IconFieldBox: FC<{
  children: ReactNode;
  icon?: IconDefinition;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  color?: string;
}> = ({ children, icon, startAdornment, endAdornment, color }) => (
  <Stack direction="row" alignItems="stretch">
    {(!!icon || !!startAdornment) && (
      <FieldLabel position="start" color={color} icon={icon}>
        {!!icon ? <FontAwesomeIcon icon={icon} /> : startAdornment}
      </FieldLabel>
    )}
    <FieldBox hasStart={!!icon || !!startAdornment} hasEnd={!!endAdornment}>
      {children}
    </FieldBox>
    {!!endAdornment && <FieldLabel position="end">{endAdornment}</FieldLabel>}
  </Stack>
);
