import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const mmEgg: IconDefinition = {
  prefix: 'fab',
  iconName: 'accessible-icon', // We need to fake the icon name here
  icon: [
    13,
    16,
    [],
    '',
    `M6.91992 0C3.60742 0 0.919922 6.6875 0.919922 10C0.919922 13.3125 3.60742 16 6.91992 16C10.2324 16 12.9199 13.3125 12.9199 10C12.9199 6.6875 10.2324 0 6.91992 0Z`,
  ],
};
