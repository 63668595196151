import classNames from 'classnames';
import Image, { Source } from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import { useAnimalImageUploadContext } from 'context/AnimalImageUploadContext/AnimalImageUploadContext';
import { useCustomStyle } from './helper';
import style from './UploadableImage.module.scss';

export const UploadableImage: React.FC<UploadableImageProps> = ({
  title,
  srcset,
  images,
  imageCount,
  overrideStyle = defaultStyleForUploadImage,
}) => {
  const { isLoading = false, clickableRef } = useAnimalImageUploadContext();
  const { classes } = useCustomStyle(overrideStyle, clickableRef);
  // Not all endpoints provide the `image_count` property, so we need to check for it
  // and fall back to the `images` array length if it's not available.
  const trueImagesCount = imageCount || (images && images.length) || 0;

  return (
    <div ref={clickableRef} className={classes.mainWrapper}>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={classes.container}>
          <Image
            srcset={srcset}
            alt={`Thumbnail image of ${title}`}
            className={classes.thumbnail}
          />
          {trueImagesCount >= 2 && (
            <div className={classNames('font_labelSmall', style.imagesCount)}>
              {trueImagesCount}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export interface UploadableImageOverrideStyle {
  thumbnail?: string;
  mainWrapper?: string;
  imageContainer?: string;
  applyDefaultStyle?: boolean;
}

export const defaultStyleForUploadImage: UploadableImageOverrideStyle = {
  applyDefaultStyle: true,
};

export type UploadableImageProps = {
  title: string;
  images: unknown[];
  imageCount?: number;
  srcset: Source[] | null;
  overrideStyle?: Partial<UploadableImageOverrideStyle>;
};

export default UploadableImage;
