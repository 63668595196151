/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreRatings
 */
export interface StoreRatings {
    /**
     * 
     * @type {number}
     * @memberof StoreRatings
     */
    overallRatingsScore: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreRatings
     */
    positiveRatingsPct: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreRatings
     */
    positiveRatingsAllTimePct: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreRatings
     */
    allTimeTotalRating: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreRatings
     */
    reciprocatedRatingsPct: number | null;
}

export function StoreRatingsFromJSON(json: any): StoreRatings {
    return StoreRatingsFromJSONTyped(json, false);
}

export function StoreRatingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreRatings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overallRatingsScore': json['overall_ratings_score'],
        'positiveRatingsPct': json['positive_ratings_pct'],
        'positiveRatingsAllTimePct': json['positive_ratings_all_time_pct'],
        'allTimeTotalRating': json['all_time_total_rating'],
        'reciprocatedRatingsPct': json['reciprocated_ratings_pct'],
    };
}

export function StoreRatingsToJSON(value?: StoreRatings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overall_ratings_score': value.overallRatingsScore,
        'positive_ratings_pct': value.positiveRatingsPct,
        'positive_ratings_all_time_pct': value.positiveRatingsAllTimePct,
        'all_time_total_rating': value.allTimeTotalRating,
        'reciprocated_ratings_pct': value.reciprocatedRatingsPct,
    };
}

