/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ActionRequest,
    ActionRequestFromJSON,
    ActionRequestFromJSONTyped,
    ActionRequestToJSON,
} from './ActionRequest';
import {
    BuyerReviewRequest,
    BuyerReviewRequestFromJSON,
    BuyerReviewRequestFromJSONTyped,
    BuyerReviewRequestToJSON,
} from './BuyerReviewRequest';
import {
    SellerReviewRequest,
    SellerReviewRequestFromJSON,
    SellerReviewRequestFromJSONTyped,
    SellerReviewRequestToJSON,
} from './SellerReviewRequest';

/**
 * @type MessangerActionProxyRequest
 * 
 * @export
 */
export type MessangerActionProxyRequest = { actionType: 'buyer_review' } & BuyerReviewRequest | { actionType: 'default' } & ActionRequest | { actionType: 'seller_review' } & SellerReviewRequest;

export function MessangerActionProxyRequestFromJSON(json: any): MessangerActionProxyRequest {
    return MessangerActionProxyRequestFromJSONTyped(json, false);
}

export function MessangerActionProxyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessangerActionProxyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['actionType']) {
        case 'buyer_review':
            return {...BuyerReviewRequestFromJSONTyped(json, true), actionType: 'buyer_review'};
        case 'default':
            return {...ActionRequestFromJSONTyped(json, true), actionType: 'default'};
        case 'seller_review':
            return {...SellerReviewRequestFromJSONTyped(json, true), actionType: 'seller_review'};
        default:
            throw new Error(`No variant of MessangerActionProxyRequest exists with 'actionType=${json['actionType']}'`);
    }
}

export function MessangerActionProxyRequestToJSON(value?: MessangerActionProxyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['actionType']) {
        case 'buyer_review':
            return BuyerReviewRequestToJSON(value);
        case 'default':
            return ActionRequestToJSON(value);
        case 'seller_review':
            return SellerReviewRequestToJSON(value);
        default:
            throw new Error(`No variant of MessangerActionProxyRequest exists with 'actionType=${value['actionType']}'`);
    }
}

