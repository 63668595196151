/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppQrCodeRedirectModeEnum,
    AppQrCodeRedirectModeEnumFromJSON,
    AppQrCodeRedirectModeEnumFromJSONTyped,
    AppQrCodeRedirectModeEnumToJSON,
} from './AppQrCodeRedirectModeEnum';

/**
 * 
 * @export
 * @interface UserModelPreferences
 */
export interface UserModelPreferences {
    /**
     * 
     * @type {AppQrCodeRedirectModeEnum}
     * @memberof UserModelPreferences
     */
    appQrCodeRedirectMode?: AppQrCodeRedirectModeEnum;
}

export function UserModelPreferencesFromJSON(json: any): UserModelPreferences {
    return UserModelPreferencesFromJSONTyped(json, false);
}

export function UserModelPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserModelPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appQrCodeRedirectMode': !exists(json, 'app_qr_code_redirect_mode') ? undefined : AppQrCodeRedirectModeEnumFromJSON(json['app_qr_code_redirect_mode']),
    };
}

export function UserModelPreferencesToJSON(value?: UserModelPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app_qr_code_redirect_mode': AppQrCodeRedirectModeEnumToJSON(value.appQrCodeRedirectMode),
    };
}

