// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--H3HwE{width:100%;top:0;z-index:1101}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Toaster/Toaster.module.scss"],"names":[],"mappings":"AAEA,kBACE,UAAA,CACA,KAAA,CACA,YAAA","sourcesContent":["@use 'style/stacking' as *;\n\n.container {\n  width: 100%;\n  top: 0;\n  z-index: $zindex-sticky + 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zindex_sticky": `1100`,
	"zindex_fixed": `1200`,
	"zindex_modal_bg": `1300`,
	"zindex_modal": `1301`,
	"zindex_dropdown": `1400`,
	"zindex_popover": `1500`,
	"zindex_local_4": `4`,
	"zindex_local_3": `3`,
	"zindex_local_2": `2`,
	"zindex_local_1": `1`,
	"zindex_local_0": `0`,
	"zindex_local__1": `-1`,
	"zindex_local__2": `-2`,
	"zindex_local__3": `-3`,
	"zindex_local__4": `-4`,
	"container": `container--H3HwE`
};
export default ___CSS_LOADER_EXPORT___;
