import camelCase from 'lodash/camelCase';
import { messages } from 'api/messages/generic';
import { GeneralParams } from 'api/types';

import { DEFAULT_RESULTS_PER_PAGE } from 'config';

type Sort = {
  column: string;
  direction: string;
};

type StandardError = {
  type: string;
  errors: {
    code: string;
    detail: string;
    attr: string;
  }[];
};

type SimpleErrorWithDetail = {
  detail: string;
};

type SimpleErrorWithMessage = {
  message: string;
};

type SimpleFieldError = {
  message: string;
  field_errors: {
    [field: string]: string[];
  };
};

export type ParsableAPIError =
  | SimpleErrorWithDetail
  | SimpleErrorWithMessage
  | SimpleFieldError
  | StandardError;

export type APIErrorResponse = {
  response: Response;
};

export type DataGridStateSearchParams = {
  page?: number;
  resultsPerPage?: number;
  sort?: Sort[];
};

/**
 * Formats Sort[] to a single sort by value, regardless of how many there are.
 */
const formatSort = (sort?: Sort[]) => {
  const sortBy = [...(sort || [])].pop();

  if (!!sortBy) {
    const { column, direction } = sortBy;
    return direction === 'asc' ? column : `-${column}`;
  }

  return undefined;
};

/**
 * Converts a subset of DataGridState to legacy API search parameters.
 * The parameters supported are: page, results per page and ordering.
 */
export const toLegacySearchParams = (query: DataGridStateSearchParams) => {
  const { page, resultsPerPage, sort } = query;
  const ordering = formatSort(sort);

  return [
    ['page', `${page || 1}`],
    ['page_size', `${resultsPerPage || DEFAULT_RESULTS_PER_PAGE}`],
    ['ordering', ordering],
  ].filter(([, value]) => !!value) as GeneralParams;
};

/**
 * Fetches new data from the API after successfully executing a query with callback.
 */
export const refetchAfterCall = <
  C extends (...args: any) => ReturnType<C> & { error?: any },
  F extends (...args: any) => ReturnType<F>
>(
  callback: C,
  fetch: F
) => {
  return async (...args: Parameters<C>) => {
    const response = await callback(...args);

    if (!response?.error) {
      fetch();
      return response;
    }
  };
};

/**
 * Encapsulates the most common pattern for non-idempotent API calls, which is:
 * 1. Execute API call.
 * 2. Fetch updated list data.
 * 3. Show success message.
 * 4. Alternatively, show an error message.
 */
export const wrapAPICall =
  <C extends (...args: any) => any, R extends (response: Awaited<ReturnType<C>>) => any>(
    callback: C,
    refetch?: R,
    onSuccess?: (response: Awaited<ReturnType<C>>) => void,
    onError?: (response: Awaited<ReturnType<C>>) => void
  ) =>
  async (...args: Parameters<C>) => {
    const response = (await callback(...args)) as Awaited<ReturnType<C>>;

    if (!!response) {
      refetch?.(response);
      onSuccess?.(response);
    } else {
      onError?.(response);
    }

    return response;
  };

export type ErrorResponse = {
  nonFieldError?: string;
  fieldErrors?: Record<string, string>;
};

/**
 * Parses the field error response returned by the Django API into a single consistent format.
 */
export const parseAPIFieldErrorResponseForTest = (error: any): ErrorResponse => {
  const nonFieldError = !error.field_errors ? error?.message : undefined;
  const fieldErrors = Object.entries(error?.field_errors || {}).reduce(
    (acc, [key, values]) => ({ ...acc, [camelCase(key)]: values }),
    {}
  );

  return {
    nonFieldError,
    fieldErrors,
  };
};

/**
 * Downloads a Blob response from an API request as a file.
 * @param blob - The Blob response from the API.
 * @param format - The file format or extension.
 */
export const downloadAPIBlobResponse = async (blob: Blob, format: string) => {
  const fileUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');

  downloadLink.href = fileUrl;
  downloadLink.download = `export.${format}`;
  downloadLink.style.display = 'none';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  URL.revokeObjectURL(fileUrl);
};

const isSimpleErrorWithDetail = (e: ParsableAPIError): e is SimpleErrorWithDetail =>
  (e as SimpleErrorWithDetail).detail !== undefined;

const isSimpleErrorWithMessage = (e: ParsableAPIError): e is SimpleErrorWithMessage =>
  (e as SimpleErrorWithMessage).message !== undefined;

const isFieldError = (e: ParsableAPIError): e is SimpleFieldError =>
  (e as SimpleFieldError).field_errors !== undefined;

export const parseAPIErrorResponse = async (e: APIErrorResponse): Promise<string[]> => {
  // We need `clone` because `json` consumes the response body
  // and subsequent calls to `json` will return "body streadm
  // already read" error.
  try {
    const json = await e.response?.clone()?.json();
    if (isSimpleErrorWithDetail(json)) return [json.detail];
    if (isSimpleErrorWithMessage(json)) return [json.message];
    if (isFieldError(json)) return Object.values(json.field_errors).flat();
    return json?.errors?.map(({ detail }) => detail) ?? messages.error;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('parseAPIErrorResponse: ', error);
    return [
      "parseAPIErrorResponse: Couldn't parse the error response. Perhaps it's not a JSON response.",
    ];
  }
};

export const lookFor5XX = (e: any) => {
  let status: number | undefined;
  let isHTML = false;

  try {
    const resStatus = e?.response?.status;
    const contentType = e?.response?.headers.get('content-type');
    if (resStatus && typeof resStatus === 'number') {
      status = resStatus;
    }
    if (contentType && contentType.includes('text/html')) {
      isHTML = true;
    }
  } catch (error) {
    status = undefined;
  }
  return { status, isHTML };
};
