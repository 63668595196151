/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { useSDKList } from "api/utilities/useAPIList";
import { StoresApi } from "../sdk/apis/StoresApi";
import { configuration } from "./configuration";

export const useStores = () => {
  const instance = new StoresApi(configuration);

  const getBreedingPairingBy = useAPIHook(instance.storesBreedingPairingByRetrieve.bind(instance));
  const createBreedingPairing = useAPIHook(instance.storesBreedingPairingCreate.bind(instance));
  const createBreedingPairingCreateOffspringGroup = useAPIHook(instance.storesBreedingPairingCreateOffspringGroupCreate.bind(instance));
  const destroyBreedingPairing = useAPIHook(instance.storesBreedingPairingDestroy.bind(instance));
  const listBreedingPairing = useAPIHook(instance.storesBreedingPairingList.bind(instance));
  const getBreedingPairingOutcomes = useAPIHook(instance.storesBreedingPairingOutcomesRetrieve.bind(instance));
  const updateBreedingPairingPartial = useAPIHook(instance.storesBreedingPairingPartialUpdate.bind(instance));
  const getBreedingPairing = useAPIHook(instance.storesBreedingPairingRetrieve.bind(instance));
  const updateBreedingPairing = useAPIHook(instance.storesBreedingPairingUpdate.bind(instance));
  const listCollection = useAPIHook(instance.storesCollectionList.bind(instance));
  const listFeaturedStores = useAPIHook(instance.storesFeaturedStoresList.bind(instance));
  const list = useAPIHook(instance.storesList.bind(instance));
  const listOffspringGroups = useAPIHook(instance.storesOffspringGroupsList.bind(instance));
  const getOffspringGroups = useAPIHook(instance.storesOffspringGroupsRetrieve.bind(instance));
  const getOwn = useAPIHook(instance.storesOwnRetrieve.bind(instance));
  const get = useAPIHook(instance.storesRetrieve.bind(instance));

  const breedingPairing = useSDKList(listBreedingPairing.callback, false);
  const collection = useSDKList(listCollection.callback, false);
  const featuredStores = useSDKList(listFeaturedStores.callback, false);
  const stores = useSDKList(list.callback, false);
  const offspringGroups = useSDKList(listOffspringGroups.callback, false);

  return {
    error:
      getBreedingPairingBy.error ||
      createBreedingPairing.error ||
      createBreedingPairingCreateOffspringGroup.error ||
      destroyBreedingPairing.error ||
      listBreedingPairing.error ||
      getBreedingPairingOutcomes.error ||
      updateBreedingPairingPartial.error ||
      getBreedingPairing.error ||
      updateBreedingPairing.error ||
      listCollection.error ||
      listFeaturedStores.error ||
      list.error ||
      listOffspringGroups.error ||
      getOffspringGroups.error ||
      getOwn.error ||
      get.error,
    isLoading:
      getBreedingPairingBy.isLoading ||
      createBreedingPairing.isLoading ||
      createBreedingPairingCreateOffspringGroup.isLoading ||
      destroyBreedingPairing.isLoading ||
      listBreedingPairing.isLoading ||
      getBreedingPairingOutcomes.isLoading ||
      updateBreedingPairingPartial.isLoading ||
      getBreedingPairing.isLoading ||
      updateBreedingPairing.isLoading ||
      listCollection.isLoading ||
      listFeaturedStores.isLoading ||
      list.isLoading ||
      listOffspringGroups.isLoading ||
      getOffspringGroups.isLoading ||
      getOwn.isLoading ||
      get.isLoading,
    breedingPairing: {
      data: breedingPairing.data,
      parametrizedFetch: breedingPairing.parametrizedFetch,
      refetch: breedingPairing.refetch,
      params: breedingPairing.params,
      setParams: breedingPairing.setParams,
    },
    collection: {
      data: collection.data,
      parametrizedFetch: collection.parametrizedFetch,
      refetch: collection.refetch,
      params: collection.params,
      setParams: collection.setParams,
    },
    featuredStores: {
      data: featuredStores.data,
      parametrizedFetch: featuredStores.parametrizedFetch,
      refetch: featuredStores.refetch,
      params: featuredStores.params,
      setParams: featuredStores.setParams,
    },
    stores: {
      data: stores.data,
      parametrizedFetch: stores.parametrizedFetch,
      refetch: stores.refetch,
      params: stores.params,
      setParams: stores.setParams,
    },
    offspringGroups: {
      data: offspringGroups.data,
      parametrizedFetch: offspringGroups.parametrizedFetch,
      refetch: offspringGroups.refetch,
      params: offspringGroups.params,
      setParams: offspringGroups.setParams,
    },

    getBreedingPairingBy: getBreedingPairingBy.callback,
    createBreedingPairing: createBreedingPairing.callback,
    createBreedingPairingCreateOffspringGroup: createBreedingPairingCreateOffspringGroup.callback,
    destroyBreedingPairing: destroyBreedingPairing.callback,
    listBreedingPairing: listBreedingPairing.callback,
    getBreedingPairingOutcomes: getBreedingPairingOutcomes.callback,
    updateBreedingPairingPartial: updateBreedingPairingPartial.callback,
    getBreedingPairing: getBreedingPairing.callback,
    updateBreedingPairing: updateBreedingPairing.callback,
    listCollection: listCollection.callback,
    listFeaturedStores: listFeaturedStores.callback,
    list: list.callback,
    listOffspringGroups: listOffspringGroups.callback,
    getOffspringGroups: getOffspringGroups.callback,
    getOwn: getOwn.callback,
    get: get.callback,
  };
};
