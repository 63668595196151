import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { QUANTITY_ORDERING } from 'api/inventory/ordering';
import { Animal } from 'types';
export const QUANTITY_COLUMN = Symbol('QUANTITY_COLUMN');

const quantityColumn = {
  id: QUANTITY_COLUMN,
  ordering: QUANTITY_ORDERING,
  label: 'Quantity',
  shortLabel: 'Qty',
  hideable: true,
  className: style.quantityColumn,

  render: ({ quantity_display }: Animal) => quantity_display,
};

export default quantityColumn;
