export default {
  // black
  black: 'var(--color-hardcoded-000)',

  gray000: 'var(--color-hardcoded-333)',
  gray100: 'var(--color-hardcoded-4a4b4d)',
  gray200: 'var(--color-hardcoded-d9d9d9)',
  gray300: 'var(--color-hardcoded-b4b4b4)',
  gray400: 'var(--color-hardcoded-d9d9d9)',
  gray500: 'var(--color-hardcoded-ced4da)',
  gray600: 'var(--color-hardcoded-e9ecef)',
  gray700: 'var(--color-hardcoded-f9f9f9)',
  gray800: 'var(--color-hardcoded-ffffff)',

  gold000: 'var(--color-hardcoded-b48007)',
  gold100: 'var(--color-hardcoded-c68e00)',
  gold200: 'var(--color-hardcoded-ffa41c)',
  gold300: 'var(--color-hardcoded-f5e5bf)',
  gold400: 'var(--color-hardcoded-f7eacc)',
  gold500: 'var(--color-hardcoded-fbf4e4)',

  red000: 'var(--color-hardcoded-eb5757)',
  red100: 'var(--color-hardcoded-eb5757)',
  red200: 'var(--color-hardcoded-ffcccf)',

  green000: 'var(--color-hardcoded-476f30)',
  green100: 'var(--color-hardcoded-60b760)',
  green200: 'var(--color-hardcoded-d6f0d9)',
  green300: 'var(--color-hardcoded-e3e9e0)',
  green400: 'var(--color-hardcoded-dff1df)',

  purple000: 'var(--color-hardcoded-7e54cf)',
  purple100: 'var(--color-hardcoded-926cdb)',
  purple200: 'var(--color-hardcoded-eae2f9)',
  // other
  white: 'var(--color-hardcoded-fff)',

  // status
  freemember: 'var(--color-hardcoded-e9ecef)',
  basicmember: 'var(--color-hardcoded-dff3f8)',
  standardmember: 'var(--color-hardcoded-dff1df)',
  premiummember: 'var(--color-hardcoded-f7eacc)',
  promember: 'var(--color-hardcoded-e9e2f8)',
} as const;
