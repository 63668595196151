/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';
import {
    PreyFoodEnum,
    PreyFoodEnumFromJSON,
    PreyFoodEnumFromJSONTyped,
    PreyFoodEnumToJSON,
} from './PreyFoodEnum';

/**
 * @type AnimalPreyFood
 * 
 * @export
 */
export type AnimalPreyFood = NullEnum | PreyFoodEnum;

export function AnimalPreyFoodFromJSON(json: any): AnimalPreyFood {
    return AnimalPreyFoodFromJSONTyped(json, false);
}

export function AnimalPreyFoodFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalPreyFood {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return PreyFoodEnumFromJSONTyped(json, true);
}

export function AnimalPreyFoodToJSON(value?: AnimalPreyFood | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return value;
}

