/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * There might be some problematic or unknown type of attachments,
 * which will result in no records in the MessageAttachment table,
 * while still having values in the Message.attachment_count and Message.attachment_size fields.
 * In that case, we will still use this serializer but feed it with instance
 * of MessageAttachment with values from Message fields. That instance will not have
 * id, so we may differentiate that two cases.
 * @export
 * @interface MessageAttachment
 */
export interface MessageAttachment {
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    readonly fileLabel: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    readonly thumbnailUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    readonly fileSizeDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    fileType?: string;
}

export function MessageAttachmentFromJSON(json: any): MessageAttachment {
    return MessageAttachmentFromJSONTyped(json, false);
}

export function MessageAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileLabel': json['file_label'],
        'fileUrl': json['file_url'],
        'thumbnailUrl': json['thumbnail_url'],
        'fileSizeDisplay': json['file_size_display'],
        'fileType': !exists(json, 'file_type') ? undefined : json['file_type'],
    };
}

export function MessageAttachmentToJSON(value?: MessageAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file_url': value.fileUrl,
        'file_type': value.fileType,
    };
}

