export const calculateInventoryColor = (active, can_renew, needsUpdate) => {
  if (needsUpdate){
    return 'RED';
  }
  if (can_renew) {
    return 'GREEN';
  }
  if (active !== 'active') {
    return 'GRAY';
  }
  return 'GOLD';
};
