import { RouteObject } from 'react-router-dom';

const ChooseSearchOrAnimalPage = () =>
  import('components/ChooseSearchOrAnimalPage/ChooseSearchOrAnimalPage');

const categoryRoutes: RouteObject[] = [
  { path: '', lazy: ChooseSearchOrAnimalPage },
  { path: 'gene/:trait', lazy: ChooseSearchOrAnimalPage },
  { path: 'trait/:trait', lazy: ChooseSearchOrAnimalPage },
  { path: 'multigene/:trait', lazy: ChooseSearchOrAnimalPage },
];

export const canonicalRoutes: RouteObject[] = [
  {
    path: ':category',
    children: categoryRoutes,
  },
  {
    path: ':parent/:category',
    children: categoryRoutes,
  },
  {
    path: ':ancestor/:parent/:category',
    children: categoryRoutes,
  },
  {
    path: ':anotherAncestor/:ancestor/:parent/:category',
    children: categoryRoutes,
  },
  {
    path: ':ancestor/:parent/:animalPageId',
    lazy: ChooseSearchOrAnimalPage,
  },
  {
    path: ':ancestor/:category/:animal?/:animalPageId',
    lazy: ChooseSearchOrAnimalPage,
  },
  {
    path: ':ancestor/:parent/:category/:animal/:animalPageId',
    lazy: ChooseSearchOrAnimalPage,
  },
];
