/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThreadBulkResultEnum,
    ThreadBulkResultEnumFromJSON,
    ThreadBulkResultEnumFromJSONTyped,
    ThreadBulkResultEnumToJSON,
} from './ThreadBulkResultEnum';

/**
 * 
 * @export
 * @interface ThreadActionResult
 */
export interface ThreadActionResult {
    /**
     * 
     * @type {number}
     * @memberof ThreadActionResult
     */
    threadId: number;
    /**
     * 
     * @type {ThreadBulkResultEnum}
     * @memberof ThreadActionResult
     */
    result: ThreadBulkResultEnum;
}

export function ThreadActionResultFromJSON(json: any): ThreadActionResult {
    return ThreadActionResultFromJSONTyped(json, false);
}

export function ThreadActionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThreadActionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threadId': json['thread_id'],
        'result': ThreadBulkResultEnumFromJSON(json['result']),
    };
}

export function ThreadActionResultToJSON(value?: ThreadActionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thread_id': value.threadId,
        'result': ThreadBulkResultEnumToJSON(value.result),
    };
}

