import { useEffect, useState } from 'react';
import {
  EXCLUDING_TAGS_FILTER,
  INCLUDING_TAGS_FILTER,
} from 'components/Common/Filters/TagsFilter/definitions';
import { getAllTags } from 'components/Inventory/Modals/EditTagsModal/api';
import { generateSingletonHook } from 'context/SingletonContext';
import { FiltersObject } from 'api/types';

export type Tag = {
  value: string;
  label: string;
};

type UseTagFilter = {
  filter: typeof EXCLUDING_TAGS_FILTER | typeof INCLUDING_TAGS_FILTER;
  filters: FiltersObject;
  allTags: Tag[];
};

export const useFetchInventoryTags = () => {
  const [allTags, setAllTags] = useState<Tag[]>([]);

  useEffect(() => {
    getAllTags()
      .then(({ results }) => {
        const tags = results.map(({ id, name }: { id: string; name: string }) => {
          const processedId = `${id}-l`;
          return { value: processedId, label: name };
        });
        setAllTags(tags);
      })
      .catch((error) => console.error(error));
  }, []);

  return allTags;
};

export const useInventoryTagFilter = ({ allTags, filter, filters }: UseTagFilter) => {
  const tags = filters.getValuesByKey(filter);

  const tagType = filter === EXCLUDING_TAGS_FILTER ? 'exclude' : 'include';
  const searchTagsPlaceholderText = `Search for tags to ${tagType}`;

  const selectedTags = allTags.filter((tag) => tags.includes(tag.value));

  return {
    allTags,
    selectedTags,
    searchTagsPlaceholderText,
  };
};

const useInventoryTagsInternal = () => {
  const allTags = useFetchInventoryTags();
  return allTags;
};

export const [InventoryTagsProvider, useInventoryTags] =
  generateSingletonHook(useInventoryTagsInternal);
