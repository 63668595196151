import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gold200, gold300 } = colors;

export const MuiSwitch: Components<Omit<Theme, 'components'>>['MuiSwitch'] = {
  styleOverrides: {
    switchBase: {
      '&.Mui-checked': {
        color: gold200,
        '+ .MuiSwitch-track': {
          backgroundColor: gold300,
        },
      },
    },
  },
};
