import { useEffect, useMemo } from 'react';
import { EnumsBucket } from 'constants/enums';
import Portal from 'components/Portal/Portal';
import { useAuth, useRegion } from 'hooks';
import { categoryMenu } from 'services/menu/extendedCategories';
import { NavbarLinks } from './components';
import { DesktopNavigation } from './desktop';
import { replaceRegionInMenu } from './helpers';
import { MobileNavigation } from './mobile';
import styles from './Navigation.module.scss';

/**
 * Navbar menu component. It is automatically hoisted to the top of the page via createPortal.
 */

export const Navbar: React.FC = () => {
  const { userName, unseenMessages } = useAuth();
  const { main_calculators_menu } = EnumsBucket;
  const region = useRegion();

  const categories = useMemo(
    () => replaceRegionInMenu(categoryMenu, region),
    [categoryMenu, region]
  );
  const links = NavbarLinks(categories, main_calculators_menu?.links);
  const props = { unseenMessages, links, userName };

  useEffect(() => {
    document.querySelector('#react-navbar-container')?.setAttribute('class', styles.container);
  }, []);

  return (
    <Portal parent={document.querySelector('#react-navbar-container')}>
      <DesktopNavigation {...props} />
      <MobileNavigation {...props} />
    </Portal>
  );
};
