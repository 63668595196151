/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageAttachment,
    MessageAttachmentFromJSON,
    MessageAttachmentFromJSONTyped,
    MessageAttachmentToJSON,
} from './MessageAttachment';
import {
    MessageByEnum,
    MessageByEnumFromJSON,
    MessageByEnumFromJSONTyped,
    MessageByEnumToJSON,
} from './MessageByEnum';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {MessageByEnum}
     * @memberof Message
     */
    readonly messageBy: MessageByEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly messageTime: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly messageTimeDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly messageText: string;
    /**
     * 
     * @type {Array<MessageAttachment>}
     * @memberof Message
     */
    readonly attachments: Array<MessageAttachment>;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageBy': MessageByEnumFromJSON(json['message_by']),
        'messageTime': json['message_time'],
        'messageTimeDisplay': json['message_time_display'],
        'messageText': json['message_text'],
        'attachments': ((json['attachments'] as Array<any>).map(MessageAttachmentFromJSON)),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

