import { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { filtersToSearchUrl } from 'pages/Search/utils';
import { PAGE_FILTER, PER_PAGE_FILTER } from 'components/ResultsCounter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import searchFilters from 'api/search/filters';
import { FiltersObject } from 'api/types';
import { filterDefinitionsToDefaults, queryParamsToFilters } from 'api/url';
import { mergeSearchParams } from 'services/CustomURLs/canonical';
import { lockedFilters } from './constants';

/**
 * Handles changing the filters and applying new filter state.
 */
export const useSearchFilters = () => {
  const routerParams = useParams();
  const params = useMemo<Record<string, string | undefined>>(
    () => ({ ...routerParams }),
    [routerParams]
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mergedParams = mergeSearchParams(searchParams, params);
  const applyFilters = (filters: FiltersObject) => navigate(filtersToSearchUrl(filters));
  const defaults = filterDefinitionsToDefaults(searchFilters);
  const filters = useMemo(
    () =>
      queryParamsToFilters(searchFilters, mergedParams)
        .set(LOCATION_FILTER, params.region)
        .setDefaults(defaults),
    [mergedParams?.toString()]
  );
  const clearableFiltersCount = filters?.remove(...lockedFilters).countKeys();
  const currentPage = filters ? filters?.get(PAGE_FILTER) : null;
  const currentPerPage = filters ? filters?.get(PER_PAGE_FILTER) : null;

  return {
    filters,
    currentPage,
    currentPerPage,
    clearableFiltersCount,
    applyFilters,
  };
};
