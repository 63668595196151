/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Self Produced` - Self Produced
 * * `Captive Bred` - Captive Bred
 * * `Captive Hatched/Born` - Captive Hatched/Born
 * * `Wild Caught` - Wild Caught
 * @export
 */
export const ItemOriginDisplayEnum = {
    SelfProduced: 'Self Produced',
    CaptiveBred: 'Captive Bred',
    CaptiveHatchedBorn: 'Captive Hatched/Born',
    WildCaught: 'Wild Caught'
} as const;
export type ItemOriginDisplayEnum = typeof ItemOriginDisplayEnum[keyof typeof ItemOriginDisplayEnum];


export function ItemOriginDisplayEnumFromJSON(json: any): ItemOriginDisplayEnum {
    return ItemOriginDisplayEnumFromJSONTyped(json, false);
}

export function ItemOriginDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemOriginDisplayEnum {
    return json as ItemOriginDisplayEnum;
}

export function ItemOriginDisplayEnumToJSON(value?: ItemOriginDisplayEnum | null): any {
    return value as any;
}

