import React, { HTMLAttributes } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './IconButton.module.scss';

export type IconButtonProps = HTMLAttributes<HTMLButtonElement> & {
  icon: IconDefinition;
};

export type IconDivProps = HTMLAttributes<HTMLDivElement> & {
  icon: IconDefinition;
};

export const IconButton: React.FC<IconButtonProps> = ({ icon, ...props }) => {
  return (
    <button className={styles.button} type="button" {...props}>
      <Icon icon={icon} />
    </button>
  );
};

export const IconWrapper: React.FC<IconDivProps> = ({ icon, className, ...props }) => {
  return (
    <div className={classNames(styles.icon, className)} {...props}>
      <Icon icon={icon} />
    </div>
  );
};
