import { ReactNode, useState } from 'react';
import { IconButton, Snackbar, SnackbarProps } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { generateSingletonHook } from 'context/SingletonContext';
import { useOpenState } from 'hooks/utils/useOpenState';

const useInternalSnackbar = () => {
  const { isOpen, open, close } = useOpenState();
  const [props, setProps] = useState<SnackbarProps>({});
  const TheSnackbar = () => (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
      open={isOpen}
      onClose={hide}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
            <GridCloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
  const show = (message: ReactNode, props?: SnackbarProps) => {
    setProps({ message, ...props });
    open();
  };
  const hide = () => {
    close();
    setProps({});
  };

  return {
    show,
    TheSnackbar,
  };
};

const [SnackbarProvider, useSnackbar] = generateSingletonHook(useInternalSnackbar);
export { SnackbarProvider, useSnackbar };
