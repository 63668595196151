import React from 'react';
import { faMars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './SexMale.module.scss';

interface SexMaleProps {
  className?: string;
}
const SexMale: React.FC<SexMaleProps> = ({ className, ...props }) => (
  <FontAwesomeIcon icon={faMars} className={classNames(style.icon, className)} {...props} />
);

export default SexMale;
