import { createContext, ReactNode, useContext, useRef, useState } from 'react';

export type NestedMenuState = {
  previous?: any[];
  branch: any[];
  changeBranch: (branch: any[]) => void;
  resetToRoot: () => void;
  goBack: () => void;
};

const NestedMenuContext = createContext<NestedMenuState>({
  branch: [],
  previous: undefined,
  goBack: () => {},
  changeBranch: () => {},
  resetToRoot: () => {},
});
export const useNestedMenu = () => useContext(NestedMenuContext);

export const NestedMenu = ({
  children,
  options,
}: {
  children: ReactNode | ((params: NestedMenuState) => JSX.Element);
  options: any[];
}) => {
  const isChildAFunction = typeof children === 'function';
  const root = options;
  const previous = useRef<any[][]>([]);
  const [branch, setBranch] = useState(options);
  const resetToRoot = () => {
    previous.current = [];
    setBranch(root);
  };
  const goBack = () => {
    const previousBranch = previous.current.pop() as any[];
    setBranch(previousBranch);
  };
  const changeBranch = (newBranch: any[]) => {
    previous.current.push(branch);
    setBranch(newBranch);
  };

  return (
    <NestedMenuContext.Provider
      value={{ branch, previous: previous.current, changeBranch, resetToRoot, goBack }}
    >
      {isChildAFunction
        ? (children as any)({
            branch,
            previous: previous.current,
            changeBranch,
            resetToRoot,
            goBack,
          })
        : children}
    </NestedMenuContext.Provider>
  );
};
