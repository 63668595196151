/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Animal,
    AnimalFromJSON,
    AnimalFromJSONTyped,
    AnimalToJSON,
} from './Animal';
import {
    AnimalTrait,
    AnimalTraitFromJSON,
    AnimalTraitFromJSONTyped,
    AnimalTraitToJSON,
} from './AnimalTrait';
import {
    ApproximateDate,
    ApproximateDateFromJSON,
    ApproximateDateFromJSONTyped,
    ApproximateDateToJSON,
} from './ApproximateDate';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';
import {
    OffspringGroupImage,
    OffspringGroupImageFromJSON,
    OffspringGroupImageFromJSONTyped,
    OffspringGroupImageToJSON,
} from './OffspringGroupImage';
import {
    VisibilityDisplayEnum,
    VisibilityDisplayEnumFromJSON,
    VisibilityDisplayEnumFromJSONTyped,
    VisibilityDisplayEnumToJSON,
} from './VisibilityDisplayEnum';
import {
    VisibilityEnum,
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * 
 * @export
 * @interface OffspringGroup
 */
export interface OffspringGroup {
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly url: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly path: string;
    /**
     * 
     * @type {Date}
     * @memberof OffspringGroup
     */
    readonly modified: Date;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly modifiedDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly viewUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly storeName: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly storeUrl: string;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroup
     */
    readonly key: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof OffspringGroup
     */
    readonly shareQueryParam: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly maxPhotosPerAd: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly offspringGroupType: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly categoryDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroup
     */
    readonly categoryId: number;
    /**
     * 
     * @type {Date}
     * @memberof OffspringGroup
     */
    layDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly layDateDisplay: string | null;
    /**
     * Total Weight - Grams
     * @type {number}
     * @memberof OffspringGroup
     */
    clutchTotalWeight?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OffspringGroup
     */
    readonly isEggLaying: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly eggsCountDisplay: string | null;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroup
     */
    nonViableEggsCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly nonViableEggsCountDisplay: string | null;
    /**
     * 
     * @type {number}
     * @memberof OffspringGroup
     */
    viableEggsCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly viableEggsCountDisplay: string | null;
    /**
     * 
     * @type {ApproximateDate}
     * @memberof OffspringGroup
     */
    birthDate?: ApproximateDate;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly birthDatePrefix: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly birthDateDisplay: string | null;
    /**
     * 
     * @type {Array<OffspringGroupImage>}
     * @memberof OffspringGroup
     */
    images: Array<OffspringGroupImage>;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof OffspringGroup
     */
    readonly thumbImage: Array<ImageVariants> | null;
    /**
     * 
     * @type {VisibilityDisplayEnum}
     * @memberof OffspringGroup
     */
    readonly visibilityDisplay: VisibilityDisplayEnum | null;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof OffspringGroup
     */
    visibility: VisibilityEnum;
    /**
     * 
     * @type {Array<Animal>}
     * @memberof OffspringGroup
     */
    dams?: Array<Animal>;
    /**
     * 
     * @type {Array<Animal>}
     * @memberof OffspringGroup
     */
    sires?: Array<Animal>;
    /**
     * 
     * @type {Array<Animal>}
     * @memberof OffspringGroup
     */
    offsprings?: Array<Animal>;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly childrenCountDisplay: string | null;
    /**
     * 
     * @type {Array<AnimalTrait>}
     * @memberof OffspringGroup
     */
    readonly traits: Array<AnimalTrait>;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    publicNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    privateNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffspringGroup
     */
    readonly damCrossSireOrChildrenTraits: string;
    /**
     * 
     * @type {boolean}
     * @memberof OffspringGroup
     */
    readonly canEdit: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OffspringGroup
     */
    clutchEstimatedHatchDate?: Date | null;
}

export function OffspringGroupFromJSON(json: any): OffspringGroup {
    return OffspringGroupFromJSONTyped(json, false);
}

export function OffspringGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffspringGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'path': json['path'],
        'modified': (new Date(json['modified'])),
        'modifiedDisplay': json['modified_display'],
        'viewUrl': json['view_url'],
        'storeName': json['store_name'],
        'storeUrl': json['store_url'],
        'key': json['key'],
        'shareQueryParam': json['share_query_param'],
        'groupId': json['group_id'],
        'maxPhotosPerAd': json['max_photos_per_ad'],
        'offspringGroupType': json['offspring_group_type'],
        'categoryDisplay': json['category_display'],
        'category': json['category'],
        'categoryId': json['category_id'],
        'layDate': !exists(json, 'lay_date') ? undefined : (json['lay_date'] === null ? null : new Date(json['lay_date'])),
        'layDateDisplay': json['lay_date_display'],
        'clutchTotalWeight': !exists(json, 'clutch_total_weight') ? undefined : json['clutch_total_weight'],
        'isEggLaying': json['is_egg_laying'],
        'eggsCountDisplay': json['eggs_count_display'],
        'nonViableEggsCount': !exists(json, 'non_viable_eggs_count') ? undefined : json['non_viable_eggs_count'],
        'nonViableEggsCountDisplay': json['non_viable_eggs_count_display'],
        'viableEggsCount': !exists(json, 'viable_eggs_count') ? undefined : json['viable_eggs_count'],
        'viableEggsCountDisplay': json['viable_eggs_count_display'],
        'birthDate': !exists(json, 'birth_date') ? undefined : ApproximateDateFromJSON(json['birth_date']),
        'birthDatePrefix': json['birth_date_prefix'],
        'birthDateDisplay': json['birth_date_display'],
        'images': ((json['images'] as Array<any>).map(OffspringGroupImageFromJSON)),
        'thumbImage': (json['thumb_image'] === null ? null : (json['thumb_image'] as Array<any>).map(ImageVariantsFromJSON)),
        'visibilityDisplay': VisibilityDisplayEnumFromJSON(json['visibility_display']),
        'visibility': VisibilityEnumFromJSON(json['visibility']),
        'dams': !exists(json, 'dams') ? undefined : ((json['dams'] as Array<any>).map(AnimalFromJSON)),
        'sires': !exists(json, 'sires') ? undefined : ((json['sires'] as Array<any>).map(AnimalFromJSON)),
        'offsprings': !exists(json, 'offsprings') ? undefined : ((json['offsprings'] as Array<any>).map(AnimalFromJSON)),
        'childrenCountDisplay': json['children_count_display'],
        'traits': ((json['traits'] as Array<any>).map(AnimalTraitFromJSON)),
        'publicNotes': !exists(json, 'public_notes') ? undefined : json['public_notes'],
        'privateNotes': !exists(json, 'private_notes') ? undefined : json['private_notes'],
        'damCrossSireOrChildrenTraits': json['dam_cross_sire_or_children_traits'],
        'canEdit': json['can_edit'],
        'clutchEstimatedHatchDate': !exists(json, 'clutch_estimated_hatch_date') ? undefined : (json['clutch_estimated_hatch_date'] === null ? null : new Date(json['clutch_estimated_hatch_date'])),
    };
}

export function OffspringGroupToJSON(value?: OffspringGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.groupId,
        'category': value.category,
        'lay_date': value.layDate === undefined ? undefined : (value.layDate === null ? null : value.layDate.toISOString().substr(0,10)),
        'clutch_total_weight': value.clutchTotalWeight,
        'non_viable_eggs_count': value.nonViableEggsCount,
        'viable_eggs_count': value.viableEggsCount,
        'birth_date': ApproximateDateToJSON(value.birthDate),
        'images': ((value.images as Array<any>).map(OffspringGroupImageToJSON)),
        'visibility': VisibilityEnumToJSON(value.visibility),
        'dams': value.dams === undefined ? undefined : ((value.dams as Array<any>).map(AnimalToJSON)),
        'sires': value.sires === undefined ? undefined : ((value.sires as Array<any>).map(AnimalToJSON)),
        'offsprings': value.offsprings === undefined ? undefined : ((value.offsprings as Array<any>).map(AnimalToJSON)),
        'public_notes': value.publicNotes,
        'private_notes': value.privateNotes,
        'clutch_estimated_hatch_date': value.clutchEstimatedHatchDate === undefined ? undefined : (value.clutchEstimatedHatchDate === null ? null : value.clutchEstimatedHatchDate.toISOString().substr(0,10)),
    };
}

