/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { MmNativeAppApi } from "../sdk/apis/MmNativeAppApi";
import { configuration } from "./configuration";

export const useMmNativeApp = () => {
  const instance = new MmNativeAppApi(configuration);

  const createNativeAppReviewsAppReview = useAPIHook(instance.mmNativeAppReviewsAppReviewCreate.bind(instance));

  return {
    error: createNativeAppReviewsAppReview.error,
    isLoading: createNativeAppReviewsAppReview.isLoading,

    createNativeAppReviewsAppReview: createNativeAppReviewsAppReview.callback,
  };
};
