import { HTMLAttributes } from 'react';
import { faCaretDown, faCaretRight, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import styles from './SettingsMenuItems.module.scss';

export type AccordionMenuButtonProps = HTMLAttributes<HTMLButtonElement> & {
  name?: string;
  icon: IconDefinition;
  isOpen?: boolean;
  tooltipText?: string;
  isOpenSidebar?: boolean;
};

export const AccordionMenuButton: React.FC<AccordionMenuButtonProps> = ({
  children,
  name,
  isOpen,
  isOpenSidebar,
  icon,
  tooltipText,
  ...props
}) => {
  return (
    <button tabIndex={0} role="menuitem" className={styles.root} {...props}>
      <span className={styles.prefix}>
        <Icon icon={icon} data-tip={!isOpenSidebar && tooltipText ? tooltipText : null} />
      </span>
      <span className={styles.label}>{children || name}</span>
      <span className={styles.suffix}>
        <Icon icon={isOpen ? faCaretDown : faCaretRight} />
      </span>
    </button>
  );
};
