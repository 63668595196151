import { useMemo } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import styles from './Highlighted.module.scss';

export type HighlightedProps = {
  children: string;
  highlight?: string;
  className?: string;
};

/**
 * Wraps all occurences of pattern in text with <mark>.
 */
export const highlightMatches = (text: string, pattern: string) => {
  const regExp = new RegExp(escapeRegExp(pattern), 'ig');

  return text
    .replace(regExp, (substr) => `___${substr}___`)
    .split('___')
    .map((piece, index) => {
      const textWithoutSpecialChars = piece.replace(/[^a-zA-Z0-9\s]/g, '');

      const isMatchWithSpecial = textWithoutSpecialChars.match(regExp);
      const isMatchWithoutSpecial = piece.match(regExp);

      return isMatchWithSpecial || isMatchWithoutSpecial ? (
        <mark key={index}>{piece}</mark>
      ) : (
        <span key={index}>{piece}</span>
      );
    });
};

/**
 * Highlights all occurences of prop highlight in children.
 */
export const Highlighted: React.FC<HighlightedProps> = ({
  children,
  highlight = '',
  className = styles.highlighted,
}) => {
  const pieces = useMemo(
    () => (!!highlight ? highlightMatches(children, highlight) : children),
    [children, highlight]
  );
  return <span className={className}>{pieces}</span>;
};
