import FlexSearch from 'flexsearch';
import { traits, EnumsBucket } from 'constants/enums';
import { CATEGORY_ID } from 'constants/index';
import { fetchStores } from 'api/enums';
import {
  MIN_QUERY_LENGTH,
  FLEXSEARCH_OPTIONS,
  TYPES,
  RESULTS_LIMIT,
  TRAITS_RESULTS_LIMIT,
  CATEGORY_RESULTS_LIMIT,
  STORES_RESULTS_LIMIT,
} from './constants';
import {
  formatTraits,
  formatCategories,
  filterByType,
  formatStores,
  filterByCategory,
  buildCategoryTree,
  flattenTree,
  branchesToId,
  sortStores,
} from './helpers/data';

const { category_menu_choices_with_root } = EnumsBucket;
const tree = buildCategoryTree(category_menu_choices_with_root);
const branches = flattenTree(tree);
const valid = branchesToId(branches);
const flexSearch = FlexSearch.create(FLEXSEARCH_OPTIONS);

void fetchStores().then((stores) => {
  flexSearch
    .add(formatCategories(category_menu_choices_with_root))
    .add(formatTraits(traits, category_menu_choices_with_root))
    .add(formatStores(stores));
});

/**
 * Internal hook for FlexSearch, to avoid polluting useTextSearch too much. Given a query string,
 * this hook returns category, store and trait matches.
 */
export const useFlexSearch = (query?: string) => {
  const category = parseInt(CATEGORY_ID, 10);

  if (!query || query.length < MIN_QUERY_LENGTH) {
    return {
      length: 0,
      categories: [],
      traits: [],
      stores: [],
    };
  } else {
    const results = flexSearch.search({
      field: ['scientific_name', 'name', 'owner'],
      query: decodeURIComponent(query),
      limit: RESULTS_LIMIT,
    });
    const categories = filterByCategory(
      filterByType(results, TYPES.CATEGORY),
      valid[category]
    ).slice(0, CATEGORY_RESULTS_LIMIT);
    const traits = filterByCategory(filterByType(results, TYPES.TRAIT), valid[category]).slice(
      0,
      TRAITS_RESULTS_LIMIT
    );
    const stores = filterByType(results, TYPES.STORE)
      .sort(sortStores(query))
      .slice(0, STORES_RESULTS_LIMIT);

    return {
      categories,
      traits,
      stores,
    };
  }
};
