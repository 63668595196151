import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gold400 } = colors;

export const MuiMenuItem: Components<Omit<Theme, 'components'>>['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      padding: '6px 24px 6px 24px',
      lineHeight: 1.6,
      minHeight: 0,
      transition: 'background-color 0.2s',
      '&.Mui-selected': {
        backgroundColor: gold400,
      },
    },
  },
};
