import * as Sentry from '@sentry/browser';
import { API_URL } from 'api/config';

export type Options = Partial<Omit<RequestInit, 'body'>> & {
  body?: Record<string, any> | any[];
  params?: Record<string, string> | string[][];
};
export type APICall = (url: string, options?: Options, func?: typeof fetch) => Promise<Response>;

export class APIError extends Error {
  status: number;
  statusText: string;
  response: Response;

  constructor(status: number, statusText: string, response: Response) {
    super(`${status}: ${statusText}`);

    this.name = 'APIError';
    this.status = status;
    this.statusText = statusText;
    this.response = response;
  }
}

export const fetchAPI: APICall = async (url, options = {}, func = fetch) => {
  const isAbsolute = /^([a-z]+:)?\/\//i.test(url);
  const isLocal = url.startsWith(API_URL);
  const base = !isAbsolute && !isLocal ? `${API_URL}${url}` : url;
  const params = options?.params && new URLSearchParams(options.params).toString();
  const request = base + (params ? `?${params}` : '');

  const defaults: RequestInit = {
    credentials: 'same-origin', // It's not a default in Safari 11 or older
  };

  const optionsWithDefaults = {
    ...defaults,
    ...options,
    body: JSON.stringify(options.body),
  };

  const response = await func(request, optionsWithDefaults);

  if (!response.ok) {
    const { status, statusText } = response;
    throw new APIError(status, statusText, response);
  }

  return response;
};

export const get: APICall = (url, options, func) =>
  fetchAPI(url, { ...options, method: 'GET' }, func);

export const put: APICall = (url, options, func) =>
  fetchAPI(
    url,
    {
      ...options,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': window.CSRF_TOKEN },
    },
    func
  );

export const post: APICall = (url, options, func) =>
  fetchAPI(
    url,
    {
      ...options,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': window.CSRF_TOKEN },
    },
    func
  );

export const patch: APICall = (url, options, func) =>
  fetchAPI(
    url,
    {
      ...options,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': window.CSRF_TOKEN },
    },
    func
  );

// We can't use delete because it's a reserved keyword in Javacript
export const del: APICall = (url, options, func) =>
  fetchAPI(
    url,
    {
      ...options,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': window.CSRF_TOKEN },
    },
    func
  );

export const parseApiError = async (payload: APIError) => {
  try {
    const error = await payload.response?.clone()?.json();
    return error;
  } catch (e) {
    Sentry.captureException(e);
    return {
      message: `Error: Received invalid JSON response: message=${payload.message} status=${payload.status} name=${payload.name} statusText=${payload.response.statusText}`,
    };
  }
};
