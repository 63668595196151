// in routes.tsx, we have two paths:
// /users/:username/collection and /users/:username/label_settings
// those are react routes but /users/:username is not a react route
// so we need to exclude /users/:username from react routes
// should be removed when we get rid of django view

export const excludeUserProfile = (path: string) => {
  if (
    path.startsWith('/users') &&
    !path.includes('collection') &&
    !path.includes('label_settings')
  ) {
    return false;
  }
  return true;
};
