import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { TRANSFERRED_FROM_ORDERING } from 'api/inventory/ordering';

export const TRANSFERRED_FROM_COLUMN = Symbol('TRANSFERRED_FROM_COLUMN');

const transferredFromColumn = {
  id: TRANSFERRED_FROM_COLUMN,
  ordering: TRANSFERRED_FROM_ORDERING,
  label: 'Purchased From',
  hideable: true,
  className: style.transferredFromColumn,

  render: ({ transferred_from }: { transferred_from: string }) => transferred_from,
};

export default transferredFromColumn;
