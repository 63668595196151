/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OtherParty
 */
export interface OtherParty {
    /**
     * 
     * @type {string}
     * @memberof OtherParty
     */
    readonly key: string;
    /**
     * 
     * @type {string}
     * @memberof OtherParty
     */
    readonly name: string;
}

export function OtherPartyFromJSON(json: any): OtherParty {
    return OtherPartyFromJSONTyped(json, false);
}

export function OtherPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
    };
}

export function OtherPartyToJSON(value?: OtherParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

