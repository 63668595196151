export const EXPO_COLUMN = Symbol('EXPO_COLUMN');

import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import TagsList from 'components/Inventory/TagsList/TagsList';
import { EXPO_ORDERING } from 'api/inventory/ordering';

interface ExpoColumnProps {
  events_with_detail: {
    id: number;
    name: string;
  }[];
}

const expoColumn = {
  id: EXPO_COLUMN,
  ordering: EXPO_ORDERING,
  label: 'Expo Availability',
  hideable: true,
  className: style.expoColumn,

  render: ({ events_with_detail }: ExpoColumnProps) => {
    if (events_with_detail.length > 0 && events_with_detail[0]?.id) {
      return <TagsList tags={events_with_detail} />;
    }
    return null;
  },
};

export default expoColumn;
