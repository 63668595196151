import { CreateOptions } from 'flexsearch';

// Minimum lengh of search query before running search
export const MIN_QUERY_LENGTH = 2;
export const RESULTS_LIMIT = 100;
export const TRAITS_RESULTS_LIMIT = 10;
export const CATEGORY_RESULTS_LIMIT = 3;
export const STORES_RESULTS_LIMIT = 3;
export const FLEXSEARCH_OPTIONS: CreateOptions = {
  async: false,
  tokenize: 'forward',
  doc: {
    id: 'id',
    field: ['owner', 'name', 'scientific_name'],
  },
  encode: (input) => {
    return input.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase();
  },
};

export const TYPES = {
  CATEGORY: 'category',
  TRAIT: 'trait',
  STORE: 'store',
} as const;
