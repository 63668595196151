// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fadedImage--eEEFQ{display:inline-block;opacity:0;transition:opacity .5s}.loaded--uXJO8{opacity:1}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Image/Image.module.scss"],"names":[],"mappings":"AAAA,mBACE,oBAAA,CACA,SAAA,CACA,sBAAA,CAGF,eACE,SAAA","sourcesContent":[".fadedImage {\n  display: inline-block; /* Missing images don't respect dimensions otherwise */\n  opacity: 0;\n  transition: opacity 0.5s;\n}\n\n.loaded {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fadedImage": `fadedImage--eEEFQ`,
	"loaded": `loaded--uXJO8`
};
export default ___CSS_LOADER_EXPORT___;
