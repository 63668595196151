import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Box, List, ListItem, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import ColumnTooltip from 'components/Inventory/Columns/ColumnTooltip/ColumnTooltip';
import { ActivityTypeEnum, AnimalManagerHusbandryView, FeedingItem } from 'api/sdk';
import { INQUIRY_DATE_FORMAT } from 'services/constants';
import { camelCaseKeys } from '../../../../../../src/js/utils';
import styles from './styles.module.scss';

export const FEEDING_ACTIVITY = Symbol('FEEDING_ACTIVITY');

// unknown is returned when user selects N/A as a value
const replaceUnknown = (str?: string): string => {
  if (!str) return '';
  return str.toLowerCase() === 'unknown' ? '' : str;
};

const prepareOneFeederString = (feederItem: FeedingItem): string => {
  const preyRefused = feederItem?.preyRefused ? 'Refused' : '';
  const [preySize, preyState, preyFood] = [
    feederItem?.preySize,
    feederItem?.preyState,
    feederItem?.preyFood,
  ].map((item) => startCase(replaceUnknown(item)));
  const preyCount = feederItem?.preyCount ?? `${feederItem.preyCount}`;
  const detailsArray = [preyRefused, preyCount, preySize, preyState, preyFood].filter(Boolean);
  return detailsArray.join(' ');
};

const AdditionalFeedingItems: FC<{ feedingItems: string[] }> = ({ feedingItems }) => (
  <List sx={{ listStyleType: 'none', listStylePosition: 'inside' }}>
    {feedingItems.map((item, index) => (
      <ListItem key={index} sx={{ display: 'list-item' }}>
        {item}
      </ListItem>
    ))}
  </List>
);

const FeedingColumn = {
  id: FEEDING_ACTIVITY,
  label: 'Feeding',
  hideable: true,
  disableOrdering: true,
  render: ({ activities }: AnimalManagerHusbandryView) => {
    const camelCasedActivities = activities.map((activity) => camelCaseKeys(activity));
    const latest = camelCasedActivities.find(
      (activity) => activity.activityType === ActivityTypeEnum.Feeding
    );

    if (!latest) return <div>-</div>;

    const date = latest?.activityDate ? dayjs(latest.activityDate).format(INQUIRY_DATE_FORMAT) : '';

    const [firstFeedingLine, ...remainingFeedingLines] = latest?.feederItems
      ? latest?.feederItems?.map(prepareOneFeederString)
      : [''];

    return (
      <div className={styles.defaultColumn}>
        <span className={styles.value}>
          {remainingFeedingLines.length > 1 ? (
            <Tooltip
              arrow={true}
              title={<AdditionalFeedingItems feedingItems={remainingFeedingLines} />}
            >
              <Box>
                {firstFeedingLine} (+{remainingFeedingLines.length})
              </Box>
            </Tooltip>
          ) : (
            <Box>{firstFeedingLine}</Box>
          )}
          {latest?.privateNotes && <ColumnTooltip text={latest.privateNotes} icon={faInfoCircle} />}
        </span>
        {date && <span className={styles.date}>{date}</span>}
      </div>
    );
  },
};

export default FeedingColumn;
