import style from 'components/Offspring/OffspringTable/OffspringTable.module.scss';
import { BIRTH_DATE_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const BIRTH_DATE_COLUMN = Symbol('BIRTH_DATE_COLUMN');

const birthDateColumn = {
  id: BIRTH_DATE_COLUMN,
  ordering: BIRTH_DATE_ORDERING,
  label: 'Date of Birth',
  shortLabel: 'Birth',
  hideable: true,
  className: style.dateColumn,
  defaultDirection: DESCENDING,

  render: ({ birth_date_display }) => birth_date_display,
};

export default birthDateColumn;
