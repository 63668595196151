import { SymbolIndex } from '../../../../types';

export const TRANSFER_FILTER = Symbol('TRANSFER_FILTER');

export const TRANSFER_TRANSFERRED = Symbol('TRANSFER_TRANSFERRED');
export const TRANSFER_NOT_TRANSFERRED = Symbol('TRANSFER_NOT_TRANSFERRED');

export const transferNames: SymbolIndex = {
  [TRANSFER_TRANSFERRED]: 'Purchased',
  [TRANSFER_NOT_TRANSFERRED]: 'Not Purchased',
};

// Ordered options
export const transferOptions = [TRANSFER_FILTER, TRANSFER_TRANSFERRED, TRANSFER_NOT_TRANSFERRED];
