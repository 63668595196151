import { createContext, useState } from 'react';

export type SidebarContextValues = {
  disableSidebar: boolean;
  actions: {
    setDisableSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const SidebarContext = createContext<SidebarContextValues>({
  disableSidebar: false,
  actions: {
    setDisableSidebar: () => {},
  },
});

export const SidebarContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [disableSidebar, setDisableSidebar] = useState<boolean>(false);

  const contextValues = {
    disableSidebar,
    actions: {
      setDisableSidebar,
    },
  };

  return <SidebarContext.Provider value={contextValues}>{children}</SidebarContext.Provider>;
};
