import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, IconButton, IconButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mmNewMessages } from 'components/experimental/IconDefinitions/mmNewMessages';
import { useAuth } from 'hooks';
import { isReactRoute } from 'routes/utils/routeUtils';
import styles from './MessengerButton.module.scss';

type MessagesIndicatorProps = BoxProps & {
  count?: number;
};

const MessagesIndicator: React.FC<MessagesIndicatorProps> = ({ count, ...props }) => {
  const truncatedCount = !!count && count > 99 ? '99+' : count;

  return !!count ? (
    <Box className={styles.indicator} {...props}>
      {truncatedCount}
    </Box>
  ) : null;
};

export const MessengerButton: React.FC<IconButtonProps> = () => {
  const { unseenMessages, user } = useAuth();
  const isReact = isReactRoute(window.location.pathname);
  const navigate = useNavigate();

  if (isReact) {
    return (
      <IconButton
        aria-label={`Go to Messenger (${unseenMessages} unread messages)`}
        onClick={() => navigate(user?.urls.messageInbox as string)}
        component="span"
        sx={{ fontSize: 24, padding: 0 }}
      >
        <FontAwesomeIcon icon={mmNewMessages} />
        {!!unseenMessages && (
          <MessagesIndicator count={unseenMessages} sx={{ top: '-10%', right: '-20%' }} />
        )}
      </IconButton>
    );
  }

  return (
    <IconButton
      href={user?.urls?.messageInbox}
      component="a"
      sx={{ fontSize: 24, padding: 0 }}
      aria-label={`Go to Messenger (${unseenMessages} unread messages)`}
    >
      <FontAwesomeIcon icon={mmNewMessages} />
      {!!unseenMessages && (
        <MessagesIndicator count={unseenMessages} sx={{ top: '-10%', right: '-20%' }} />
      )}
    </IconButton>
  );
};
