/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AbstractUser,
  AbstractUserFromJSON,
  AbstractUserToJSON,
  AnonymousUser,
  AnonymousUserFromJSON,
  AnonymousUserToJSON,
  PatchedUserModelPreferencesRequest,
  PatchedUserModelPreferencesRequestFromJSON,
  PatchedUserModelPreferencesRequestToJSON,
  UserModelPreferences,
  UserModelPreferencesFromJSON,
  UserModelPreferencesToJSON,
  UserPaymentInfo,
  UserPaymentInfoFromJSON,
  UserPaymentInfoToJSON,
} from "../models";

export interface AccountPreferencesPartialUpdateRequest {
  /** @deprecated Use request instead. **/
  patchedUserModelPreferencesRequest?: PatchedUserModelPreferencesRequest;
  request?: PatchedUserModelPreferencesRequest;
}

/**
 *
 */
export class AccountApi extends runtime.BaseAPI {
  /**
   */
  async accountAuctionConsentCreateRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AnonymousUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/account/auction_consent/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AnonymousUserFromJSON(jsonValue));
  }

  /**
   */
  async accountAuctionConsentCreate(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AnonymousUser> {
    const response = await this.accountAuctionConsentCreateRaw(initOverrides);
    return await response.value();
  }

  /**
   * This endpoint returns information pertaining to the user when the user is logged in.  When the user is not logged in, it returns \"successfully\" with 202. The FE uses the response to determine that it is not logged in, doing so in a way that is decoupled from the HTML produced by Django.
   */
  async accountMeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AbstractUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/account/me/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AbstractUserFromJSON(jsonValue));
  }

  /**
   * This endpoint returns information pertaining to the user when the user is logged in.  When the user is not logged in, it returns \"successfully\" with 202. The FE uses the response to determine that it is not logged in, doing so in a way that is decoupled from the HTML produced by Django.
   */
  async accountMeRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AbstractUser> {
    const response = await this.accountMeRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * This endpoint is separate from the `me` endpoint because it runs some additional joins that are unnecessary (and expensive for perf) in most situations.
   */
  async accountPaymentInfoRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserPaymentInfo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/account/payment_info/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserPaymentInfoFromJSON(jsonValue));
  }

  /**
   * This endpoint is separate from the `me` endpoint because it runs some additional joins that are unnecessary (and expensive for perf) in most situations.
   */
  async accountPaymentInfoRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserPaymentInfo> {
    const response = await this.accountPaymentInfoRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async accountPreferencesPartialUpdateRaw(
    requestParameters: AccountPreferencesPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserModelPreferences>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/account/preferences/`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedUserModelPreferencesRequestToJSON(requestParameters.request || requestParameters.patchedUserModelPreferencesRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserModelPreferencesFromJSON(jsonValue));
  }

  /**
   */
  async accountPreferencesPartialUpdate(
    requestParameters: AccountPreferencesPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserModelPreferences> {
    const response = await this.accountPreferencesPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
