/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponsivenessRatingSummary,
    ResponsivenessRatingSummaryFromJSON,
    ResponsivenessRatingSummaryFromJSONTyped,
    ResponsivenessRatingSummaryToJSON,
} from './ResponsivenessRatingSummary';

/**
 * 
 * @export
 * @interface ResponsivenessRatingRating
 */
export interface ResponsivenessRatingRating {
    /**
     * 
     * @type {string}
     * @memberof ResponsivenessRatingRating
     */
    verb: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsivenessRatingRating
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsivenessRatingRating
     */
    minValue: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsivenessRatingRating
     */
    color: string;
}

export function ResponsivenessRatingRatingFromJSON(json: any): ResponsivenessRatingRating {
    return ResponsivenessRatingRatingFromJSONTyped(json, false);
}

export function ResponsivenessRatingRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponsivenessRatingRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verb': json['verb'],
        'name': json['name'],
        'minValue': json['min_value'],
        'color': json['color'],
    };
}

export function ResponsivenessRatingRatingToJSON(value?: ResponsivenessRatingRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verb': value.verb,
        'name': value.name,
        'min_value': value.minValue,
        'color': value.color,
    };
}

