/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnimalSex,
    AnimalSexFromJSON,
    AnimalSexFromJSONTyped,
    AnimalSexToJSON,
} from './AnimalSex';
import {
    AnimalTrait,
    AnimalTraitFromJSON,
    AnimalTraitFromJSONTyped,
    AnimalTraitToJSON,
} from './AnimalTrait';
import {
    ApproximateDate,
    ApproximateDateFromJSON,
    ApproximateDateFromJSONTyped,
    ApproximateDateToJSON,
} from './ApproximateDate';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';
import {
    LiteAnimal,
    LiteAnimalFromJSON,
    LiteAnimalFromJSONTyped,
    LiteAnimalToJSON,
} from './LiteAnimal';
import {
    VisibilityEnum,
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * 
 * @export
 * @interface BreedingPairingDetailsSire
 */
export interface BreedingPairingDetailsSire {
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsSire
     */
    key?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    readonly path: string;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof BreedingPairingDetailsSire
     */
    readonly thumbImage: Array<ImageVariants> | null;
    /**
     * 
     * @type {ApproximateDate}
     * @memberof BreedingPairingDetailsSire
     */
    birthDate?: ApproximateDate;
    /**
     * 
     * @type {AnimalSex}
     * @memberof BreedingPairingDetailsSire
     */
    sex: AnimalSex | null;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    readonly serial: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    description?: string | null;
    /**
     * 
     * @type {Array<AnimalTrait>}
     * @memberof BreedingPairingDetailsSire
     */
    traits: Array<AnimalTrait>;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    readonly categoryDisplay: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsSire
     */
    category: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsSire
     */
    readonly categoryShort: string;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof BreedingPairingDetailsSire
     */
    visibility: VisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BreedingPairingDetailsSire
     */
    readonly isGravid: boolean;
}

export function BreedingPairingDetailsSireFromJSON(json: any): BreedingPairingDetailsSire {
    return BreedingPairingDetailsSireFromJSONTyped(json, false);
}

export function BreedingPairingDetailsSireFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingDetailsSire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'path': json['path'],
        'thumbImage': (json['thumb_image'] === null ? null : (json['thumb_image'] as Array<any>).map(ImageVariantsFromJSON)),
        'birthDate': !exists(json, 'birth_date') ? undefined : ApproximateDateFromJSON(json['birth_date']),
        'sex': AnimalSexFromJSON(json['sex']),
        'serial': json['serial'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'traits': ((json['traits'] as Array<any>).map(AnimalTraitFromJSON)),
        'categoryDisplay': json['category_display'],
        'category': json['category'],
        'categoryShort': json['category_short'],
        'visibility': VisibilityEnumFromJSON(json['visibility']),
        'isGravid': json['is_gravid'],
    };
}

export function BreedingPairingDetailsSireToJSON(value?: BreedingPairingDetailsSire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'birth_date': ApproximateDateToJSON(value.birthDate),
        'sex': AnimalSexToJSON(value.sex),
        'title': value.title,
        'description': value.description,
        'traits': ((value.traits as Array<any>).map(AnimalTraitToJSON)),
        'category': value.category,
        'visibility': VisibilityEnumToJSON(value.visibility),
    };
}

