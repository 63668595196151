import { generateSingletonHook } from 'context/SingletonContext';
import { useFollowedStores as useFollowedStoresAPI } from 'api/generated/useFollowedStores';

const useDecoratedHook = () => {
  const unwrapped = useFollowedStoresAPI();
  return {
    unwrapped,
    isLoading: unwrapped.isLoading,
    createStores: unwrapped.createStores,
    destroyStores: unwrapped.destroyStores,
    error: unwrapped.error,
  };
};

const [FollowedStoresProvider, useFollowedStores] = generateSingletonHook(useDecoratedHook);
export { FollowedStoresProvider, useFollowedStores };
