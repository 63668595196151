/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';

/**
 * 
 * @export
 * @interface AdImage
 */
export interface AdImage {
    /**
     * 
     * @type {number}
     * @memberof AdImage
     */
    readonly id: number;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof AdImage
     */
    readonly urls: Array<ImageVariants> | null;
    /**
     * 
     * @type {string}
     * @memberof AdImage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof AdImage
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof AdImage
     */
    caption?: string;
}

export function AdImageFromJSON(json: any): AdImage {
    return AdImageFromJSONTyped(json, false);
}

export function AdImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'urls': (json['urls'] === null ? null : (json['urls'] as Array<any>).map(ImageVariantsFromJSON)),
        'image': !exists(json, 'image') ? undefined : json['image'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
    };
}

export function AdImageToJSON(value?: AdImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
        'priority': value.priority,
        'caption': value.caption,
    };
}

