import React, { ReactNode, useEffect, useLayoutEffect } from 'react';
import { Suspense } from 'react';
import classNames from 'classnames';
import {
  useMatch,
  Outlet,
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { IS_AUTHENTICATED, OMIT_NAVIGATION_SHELL } from 'constants/index';
import { Navbar } from 'components/experimental/Navigation';
import UtilityNavDesktop from 'components/experimental/Navigation/desktop/UtilityNavDesktop/UtilityNavDesktop';
import UtilityNavMobile from 'components/experimental/Navigation/mobile/UtilityNavMobile';
import { Sidebar } from 'components/experimental/Sidebar';
import { PreloaderSpinner } from 'components/PreloaderSpinner/PreloaderSpinner';
import ToastAndModalRenderer from 'components/ToastAndModalRenderer/ToastAndModalRenderer';
import { AllGlobalContexts } from 'context/AllGlobalContexts';
import { useSnackbar } from 'context/SnackbarContext';
import { useWindowSize } from 'hooks';
import { GtmRouteTracker } from 'hooks/utils/useGtm';
import { UserStore } from 'core/UserStore';
import { ErrorBoundary } from 'ErrorBoundary';
import { routes } from 'routes/routes';
import { isReactRoute } from 'routes/utils/routeUtils';

import style from './MainApp.module.scss';

const ScrollWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

const Layout: React.FC = () => {
  const isMessengerPage = window.location.pathname.startsWith('/messages');
  // To get to this point, the page already has to be a React page
  // However, the NotFoundPage is not in the routes list (because it's the "default" route)
  // So if it _is_ a React page, but is not in the list of routes, it can only be the NotFoundPage
  const isNotFoundPage = !isReactRoute(window.location.pathname);
  if (OMIT_NAVIGATION_SHELL) {
    return <Outlet />;
  }

  return (
    <>
      <Navbar />
      {!isNotFoundPage && <UtilityNavMobile />}
      {!isNotFoundPage && <UtilityNavDesktop />}
      <div className={isMessengerPage ? style.flexGrow : ''}>
        <div className={classNames(style.main, 'mainWrapper')}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

const MainApp = () => {
  const { isMobile } = useWindowSize();
  const { TheSnackbar } = useSnackbar();

  useEffect(() => {
    const URL = window.location.toString();

    if (URL.includes('reptile-expos') || URL.includes('inventory')) {
      UserStore.load();
    }
  }, []);

  const isInventory = !!useMatch('/inventory/*');
  const isNewMessenger = !!useMatch('/messages/*');

  return (
    <>
      <GtmRouteTracker />
      <ToastAndModalRenderer className={isInventory ? style.inventoryToasts : undefined} />
      <div
        id="mainApp"
        className={classNames(style.app, {
          [style.mobile]: isMobile,
          [style.withAuthedFooter]: !!IS_AUTHENTICATED && !isNewMessenger,
          [style.withoutFooter]: !!IS_AUTHENTICATED && isNewMessenger,
        })}
      >
        <Suspense fallback={<PreloaderSpinner />}>
          {!OMIT_NAVIGATION_SHELL && <Sidebar />}
          <TheSnackbar />
          <ScrollWrapper>
            <Layout />
          </ScrollWrapper>
        </Suspense>
      </div>
    </>
  );
};

// The double ErrorBoundary is because we still want to render
// the layout if an inner page throws an error.
const router = createBrowserRouter([
  {
    path: '/',
    element: <MainApp />,
    children: [
      {
        path: '',
        children: routes,
        ErrorBoundary: ErrorBoundary,
      },
    ],
    ErrorBoundary: ErrorBoundary,
  },
]);

export default () => {
  return (
    <React.StrictMode>
      <AllGlobalContexts>
        <RouterProvider router={router} />
      </AllGlobalContexts>
    </React.StrictMode>
  );
};
