import HumanReadableError from 'errors/HumanReadableError';
import { useMmNativeApp } from 'api/generated/useMmNativeApp';
import { useToaster } from 'hooks';
import { reactNativeAPIs } from 'services/mmAppAPIs';

interface UseHandleSubmitProps {
  rating: number;
  review: string;
  platform: string;
  isRated: boolean;
  showRateInAppStore: boolean;
  actions: {
    closeModal: () => void;
    handleDisableSubmit: () => boolean;
    setTouched: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRated: React.Dispatch<React.SetStateAction<boolean>>;
    setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const useHandleAppReviewSubmit = ({
  rating,
  review,
  platform,
  isRated,
  showRateInAppStore,
  actions: { closeModal, handleDisableSubmit, setSubmitted, setTouched, setIsRated },
}: UseHandleSubmitProps) => {
  const { createNativeAppReviewsAppReview, isLoading, error } = useMmNativeApp();
  const { sendHandleRatingPromptMessageToReactNative, MM_APP_RATING_EVENTS } = reactNativeAPIs;
  const { showError } = useToaster();

  const handleShowRateInAppStore = () => {
    sendHandleRatingPromptMessageToReactNative(MM_APP_RATING_EVENTS.USER_WANTS_TO_RATE_APP);
    closeModal();
    return;
  };

  // handleSubmit used for both rating in first modal page and review submission/in-app rating.
  // Submit behavior is different based on the user action and is achieved by guarding the logic with conditions.
  const handleSubmit = async () => {
    setTouched(true);

    if (!handleDisableSubmit()) return;

    // This is to track if the user has performed the rating action in first page of modal.
    if (rating && !isRated) {
      setIsRated(true);
      return;
    }

    if (showRateInAppStore) {
      handleShowRateInAppStore();
      return;
    }

    const payload = {
      review,
      rating,
      platform,
    };

    let response;
    let errorMessage;
    let apiErrorOccurred;
    try {
      response = await createNativeAppReviewsAppReview({
        request: payload,
      });
    } catch (error) {
      apiErrorOccurred = true;
      const isReadable = error instanceof HumanReadableError;
      errorMessage = isReadable && error.displayMessage;
      console.error('Failed to submit review!', error);
    } finally {
      if (!response || !!apiErrorOccurred) {
        const toastErrorMessage =
          errorMessage ||
          'Something went wrong, please try again after a minute or contact support if the issue persists.';
        showError(toastErrorMessage);
        return;
      }
      setSubmitted(true);
      closeModal();
    }
  };

  return { isLoading, error, handleSubmit };
};
