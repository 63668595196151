import { createContext, useState } from 'react';
import { useOpenState } from 'hooks/utils/useOpenState';

export type TabsContextType = {
  activeTabPath: string;
  tabClicked: boolean;
  actions: {
    triggerTabClick: () => void;
    setActiveTabPath: React.Dispatch<React.SetStateAction<string>>;
  };
};

export const TabsContext = createContext<TabsContextType>({
  activeTabPath: '',
  tabClicked: false,
  actions: { setActiveTabPath: () => {}, triggerTabClick: () => {} },
});

export const TabsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeTabPath, setActiveTabPath] = useState<string>('');
  const { toggle: triggerTabClick, isOpen: tabClicked } = useOpenState();
  const contextValues = {
    activeTabPath,
    tabClicked,
    actions: { setActiveTabPath, triggerTabClick },
  };

  return <TabsContext.Provider value={contextValues}>{children}</TabsContext.Provider>;
};
