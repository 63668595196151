import DiodeIcon from 'components/Icons/DiodeIcon/DiodeIcon';
import { ActiveEnum } from 'api/sdk';

const isActiveNames = {
  [ActiveEnum.Active]: 'Active',
  [ActiveEnum.Inactive]: 'Inactive',
  [ActiveEnum.Expired]: 'Expired',
};

export type IsActiveIconProps = { isActive: ActiveEnum };
const IsActiveIcon: React.FC<IsActiveIconProps> = ({ isActive, ...props }) => (
  <DiodeIcon
    isOn={isActive === ActiveEnum.Active}
    data-tip={isActiveNames[isActive]}
    role="img"
    aria-label={isActiveNames[isActive]}
    tabIndex={0}
    {...props}
  />
);

export default IsActiveIcon;
