/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DistanceUnitEnum = {
    Mi: 'mi',
    Km: 'km'
} as const;
export type DistanceUnitEnum = typeof DistanceUnitEnum[keyof typeof DistanceUnitEnum];


export function DistanceUnitEnumFromJSON(json: any): DistanceUnitEnum {
    return DistanceUnitEnumFromJSONTyped(json, false);
}

export function DistanceUnitEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistanceUnitEnum {
    return json as DistanceUnitEnum;
}

export function DistanceUnitEnumToJSON(value?: DistanceUnitEnum | null): any {
    return value as any;
}

