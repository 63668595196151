import { useEffect } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useWindowSize } from 'hooks';
import styles from './Modal.module.scss';

export type ModalProps = {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
  className?: string;
  isBottomRounded?: boolean;
  preventClose?: boolean;
  isNarrow?: boolean;
  portalTarget?: Element;
  mobileMinHeight?: number | string;
  contentStyles?: string;
};

export const ModalContainer: React.FC<ModalProps> = ({
  title,
  children,
  onClose,
  // TODO: This property isn't used yet. Decide what to do with it / delete it from here and all callers.
  className,
  mobileMinHeight,
  isBottomRounded = false,
  preventClose = false,
  isNarrow = false,
  contentStyles,
}) => {
  const { isMobile } = useWindowSize();
  const minHeight = isMobile ? mobileMinHeight || window.innerHeight : undefined;

  const onClick = () => {
    onClose?.();
  };

  const onEsc = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      onClose?.();
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    if (body && html) {
      body.style.overflow = 'hidden';
      window.addEventListener('keydown', onEsc);
      if (preventClose) {
        window.removeEventListener('keydown', onEsc); // for keep modal Choose Sire opened #3699
      }

      return () => {
        body.style.overflow = '';
        window.removeEventListener('keydown', onEsc);
      };
    }
  }, [preventClose]);

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.overlay} onClick={onClick} />
      <div
        className={classNames(
          styles.modal,
          { [styles.bottomRounded]: isBottomRounded },
          { [styles.narrow]: isNarrow },
          className
        )}
        style={{ minHeight }}
      >
        <header className={styles.header}>
          {title}
          <button type="button" className={styles.closeButton} onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </header>
        <div
          className={classNames(
            styles.content,
            {
              [styles.isMobile]: isMobile,
            },
            contentStyles
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const Modal: React.FC<ModalProps> = ({ portalTarget, ...props }) =>
  createPortal(<ModalContainer {...props} />, portalTarget || document.body);
