/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreFeatures
 */
export interface StoreFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof StoreFeatures
     */
    enhancedEventListing: boolean;
}

export function StoreFeaturesFromJSON(json: any): StoreFeatures {
    return StoreFeaturesFromJSONTyped(json, false);
}

export function StoreFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enhancedEventListing': json['enhanced_event_listing'],
    };
}

export function StoreFeaturesToJSON(value?: StoreFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enhanced_event_listing': value.enhancedEventListing,
    };
}

