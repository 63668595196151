import { makeObservable, action, computed } from 'mobx';
import { getAccountInfo } from 'services/api';
import { AsyncOperationWithStatus } from './AsyncOperationWithStatus/AsyncOperationWithStatus';

declare global {
  const IS_AUTHENTICATED: boolean;
}

class UserStoreClass {
  constructor() {
    makeObservable(this);
  }

  @action load() {
    IS_AUTHENTICATED && this.accountLoader.run();
  }

  accountLoader = new AsyncOperationWithStatus(() => getAccountInfo());

  @computed get hasStoreMM() {
    return Boolean(this.accountLoader.data?.has_store);
  }

  @computed get loading() {
    return this.accountLoader.isLoading;
  }

  @computed get hasOrganization() {
    return Boolean(this.accountLoader.data?.has_organization);
  }

  @computed get loggedIn() {
    return Boolean(this.accountLoader.data?.username);
  }

  @computed get user() {
    return this.accountLoader.data;
  }
}

export const UserStore = new UserStoreClass();
