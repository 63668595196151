import { isObjectLiteral } from '../../../src/js/utils';

// The plural type is used because this is meant to be expandable.
type DjangoErrorObjects = { message: string };

// This function is necessary because we our code sometimes passed in non-string objects
// as the error message. This handles this is at least one known case.
const handleObjectMessages = (message: string | DjangoErrorObjects) => {
  if (isObjectLiteral(message)) {
    // TODO: This type error can be fixed once isObjectLiteral is typed.
    return message.message;
  } else {
    return message;
  }
};

export default class HumanReadableError extends Error {
  displayMessage: string;
  isHtml: boolean;
  data: any;

  constructor(
    displayMessage: string | DjangoErrorObjects,
    isHtml?: boolean,
    data?: any,
    ...params: any[]
  ) {
    super(...params);
    this.message = handleObjectMessages(displayMessage);
    this.displayMessage = handleObjectMessages(displayMessage);
    this.isHtml = isHtml ?? false;
    this.data = data;
  }
}
