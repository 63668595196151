// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div>button.root--jsyKM,div>a.root--jsyKM{display:flex}.prefix--OqIn2{width:1.5rem;text-align:center}.label--DEa42{margin-left:.5rem}.suffix--yHtMF{flex-grow:1;text-align:right}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Navigation/components/MenuItems/SettingsMenuItems.module.scss"],"names":[],"mappings":"AAAA,yCAEE,YAAA,CAGF,eACE,YAAA,CACA,iBAAA,CAGF,cACE,iBAAA,CAGF,eACE,WAAA,CACA,gBAAA","sourcesContent":["div > button.root,\ndiv > a.root {\n  display: flex;\n}\n\n.prefix {\n  width: 1.5rem;\n  text-align: center;\n}\n\n.label {\n  margin-left: 0.5rem;\n}\n\n.suffix {\n  flex-grow: 1;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `root--jsyKM`,
	"prefix": `prefix--OqIn2`,
	"label": `label--DEa42`,
	"suffix": `suffix--yHtMF`
};
export default ___CSS_LOADER_EXPORT___;
