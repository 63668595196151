/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkThreadActionEnum,
    BulkThreadActionEnumFromJSON,
    BulkThreadActionEnumFromJSONTyped,
    BulkThreadActionEnumToJSON,
} from './BulkThreadActionEnum';

/**
 * 
 * @export
 * @interface ThreadBulkActionsRequest
 */
export interface ThreadBulkActionsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ThreadBulkActionsRequest
     */
    threadIds: Array<number>;
    /**
     * 
     * @type {BulkThreadActionEnum}
     * @memberof ThreadBulkActionsRequest
     */
    bulkThreadAction: BulkThreadActionEnum;
}

export function ThreadBulkActionsRequestFromJSON(json: any): ThreadBulkActionsRequest {
    return ThreadBulkActionsRequestFromJSONTyped(json, false);
}

export function ThreadBulkActionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThreadBulkActionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threadIds': json['thread_ids'],
        'bulkThreadAction': BulkThreadActionEnumFromJSON(json['bulk_thread_action']),
    };
}

export function ThreadBulkActionsRequestToJSON(value?: ThreadBulkActionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thread_ids': value.threadIds,
        'bulk_thread_action': BulkThreadActionEnumToJSON(value.bulkThreadAction),
    };
}

