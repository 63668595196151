import { SymbolIndex } from '../../../../types';

export const ORIGIN_FILTER = Symbol('ORIGIN_FILTER');

export const ORIGIN_SELF_PRODUCED = Symbol('ORIGIN_SELF_PRODUCED');
export const ORIGIN_CAPTIVE_BRED = Symbol('ORIGIN_CAPTIVE_BRED');
export const ORIGIN_CAPTIVE_HATCHED_OR_BORN = Symbol('ORIGIN_CAPTIVE_HATCHED_OR_BORN');
export const ORIGIN_WILD_CAUGHT = Symbol('ORIGIN_WILD_CAUGHT');

export const originOptions = [
  ORIGIN_SELF_PRODUCED,
  ORIGIN_CAPTIVE_BRED,
  ORIGIN_CAPTIVE_HATCHED_OR_BORN,
  ORIGIN_WILD_CAUGHT,
];

export const originNames: SymbolIndex = {
  [ORIGIN_SELF_PRODUCED]: 'Self Produced',
  [ORIGIN_CAPTIVE_BRED]: 'Captive Bred',
  [ORIGIN_CAPTIVE_HATCHED_OR_BORN]: 'Captive Hatched/Born',
  [ORIGIN_WILD_CAUGHT]: 'Wild Caught',
};

export const INDIVIDUALITY_FILTER = Symbol('INDIVIDUALITY_FILTER');

export const INDIVIDUALITY_GROUP = Symbol('INDIVIDUALITY_GROUP');
export const INDIVIDUALITY_INDIVIDUAL = Symbol('INDIVIDUALITY_INDIVIDUAL');

export const individualityOptions = [INDIVIDUALITY_GROUP, INDIVIDUALITY_INDIVIDUAL];

export const individualityNames: SymbolIndex = {
  [INDIVIDUALITY_GROUP]: 'Group',
  [INDIVIDUALITY_INDIVIDUAL]: 'Individual',
};
