/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Unsexed` - Unsexed
 * * `Male` - Male
 * * `Female` - Female
 * * `Mixed` - Mixed
 * @export
 */
export const SexDisplayEnum = {
    Unsexed: 'Unsexed',
    Male: 'Male',
    Female: 'Female',
    Mixed: 'Mixed'
} as const;
export type SexDisplayEnum = typeof SexDisplayEnum[keyof typeof SexDisplayEnum];


export function SexDisplayEnumFromJSON(json: any): SexDisplayEnum {
    return SexDisplayEnumFromJSONTyped(json, false);
}

export function SexDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexDisplayEnum {
    return json as SexDisplayEnum;
}

export function SexDisplayEnumToJSON(value?: SexDisplayEnum | null): any {
    return value as any;
}

