import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { EnumsBucket } from 'constants/enums';

import { listingsUrlToProjectUrl, projectsPathToListingsPath } from 'pages/Project/Search/helpers';
import {
  CATEGORY_FILTER,
  expandedCategories,
  getCategoryBranch,
  useCurrentCategory,
} from 'components/Common/Filters/CategoryFilter/definitions';
import { LOCATION_FILTER } from 'components/Search/Filters/LocationFilter/definitions';
import { LocationFilterContext } from 'context/LocationFilterContext/LocationFilterContext';
import { FiltersObject } from 'api/types';
import { useRegion } from 'hooks';
import { useUserData } from 'hooks/useUserData';
import { getRegion } from 'services/helpers';
import {
  generateStoresPath,
  generateCalculatorPath,
  isIndexPath,
  isListingsPath,
  isProjectsPath,
  isSavedSearchesPath,
  isStoresPath,
  generateProjectsPath,
  generateListingsPath,
  generateLocalListingsPath,
  generateSavedSearchesPath,
  isCalculatorPath,
  isLocalListingsPath,
  isDealsPath,
  isForYouPath,
  isPopularPath,
  generateDealsListingsPath,
  generateForYouListingsPath,
  generatePopularListingsPath,
  generateWholesaleListingsPath,
  isWholesalePath,
  generateAuctionListingsPath,
  isAuctionsPath,
  generateLikedListingsPath,
  isLikedPath,
  isSavedPath,
  generateSavedListingsPath,
  isHomePath,
  isMyBidsPath,
  generateMyBidsListingsPath,
} from 'services/urls';

const convertToProjectIndexIfNeeded = (indexPagePath: string | undefined, region: string) => {
  if (!indexPagePath) {
    return undefined;
  }

  const regionAwareIndexPagePath = indexPagePath.replace(/^\/all/, `/${region}`);

  const currentPath = window.location.pathname;
  if (isProjectsPath(currentPath)) {
    return regionAwareIndexPagePath.replace('/c/', '/p/');
  } else {
    return regionAwareIndexPagePath;
  }
};

export const useContextTabs = (filters?: FiltersObject) => {
  const { IS_AUTHENTICATED } = window;
  const [searchParams] = useSearchParams();
  const canonicalParams = useParams();
  const { postalCode, distance } = useContext(LocationFilterContext);
  const hookRegion = useRegion();

  const regionUrl = getRegion();
  const findRegionInPath = EnumsBucket.regions.find(({ id }) => id === regionUrl);
  const regionFromPath = findRegionInPath?.id || false;

  const regionValue = filters?.get(LOCATION_FILTER);
  const regionFallbackValue =
    canonicalParams.region || searchParams.get('region') || regionFromPath || hookRegion || 'all';
  const region = regionValue || regionFallbackValue;

  const categoryHierarchy = getCategoryBranch(filters?.get(CATEGORY_FILTER), expandedCategories);
  const [animalSubcategory, animalCategory] = categoryHierarchy.filter(
    ({ fine_grained_subcategory }) => !fine_grained_subcategory
  );
  // TODO It might now be possible to only use `useCurrentCategory` here and remove the other ways of getting a category above.
  const categoryFallback = useCurrentCategory({ shouldFallBackToCategoryContext: false });
  const category = animalSubcategory || animalCategory || categoryFallback;
  const [indexPagePath, setIndexPagePath] = useState<string | undefined>(
    convertToProjectIndexIfNeeded(categoryFallback?.index_page_path, region) || ''
  );

  useEffect(() => {
    setIndexPagePath(convertToProjectIndexIfNeeded(categoryFallback?.index_page_path, region));
  }, [category, categoryFallback, region]);

  // Initialize paths
  // ====
  const currentPath = window.location.pathname;
  let projectsPath;
  let listingsPath;
  let storesPath;
  let selected: string;
  // Sometimes this will be null, i.e. when there is no calculator wizard
  // available for the current category
  const calculatorPath = generateCalculatorPath(region, category);
  const savedSearchesPath = generateSavedSearchesPath(region, category);
  const forYouPath = generateForYouListingsPath(region, category);
  const likedPath = generateLikedListingsPath(region, category);
  const savedPath = generateSavedListingsPath(region, category);
  const popularPath = generatePopularListingsPath(region, category);
  const dealsListingsPath = generateDealsListingsPath(region, category);
  const localListingsPath = generateLocalListingsPath(region, category, postalCode, distance);
  const wholesaleListingsPath = generateWholesaleListingsPath(region, category);
  const auctionListingsPath = generateAuctionListingsPath(region, category);
  const myBidsListingsPath = generateMyBidsListingsPath(region, category);

  const hasWholesaleFeature = !!useUserData()?.about?.hasWholesaleFeature;

  // TODO: There's no reason for this to be so repetitive. Refactor this
  // Logic to generate paths depending on starting page
  // ===
  if (isProjectsPath(currentPath)) {
    projectsPath = currentPath;
    listingsPath = projectsPathToListingsPath(searchParams, canonicalParams);
    storesPath = generateStoresPath(region, category);
    selected = 'Projects';
  } else if (isLocalListingsPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Local';
  } else if (isForYouPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'For You';
  } else if (isLikedPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Liked';
  } else if (isSavedPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Saved';
  } else if (isPopularPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Popular';
  } else if (isDealsPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Deals';
  } else if (isWholesalePath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Wholesale';
  } else if (isAuctionsPath(window.location.toString())) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Auctions';
  } else if (isMyBidsPath(window.location.toString())) {
    listingsPath = generateListingsPath(region, category);
    projectsPath = generateProjectsPath(region, category);
    storesPath = generateStoresPath(region, category);
    selected = 'My Bids';
  } else if (isListingsPath(currentPath)) {
    projectsPath = listingsUrlToProjectUrl(searchParams, canonicalParams);
    listingsPath = currentPath;
    storesPath = generateStoresPath(region, category);
    selected = 'Shop';
  } else if (isStoresPath(currentPath)) {
    projectsPath = generateProjectsPath(region, category);
    listingsPath = generateListingsPath(region, category);
    // Without this addition, the category gets lost if you re-click
    // on stores while currently on stores.
    storesPath = currentPath + window.location.search;
    selected = 'Stores';
  } else if (isIndexPath(currentPath)) {
    storesPath = generateStoresPath(region, category);
    listingsPath = generateListingsPath(region, category);
    projectsPath = generateProjectsPath(region, category);
    selected = 'Traits';
  } else if (isCalculatorPath(currentPath)) {
    storesPath = generateStoresPath(region, category);
    listingsPath = generateListingsPath(region, category);
    projectsPath = generateProjectsPath(region, category);
    selected = 'Calculator';
  } else if (isSavedSearchesPath(currentPath)) {
    listingsPath = generateListingsPath(region, category);
    projectsPath = generateProjectsPath(region, category);
    storesPath = generateStoresPath(region, category);
    selected = 'Searches';
  } else if (isHomePath(currentPath)) {
    listingsPath = generateListingsPath(region, category);
    projectsPath = generateProjectsPath(region, category);
    storesPath = generateStoresPath(region, category);
  }

  const tabs = [
    {
      title: 'Shop',
      value: listingsPath,
    },
    // Only show the "Index" link if `indexPagePath` is defined. And never show on homepage.
    ...(indexPagePath && !isHomePath(currentPath)
      ? [
          {
            title: 'Traits',
            value: indexPagePath,
          },
        ]
      : []),
    {
      title: 'Local',
      value: localListingsPath,
    },
    {
      title: 'Deals',
      value: dealsListingsPath,
    },
    {
      title: 'Auctions',
      value: auctionListingsPath,
    },
    ...(hasWholesaleFeature
      ? [
          {
            title: 'Wholesale',
            value: wholesaleListingsPath,
          },
        ]
      : []),
    ...(IS_AUTHENTICATED
      ? [
          {
            title: 'For You',
            value: forYouPath,
          },
        ]
      : [
          {
            title: 'For You',
            value: `/accounts/login/?next=${forYouPath}`,
            link: true,
          },
        ]),
    {
      title: 'Popular',
      value: popularPath,
    },

    {
      title: 'Stores',
      value: storesPath,
    },
    ...(IS_AUTHENTICATED
      ? [
          {
            title: 'My Bids',
            value: myBidsListingsPath,
          },
        ]
      : [
          {
            title: 'My Bids',
            value: `/accounts/login/?next=${myBidsListingsPath}}`,
            link: true,
          },
        ]),
    ...(IS_AUTHENTICATED
      ? [
          {
            title: 'Liked',
            value: likedPath,
          },
        ]
      : [
          {
            title: 'Liked',
            value: `/accounts/login/?next=${likedPath}`,
            link: true,
          },
        ]),
    ...(IS_AUTHENTICATED
      ? [
          {
            title: 'Saved',
            value: savedPath,
          },
        ]
      : [
          {
            title: 'Saved',
            value: `/accounts/login/?next=${savedPath}`,
            link: true,
          },
        ]),
    {
      title: 'Projects',
      value: projectsPath,
    },
    // Only show the "Searches" link if the user is logged in
    ...(IS_AUTHENTICATED
      ? [
          {
            title: 'Searches',
            value: savedSearchesPath,
          },
        ]
      : []),
    // Only show the "Calculator" link if `calculatorPath` is defined.
    ...(calculatorPath
      ? [
          {
            title: 'Calculator',
            value: calculatorPath,
          },
        ]
      : []),
  ];
  return {
    tabs,
    selected: tabs.find(({ title }) => title === selected)?.value,
  };
};
