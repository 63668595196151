export const serializeView = (viewToSerialize, viewType) => {
  const [, viewValue] = viewType.find(([view]) => view === viewToSerialize) ?? [];
  return viewValue;
};

export const unserializeView = (viewValueToUnserialize, viewType) => {
  const [view] = viewType.find(([, viewValue]) => viewValue === viewValueToUnserialize) ?? [];
  return view;
};

export const serializeColumns = (value) =>
  value.map(({ id, isVisible }) => ({
    column: id.description,
    visible: isVisible,
  }));

export const unserializeColumns = (value, defaultColumns) => {
  const preferencesColumns = value.reduce((result, { column: stringId, visible: isVisible }) => {
    const [column] = defaultColumns.find(([{ id }]) => id.description === stringId) ?? [];
    if (!column) {
      return result;
    }
    return [
      ...result,
      {
        ...column,
        isVisible,
      },
    ];
  }, []);

  const missingColumns = defaultColumns
    .filter(([{ id }]) => !preferencesColumns.some((column) => column.id === id))
    .map(([column, isVisible]) => ({
      ...column,
      isVisible,
    }));

  return [...preferencesColumns, ...missingColumns];
};
