/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Not Ready` - Not Ready
 * * `Baby` - Baby
 * * `Juvenile` - Juvenile
 * * `Subadult` - Subadult
 * * `Adult` - Adult
 * @export
 */
export const MaturityDisplayEnum = {
    NotReady: 'Not Ready',
    Baby: 'Baby',
    Juvenile: 'Juvenile',
    Subadult: 'Subadult',
    Adult: 'Adult'
} as const;
export type MaturityDisplayEnum = typeof MaturityDisplayEnum[keyof typeof MaturityDisplayEnum];


export function MaturityDisplayEnumFromJSON(json: any): MaturityDisplayEnum {
    return MaturityDisplayEnumFromJSONTyped(json, false);
}

export function MaturityDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaturityDisplayEnum {
    return json as MaturityDisplayEnum;
}

export function MaturityDisplayEnumToJSON(value?: MaturityDisplayEnum | null): any {
    return value as any;
}

