import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faStar as faStarLight } from '@fortawesome/sharp-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/sharp-solid-svg-icons';
import { colors } from '../../../context/ThemeContext/Theme/constants';
import style from '../NativeAppReviewModal.module.scss';

export type zeroStarRating = 0;
export type fiveStarRating = 1 | 2 | 3 | 4 | 5;

const ratingStarColors = {
  starColor: colors.gray300,
  hoverColor: colors.black,
};

type StarRatingProps = {
  onChange: (rating: fiveStarRating) => void;
};

export const StarRating: React.FC<StarRatingProps> = ({ onChange }) => {
  const [rating, setRating] = useState<fiveStarRating | zeroStarRating>(0);
  const [hover, setHover] = useState<number | null>(null);

  const handleStarClick = (rating: fiveStarRating) => {
    setRating(rating);
    onChange(rating);
  };

  const getStarColor = (startIndex: number): string => {
    return startIndex <= (hover || rating)
      ? ratingStarColors.hoverColor
      : ratingStarColors.starColor;
  };

  const getStarIcon = (startIndex: number): IconDefinition => {
    return startIndex <= (hover || rating) ? faStarSolid : faStarLight;
  };

  return (
    <div className={style.startRating}>
      {[...Array(5)].map((_, index) => {
        const startIndex = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={startIndex}
              onClick={() => handleStarClick(startIndex as fiveStarRating)}
            />
            <FontAwesomeIcon
              className={style.star}
              icon={getStarIcon(startIndex)}
              color={getStarColor(startIndex)}
              onMouseLeave={() => setHover(null)}
              onMouseEnter={() => setHover(startIndex)}
            />
          </label>
        );
      })}
    </div>
  );
};
