/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AnonymousUser,
    AnonymousUserFromJSON,
    AnonymousUserFromJSONTyped,
    AnonymousUserToJSON,
} from './AnonymousUser';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * @type AbstractUser
 * 
 * @export
 */
export type AbstractUser = { type: 'anonymous' } & AnonymousUser | { type: 'authenticated' } & User;

export function AbstractUserFromJSON(json: any): AbstractUser {
    return AbstractUserFromJSONTyped(json, false);
}

export function AbstractUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbstractUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'anonymous':
            return {...AnonymousUserFromJSONTyped(json, true), type: 'anonymous'};
        case 'authenticated':
            return {...UserFromJSONTyped(json, true), type: 'authenticated'};
        default:
            throw new Error(`No variant of AbstractUser exists with 'type=${json['type']}'`);
    }
}

export function AbstractUserToJSON(value?: AbstractUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'anonymous':
            return AnonymousUserToJSON(value);
        case 'authenticated':
            return UserToJSON(value);
        default:
            throw new Error(`No variant of AbstractUser exists with 'type=${value['type']}'`);
    }
}

