import { createTheme } from '@mui/material';
import { MuiIconButton, MuiList, MuiMenuItem, MuiLink } from './overrides';

export const theme = createTheme({
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
  },
  palette: {
    error: {
      main: '#eb5757',
    },
  },
  components: {
    MuiLink,
    MuiIconButton,
    MuiList: MuiList,
    MuiMenuItem,
  },
});
