import Badge, { BADGE_GOLD } from 'components/Badge/Badge';

const tagTypes = {
  gold: BADGE_GOLD,
};

interface TagBadgeProps {
  element: keyof JSX.IntrinsicElements;
  name: string;
  type: keyof typeof tagTypes;
  className?: string;
}

const TagBadge = ({ element, name, type, className }: TagBadgeProps) => {
  return (
    <Badge element={element} type={tagTypes[type]} className={className}>
      {name}
    </Badge>
  );
};

export default TagBadge;
