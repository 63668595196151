import { fetchApi } from 'api/utils';

export class ApiError extends Error {
  statusCode?: number;

  constructor(message: string, statusCode?: number) {
    super(message);

    this.statusCode = statusCode;
  }
}

function serializeQuery(queryParams: object, keyPrefix = ''): string {
  return Object.entries(queryParams)
    .map(([key, value]) => {
      if (value === undefined) {
        return null;
      }

      if (value === null) {
        value = '';
      } else if (typeof value === 'object') {
        if (Array.isArray(value)) {
          if (!value.length) return null;
          return (
            `${keyPrefix}${key}=` +
            value.map((x) => encodeURIComponent(x)).join(`&${keyPrefix}${key}=`)
          );
        }
        return serializeQuery(value, `${keyPrefix}${key}.`);
      }

      return `${keyPrefix}${key}=${encodeURIComponent(value)}`;
    })
    .filter((value) => value !== null)
    .join('&');
}

function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export class BaseApi {
  createHeaders() {
    const headers = new Headers({ Accept: 'application/json', 'Content-Type': 'application/json' });

    const csrftoken = getCookie('csrftoken');

    if (!!csrftoken) {
      headers.append('X-CSRFToken', csrftoken);
    }

    return headers;
  }

  public async get<T>(path: string, queryParams?: object): Promise<T> {
    const headers = this.createHeaders();

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
    };

    if (queryParams) {
      path = `${path}?${serializeQuery(queryParams)}`;
    }

    const response = await fetchApi(path, options);
    if (response.ok) {
      return response.json();
    } else {
      throw new ApiError(response.statusText, response.status);
    }
  }

  public async post<T>(path = '', data?: object | null, queryParams?: object): Promise<T> {
    const options: RequestInit = {
      method: 'POST',
      headers: this.createHeaders(),
      body: data && JSON.stringify(data),
    };

    if (queryParams) {
      path = `${path}?${serializeQuery(queryParams)}`;
    }

    const response = await fetchApi(path, options);

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();

      throw new ApiError(body?.message || response.statusText, response.status);
    }
  }

  public async put<T>(path = '', data?: object | null, queryParams?: object): Promise<T> {
    const options: RequestInit = {
      method: 'PUT',
      headers: this.createHeaders(),
      body: data && JSON.stringify(data),
    };

    if (queryParams) {
      path = `${path}?${serializeQuery(queryParams)}`;
    }

    const response = await fetchApi(path, options);

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();

      throw new ApiError(body?.message || response.statusText, response.status);
    }
  }

  public async patch<T>(path = '', data?: object | null, queryParams?: object): Promise<T> {
    const options: RequestInit = {
      method: 'PATCH',
      headers: this.createHeaders(),
      body: data && JSON.stringify(data),
    };

    if (queryParams) {
      path = `${path}?${serializeQuery(queryParams)}`;
    }

    const response = await fetchApi(path, options);
    if (response.ok) {
      return response.json();
    } else {
      throw new ApiError(response.statusText, response.status);
    }
  }

  public async delete<T>(path = '', data?: object): Promise<T> {
    const options: RequestInit = {
      method: 'DELETE',
      headers: await this.createHeaders(),
      body: data && JSON.stringify(data),
    };

    const response = await fetchApi(path, options);
    if (response.ok) {
      return undefined as unknown as T;
    } else {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
  }
}
