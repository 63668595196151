/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NativeAppReviewRequest
 */
export interface NativeAppReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof NativeAppReviewRequest
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof NativeAppReviewRequest
     */
    review: string;
    /**
     * 
     * @type {string}
     * @memberof NativeAppReviewRequest
     */
    platform?: string | null;
}

export function NativeAppReviewRequestFromJSON(json: any): NativeAppReviewRequest {
    return NativeAppReviewRequestFromJSONTyped(json, false);
}

export function NativeAppReviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NativeAppReviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': json['rating'],
        'review': json['review'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
    };
}

export function NativeAppReviewRequestToJSON(value?: NativeAppReviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
        'review': value.review,
        'platform': value.platform,
    };
}

