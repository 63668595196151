// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--pl7hh{align-items:center;display:flex;flex-wrap:wrap;list-style:none;margin:0;margin-bottom:-0.7em;margin-right:-0.7em;padding:0}.container--pl7hh>*{margin-bottom:.7em;margin-right:.7em}.mobile--CPCQ8{justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Inventory/TraitsList/TraitsList.module.scss","webpack://./mysite/static/react-app/src/style/mixins.scss"],"names":[],"mappings":"AAEA,kBACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,QAAA,CCDA,oBAAA,CACA,mBAAA,CDEA,SAAA,CAAA,oBCCE,kBDFW,CCGX,iBDHW,CAIf,eACE,wBAAA","sourcesContent":["@use 'style/mixins' as *;\n\n.container {\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  list-style: none;\n  margin: 0;\n  @include gap(0.7em); /* Needs to be under margin */\n  padding: 0;\n}\n\n.mobile {\n  justify-content: flex-end;\n}\n","@mixin cell-width($width) {\n  max-width: $width;\n  min-width: $width;\n}\n\n@mixin gap($size) {\n  margin-bottom: -$size;\n  margin-right: -$size;\n\n  > * {\n    margin-bottom: $size;\n    margin-right: $size;\n  }\n}\n\n@mixin gap-row($size) {\n  > :not(:first-child) {\n    margin-left: $size;\n  }\n}\n\n@mixin gap-row-reverse($size) {\n  > :not(:last-child) {\n    margin-left: $size;\n  }\n}\n\n@mixin gap-column($size) {\n  > :not(:first-child) {\n    margin-top: $size;\n  }\n}\n\n@mixin gap-column-reverse($size) {\n  > :not(:last-child) {\n    margin-top: $size;\n  }\n}\n\n@mixin hide-scrollbar {\n   // For webkit browsers like Chrome, Safari, Edge\n   &::-webkit-scrollbar {\n    display: none; \n  }\n  // Hide scrollbar for Firefox\n  scrollbar-width: none; \n  // Hide scrollbar for IE 10+\n  -ms-overflow-style: none; \n}\n\n@mixin all-scroll-no-bar {\n  overflow: auto;\n  @include hide-scrollbar;\n\n  * {\n    @include hide-scrollbar;\n  }\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--pl7hh`,
	"mobile": `mobile--CPCQ8`
};
export default ___CSS_LOADER_EXPORT___;
