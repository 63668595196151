// PURPOSE:
//
// This powers a set of tabs that retains context about category and location
// when switching between listings, projects, and stores.
//
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'pages/Dashboard/components/Tabs/Tabs';
import { TabsContext } from 'context/TabsContext';
import { colors } from 'context/ThemeContext/Theme/constants';
import { useApp } from 'api/generated/useApp';
import { FiltersObject } from 'api/types';
import { useWindowSize } from 'hooks';
import { isLocalListingsPath } from 'services/urls';
import { useContextTabs } from './useContextTabs';
import style from './ContextTabs.module.scss';

type ContextTabsProps = {
  filters?: FiltersObject;
};

export const ContextTabs = ({ filters }: ContextTabsProps) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const isApp = useApp();
  const { activeTabPath, actions: tabsContextActions } = useContext(TabsContext);
  const { tabs, selected } = useContextTabs(filters);
  const onChangeTab = (pathValue: string, isLink?: boolean) => {
    isLink ? window.location.assign(pathValue) : navigate(pathValue);
    tabsContextActions.setActiveTabPath(pathValue);
    tabsContextActions.triggerTabClick();
  };
  const localPathAndMobileSize = isLocalListingsPath(activeTabPath) && isMobile && isApp;
  const hoverColor = localPathAndMobileSize ? colors.black : colors.gold200;

  return (
    <div className={style.tabsWrapper} data-communicate-scroll-to-app="true">
      <Tabs onChange={onChangeTab} value={selected} tabs={tabs} hoverColor={hoverColor} />
    </div>
  );
};
