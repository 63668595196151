/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThreadTagUpdate,
    ThreadTagUpdateFromJSON,
    ThreadTagUpdateFromJSONTyped,
    ThreadTagUpdateToJSON,
} from './ThreadTagUpdate';

/**
 * 
 * @export
 * @interface TagAction
 */
export interface TagAction {
    /**
     * 
     * @type {Array<ThreadTagUpdate>}
     * @memberof TagAction
     */
    add?: Array<ThreadTagUpdate>;
    /**
     * 
     * @type {Array<ThreadTagUpdate>}
     * @memberof TagAction
     */
    remove?: Array<ThreadTagUpdate>;
}

export function TagActionFromJSON(json: any): TagAction {
    return TagActionFromJSONTyped(json, false);
}

export function TagActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add': !exists(json, 'add') ? undefined : ((json['add'] as Array<any>).map(ThreadTagUpdateFromJSON)),
        'remove': !exists(json, 'remove') ? undefined : ((json['remove'] as Array<any>).map(ThreadTagUpdateFromJSON)),
    };
}

export function TagActionToJSON(value?: TagAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add': value.add === undefined ? undefined : ((value.add as Array<any>).map(ThreadTagUpdateToJSON)),
        'remove': value.remove === undefined ? undefined : ((value.remove as Array<any>).map(ThreadTagUpdateToJSON)),
    };
}

