import React from 'react';

import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './Tabs.module.scss';

export interface Tab {
  title: string;
  value: any;
  link?: boolean;
  icon?: IconDefinition;
}

export interface TabsProps {
  tabs: Tab[];
  value?: string;
  noHover?: boolean;
  hoverColor?: string;
  allowSelfClick?: boolean;
  onChange: (value: any, isLink?: boolean | undefined) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  value,
  noHover,
  hoverColor,
  allowSelfClick,
  onChange,
}) => {
  const onTabClick = (tab: Tab) => {
    if (allowSelfClick || tab.value !== value) {
      onChange(tab.value, tab?.link);
    }
  };

  const getClasses = (tab: Tab) => {
    const classes = classnames(styles.tab, {
      [styles.noHover]: noHover,
      [styles.active]: value && tab.value === value,
      [styles.allowSelfClick]: allowSelfClick,
    });
    return classes;
  };

  const hoverColorProp = hoverColor
    ? ({ '--hover-color': hoverColor } as React.CSSProperties)
    : undefined;

  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={getClasses(tab)}
          style={hoverColorProp}
          onClick={() => onTabClick(tab)}
        >
          {!tab.icon && tab.title}
          {tab.icon && <FontAwesomeIcon icon={tab.icon} title={tab.title} />}
        </div>
      ))}
    </div>
  );
};
