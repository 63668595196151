import categoryColumn from 'components/Common/Columns/CategoryColumn/CategoryColumn';
import damColumn from 'components/Common/Columns/DamColumn/DamColumn';
import imageColumn from 'components/Common/Columns/ImageColumn/ImageColumn';
import sireColumn from 'components/Common/Columns/SireColumn/SireColumn';
import traitsColumn from 'components/Common/Columns/TraitsColumn/TraitsColumn';
import animalIdColumn from 'components/Inventory/Columns/AnimalIdColumn/AnimalIdColumn';
import birthDateColumn from 'components/Inventory/Columns/BirthDateColumn/BirthDateColumn';
import clicksColumn from 'components/Inventory/Columns/ClicksColumn/ClicksColumn';
import groupIdColumn from 'components/Inventory/Columns/GroupIdColumn/GroupIdColumn';
import impressionsColumn from 'components/Inventory/Columns/ImpressionsColumn/ImpressionsColumn';
import inquiriesColumn from 'components/Inventory/Columns/InquiriesColumn/InquiriesColumn';
import lastUpdatedColumn from 'components/Inventory/Columns/LastUpdatedColumn/LastUpdatedColumn';
import maturityColumn from 'components/Inventory/Columns/MaturityColumn/MaturityColumn';
import priceColumn from 'components/Inventory/Columns/PriceColumn/PriceColumn';
import quantityColumn from 'components/Inventory/Columns/QuantityColumn/QuantityColumn';
import sexColumn from 'components/Inventory/Columns/SexColumn/SexColumn';
import statusColumn from 'components/Inventory/Columns/StatusColumn/StatusColumn';
import titleColumn from 'components/Inventory/Columns/TitleColumn/TitleColumn';
import titleIdColumn from 'components/Inventory/Columns/TitleIdColumn/TitleIdColumn';
import weightColumn from 'components/Inventory/Columns/WeightColumn/WeightColumn';

const defaultColumns = [
  [imageColumn, true],
  [titleColumn, true],
  [animalIdColumn, true],
  [sexColumn, true],
  [traitsColumn, true],
  [sireColumn, true],
  [damColumn, true],
  [statusColumn, true],
  [birthDateColumn, true],
  [maturityColumn, true],
  [weightColumn, true],
  [priceColumn, true],
  [lastUpdatedColumn, false],
  [quantityColumn, false],
  [groupIdColumn, false],
  [impressionsColumn, false],
  [clicksColumn, false],
  [inquiriesColumn, false],
  [categoryColumn, false],
  [titleIdColumn, false],
];

export default defaultColumns;
