import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { MODIFIED_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from 'api/ordering';

export const MODIFIED_COLUMN = Symbol('MODIFIED_COLUMN');

const modifiedColumn = {
  id: MODIFIED_COLUMN,
  ordering: MODIFIED_ORDERING,
  defaultDirection: DESCENDING,
  label: 'Last Updated',
  hideable: true,
  className: style.dateColumn,

  render: ({ modified_display }) => modified_display,
};

export default modifiedColumn;
