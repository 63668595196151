import { LegacyAnimal } from 'components/Inventory/ActionsDrawer/ActionsDrawer';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { TITLE_ORDERING } from 'api/inventory/ordering';
export const TITLE_COLUMN = Symbol('TITLE_COLUMN');

const TitleColumn = {
  id: TITLE_COLUMN,
  ordering: TITLE_ORDERING,
  label: 'Title & Category',
  hideable: true,
  className: style.titleColumn,
  render: (animal: LegacyAnimal, _readonly: boolean, _link_title: string, toggle?: () => void) => {
    const { title } = animal;
    return (
      <div>
        <div lang="en" className={style.itemTitle} onClick={() => toggle?.()}>
          {title}
        </div>
      </div>
    );
  },
};

export default TitleColumn;
