/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `m (total length)` - m (total length)
 * * `cm (total length)` - cm (total length)
 * * `m (snout to vent)` - m (snout to vent)
 * * `cm (snout to vent)` - cm (snout to vent)
 * @export
 */
export const LengthTypeDisplayEnum = {
    MTotalLength: 'm (total length)',
    CmTotalLength: 'cm (total length)',
    MSnoutToVent: 'm (snout to vent)',
    CmSnoutToVent: 'cm (snout to vent)'
} as const;
export type LengthTypeDisplayEnum = typeof LengthTypeDisplayEnum[keyof typeof LengthTypeDisplayEnum];


export function LengthTypeDisplayEnumFromJSON(json: any): LengthTypeDisplayEnum {
    return LengthTypeDisplayEnumFromJSONTyped(json, false);
}

export function LengthTypeDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LengthTypeDisplayEnum {
    return json as LengthTypeDisplayEnum;
}

export function LengthTypeDisplayEnumToJSON(value?: LengthTypeDisplayEnum | null): any {
    return value as any;
}

