/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Unspecified` - Unspecified
 * * `Will Consider` - Will Consider
 * * `No Trades` - No Trades
 * @export
 */
export const AcceptTradesDisplayEnum = {
    Unspecified: 'Unspecified',
    WillConsider: 'Will Consider',
    NoTrades: 'No Trades'
} as const;
export type AcceptTradesDisplayEnum = typeof AcceptTradesDisplayEnum[keyof typeof AcceptTradesDisplayEnum];


export function AcceptTradesDisplayEnumFromJSON(json: any): AcceptTradesDisplayEnum {
    return AcceptTradesDisplayEnumFromJSONTyped(json, false);
}

export function AcceptTradesDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptTradesDisplayEnum {
    return json as AcceptTradesDisplayEnum;
}

export function AcceptTradesDisplayEnumToJSON(value?: AcceptTradesDisplayEnum | null): any {
    return value as any;
}

