import './polyfills';
import './index.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { createRoot } from 'react-dom/client';
import MainApp from 'apps/MainApp';

// See JSDoc
import { patchGoogleTranslate } from 'patchGoogleTranslate';
patchGoogleTranslate();

// Extend dayjs with additional formats, namely "Do"
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

if (!!window.IS_STANDALONE) {
  // The app has its own navigation system, therefore we avoid displaying the React app for navigation here.
  console.debug('This is index file. Supposed to be used for react template.');
} else {
  console.debug('Main App');
  const root = createRoot(document.getElementById('root')!);
  root.render(<MainApp />);
}
