import { useState } from 'react';
import { useNativeAppPullToReloadContext } from '../../context/NativeAppPullToReloadContext';

/**
 * Handles the repetitive state management for components such as modals or drawers.
 * Warning: This has impacts for pull to refresh in the app so only use this component
 * if you are sure that you want to disable pull to refresh.
 */
export const useOpenState = (defaultState: boolean = false) => {
  const [isOpen, setOpen] = useState(defaultState);
  const { handleSetIsPullToRefreshEnabledInReactNative } = useNativeAppPullToReloadContext();

  return {
    isOpen,
    open: () => {
      handleSetIsPullToRefreshEnabledInReactNative({ isEnabled: false });
      setOpen(true);
    },
    close: () => {
      handleSetIsPullToRefreshEnabledInReactNative({ isEnabled: true });
      setOpen(false);
    },
    toggle: () => {
      handleSetIsPullToRefreshEnabledInReactNative({ isEnabled: isOpen });
      setOpen(!isOpen);
    },
  };
};
