import Button, { ButtonProps } from '@mui/material/Button';

export type MuiButtonProps = ButtonProps & {
  label: string;
};

const MuiButton: React.FC<MuiButtonProps> = ({ label, ...props }) => {
  return (
    <Button variant="text" {...props}>
      {label}
    </Button>
  );
};

export default MuiButton;
