// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traitsColumn--VUd3H{max-width:300px;min-width:300px}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/Common/columns.module.scss","webpack://./mysite/static/react-app/src/style/mixins.scss"],"names":[],"mappings":"AAEA,qBCDE,eDEoB,CCDpB,eDCoB","sourcesContent":["@use 'style/mixins' as *;\n\n.traitsColumn {\n  @include cell-width(300px);\n}\n","@mixin cell-width($width) {\n  max-width: $width;\n  min-width: $width;\n}\n\n@mixin gap($size) {\n  margin-bottom: -$size;\n  margin-right: -$size;\n\n  > * {\n    margin-bottom: $size;\n    margin-right: $size;\n  }\n}\n\n@mixin gap-row($size) {\n  > :not(:first-child) {\n    margin-left: $size;\n  }\n}\n\n@mixin gap-row-reverse($size) {\n  > :not(:last-child) {\n    margin-left: $size;\n  }\n}\n\n@mixin gap-column($size) {\n  > :not(:first-child) {\n    margin-top: $size;\n  }\n}\n\n@mixin gap-column-reverse($size) {\n  > :not(:last-child) {\n    margin-top: $size;\n  }\n}\n\n@mixin hide-scrollbar {\n   // For webkit browsers like Chrome, Safari, Edge\n   &::-webkit-scrollbar {\n    display: none; \n  }\n  // Hide scrollbar for Firefox\n  scrollbar-width: none; \n  // Hide scrollbar for IE 10+\n  -ms-overflow-style: none; \n}\n\n@mixin all-scroll-no-bar {\n  overflow: auto;\n  @include hide-scrollbar;\n\n  * {\n    @include hide-scrollbar;\n  }\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"traitsColumn": `traitsColumn--VUd3H`
};
export default ___CSS_LOADER_EXPORT___;
