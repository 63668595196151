import { useEffect, useContext } from 'react';

import { GeolocationContext } from 'context/GeolocationContext';

import { useToaster } from './useToaster';

const POSITION_OPTIONS: PositionOptions = {
  enableHighAccuracy: false,
  // The timeout member denotes the maximum length of time, expressed in milliseconds, before acquiring a position expires.
  timeout: 5000,
  //   The maximumAge member indicates that the web application is willing to accept a cached position whose age is no greater than the specified time in milliseconds.
  maximumAge: 30 * 60 * 1000, // 30 minutes
};

/**
 * A hook that handles Geolocation, such as asking for permissions from the user to access his location and getting his position.
 *
 * @param shouldBubbleUpException If set to true, the hook will throw an exception if the user denies access to his location.
 * This exception needs to be rescue further up the stack
 */
export const useGeolocation = (shouldBubbleUpException = false) => {
  const { status, setStatus, isLoading, setLoading } = useContext(GeolocationContext);
  const { showError } = useToaster();
  const prompt = async () => getLocation();
  // We use default precision of 2 decimal places which is about 1.1 km from the actual location.
  // With this precision, we are masking the exact location of the user and preventing them from doxing themselves.
  // Precision levels:
  // 2 - 1.1 km
  // 3 - 110 m
  // 4 - 11 m
  // 5 - 1.1 m
  // 6 - 0.11 m
  // 7 - 0.011 m
  // More at: https://en.wikipedia.org/wiki/Decimal_degrees
  const getLocation = (precision = 2) => {
    setLoading(true);
    return new Promise((resolve: PositionCallback, reject: PositionErrorCallback) =>
      navigator.geolocation.getCurrentPosition(
        (success) => {
          setStatus('granted');
          setLoading(false);
          const roundedCoords = {
            latitude: parseFloat(success.coords.latitude.toFixed(precision)),
            longitude: parseFloat(success.coords.longitude.toFixed(precision)),
          };
          const modifiedSuccess = { ...success, coords: { ...success.coords, ...roundedCoords } };
          resolve(modifiedSuccess);
        },
        (error) => {
          setStatus('granted');
          showError(
            "Problem detecting your location. You may have denied access to your location. Read more <a href='https://support.morphmarket.com/article/187-how-to-search-for-local-breeders'>here</a>",
            { isHtml: true }
          );
          setStatus('denied');
          setLoading(false);

          if (shouldBubbleUpException) {
            reject(error);
          }
        },
        POSITION_OPTIONS
      )
    );
  };

  const checkPermissions = () => {
    if (navigator?.permissions) {
      setLoading(true);
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(({ state }) => {
          setStatus(state);
        })
        .catch(() => {
          setStatus('denied');
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status === 'prompt') {
      checkPermissions();
    }
  }, [status]);

  return {
    prompt,
    getLocation,
    status,
    isLoading,
  };
};
