import { useEffect, useState } from 'react';
import { generateSingletonHook } from 'context/SingletonContext';
import {
  MOBILE_MEDIUM,
  MOBILE_MEDIUM_MINUS,
  MOBILE_LARGE,
  TABLET,
  TABLET_LARGE,
  DESKTOP,
} from 'services/constants/breakpoints';

type WindowSizeProps = {
  width?: number;
  height?: number;
  isMobile?: boolean;
  isMobileMedium?: boolean;
  isMobileMediumMinus?: boolean;
  isMobileLarge?: boolean;
  isTablet?: boolean;
  isTabletLarge?: boolean;
  isDesktop?: boolean;
};

const useInternalWindowSize = (): WindowSizeProps => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: undefined,
    height: undefined,
    isMobile: undefined,
    isMobileMedium: undefined,
    isMobileMediumMinus: undefined,
    isTablet: undefined,
    isTabletLarge: undefined,
    isDesktop: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < MOBILE_LARGE,
        isMobileMedium: window.innerWidth < MOBILE_MEDIUM,
        isMobileMediumMinus: window.innerWidth < MOBILE_MEDIUM_MINUS,
        isMobileLarge: window.innerWidth < MOBILE_LARGE,
        isTablet: window.innerWidth < TABLET,
        isTabletLarge: window.innerWidth < TABLET_LARGE,
        isDesktop: window.innerWidth < DESKTOP,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const [WindowSizeProvider, useWindowSize] = generateSingletonHook(useInternalWindowSize);

export { WindowSizeProvider, useWindowSize };
