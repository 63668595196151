import { Components, Theme } from '@mui/material';
import { colors } from '../constants';

const { white, gray000 } = colors;

export const MuiSnackbarContent: Components<Omit<Theme, 'components'>>['MuiSnackbarContent'] = {
  styleOverrides: {
    root: {
        backgroundColor: white,
        color: gray000
    },
  },
};
