import { AdvancedSearchIcon } from '../Buttons';
export type AdvancedSearchFormProps = {
  onClose: () => void;
};

export const DesktopAdvancedSearch: React.FC = () => {
  return (
    <>
      <AdvancedSearchIcon />
    </>
  );
};
