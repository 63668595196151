const class_tag = 'gold';
import TagBadge from 'components/TagBadge/TagBadge';
import style from '../TraitsList/TraitsList.module.scss';

interface TagsListProps {
  tags: { id: number; name: string }[];
  isMobile?: boolean;
}

const TagsList = ({ tags, isMobile = false }: TagsListProps) =>
  tags.length ? (
    <ul className={`${style.container} ${isMobile ? style.mobile : ''}`}>
      {tags.map(({ id, name }) => (
        <TagBadge key={id} element="li" name={name} type={class_tag} />
      ))}
    </ul>
  ) : null;

export default TagsList;
