import { useNavigate } from 'react-router-dom';
import { useInventoryNavigation } from 'pages/Inventory/hooks/useInventoryNavigation';
import { useRecentlyVisitedCategories } from 'hooks/useRecentlyVisited';
import { isRtoR } from 'services/isRtoR';
import { isInventoryPath } from 'services/urls';

export type UseHandleLinkClickParameters = {
  recentlyVisited?: boolean;
  closeMenu?: () => void;
  href: string;
  id?: string;
};

/**
 * When the user clicks a category in the navigation menu, several things need to happen:
 * - the category visit needs to be tracked (only if the menu option has a category id and is NOT a recently visited category)
 * - the user needs to be redirected to the target page
 * - the menu should be manually closed if it's a React to React page
 */
export const useHandleLinkClick = ({
  id,
  recentlyVisited,
  closeMenu,
  href,
}: UseHandleLinkClickParameters) => {
  const navigate = useNavigate();
  const navigateToInventory = useInventoryNavigation();
  let rToNavigator;
  if (href && isInventoryPath(href)) {
    rToNavigator = navigateToInventory;
  } else {
    rToNavigator = navigate;
  }
  const { trackVisit } = useRecentlyVisitedCategories();
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    !!id && !recentlyVisited && trackVisit(id);
    if (isRtoR(href)) {
      e.preventDefault();
      rToNavigator(href);
      closeMenu?.();
    }
  };

  return onClick;
};
