/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This serializer is used to manage user-available tags.
 * @export
 * @interface UserThreadTagRequest
 */
export interface UserThreadTagRequest {
    /**
     * 
     * @type {string}
     * @memberof UserThreadTagRequest
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof UserThreadTagRequest
     */
    name: string;
}

export function UserThreadTagRequestFromJSON(json: any): UserThreadTagRequest {
    return UserThreadTagRequestFromJSONTyped(json, false);
}

export function UserThreadTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserThreadTagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': !exists(json, 'color') ? undefined : json['color'],
        'name': json['name'],
    };
}

export function UserThreadTagRequestToJSON(value?: UserThreadTagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'name': value.name,
    };
}

