import { useEffect } from 'react';
import { generateSingletonHook } from 'context/SingletonContext';
import { useUserData } from 'hooks/useUserData';

/**
 * Gets the user's preferred color scheme (light or dark).
 */
export const useInternalDarkMode = () => {
  const { preferences, set } = useUserData();
  const toggle = () => {
    set('theme', getTheme() === 'dark' ? 'light' : 'dark');
  };

  const getTheme = (): 'light' | 'dark' => {
    // TODO: Revert this commit after #7872 is merged
    // const isUserUsingDarkMode = !!window?.matchMedia('(prefers-color-scheme: dark)').matches;

    // if (preferences?.theme) return preferences.theme;
    // if (isUserUsingDarkMode) return 'dark';
    return 'light';
  };
  const theme = getTheme();

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  return {
    toggle,
    theme,
  };
};

const [DarkModeProvider, useDarkMode] = generateSingletonHook(useInternalDarkMode);
export { DarkModeProvider, useDarkMode };
