import siblingsDefaultColumns from 'pages/EditOffspringGroup/columns';
import inventoryDefaultColumns from 'pages/Inventory/Animals/helpers/columns';
import husbandryInventoryDefaultColumns from 'pages/Inventory/Husbandry/helpers/columns';
import offspringDefaultColumns from 'pages/Offspring/columns';
import { LOCAL_RADIUS } from 'pages/Search/components/services/usePopulateParamsForLocalSearch';
import {
  SEARCH_PER_PAGE_PREFERENCE,
  SEARCH_VIEW_PREFERENCE,
  INVENTORY_PER_PAGE_PREFERENCE,
  INVENTORY_VIEW_PREFERENCE,
  INVENTORY_COLUMNS_PREFERENCE,
  HUSBANDRY_INVENTORY_VIEW_PREFERENCE,
  HUSBANDRY_INVENTORY_COLUMNS_PREFERENCE,
  OFFSPRING_PRIVATE_PER_PAGE_PREFERENCE,
  OFFSPRING_PRIVATE_COLUMNS_PREFERENCE,
  OFFSPRING_PRIVATE_VIEW_PREFERENCE,
  OFFSPRING_PUBLIC_PER_PAGE_PREFERENCE,
  OFFSPRING_PUBLIC_COLUMNS_PREFERENCE,
  OFFSPRING_PUBLIC_VIEW_PREFERENCE,
  SIBLINGS_COLUMNS_PREFERENCE,
  INDEX_VIEW_PREFERENCE,
  inventoryViews,
  searchViews,
  indexViews,
  offspringViews,
  BREEDER_PAIRING_PREFERENCE,
  breederPairingPreferences,
  FOLLOWING_STORES_PREFERENCE,
  BLOCKED_STORES_PREFERENCE,
  blockedViews,
  followingViews,
  SNAKE_ORDERS_PREFERENCE,
  snakeOrdersView,
  LIKED_SAVED_ANIMALS_PREFERENCES,
  likedSavedViews,
  RADIUS_PREFERENCES,
  POSTAL_CODE_PREFERENCES,
  BREEDING_PAIRING_SEASON,
  SHOW_SOCIAL_ACTIONS_ON_LISTINGS_DETAIL,
} from './definitions';
import { serializeColumns, serializeView, unserializeColumns, unserializeView } from './helpers';
import { DEFAULT_RESULTS_PER_PAGE } from 'config';

export const settings = {
  [SEARCH_PER_PAGE_PREFERENCE]: {
    apiParam: 'search_number_of_rows',
    defaultValue: DEFAULT_RESULTS_PER_PAGE,
  },
  [SEARCH_VIEW_PREFERENCE]: {
    apiParam: 'search_view',
    defaultValue: 'grid',
    serialize: (value) => serializeView(value, searchViews),
    unserialize: (value) => unserializeView(value, searchViews),
  },
  [BREEDER_PAIRING_PREFERENCE]: {
    apiParam: 'breeding_pairing_grouping',
    defaultValue: 'male',
    serialize: (value) => serializeView(value, breederPairingPreferences),
    unserialize: (value) => unserializeView(value, breederPairingPreferences),
  },
  [INVENTORY_PER_PAGE_PREFERENCE]: {
    apiParam: 'inventory_number_of_rows',
  },
  [INVENTORY_VIEW_PREFERENCE]: {
    apiParam: 'inventory_view',
    defaultValue: 'list',
    serialize: (value) => serializeView(value, inventoryViews),
    unserialize: (value) => unserializeView(value, inventoryViews),
  },
  [INVENTORY_COLUMNS_PREFERENCE]: {
    apiParam: 'inventory_column_preferences',
    defaultValue: inventoryDefaultColumns.map(([{ id }, isVisible]) => ({
      column: id.description,
      visible: isVisible,
    })),
    serialize: serializeColumns,
    unserialize: (value) => unserializeColumns(value, inventoryDefaultColumns),
  },
  [HUSBANDRY_INVENTORY_VIEW_PREFERENCE]: {
    apiParam: 'husbandry_inventory_view',
    defaultValue: 'list',
    serialize: (value) => serializeView(value, inventoryViews),
    unserialize: (value) => unserializeView(value, inventoryViews),
  },
  [HUSBANDRY_INVENTORY_COLUMNS_PREFERENCE]: {
    apiParam: 'husbandry_inventory_column_preferences',
    defaultValue: husbandryInventoryDefaultColumns.map(([{ id }, isVisible]) => ({
      column: id.description,
      visible: isVisible,
    })),
    serialize: serializeColumns,
    unserialize: (value) => unserializeColumns(value, husbandryInventoryDefaultColumns),
  },
  [OFFSPRING_PRIVATE_PER_PAGE_PREFERENCE]: {
    apiParam: 'offspring_number_of_rows',
  },
  [OFFSPRING_PRIVATE_COLUMNS_PREFERENCE]: {
    apiParam: 'offspring_column_preferences',
    defaultValue: offspringDefaultColumns.map(([{ id }, isVisible]) => ({
      column: id.description,
      visible: isVisible,
    })),
    serialize: serializeColumns,
    unserialize: (value) => unserializeColumns(value, offspringDefaultColumns),
  },
  [OFFSPRING_PRIVATE_VIEW_PREFERENCE]: {
    apiParam: 'offspring_view',
    defaultValue: 'list',
    serialize: (value) => serializeView(value, offspringViews),
    unserialize: (value) => unserializeView(value, offspringViews),
  },
  [OFFSPRING_PUBLIC_PER_PAGE_PREFERENCE]: {
    apiParam: 'public_offspring_number_of_rows',
    defaultValue: DEFAULT_RESULTS_PER_PAGE,
  },
  [OFFSPRING_PUBLIC_COLUMNS_PREFERENCE]: {
    apiParam: 'public_offspring_column_preferences',
    defaultValue: offspringDefaultColumns.map(([{ id }, isVisible]) => ({
      column: id.description,
      visible: isVisible,
    })),
    serialize: serializeColumns,
    unserialize: (value) => unserializeColumns(value, offspringDefaultColumns),
  },
  [OFFSPRING_PUBLIC_VIEW_PREFERENCE]: {
    apiParam: 'public_offspring_view',
    defaultValue: 'card',
    serialize: (value) => serializeView(value, offspringViews),
    unserialize: (value) => unserializeView(value, offspringViews),
  },
  [SIBLINGS_COLUMNS_PREFERENCE]: {
    apiParam: 'siblings_column_preferences',
    defaultValue: siblingsDefaultColumns.map(([{ id }, isVisible]) => ({
      column: id.description,
      visible: isVisible,
    })),
    serialize: serializeColumns,
    unserialize: (value) => unserializeColumns(value, siblingsDefaultColumns),
  },
  [INDEX_VIEW_PREFERENCE]: {
    apiParam: 'index_view',
    defaultValue: 'list',
    serialize: (value) => serializeView(value, indexViews),
    unserialize: (value) => unserializeView(value, indexViews),
  },
  [FOLLOWING_STORES_PREFERENCE]: {
    apiParam: 'following_stores_view',
    defaultValue: 'grid',
    serialize: (value) => serializeView(value, followingViews),
    unserialize: (value) => unserializeView(value, followingViews),
  },
  [BLOCKED_STORES_PREFERENCE]: {
    apiParam: 'blocked_stores_view',
    defaultValue: 'grid',
    serialize: (value) => serializeView(value, blockedViews),
    unserialize: (value) => unserializeView(value, blockedViews),
  },
  [SNAKE_ORDERS_PREFERENCE]: {
    apiParam: 'snake_orders_view',
    defaultValue: 'list',
    serialize: (value) => serializeView(value, snakeOrdersView),
    unserialize: (value) => unserializeView(value, snakeOrdersView),
  },
  [LIKED_SAVED_ANIMALS_PREFERENCES]: {
    apiParam: 'liked_saved_animals_view',
    defaultValue: 'grid',
    serialize: (value) => serializeView(value, likedSavedViews),
    unserialize: (value) => unserializeView(value, likedSavedViews),
  },
  [RADIUS_PREFERENCES]: {
    apiParam: 'radius_preferences',
    serialize: (value) => value,
    defaultValue: LOCAL_RADIUS,
  },
  [POSTAL_CODE_PREFERENCES]: {
    apiParam: 'postal_code_for_listing_search',
    serialize: (value) => value,
    unserialize: (value) => value,
  },
  [BREEDING_PAIRING_SEASON]: {
    apiParam: 'breeding_pairing_season',
    serialize: (value) => value,
    unserialize: (value) => value,
  },
  [SHOW_SOCIAL_ACTIONS_ON_LISTINGS_DETAIL]: {
    apiParam: 'show_social_actions_on_listings_detail',
    serialize: (value) => value,
    unserialize: (value) => value,
  },
};
