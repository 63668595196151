// Purpose:
//
// This context provides a variable `backOrForwardButtonWasPressed` that tells us if the current URL
// was reached via the back or forward buttons. This is useful for certain kinds of state
// restoral (e.g. with scroll position)
//
// Notes:
//
// 1. The key implementation detail is that we monkey path the `history` object's methods
// to send a custom event when the URL changes for any reason. By comparing this event, which will
// happen no matter what, with the JS built-in `popstate` event that happens when forward/back was
// pressed, we can determine if the user pressed the back button.
// 2. It is key that this is in a global context because we do not want to risk run this code
// multiple times in parallel.
//
import { useCallback, useEffect, useState, createContext } from 'react';

type BackForwardButtonContextType = {
  backOrForwardButtonWasPressed: boolean;
};

const monkeyPatchHistoryObjectToSendLocationChangeEvents = () => {
  const oldPushState = history.pushState;

  history.pushState = function pushState() {
    const returnValue = oldPushState.apply(this, arguments as any);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return returnValue;
  };

  const oldReplaceState = history.replaceState;
  history.replaceState = function replaceState() {
    const returnValue = oldReplaceState.apply(this, arguments as any);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
    return returnValue;
  };

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
};

export const BackForwardButtonContext = createContext<BackForwardButtonContextType>({
  backOrForwardButtonWasPressed: false,
});

export const BackForwardButtonProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [backOrForwardButtonWasPressed, setBackOrForwardButtonWasPressed] =
    useState<boolean>(false);

  const onPopState = useCallback(() => {
    setBackOrForwardButtonWasPressed(true);
  }, []);

  const onLocationChange = useCallback(() => {
    setBackOrForwardButtonWasPressed(false);
  }, []);

  useEffect(() => {
    monkeyPatchHistoryObjectToSendLocationChangeEvents();

    window.addEventListener('locationchange', onLocationChange);
    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
      window.removeEventListener('locationchange', onLocationChange);
    };
  }, []);

  return (
    <BackForwardButtonContext.Provider value={{ backOrForwardButtonWasPressed }}>
      {children}
    </BackForwardButtonContext.Provider>
  );
};
