import PropTypes from 'prop-types';
import Badge, {
  BADGE_BLUE,
  BADGE_GOLD,
  BADGE_GREEN,
  BADGE_ORANGE,
  BADGE_PINK,
  BADGE_PURPLE,
  BADGE_GRAY,
  BADGE_DARK_GRAY,
  BADGE_MINT,
  BADGE_LIGHT_BLUE,
} from 'components/Badge/Badge';

const traitsTypes = {
  'dom-codom': BADGE_BLUE,
  'het-rec': BADGE_ORANGE,
  other: BADGE_DARK_GRAY,
  'pos-rec': BADGE_GOLD,
  'super-dom-codom': BADGE_PURPLE,
  'vis-rec': BADGE_PINK,
  'pos-other': BADGE_GRAY,
  polygenic: BADGE_LIGHT_BLUE,
  locality: BADGE_GREEN,
  Combination: BADGE_MINT,
  Subcategory: BADGE_GRAY,
};

/**
 * @deprecated Use https://mui.com/material-ui/react-chip/ instead.
 */
const TraitBadge = ({ element, name, type, ...props }) => {
  return (
    <Badge element={element} type={traitsTypes[type]} {...props}>
      {name}
    </Badge>
  );
};

TraitBadge.propTypes = {
  element: PropTypes.node,
  name: PropTypes.node,
  type: PropTypes.string,
};

export default TraitBadge;
