/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnimalSex,
    AnimalSexFromJSON,
    AnimalSexFromJSONTyped,
    AnimalSexToJSON,
} from './AnimalSex';
import {
    ApproximateDate,
    ApproximateDateFromJSON,
    ApproximateDateFromJSONTyped,
    ApproximateDateToJSON,
} from './ApproximateDate';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';

/**
 * PublicAnimalSerializer
 * @export
 * @interface PublicAnimal
 */
export interface PublicAnimal {
    /**
     * 
     * @type {number}
     * @memberof PublicAnimal
     */
    key?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicAnimal
     */
    readonly path: string;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof PublicAnimal
     */
    readonly thumbImage: Array<ImageVariants> | null;
    /**
     * 
     * @type {ApproximateDate}
     * @memberof PublicAnimal
     */
    birthDate?: ApproximateDate;
    /**
     * 
     * @type {AnimalSex}
     * @memberof PublicAnimal
     */
    sex?: AnimalSex | null;
    /**
     * 
     * @type {string}
     * @memberof PublicAnimal
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAnimal
     */
    description?: string | null;
}

export function PublicAnimalFromJSON(json: any): PublicAnimal {
    return PublicAnimalFromJSONTyped(json, false);
}

export function PublicAnimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicAnimal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'path': json['path'],
        'thumbImage': (json['thumb_image'] === null ? null : (json['thumb_image'] as Array<any>).map(ImageVariantsFromJSON)),
        'birthDate': !exists(json, 'birth_date') ? undefined : ApproximateDateFromJSON(json['birth_date']),
        'sex': !exists(json, 'sex') ? undefined : AnimalSexFromJSON(json['sex']),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function PublicAnimalToJSON(value?: PublicAnimal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'birth_date': ApproximateDateToJSON(value.birthDate),
        'sex': AnimalSexToJSON(value.sex),
        'title': value.title,
        'description': value.description,
    };
}

