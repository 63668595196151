import React, { ReactNode, ReactElement } from 'react';

import { ExpoContextProvider } from 'pages/Search/ExpoContext';
import { FeaturedStoresProvider } from 'pages/Search/hooks/useFeaturedStores';
import { FollowedStoresProvider } from 'pages/Search/hooks/useFollowedStores';
import { BackForwardButtonProvider } from 'context/BackForwardButtonContext';
import { DarkModeProvider } from 'context/DarkMode/DarkModeContext';
import { DocumentHeadProvider } from 'context/DocumentHeadContext/DocumentHeadContext';
import { GeolocationProvider } from 'context/GeolocationContext';
import { ModalProvider } from 'context/ModalContext/ModalContext';
import { ToastProvider } from 'context/ToastContext/ToastContext';
import { CustomHistoryProvider } from 'hooks/useBackButton/useManageUrls';
import { WindowSizeProvider } from 'hooks/utils/ui/useWindowSize';
import { GlobalContext } from './GlobalContext';
import { LocationFilterContextProvider } from './LocationFilterContext/LocationFilterContext';
import { NativeAppGesturesContext } from './NativeAppGesturesContext';
import { NativeAppPullToReloadContext } from './NativeAppPullToReloadContext';
import { NativeAppReviewModalProvider } from './NativeAppReviewModalContext';
import { SidebarContextProvider } from './SideBarContext/SidebarContext';
import { SnackbarProvider } from './SnackbarContext';
import { TabsContextProvider } from './TabsContext';
import { ThemeContext } from './ThemeContext/ThemeContext';
import { UrlParamsContext } from './UrlParamsContext';
import { UserProvider } from './UserContext/UserContext';

const contextProviders: ContextProvider[] = [
  GlobalContext,
  NativeAppPullToReloadContext,
  NativeAppGesturesContext,
  ToastProvider,
  UserProvider,
  DarkModeProvider,
  ThemeContext,
  WindowSizeProvider,
  SnackbarProvider,
  ModalProvider,
  DocumentHeadProvider,
  GeolocationProvider,
  BackForwardButtonProvider,
  CustomHistoryProvider,
  TabsContextProvider,
  LocationFilterContextProvider,
  SidebarContextProvider,
  ExpoContextProvider,
  NativeAppReviewModalProvider,
  UrlParamsContext,
  FeaturedStoresProvider,
  FollowedStoresProvider,
];

type Child = ReactElement | null;

type ContextProvider = React.ComponentType<{ children: ReactNode }>;

export const composeProviders = (children: ReactNode, providers: ContextProvider[]): Child => {
  // No more providers to compose. Render the children.
  if (providers.length === 0) {
    return children as Child;
  }

  const [SingleProvider] = providers;
  const nestedProviders = composeProviders(children, providers.slice(1));

  return <SingleProvider>{nestedProviders}</SingleProvider>;
};

/**
 * Contains all the global contexts used by App.jsx.
 * Kept here to avoid polluting App.jsx every time a new context is added.
 */
const AllGlobalContexts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return composeProviders(children, contextProviders);
};

export { AllGlobalContexts };
