/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BreedingPairingDetailsVisibility,
    BreedingPairingDetailsVisibilityFromJSON,
    BreedingPairingDetailsVisibilityFromJSONTyped,
    BreedingPairingDetailsVisibilityToJSON,
} from './BreedingPairingDetailsVisibility';

/**
 * 
 * @export
 * @interface PatchedBreedingPairingDetailsRequest
 */
export interface PatchedBreedingPairingDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedBreedingPairingDetailsRequest
     */
    season?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedBreedingPairingDetailsRequest
     */
    sireId?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedBreedingPairingDetailsRequest
     */
    damId?: number;
    /**
     * 
     * @type {BreedingPairingDetailsVisibility}
     * @memberof PatchedBreedingPairingDetailsRequest
     */
    visibility?: BreedingPairingDetailsVisibility | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedBreedingPairingDetailsRequest
     */
    offspringGroupId?: number | null;
}

export function PatchedBreedingPairingDetailsRequestFromJSON(json: any): PatchedBreedingPairingDetailsRequest {
    return PatchedBreedingPairingDetailsRequestFromJSONTyped(json, false);
}

export function PatchedBreedingPairingDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedBreedingPairingDetailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'season': !exists(json, 'season') ? undefined : json['season'],
        'sireId': !exists(json, 'sire_id') ? undefined : json['sire_id'],
        'damId': !exists(json, 'dam_id') ? undefined : json['dam_id'],
        'visibility': !exists(json, 'visibility') ? undefined : BreedingPairingDetailsVisibilityFromJSON(json['visibility']),
        'offspringGroupId': !exists(json, 'offspring_group_id') ? undefined : json['offspring_group_id'],
    };
}

export function PatchedBreedingPairingDetailsRequestToJSON(value?: PatchedBreedingPairingDetailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'season': value.season,
        'sire_id': value.sireId,
        'dam_id': value.damId,
        'visibility': BreedingPairingDetailsVisibilityToJSON(value.visibility),
        'offspring_group_id': value.offspringGroupId,
    };
}

