import React, { ReactNode, useState } from 'react';
import { NestedHelpers, NestedItem } from './types';

type ExpandedHelpers = NestedHelpers & { resetMenu: (timeout?: number) => void };

export type NestedMenuProps = {
  children: (helpers: ExpandedHelpers, subMenu?: NestedItem[]) => ReactNode;
};

export const NestedMenu: React.FC<NestedMenuProps> = ({ children }) => {
  const [menu, setMenu] = useState<any>();
  const nestedHelpers: ExpandedHelpers = {
    goTo: (previous) => (current) => {
      setMenu({ previous: { current: previous, previous: menu?.current }, current });
    },
    goBack: () => {
      const { previous, current } = menu?.previous;
      setMenu({ previous, current });
    },
    resetMenu: (timeout?: number) => {
      // Often we want to reset the state of a nested menu while closing it
      // To avoid the menu reseting while it's still visible to the user
      // we reset the menu asyncronously by setting a timeout (suggested interval: 300ms)
      if (timeout) {
        setTimeout(() => {
          setMenu(undefined);
        }, timeout);
      } else {
        setMenu(undefined);
      }
    },
  };

  return <>{children(nestedHelpers, menu?.current)}</>;
};
