import { MINIMAL_CATEGORY, STORE_MATCH, TRAIT_MATCH } from '../types';

export const defaultCategoryOption = (
  query: string,
  region: string,
  categoryPath: string | undefined
) => {
  return {
    label: `Search keyword "${decodeURIComponent(query)}"`,
    href: `/${region}/c/${categoryPath}?q=${query}`,
    type: 'categorySearch',
  };
};

export const adsInCategory = (results: MINIMAL_CATEGORY[], query: string, region: string) => {
  return results.map(({ name, path, scientific_name }) => {
    const label = scientific_name ? `${name} (${scientific_name})` : `${name}`;
    return {
      label,
      href: `/${region}/c/${path}`,
      type: 'category',
    };
  });
};

export const adsWithTrait = (results: TRAIT_MATCH[], query: string, region: string) => {
  return Object.values(results).map(({ category, categoryPath, name }) => ({
    label: `Ads with ${name} in ${category}`,
    href: `/${region}/c/${categoryPath}/trait/${encodeURIComponent(name.toLowerCase())}`,
    type: 'traitInCategory',
  }));
};

export const defaultStoreOption = (
  query: string,
  region: string,
  categoryValue: string | undefined
) => {
  return {
    label: `Stores matching "${decodeURIComponent(query)}"`,
    href: `/${region}/stores?search=${query}&category=${categoryValue}`,
    type: 'storeSearch',
  };
};

export const storesInCategory = (results: MINIMAL_CATEGORY[], query: string, region: string) => {
  return results.map(({ name, value, parent_value, parent_label }) => ({
    label: parent_label ? `Stores in ${name} (${parent_label})` : `Stores in ${name}`,
    href: `/${region}/stores?category=${parent_value || value}`,
    type: 'storeInCategory',
  }));
};

export const storesWithName = (results: STORE_MATCH[], isLoggedIn: boolean) => {
  return results.map(({ stub, name, owner, unauthOwnerVisibility }) => ({
    label: unauthOwnerVisibility || isLoggedIn ? `${name} by ${owner}` : name,
    href: `/stores/${stub}`,
    type: 'store',
  }));
};
