/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedLabelConfigRequest
 */
export interface PatchedLabelConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelConfigRequest
     */
    css?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelConfigRequest
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelConfigRequest
     */
    name?: string;
}

export function PatchedLabelConfigRequestFromJSON(json: any): PatchedLabelConfigRequest {
    return PatchedLabelConfigRequestFromJSONTyped(json, false);
}

export function PatchedLabelConfigRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedLabelConfigRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'css': !exists(json, 'css') ? undefined : json['css'],
        'html': !exists(json, 'html') ? undefined : json['html'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function PatchedLabelConfigRequestToJSON(value?: PatchedLabelConfigRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'css': value.css,
        'html': value.html,
        'name': value.name,
    };
}

