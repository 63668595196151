import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ThemeContainer from 'pages/ReactComponents/theme/ThemeContainer';

const Index = lazy(() => import('pages/ReactComponents/ReactComponents'));
const Buttons = lazy(() => import('pages/ReactComponents/components/Buttons'));
const Chips = lazy(() => import('pages/ReactComponents/components/Chips'));

export const reactComponentsRoutes: RouteObject[] = [
  {
    path: 'testing/react-components',
    children: [
      {
        path: '',
        element: (
          <ThemeContainer>
            <Index />
          </ThemeContainer>
        ),
      },
      {
        path: 'buttons',
        element: (
          <ThemeContainer>
            <Buttons />
          </ThemeContainer>
        ),
      },
      {
        path: 'Chips',
        element: (
          <ThemeContainer>
            <Chips />
          </ThemeContainer>
        ),
      },
    ],
  },
];
