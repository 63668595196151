import { useState } from 'react';
import React from 'react';
import { DrawerAnchor } from 'components/experimental/Drawer/helper';
import { useOpenState } from './utils/useOpenState';

export type FilterDrawerController = {
  selectedFilter: string | null;
  onSetSelectedFilter: React.Dispatch<React.SetStateAction<string | null>>;
  masterDrawer: {
    isOpen: boolean;
    noTransitionEffect: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
    navigateToSelf: () => void;
  };
  dedicatedDrawer: {
    isOpen: boolean;
    anchor: DrawerAnchor;
    open: () => void;
    close: () => void;
    toggle: () => void;
    setAnchor: React.Dispatch<React.SetStateAction<DrawerAnchor>>;
    navigateToSelf: (sections: string, anchor?: DrawerAnchor) => void;
  };
  closeAllDrawer?: () => void;
};

export const useFilterDrawer = () => {
  const [dedicatedDrawerAnchor, setDedicatedDrawerAnchor] = useState<DrawerAnchor>('bottom');
  // This state is used to disable the transition effect for the master drawer when navigating from sub/dedicated drawers
  // To give impression of a single drawer, we need to disable the transition effect when navigating from sub/dedicated drawers.
  // But we need to reset it to false when the master drawer is closed.
  // Otherwise, the transition effect will be disabled when the master drawer is opened again.
  const [noTransitionEffectForMasterDrawer, setNoTransitionEffectForMasterDrawer] = useState(false);

  const {
    isOpen: isMasterDrawerOpen,
    open: openMasterDrawer,
    close: closeMasterDrawer,
    toggle: toggleMasterDrawer,
  } = useOpenState();

  const {
    isOpen: isDedicatedDrawerOpen,
    open: openDedicatedDrawer,
    close: closeDedicatedDrawer,
    toggle: toggleDedicatedDrawer,
  } = useOpenState();

  // This function is used to navigate to the master filter drawer from dedicated drawers
  const navigateToMasterDrawer = () => {
    setNoTransitionEffectForMasterDrawer(true);
    openMasterDrawer();
    closeDedicatedDrawer();
  };

  const navigateToDedicateDrawer = (sections: string, anchor: DrawerAnchor = 'right') => {
    setDedicatedDrawerAnchor(anchor);
    openDedicatedDrawer();
  };

  return {
    masterDrawer: {
      isOpen: isMasterDrawerOpen,
      open: openMasterDrawer,
      close: closeMasterDrawer,
      toggle: toggleMasterDrawer,
      navigateToSelf: navigateToMasterDrawer,
      noTransitionEffect: noTransitionEffectForMasterDrawer,
    },
    dedicatedDrawer: {
      isOpen: isDedicatedDrawerOpen,
      anchor: dedicatedDrawerAnchor,
      open: openDedicatedDrawer,
      close: closeDedicatedDrawer,
      toggle: toggleDedicatedDrawer,
      setAnchor: setDedicatedDrawerAnchor,
      navigateToSelf: navigateToDedicateDrawer,
    },
  } as FilterDrawerController;
};
