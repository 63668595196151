import { RouteObject } from 'react-router-dom';

const ListOrderSnake = () => import('pages/SnakeOrder/pages/List');
const DetailsOrderSnake = () => import('pages/SnakeOrder/pages/DetailsSeller');
const DetailsBuyerOrderSnake = () => import('pages/SnakeOrder/pages/DetailsBuyer');

export const ordersRoutes: RouteObject[] = [
  {
    path: 'orders',
    children: [
      { path: '', lazy: ListOrderSnake },
      { path: 'sales/:id', lazy: DetailsOrderSnake },
      { path: 'purchases/:id', lazy: DetailsBuyerOrderSnake },
    ],
  },
];
