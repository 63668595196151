import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { VIABLE_EGGS_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const VIABLE_EGGS_COLUMN = Symbol('VIABLE_EGGS_COLUMN');

const viableEggsColumn = {
  id: VIABLE_EGGS_COLUMN,
  ordering: VIABLE_EGGS_ORDERING,
  label: 'Viable Eggs',
  shortLabel: 'Viable',
  hideable: true,
  className: style.countColumn,
  defaultDirection: DESCENDING,

  render: ({ viable_eggs_count_display }) => viable_eggs_count_display,
};

export default viableEggsColumn;
