/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `archive` - Archive
 * * `mark_read` - Mark as Read
 * * `mark_unread` - Mark as Unread
 * @export
 */
export const BulkThreadActionEnum = {
    Archive: 'archive',
    MarkRead: 'mark_read',
    MarkUnread: 'mark_unread'
} as const;
export type BulkThreadActionEnum = typeof BulkThreadActionEnum[keyof typeof BulkThreadActionEnum];


export function BulkThreadActionEnumFromJSON(json: any): BulkThreadActionEnum {
    return BulkThreadActionEnumFromJSONTyped(json, false);
}

export function BulkThreadActionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkThreadActionEnum {
    return json as BulkThreadActionEnum;
}

export function BulkThreadActionEnumToJSON(value?: BulkThreadActionEnum | null): any {
    return value as any;
}

