// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabsWrapper--VFJhm{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;white-space:nowrap;max-width:100vw;overflow:scroll;scrollbar-width:none;font-size:1rem;box-sizing:border-box}.tabsWrapper--VFJhm::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Navigation/mobile/components/AnimalsMenu/AnimalsMenu.module.scss","webpack://./mysite/static/react-app/src/style/typo/sizes.scss"],"names":[],"mappings":"AAMA,oBACI,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,oBAAA,CACA,cCQG,CDPH,qBAAA,CAEA,uCACI,YAAA","sourcesContent":["\n@use 'style/spacing' as *;\n@use 'style/breakpoints' as *;\n@use 'style/typo' as *;\n\n\n.tabsWrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n\n    white-space: nowrap;\n    max-width: 100vw;\n    overflow: scroll;\n    scrollbar-width: none;\n    font-size: $f_md;\n    box-sizing: border-box;\n\n    &::-webkit-scrollbar{\n        display: none;\n    }\n}","// Used fonts\n// PX:\n// 40px -> 2x\n// 32px -> 6x\n// 28px -> 4x\n// 24px -> 32x\n// 20px -> 29x\n// 19px -> 19x\n// 18px -> 18x\n// 16px -> 44x\n// 14px -> 71x\n// 13px -> 3x\n// 12px -> 51x\n// 11px -> 9x\n// 10px -> 2x\n// 9.5px -> 1x\n// 8px -> 1x\n// Rems ( A lot - 215x)\n// 1.125rem, 1.375rem\n\n$f_xxxl: 2.5rem; // 40px\n$f_xxl: 2rem; // 32px\n$f_xl: 1.5rem; // 24px\n$f_lg: 1.25rem; // 20px\n$f_md: 1rem; // 16px\n$f_sm: 0.875rem; // 14px\n$f_xs: 0.75rem; // 12px\n$f_xxs: 0.625rem; // 10px\n$f_xxxs: 0.5rem; // 8px\n\n$fonts: (\n  'f_xxxl': $f_xxxl,\n  'f_xxl': $f_xxl,\n  'f_xl': $f_xl,\n  'f_lg': $f_lg,\n  'f_md': $f_md,\n  'f_sm': $f_sm,\n  'f_xs': $f_xs,\n  'f_xxs': $f_xxs,\n  'f_xxxs': $f_xxxs,\n);\n\n:export {\n  @each $size, $value in $fonts {\n    #{unquote($size)}: #{$value};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxxxxl": `6.5rem`,
	"xxxxl": `4rem`,
	"xxxl": `2.5rem`,
	"xxl": `2rem`,
	"xl": `1.5rem`,
	"lg": `1.25rem`,
	"md": `1rem`,
	"sm": `0.875rem`,
	"xs": `0.75rem`,
	"xxs": `0.5rem`,
	"xxxs": `0.25rem`,
	"xxxxs": `0.125rem`,
	"borderRadius": `0.375rem`,
	"mobileMedium": `450`,
	"mobileLarge": `576`,
	"tablet": `768`,
	"tabletLarge": `820`,
	"desktop": `992`,
	"desktopLarge": `1200`,
	"f_xxxl": `2.5rem`,
	"f_xxl": `2rem`,
	"f_xl": `1.5rem`,
	"f_lg": `1.25rem`,
	"f_md": `1rem`,
	"f_sm": `0.875rem`,
	"f_xs": `0.75rem`,
	"f_xxs": `0.625rem`,
	"f_xxxs": `0.5rem`,
	"fw_thin": `100`,
	"fw_extra_light": `200`,
	"fw_light": `300`,
	"fw_regular": `400`,
	"fw_regular_medium": `450`,
	"fw_medium": `500`,
	"fw_semi_bold": `600`,
	"fw_bold": `700`,
	"fw_extra_bold": `800`,
	"fw_black": `900`,
	"tabsWrapper": `tabsWrapper--VFJhm`
};
export default ___CSS_LOADER_EXPORT___;
