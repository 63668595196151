import { Link } from 'react-router-dom';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { GROUP_ID_ORDERING } from 'api/offspring/ordering';

export const GROUP_ID_COLUMN = Symbol('GROUP_ID_COLUMN');

const groupIdColumn = {
  id: GROUP_ID_COLUMN,
  ordering: GROUP_ID_ORDERING,
  label: 'Group ID',
  hideable: true,
  className: style.groupIdColumn,

  render: ({ group_id, path, view_url }) => (
    // view_url Is private view
    <Link to={view_url ? `${path}edit/` : path} className={style.animalIdLink}>
      {group_id}
    </Link>
  ),
};

export default groupIdColumn;
