import { Checkbox } from '@mui/material';
import { ACCEPT_OFFERS_ORDERING } from 'api/inventory/ordering';

export const ACCEPT_OFFERS_COLUMN = Symbol('ACCEPT_OFFERS_COLUMN');

const acceptOffersColumn = {
  id: ACCEPT_OFFERS_COLUMN,
  ordering: ACCEPT_OFFERS_ORDERING,
  label: 'Accept Offers',
  hideable: true,
  render: ({ accept_offers }: { accept_offers: boolean }) => {
    return <Checkbox disabled checked={accept_offers} />;
  },
};

export default acceptOffersColumn;
