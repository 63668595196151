import { RouteObject } from 'react-router-dom';

const Customers = () => import('pages/Customers/Customers');

export const customersRoutes: RouteObject[] = [
  {
    path: 'customers/',
    children: [{ path: '', lazy: Customers }],
  },
];
