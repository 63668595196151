/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { useSDKList } from "api/utilities/useAPIList";
import { FollowedStoresApi } from "../sdk/apis/FollowedStoresApi";
import { configuration } from "./configuration";

export const useFollowedStores = () => {
  const instance = new FollowedStoresApi(configuration);

  const createStores = useAPIHook(instance.followedStoresCreate.bind(instance));
  const destroyStores = useAPIHook(instance.followedStoresDestroy.bind(instance));
  const listStores = useAPIHook(instance.followedStoresList.bind(instance));

  const stores = useSDKList(listStores.callback, false);

  return {
    error: createStores.error || destroyStores.error || listStores.error,
    isLoading: createStores.isLoading || destroyStores.isLoading || listStores.isLoading,
    stores: {
      data: stores.data,
      parametrizedFetch: stores.parametrizedFetch,
      refetch: stores.refetch,
      params: stores.params,
      setParams: stores.setParams,
    },

    createStores: createStores.callback,
    destroyStores: destroyStores.callback,
    listStores: listStores.callback,
  };
};
