/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUrls
 */
export interface UserUrls {
    /**
     * 
     * @type {string}
     * @memberof UserUrls
     */
    messageInbox: string;
    /**
     * 
     * @type {string}
     * @memberof UserUrls
     */
    membership: string;
    /**
     * 
     * @type {string}
     * @memberof UserUrls
     */
    profile: string;
    /**
     * 
     * @type {string}
     * @memberof UserUrls
     */
    profileEdit: string;
}

export function UserUrlsFromJSON(json: any): UserUrls {
    return UserUrlsFromJSONTyped(json, false);
}

export function UserUrlsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUrls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageInbox': json['message_inbox'],
        'membership': json['membership'],
        'profile': json['profile'],
        'profileEdit': json['profile_edit'],
    };
}

export function UserUrlsToJSON(value?: UserUrls | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message_inbox': value.messageInbox,
        'membership': value.membership,
        'profile': value.profile,
        'profile_edit': value.profileEdit,
    };
}

