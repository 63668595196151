/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayPreferencesRetrieveNotificationsIso,
    DisplayPreferencesRetrieveNotificationsIsoFromJSON,
    DisplayPreferencesRetrieveNotificationsIsoFromJSONTyped,
    DisplayPreferencesRetrieveNotificationsIsoToJSON,
} from './DisplayPreferencesRetrieveNotificationsIso';

/**
 * 
 * @export
 * @interface DisplayPreferencesRetrieveNotifications
 */
export interface DisplayPreferencesRetrieveNotifications {
    /**
     * 
     * @type {string}
     * @memberof DisplayPreferencesRetrieveNotifications
     */
    settingsUrl: string;
    /**
     * 
     * @type {DisplayPreferencesRetrieveNotificationsIso}
     * @memberof DisplayPreferencesRetrieveNotifications
     */
    iso: DisplayPreferencesRetrieveNotificationsIso;
}

export function DisplayPreferencesRetrieveNotificationsFromJSON(json: any): DisplayPreferencesRetrieveNotifications {
    return DisplayPreferencesRetrieveNotificationsFromJSONTyped(json, false);
}

export function DisplayPreferencesRetrieveNotificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPreferencesRetrieveNotifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settingsUrl': json['settings_url'],
        'iso': DisplayPreferencesRetrieveNotificationsIsoFromJSON(json['iso']),
    };
}

export function DisplayPreferencesRetrieveNotificationsToJSON(value?: DisplayPreferencesRetrieveNotifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings_url': value.settingsUrl,
        'iso': DisplayPreferencesRetrieveNotificationsIsoToJSON(value.iso),
    };
}

