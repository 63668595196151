import HumanReadableError from 'errors/HumanReadableError';
import { fetchApi } from 'api/utils';

export const getAllTags = async () => {
  const response = await fetchApi(`/tags/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': window.CSRF_TOKEN,
    },
  });

  const json = await response.json();

  if (!response.ok) {
    throw new HumanReadableError(json.message || 'Failed to get tags', Boolean(json.message));
  }

  return json;
};

export const createTag = async (value) => {
  const payload = {
    name: value,
    color: '#F12E41',
  };

  const response = await fetchApi(`/tags/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': window.CSRF_TOKEN,
    },
    body: JSON.stringify(payload),
  });

  const json = await response.json();

  if (!response.ok) {
    throw new HumanReadableError(json.message || 'Failed to create tag.', Boolean(json.message));
  }

  return json;
};

export const getBulkTags = async (keys) => {
  const ids = keys.reduce((acc, value) => `${acc}${value},`, '').slice(0, -1);
  const response = await fetchApi(`/animals/bulk_tags/?ids=${ids}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': window.CSRF_TOKEN,
    },
  });

  const json = await response.json();

  if (!response.ok) {
    throw new HumanReadableError(json.message || 'Failed to get tags', Boolean(json.message));
  }

  return json;
};
