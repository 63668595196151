import { RouteObject } from 'react-router-dom';

const Messenger = () => import('pages/Messenger/Messenger');
const MessengerThread = () => import('pages/Messenger/MessengerThread');

export const messagesRoutes: RouteObject[] = [
  {
    path: 'messages',
    children: [
      { path: ':username', lazy: Messenger },
      { path: ':username/:thread', lazy: MessengerThread },
    ],
  },
];
