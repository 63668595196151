import { useNavigate } from 'react-router-dom';
import { Tabs } from 'pages/Dashboard/components/Tabs/Tabs';
import {
  isAnimalActivitiesOverviewPath,
  isAnimalActivityListPath,
  isEditAnimalPath,
} from 'services/urls';
import style from './InventoryAnimalMenu.module.scss';

export enum AnimalPage {
  Edit = 'edit',
  ActivityOverview = 'activityOverview',
  ActivityList = 'activityList',
}

export const animalTabs = [
  { title: 'Edit', value: AnimalPage.Edit },
  { title: 'Husbandry', value: AnimalPage.ActivityOverview },
  { title: 'Activity Log', value: AnimalPage.ActivityList },
];

export const InventoryAnimalMenu: React.FC = () => {
  const { pathname } = window.location;

  let value;
  if (isEditAnimalPath(pathname)) value = AnimalPage.Edit;
  else if (isAnimalActivitiesOverviewPath(pathname)) value = AnimalPage.ActivityOverview;
  else if (isAnimalActivityListPath(pathname)) value = AnimalPage.ActivityList;

  const navigate = useNavigate();
  const handleChange = (value: AnimalPage) => {
    const animalId = parseInt(pathname.split('/').pop()!, 10);

    if (value === AnimalPage.Edit) {
      navigate(`/inventory/edit/${animalId}`);
    } else if (value === AnimalPage.ActivityOverview) {
      navigate(`/husbandry/activities/overview/${animalId}`);
    } else if (value === AnimalPage.ActivityList) {
      navigate(`/husbandry/activities/${animalId}`);
    }
  };

  return (
    <div className={style.tabsWrapper}>
      <Tabs onChange={handleChange} value={value as string} tabs={animalTabs} />
    </div>
  );
};
