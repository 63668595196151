import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

/**
 * Sends an event to GTM
 * @param props object  - event data
 */
export const addEvent = (props = {}) => {
  if (window?.dataLayer) {
    window.dataLayer.push(props);
  }
};

/**
 * Sends a pageview event to GTM
 * @param props  object - event data
 */
export const addPageViewEvent = (props = {}) => {
  addEvent({
    event: 'pageview',
    ...props,
  });
};

/**
 * Tracks route changes and sends them to GTM
 * @returns null
 */
export const GtmRouteTracker = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    addPageViewEvent({ url: pathname });
  }, [pathname]);

  return null;
};
