/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `local_pickup_only` - Local Pickup Only
 * * `free_shipping` - Free Shipping
 * * `fixed_price` - Flat Rate Shipping
 * * `shipping_price_range` - Shipping Price Range
 * * `undefined` - Undefined
 * @export
 */
export const ShippingTypeEnum = {
    LocalPickupOnly: 'local_pickup_only',
    FreeShipping: 'free_shipping',
    FixedPrice: 'fixed_price',
    ShippingPriceRange: 'shipping_price_range',
    Undefined: 'undefined'
} as const;
export type ShippingTypeEnum = typeof ShippingTypeEnum[keyof typeof ShippingTypeEnum];


export function ShippingTypeEnumFromJSON(json: any): ShippingTypeEnum {
    return ShippingTypeEnumFromJSONTyped(json, false);
}

export function ShippingTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingTypeEnum {
    return json as ShippingTypeEnum;
}

export function ShippingTypeEnumToJSON(value?: ShippingTypeEnum | null): any {
    return value as any;
}

