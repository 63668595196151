import { Components, Theme } from '@mui/material';
import { colors, sizes } from '../constants';

const { gray000, gray300, white, black } = colors;

export const MuiSlider: Components<Omit<Theme, 'components'>>['MuiSlider'] = {
  styleOverrides: {
    root: {
      height: 5,
      '& .MuiSlider-thumb': {
        width: sizes.sm,
        height: sizes.sm,
        backgroundColor: white,
        // For removing ripple effect on thumb
        '&:hover, &.Mui-focusVisible, &.Mui-active': {
          boxShadow: 'none',
        },

        '&:after': {
          height: 0,
          width: 0,
        },
      },
      '& .MuiSlider-valueLabel': {
        backgroundColor: 'unset',

        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: black,
        },
        '&.MuiSlider-valueLabelOpen': {
          transform: 'translateY(90%) scale(1)',
          padding: `${sizes.xxxs} 0`,
        },
      },
      '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: gray000,
      },
      '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: gray300,
      },
    },
  },
};
