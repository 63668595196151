/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreSearchStoreRatingDetails
 */
export interface StoreSearchStoreRatingDetails {
    /**
     * 
     * @type {number}
     * @memberof StoreSearchStoreRatingDetails
     */
    fiveStarRating: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchStoreRatingDetails
     */
    feedbackRating: number | null;
}

export function StoreSearchStoreRatingDetailsFromJSON(json: any): StoreSearchStoreRatingDetails {
    return StoreSearchStoreRatingDetailsFromJSONTyped(json, false);
}

export function StoreSearchStoreRatingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreSearchStoreRatingDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fiveStarRating': json['five_star_rating'],
        'feedbackRating': json['feedback_rating'],
    };
}

export function StoreSearchStoreRatingDetailsToJSON(value?: StoreSearchStoreRatingDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'five_star_rating': value.fiveStarRating,
        'feedback_rating': value.feedbackRating,
    };
}

