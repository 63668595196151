import FiltersJar from 'api/FiltersJar';

const enumToValue = (values, key) =>
  (Array.isArray(values) ? values.find((enumValue) => enumValue === key) : values.get(key)) ?? null;

const valueToEnum = (value, values) => {
  if (Array.isArray(values)) {
    return values.find((enumValue) => enumValue === value) ?? null;
  }

  const [key] = [...values].find(([, enumValue]) => enumValue === value) ?? [];

  return key ?? null;
};

export const filterValueToQueryValue = (filterDefinitions, filterId, filterValue) => {
  const { apiParam, values, serialize } = filterDefinitions[filterId] ?? {};

  if (!apiParam) {
    return null;
  }

  let queryValue = filterValue;

  if (values) {
    queryValue = enumToValue(values, queryValue);
  }

  if (serialize) {
    queryValue = serialize(queryValue, filterDefinitions[filterId]);
  }

  return queryValue;
};

export const filtersToEntries = (filterDefinitions, filters, includeDefaults = false) => {
  let filtersToTransform = filters;

  if (includeDefaults) {
    for (const [filterId] of filtersToTransform.getDefaults()) {
      filtersToTransform = filtersToTransform.set(
        filterId,
        filtersToTransform.get(filterId) // gets default value if missing
      );
    }
  }

  return [...filtersToTransform].reduce((result, [filterId, value]) => {
    const { apiParam } = filterDefinitions[filterId] ?? {};

    if (!apiParam) {
      return result;
    }

    const queryValue = filterValueToQueryValue(filterDefinitions, filterId, value);

    return [...result, [apiParam, queryValue]];
  }, []);
};

export const filtersToQueryParams = (filterDefinitions, filters, includeDefaults = false) => {
  const entries = filtersToEntries(filterDefinitions, filters, includeDefaults);

  return new URLSearchParams(entries);
};

// This function doesn't support multiple values for single filter:
export const filtersToPayload = (filterDefinitions, filters, includeDefaults = false) => {
  const entries = filtersToEntries(filterDefinitions, filters, includeDefaults);

  return Object.fromEntries(entries);
};

export const filtersToUrl = (filterDefinitions, filters, includeDefaults = false) => {
  let queryString = filtersToQueryParams(filterDefinitions, filters, includeDefaults).toString();

  const booleanFilterIds = Reflect.ownKeys(filterDefinitions).reduce(
    (result, filterId) => [
      ...result,
      ...(filterDefinitions[filterId].unserialize === Boolean ? [filterId] : []),
    ],
    []
  );

  booleanFilterIds.forEach((filterId) => {
    const queryParam = filterDefinitions[filterId].apiParam;
    queryString = queryString.replaceAll(`${queryParam}=true`, queryParam);
  });

  if (!queryString) {
    return '';
  }

  return `?${queryString}`;
};

export const queryValueToFilterValue = (filterDefinitions, filterId, queryValue) => {
  const { unserialize, values } = filterDefinitions[filterId];
  let value = queryValue;

  if (unserialize) {
    value =
      unserialize === Boolean // Needs to handle Boolean separately, since "" == false
        ? true
        : unserialize(value, filterDefinitions[filterId]);
  }

  if (values) {
    value = valueToEnum(value, values);
  }

  return value;
};

export const queryParamsToFilters = (filterDefinitions, queryParams) => {
  const filtersIds = Reflect.ownKeys(filterDefinitions);

  return [...queryParams].reduce((result, [param, paramValue]) => {
    const matchingFilterId = filtersIds.find(
      (filterId) => filterDefinitions[filterId].apiParam === param
    );

    if (!matchingFilterId) {
      return result;
    }

    const value = queryValueToFilterValue(filterDefinitions, matchingFilterId, paramValue);

    return result.add(matchingFilterId, value);
  }, new FiltersJar());
};

export const filterDefinitionsToDefaults = (filterDefinitions) =>
  Reflect.ownKeys(filterDefinitions).reduce((result, filterId) => {
    const { defaultValue } = filterDefinitions[filterId];

    if (defaultValue == null) {
      return result;
    }

    return [...result, [filterId, defaultValue()]];
  }, []);
