import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './Spinner.module.scss';

type SpinnerProps = {
  className?: string;
};

/**
 * @deprecated. Use https://mui.com/material-ui/react-progress/ instead.
 */
const Spinner = ({ className }: SpinnerProps) => (
  <FontAwesomeIcon icon={faSpinner} className={classNames(style.spinner, className)} />
);

export default Spinner;
