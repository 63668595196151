/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { AppSettings, AppSettingsFromJSON, AppSettingsToJSON } from "../models";

/**
 *
 */
export class AppApi extends runtime.BaseAPI {
  /**
   */
  async appSettingRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AppSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/app/setting/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AppSettingsFromJSON(jsonValue));
  }

  /**
   */
  async appSettingRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AppSettings> {
    const response = await this.appSettingRetrieveRaw(initOverrides);
    return await response.value();
  }
}
