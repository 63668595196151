import { useEffect, useCallback, useState, createContext } from 'react';

type ContextModel = {
  title?: string;
  setTitle: (title: string) => void;
  setMetaDataByAttribute: (attribute: string, property: string, value: string) => void;
};

export const DocumentHeadContext = createContext<ContextModel | null>(null);

export const DocumentHeadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState<string>(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const setMetaDataByAttribute = useCallback(
    (attribute: string, attributeValue: string, value: string) => {
      const metaTags = Array.from(document.head.childNodes).filter(
        (node) => node instanceof HTMLMetaElement
      ) as HTMLMetaElement[];

      const tag = metaTags.find((meta) => meta.getAttribute(attribute) === attributeValue);

      if (tag) {
        tag.setAttribute('content', value);
      } else {
        const meta = document.createElement('meta');

        meta.setAttribute(attribute, attributeValue);
        meta.setAttribute('content', value);

        document.head.appendChild(meta);
      }
    },
    []
  );

  return (
    <DocumentHeadContext.Provider value={{ title, setTitle, setMetaDataByAttribute }}>
      {children}
    </DocumentHeadContext.Provider>
  );
};
