/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BreedingPairingGroupingEnum = {
    Male: 'male',
    Female: 'female'
} as const;
export type BreedingPairingGroupingEnum = typeof BreedingPairingGroupingEnum[keyof typeof BreedingPairingGroupingEnum];


export function BreedingPairingGroupingEnumFromJSON(json: any): BreedingPairingGroupingEnum {
    return BreedingPairingGroupingEnumFromJSONTyped(json, false);
}

export function BreedingPairingGroupingEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingGroupingEnum {
    return json as BreedingPairingGroupingEnum;
}

export function BreedingPairingGroupingEnumToJSON(value?: BreedingPairingGroupingEnum | null): any {
    return value as any;
}

