import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gray200 } = colors;

export const MuiInputBase: Components<Omit<Theme, 'components'>>['MuiInputBase'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      padding: '0 0 0 9px',
      ':after': {
        borderBottom: `2px solid ${gray200} !important`,
      },
    },
  },
};
