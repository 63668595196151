// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted--Ecqg6>mark{font-weight:700;color:inherit;background-color:var(--color-named-transparent);padding:0}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Highlighted/Highlighted.module.scss","webpack://./mysite/static/react-app/src/style/typo/weights.scss"],"names":[],"mappings":"AAGE,yBACE,eCIM,CDHN,aAAA,CACA,+CAAA,CACA,SAAA","sourcesContent":["@use 'style/typo' as *;\n\n.highlighted {\n  > mark {\n    font-weight: $fw_bold;\n    color: inherit;\n    background-color: var(--color-named-transparent);\n    padding: 0;\n  }\n}\n","// Font weight variables\n$fw_thin: 100;\n$fw_extra_light: 200;\n$fw_light: 300;\n$fw_regular: 400;\n$fw_regular_medium: 450;\n$fw_medium: 500;\n$fw_semi_bold: 600;\n$fw_bold: 700;\n$fw_extra_bold: 800;\n$fw_black: 900;\n\n\n$weights: (\n  'fw_thin': $fw_thin,\n  'fw_extra_light': $fw_extra_light,\n  'fw_light': $fw_light,\n  'fw_regular': $fw_regular,\n  'fw_regular_medium': $fw_regular_medium,\n  'fw_medium': $fw_medium,\n  'fw_semi_bold': $fw_semi_bold,\n  'fw_bold': $fw_bold,\n  'fw_extra_bold': $fw_extra_bold,\n  'fw_black': $fw_black,\n);\n\n:export {\n  @each $weight, $value in $weights {\n    #{unquote($weight)}: #{$value};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"f_xxxl": `2.5rem`,
	"f_xxl": `2rem`,
	"f_xl": `1.5rem`,
	"f_lg": `1.25rem`,
	"f_md": `1rem`,
	"f_sm": `0.875rem`,
	"f_xs": `0.75rem`,
	"f_xxs": `0.625rem`,
	"f_xxxs": `0.5rem`,
	"fw_thin": `100`,
	"fw_extra_light": `200`,
	"fw_light": `300`,
	"fw_regular": `400`,
	"fw_regular_medium": `450`,
	"fw_medium": `500`,
	"fw_semi_bold": `600`,
	"fw_bold": `700`,
	"fw_extra_bold": `800`,
	"fw_black": `900`,
	"highlighted": `highlighted--Ecqg6`
};
export default ___CSS_LOADER_EXPORT___;
