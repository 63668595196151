import { RouteObject } from 'react-router-dom';

const GeneticCalculator = () => import('pages/GeneticCalculator/GeneticCalculator');

export const geneticCalculatorRoutes: RouteObject[] = [
  {
    path: ':region?/c/:category/:animal?/:animalSubType?/genetic-calculator',
    lazy: GeneticCalculator,
  },
];
