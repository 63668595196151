import { PairingSeason } from 'pages/BreedingManager/Private/types';
import { useRegion } from 'hooks';
import { useSettingsPermission } from 'hooks/useSettingsPermission';
import preferences, { BREEDING_PAIRING_SEASON } from 'services/preferences';
import { useAboutMe } from '../../../../../../context/UserContext/UserContext';
import { UserLinks } from '../../../components';

/**
 * Generates an array of options for the mobile profile menu.
 */
export const useMobileProfileOptions = () => {
  const { logout, isLoggedIn, about } = useAboutMe();
  const region = useRegion();
  const accessPermissions = useSettingsPermission();
  const breedingSeason: PairingSeason = preferences.get(BREEDING_PAIRING_SEASON) ?? 'any';

  return isLoggedIn
    ? UserLinks({
        region,
        userName: about?.username || '',
        logoutCallback: () => void logout(),
        breedingSeason,
        accessPermissions,
      })
    : [];
};
