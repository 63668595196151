import { createTheme } from '@mui/material';
import { base } from 'context/ThemeContext/Theme/base';
import { MuiButton } from 'context/ThemeContext/Theme/overrides/MuiButton';
import { MuiChip } from 'context/ThemeContext/Theme/overrides/MuiChip';

export const theme = createTheme(base, {
  components: {
    MuiButton,
    MuiChip,
  },
});
