import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { NON_VIABLE_EGGS_ORDERING } from 'api/offspring/ordering';
import { DESCENDING } from '../../../../api/ordering';

export const NON_VIABLE_EGGS_COLUMN = Symbol('NON_VIABLE_EGGS_COLUMN');

const nonViableEggsColumn = {
  id: NON_VIABLE_EGGS_COLUMN,
  ordering: NON_VIABLE_EGGS_ORDERING,
  label: 'Non-Viable Eggs',
  shortLabel: 'Non-Viable',
  hideable: true,
  className: style.right,
  defaultDirection: DESCENDING,

  render: ({ non_viable_eggs_count_display }) => non_viable_eggs_count_display,
};

export default nonViableEggsColumn;
