import { RouteObject } from 'react-router-dom';

const AddPair = () => import('../../pages/BreedingManager/Private/pages/AddPair/AddPair');
// TODO: Currently, the PublicList and PublicDetail components are using legacy code from BreedingManagerLegacy.
// We need to revise this and move the code to BreedingManager and reference components from there.
const PublicDetail = () => import('../../pages/BreedingManagerLegacy/Public/Detail');
const PublicList = () => import('../../pages/BreedingManagerLegacy/Public/List');
const PrivateDetail = () => import('../../pages/BreedingManager/Private/pages/Detail/Detail');
const PrivateList = () => import('../../pages/BreedingManager/Private/pages/List/List');
const PrivateOutcomes = () => import('../../pages/BreedingManager/Private/pages/Outcomes/Outcomes');

export const breedingRoutes: RouteObject[] = [
  {
    path: 'breeding',
    children: [
      { path: '', lazy: PrivateList },
      { path: 'add', lazy: AddPair },
      { path: 'outcomes', lazy: PrivateOutcomes },
      { path: ':pair', lazy: PrivateDetail },
    ],
  },
  {
    path: 'stores/:store/breeding',
    children: [
      { path: '', lazy: PublicList },
      { path: ':pair', lazy: PublicDetail },
    ],
  },
  {
    path: 'users/:user/breeding',
    children: [
      { path: '', lazy: PublicList },
      { path: ':pair', lazy: PublicDetail },
    ],
  },
];
