import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { TITLE_ID_ORDERING } from 'api/inventory/ordering';
import { Animal } from 'types';

export const TITLE_ID_COLUMN = Symbol('TITLE_ID_COLUMN');

const titleColumn = {
  id: TITLE_ID_COLUMN,
  ordering: TITLE_ID_ORDERING,
  label: 'Title & ID',
  hideable: true,
  className: style.titleIdColumn,

  render: ({ title_display, animal_id }: Animal) => (
    <>
      <div className={style.itemTitle}>{title_display}</div>
      <div className={style.itemSubtitle}>{animal_id}</div>
    </>
  ),
};

export default titleColumn;
