import { useState } from 'react';
import { Params } from 'react-router-dom';
import { generateSingletonHook } from 'context/SingletonContext';

const useDecoratedHook = () => {
  const [urlParams, setUrlParams] = useState<Readonly<Params<string>>>();

  return {
    urlParams,
    setUrlParams,
  };
};

const [UrlParamsContext, useUrlParamsContext] = generateSingletonHook(useDecoratedHook);
export { UrlParamsContext, useUrlParamsContext };
