import classNames from 'classnames';
import TraitBadge from 'components/TraitBadge/TraitBadge';
import style from './TraitsList.module.scss';

interface TraitsListProps {
  isMobile?: boolean;
  className?: string;
  traits: Array<{
    name: string;
    class_label?: string;
    classLabel?: string;
  }>;
}

const TraitsList = ({ traits, isMobile = false, className }: TraitsListProps) =>
  traits.length ? (
    <ul className={classNames(style.container, isMobile ? style.mobile : '', className)}>
      {traits.map(({ name, class_label, classLabel }, index) => (
        <TraitBadge key={index} element="li" name={name} type={class_label || classLabel}>
          {name}
        </TraitBadge>
      ))}
    </ul>
  ) : null;

export default TraitsList;
