/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NullEnum,
    NullEnumFromJSON,
    NullEnumFromJSONTyped,
    NullEnumToJSON,
} from './NullEnum';
import {
    SexEnum,
    SexEnumFromJSON,
    SexEnumFromJSONTyped,
    SexEnumToJSON,
} from './SexEnum';

/**
 * @type AnimalSex
 * 
 * @export
 */
export type AnimalSex = NullEnum | SexEnum;

export function AnimalSexFromJSON(json: any): AnimalSex {
    return AnimalSexFromJSONTyped(json, false);
}

export function AnimalSexFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalSex {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return SexEnumFromJSONTyped(json, true);
}

export function AnimalSexToJSON(value?: AnimalSex | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return SexEnumToJSON(value);
}

