import { createContext, useState } from 'react';
import preferences, { POSTAL_CODE_PREFERENCES, RADIUS_PREFERENCES } from 'services/preferences';

export const DEFAULT_DISTANCE = '50';

export type LocationFilterContextValues = {
  distance: string;
  postalCode: string;
  isLocationDrawerOpen: boolean;
  defaultDistance: string;
  actions: {
    onDistanceChange: (distance: string) => void;
    onPostalCodeChange: (postalCode: string) => void;
    setIsLocationDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const LocationFilterContext = createContext<LocationFilterContextValues>({
  distance: '',
  postalCode: '',
  isLocationDrawerOpen: false,
  defaultDistance: DEFAULT_DISTANCE,
  actions: {
    onDistanceChange: () => {},
    onPostalCodeChange: () => {},
    setIsLocationDrawerOpen: () => {},
  },
});

export const LocationFilterContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const defaultDistance = DEFAULT_DISTANCE;

  const [distance, setDistance] = useState<string>(defaultDistance);
  const [postalCode, setPostalCode] = useState<string>('');

  const onDistanceChange = (distance: string) => {
    setDistance(distance);
    void preferences.set(RADIUS_PREFERENCES, distance);
  };

  const onPostalCodeChange = (postalCode: string) => {
    setPostalCode(postalCode);
    void preferences.set(POSTAL_CODE_PREFERENCES, postalCode);
  };

  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState<boolean>(false);

  const contextValues = {
    distance,
    postalCode,
    isLocationDrawerOpen,
    defaultDistance,
    actions: { onDistanceChange, onPostalCodeChange, setIsLocationDrawerOpen },
  };

  return (
    <LocationFilterContext.Provider value={contextValues}>
      {children}
    </LocationFilterContext.Provider>
  );
};
