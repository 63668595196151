/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OffspringGroupOutcome,
    OffspringGroupOutcomeFromJSON,
    OffspringGroupOutcomeFromJSONTyped,
    OffspringGroupOutcomeToJSON,
} from './OffspringGroupOutcome';
import {
    ParentAnimal,
    ParentAnimalFromJSON,
    ParentAnimalFromJSONTyped,
    ParentAnimalToJSON,
} from './ParentAnimal';

/**
 * 
 * @export
 * @interface BreedingPairingDetailsOffspringGroup
 */
export interface BreedingPairingDetailsOffspringGroup {
    /**
     * 
     * @type {number}
     * @memberof BreedingPairingDetailsOffspringGroup
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsOffspringGroup
     */
    offspringGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsOffspringGroup
     */
    readonly privateUrl: string;
    /**
     * 
     * @type {Array<ParentAnimal>}
     * @memberof BreedingPairingDetailsOffspringGroup
     */
    readonly offsprings: Array<ParentAnimal>;
    /**
     * 
     * @type {string}
     * @memberof BreedingPairingDetailsOffspringGroup
     */
    readonly birthdateDisplay: string;
}

export function BreedingPairingDetailsOffspringGroupFromJSON(json: any): BreedingPairingDetailsOffspringGroup {
    return BreedingPairingDetailsOffspringGroupFromJSONTyped(json, false);
}

export function BreedingPairingDetailsOffspringGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreedingPairingDetailsOffspringGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'offspringGroupId': json['offspring_group_id'],
        'privateUrl': json['private_url'],
        'offsprings': ((json['offsprings'] as Array<any>).map(ParentAnimalFromJSON)),
        'birthdateDisplay': json['birthdate_display'],
    };
}

export function BreedingPairingDetailsOffspringGroupToJSON(value?: BreedingPairingDetailsOffspringGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offspring_group_id': value.offspringGroupId,
    };
}

