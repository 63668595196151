// Purpose:
//
// This is used to provide modals data and ensure the stacking works.
//
// Usage:
//
// You need to have the ToastAndModalsRenderer component further down
// in your component hierarchy in order to use this component.
//
import { useState, createContext } from 'react';

const ModalContext = createContext();

export default ModalContext;

export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);

  const showModal = (modal) => {
    setActiveModal(modal);
  };

  // Used when we need two or more modals at the same time (i.e. edit parent)
  const pushModal = (modal) => {
    if (Array.isArray(activeModal)) {
      setActiveModal(activeModal.concat(modal));
    } else if (activeModal) {
      setActiveModal([activeModal, modal]);
    } else {
      setActiveModal([modal]);
    }
  };

  const popModal = () => {
    if (Array.isArray(activeModal)) {
      setActiveModal(activeModal.slice(0, -1));
    } else {
      setActiveModal(null);
    }
  };

  const closeModal = () => setActiveModal(null);

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        modal: showModal,
        pushModal,
        popModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
