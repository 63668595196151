import { SymbolIndex } from '../../../../types';

export const VISIBILITY_FILTER = Symbol('VISIBILITY_FILTER');

export const VISIBILITY_PRIVATE = Symbol('VISIBILITY_PRIVATE');
export const VISIBILITY_PUBLIC = Symbol('VISIBILITY_PUBLIC');
export const VISIBILITY_UNLISTED = Symbol('VISIBILITY_UNLISTED');

export const visibilityNames: SymbolIndex = {
  [VISIBILITY_PRIVATE]: 'Private',
  [VISIBILITY_PUBLIC]: 'Public',
  [VISIBILITY_UNLISTED]: 'Unlisted',
};

// Ordered options
export const visibilityOptions = [VISIBILITY_PUBLIC, VISIBILITY_UNLISTED, VISIBILITY_PRIVATE];

export const mappedVisibilityOptions = {
  public: VISIBILITY_PUBLIC,
  unlisted: VISIBILITY_UNLISTED,
  private: VISIBILITY_PRIVATE,
} as const;

export const convertVisibility = (
  value:
    | keyof typeof mappedVisibilityOptions
    | (typeof mappedVisibilityOptions)[keyof typeof mappedVisibilityOptions]
) => {
  if (typeof value === 'string') {
    return mappedVisibilityOptions[value];
  } else {
    return Object.entries(mappedVisibilityOptions).find(
      ([, symbol]) => value === symbol
    )?.[0] as unknown as string;
  }
};

export const VISIBILITY_ANY = 'any';
