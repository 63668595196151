/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `list` - list
 * * `card` - card
 * @export
 */
export const ListCardViewChoices = {
    List: 'list',
    Card: 'card'
} as const;
export type ListCardViewChoices = typeof ListCardViewChoices[keyof typeof ListCardViewChoices];


export function ListCardViewChoicesFromJSON(json: any): ListCardViewChoices {
    return ListCardViewChoicesFromJSONTyped(json, false);
}

export function ListCardViewChoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCardViewChoices {
    return json as ListCardViewChoices;
}

export function ListCardViewChoicesToJSON(value?: ListCardViewChoices | null): any {
    return value as any;
}

