import { useAboutMe } from '../../context/UserContext/UserContext';
import { useIsApp } from './useIsApp';

export const useShowExtendedContent = (): { showPricingToUser: boolean } => {
  const isApp = useIsApp();
  const { about } = useAboutMe();
  const isSeller = !!about?.hasStore;
  const isStoreApproved = !!about?.isStoreApproved;
  const showExtendedContent = !!about?.showExtendedContent;

  const showPricingToUser = !isApp || showExtendedContent || (isSeller && isStoreApproved);

  return { showPricingToUser };
};
