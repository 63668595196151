import React, { HTMLAttributes } from 'react';
import { PairingSeason } from 'pages/BreedingManager/Private/types';
import { useInventoryNavigation } from 'pages/Inventory/hooks/useInventoryNavigation';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import { useAboutMe } from 'context/UserContext/UserContext';
import { useRegion } from 'hooks';
import { useSettingsPermission } from 'hooks/useSettingsPermission';
import preferences, { BREEDING_PAIRING_SEASON } from 'services/preferences';
import { MobileGuestLinks, SettingsItem, UserLinks } from '../Lists';
import { SettingsMenuItem } from '../MenuItems/Item';
import { GenericMenu } from './GenericMenu';
import styles from './Settings.module.scss';

type MenuProps = ReturnType<typeof useDropdownMenu>;

export type SettingsProps = {
  ButtonComponent: React.FC<
    Partial<HTMLAttributes<HTMLButtonElement>> & {
      userName: string;
      avatar: string;
      button: MenuProps['utils']['button'];
      toggle: MenuProps['toggle'];
    }
  >;
};

export const Settings: React.FC<SettingsProps> = ({ ButtonComponent }) => {
  const region = useRegion();
  const { logout, about } = useAboutMe();
  const accessPermissions = useSettingsPermission();
  const navigateToInventoryCallback = useInventoryNavigation();

  const breedingSeason: PairingSeason = preferences.get(BREEDING_PAIRING_SEASON) ?? 'any';

  const links = !!about?.username
    ? UserLinks({
        region,
        userName: about?.username || '',
        logoutCallback: () => void logout(),
        navigateToInventoryCallback,
        breedingSeason,
        accessPermissions,
      })
    : MobileGuestLinks;
  const { utils, toggle } = useDropdownMenu({ id: 'nav-menu' });
  const { button, menu } = utils;

  const webVersion = window && window.WEBSITE_VERSION;
  return (
    <>
      <ButtonComponent
        button={button}
        avatar={about?.imageUrl as string}
        userName={about?.username as string}
        toggle={toggle}
      />
      <GenericMenu {...menu}>
        {(links as SettingsItem[]).map((link, i) => (
          <SettingsMenuItem {...link} key={(link.children as string) || i} />
        ))}
        <span className={styles.version}>Version: {webVersion}</span>
      </GenericMenu>
    </>
  );
};
