/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LabelConfig
 */
export interface LabelConfig {
    /**
     * 
     * @type {number}
     * @memberof LabelConfig
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof LabelConfig
     */
    readonly source: string;
    /**
     * 
     * @type {string}
     * @memberof LabelConfig
     */
    css: string;
    /**
     * 
     * @type {string}
     * @memberof LabelConfig
     */
    html: string;
    /**
     * 
     * @type {number}
     * @memberof LabelConfig
     */
    readonly labelWidth: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabelConfig
     */
    readonly labelHeight: number | null;
    /**
     * 
     * @type {string}
     * @memberof LabelConfig
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LabelConfig
     */
    readonly _default: boolean;
}

export function LabelConfigFromJSON(json: any): LabelConfig {
    return LabelConfigFromJSONTyped(json, false);
}

export function LabelConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'source': json['source'],
        'css': json['css'],
        'html': json['html'],
        'labelWidth': json['label_width'],
        'labelHeight': json['label_height'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_default': json['default'],
    };
}

export function LabelConfigToJSON(value?: LabelConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'css': value.css,
        'html': value.html,
        'name': value.name,
    };
}

