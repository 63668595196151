/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { useSDKList } from "api/utilities/useAPIList";
import { PreferencesApi } from "../sdk/apis/PreferencesApi";
import { configuration } from "./configuration";

export const usePreferences = () => {
  const instance = new PreferencesApi(configuration);

  const updateDisplayPartial = useAPIHook(instance.preferencesDisplayPartialUpdate.bind(instance));
  const getDisplay = useAPIHook(instance.preferencesDisplayRetrieve.bind(instance));
  const updateDisplay = useAPIHook(instance.preferencesDisplayUpdate.bind(instance));
  const createLabelConfigs = useAPIHook(instance.preferencesLabelConfigsCreate.bind(instance));
  const destroyLabelConfigs = useAPIHook(instance.preferencesLabelConfigsDestroy.bind(instance));
  const listLabelConfigs = useAPIHook(instance.preferencesLabelConfigsList.bind(instance));
  const updateLabelConfigsPartial = useAPIHook(instance.preferencesLabelConfigsPartialUpdate.bind(instance));
  const getLabelConfigs = useAPIHook(instance.preferencesLabelConfigsRetrieve.bind(instance));
  const getLabelConfigsSample = useAPIHook(instance.preferencesLabelConfigsSampleRetrieve.bind(instance));
  const updateLabelConfigs = useAPIHook(instance.preferencesLabelConfigsUpdate.bind(instance));

  const labelConfigs = useSDKList(listLabelConfigs.callback, false);

  return {
    error:
      updateDisplayPartial.error ||
      getDisplay.error ||
      updateDisplay.error ||
      createLabelConfigs.error ||
      destroyLabelConfigs.error ||
      listLabelConfigs.error ||
      updateLabelConfigsPartial.error ||
      getLabelConfigs.error ||
      getLabelConfigsSample.error ||
      updateLabelConfigs.error,
    isLoading:
      updateDisplayPartial.isLoading ||
      getDisplay.isLoading ||
      updateDisplay.isLoading ||
      createLabelConfigs.isLoading ||
      destroyLabelConfigs.isLoading ||
      listLabelConfigs.isLoading ||
      updateLabelConfigsPartial.isLoading ||
      getLabelConfigs.isLoading ||
      getLabelConfigsSample.isLoading ||
      updateLabelConfigs.isLoading,
    labelConfigs: {
      data: labelConfigs.data,
      parametrizedFetch: labelConfigs.parametrizedFetch,
      refetch: labelConfigs.refetch,
      params: labelConfigs.params,
      setParams: labelConfigs.setParams,
    },

    updateDisplayPartial: updateDisplayPartial.callback,
    getDisplay: getDisplay.callback,
    updateDisplay: updateDisplay.callback,
    createLabelConfigs: createLabelConfigs.callback,
    destroyLabelConfigs: destroyLabelConfigs.callback,
    listLabelConfigs: listLabelConfigs.callback,
    updateLabelConfigsPartial: updateLabelConfigsPartial.callback,
    getLabelConfigs: getLabelConfigs.callback,
    getLabelConfigsSample: getLabelConfigsSample.callback,
    updateLabelConfigs: updateLabelConfigs.callback,
  };
};
