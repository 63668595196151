/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Locality,
    LocalityFromJSON,
    LocalityFromJSONTyped,
    LocalityToJSON,
} from './Locality';
import {
    State,
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';

/**
 * 
 * @export
 * @interface AddressLocality
 */
export interface AddressLocality {
    /**
     * 
     * @type {number}
     * @memberof AddressLocality
     */
    readonly id: number;
    /**
     * 
     * @type {State}
     * @memberof AddressLocality
     */
    state: State;
    /**
     * 
     * @type {string}
     * @memberof AddressLocality
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddressLocality
     */
    postalCode: string;
}

export function AddressLocalityFromJSON(json: any): AddressLocality {
    return AddressLocalityFromJSONTyped(json, false);
}

export function AddressLocalityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressLocality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': StateFromJSON(json['state']),
        'name': json['name'],
        'postalCode': json['postal_code'],
    };
}

export function AddressLocalityToJSON(value?: AddressLocality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': StateToJSON(value.state),
        'name': value.name,
        'postal_code': value.postalCode,
    };
}

