/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMembershipBadge,
    UserMembershipBadgeFromJSON,
    UserMembershipBadgeFromJSONTyped,
    UserMembershipBadgeToJSON,
} from './UserMembershipBadge';
import {
    UserPreferences,
    UserPreferencesFromJSON,
    UserPreferencesFromJSONTyped,
    UserPreferencesToJSON,
} from './UserPreferences';
import {
    UserUnseenMessages,
    UserUnseenMessagesFromJSON,
    UserUnseenMessagesFromJSONTyped,
    UserUnseenMessagesToJSON,
} from './UserUnseenMessages';
import {
    UserUrls,
    UserUrlsFromJSON,
    UserUrlsFromJSONTyped,
    UserUrlsToJSON,
} from './UserUrls';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly type: string;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly lastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasStore: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasOrganization: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    readonly dateJoined: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    readonly created: Date;
    /**
     * 
     * @type {UserUnseenMessages}
     * @memberof User
     */
    unseenMessages: UserUnseenMessages;
    /**
     * 
     * @type {UserUnseenMessages}
     * @memberof User
     */
    unseenThreads: UserUnseenMessages;
    /**
     * 
     * @type {UserUnseenMessages}
     * @memberof User
     */
    needsReplyMessages: UserUnseenMessages;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly maxPhotosPerAd: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasWholesaleFeature: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasOrdersEnabled: boolean;
    /**
     * 
     * @type {UserUrls}
     * @memberof User
     */
    urls: UserUrls;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSuperuser: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly imageUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly imageThumbUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly storeLogoUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly storeLogoThumbUrl: string | null;
    /**
     * 
     * @type {UserMembershipBadge}
     * @memberof User
     */
    membershipBadge: UserMembershipBadge;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly membershipLevelName: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {UserPreferences}
     * @memberof User
     */
    preferences: UserPreferences;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly biddingRestrictionReason: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly isStoreApproved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasAuctionConsent: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly currencySymbol: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly countryCode: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly hasBetaAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly showExtendedContent: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly appQrCodeRedirectMode: number | null;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'username': json['username'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'hasStore': json['has_store'],
        'hasOrganization': json['has_organization'],
        'dateJoined': (new Date(json['date_joined'])),
        'created': (new Date(json['created'])),
        'unseenMessages': UserUnseenMessagesFromJSON(json['unseen_messages']),
        'unseenThreads': UserUnseenMessagesFromJSON(json['unseen_threads']),
        'needsReplyMessages': UserUnseenMessagesFromJSON(json['needs_reply_messages']),
        'maxPhotosPerAd': json['max_photos_per_ad'],
        'hasWholesaleFeature': json['has_wholesale_feature'],
        'hasOrdersEnabled': json['has_orders_enabled'],
        'urls': UserUrlsFromJSON(json['urls']),
        'isSuperuser': json['is_superuser'],
        'imageUrl': json['image_url'],
        'imageThumbUrl': json['image_thumb_url'],
        'storeLogoUrl': json['store_logo_url'],
        'storeLogoThumbUrl': json['store_logo_thumb_url'],
        'membershipBadge': UserMembershipBadgeFromJSON(json['membership_badge']),
        'membershipLevelName': json['membership_level_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'preferences': UserPreferencesFromJSON(json['preferences']),
        'biddingRestrictionReason': json['bidding_restriction_reason'],
        'isStoreApproved': json['is_store_approved'],
        'hasAuctionConsent': json['has_auction_consent'],
        'currencySymbol': json['currency_symbol'],
        'countryCode': json['country_code'],
        'hasBetaAccess': json['has_beta_access'],
        'showExtendedContent': json['show_extended_content'],
        'appQrCodeRedirectMode': json['app_qr_code_redirect_mode'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'unseen_messages': UserUnseenMessagesToJSON(value.unseenMessages),
        'unseen_threads': UserUnseenMessagesToJSON(value.unseenThreads),
        'needs_reply_messages': UserUnseenMessagesToJSON(value.needsReplyMessages),
        'urls': UserUrlsToJSON(value.urls),
        'is_superuser': value.isSuperuser,
        'membership_badge': UserMembershipBadgeToJSON(value.membershipBadge),
        'email': value.email,
        'preferences': UserPreferencesToJSON(value.preferences),
    };
}

