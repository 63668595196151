import { HTMLAttributes } from 'react';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import styles from './MenuItems.module.scss';

export type BackButtonProps = HTMLAttributes<HTMLButtonElement> & {
  name?: string;
  has_wizard?: boolean;
  closeIcon?: boolean;
};

export const BackButton: React.FC<BackButtonProps> = ({
  children,
  name,
  has_wizard,
  closeIcon,
  ...props
}) => {
  return (
    <button
      tabIndex={0}
      role="menuitem"
      className={!!closeIcon ? styles.closeButton : styles.backButton}
      {...props}
    >
      {!closeIcon && <Icon icon={faChevronLeft} />}
      <span>{children || name}</span>
      {!!closeIcon && <Icon icon={faTimes} />}
    </button>
  );
};
