/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  PaginatedStoreSearchList,
  PaginatedStoreSearchListFromJSON,
  PaginatedStoreSearchListToJSON,
  StoreReferenceRequest,
  StoreReferenceRequestFromJSON,
  StoreReferenceRequestToJSON,
  StoreSearch,
  StoreSearchFromJSON,
  StoreSearchToJSON,
} from "../models";

export interface FollowedStoresCreateRequest {
  /** @deprecated Use request instead. **/
  storeReferenceRequest?: StoreReferenceRequest;
  request: StoreReferenceRequest;
}

export interface FollowedStoresDestroyRequest {
  id: string;
}

export interface FollowedStoresListRequest {
  canPickup?: string;
  canShip?: string;
  category?: string;
  exportingTo?: string;
  followedStoresOnly?: string;
  hasWaitlists?: string;
  joinedSince?: Date;
  locationState?: string;
  minPositiveRatingPct?: number;
  minSellerRatingScore?: number;
  minimumListings?: number;
  ordering?: string;
  organizationType?: FollowedStoresListOrganizationTypeEnum;
  page?: number;
  pageSize?: number;
  paymentMethods?: Array<FollowedStoresListPaymentMethodsEnum>;
  paymentPlan?: number;
  preyFood?: FollowedStoresListPreyFoodEnum;
  preyStatus?: FollowedStoresListPreyStatusEnum;
  randomFeaturedStoresOnly?: string;
  region?: FollowedStoresListRegionEnum;
  search?: string;
  usarkMembership?: string;
  willTrade?: string;
}

/**
 *
 */
export class FollowedStoresApi extends runtime.BaseAPI {
  /**
   * Viewset for followed stores (stores which I follow)
   */
  async followedStoresCreateRaw(
    requestParameters: FollowedStoresCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<StoreSearch>> {
    if ((requestParameters.request || requestParameters.storeReferenceRequest) === null || (requestParameters.request || requestParameters.storeReferenceRequest) === undefined) {
      throw new runtime.RequiredError(
        "storeReferenceRequest",
        "Required parameter (requestParameters.request || requestParameters.storeReferenceRequest) was null or undefined when calling followedStoresCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/followed_stores/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreReferenceRequestToJSON(requestParameters.request || requestParameters.storeReferenceRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StoreSearchFromJSON(jsonValue));
  }

  /**
   * Viewset for followed stores (stores which I follow)
   */
  async followedStoresCreate(requestParameters: FollowedStoresCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoreSearch> {
    const response = await this.followedStoresCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Viewset for followed stores (stores which I follow)
   */
  async followedStoresDestroyRaw(requestParameters: FollowedStoresDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling followedStoresDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/followed_stores/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Viewset for followed stores (stores which I follow)
   */
  async followedStoresDestroy(requestParameters: FollowedStoresDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.followedStoresDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * Overrides default list implementation to pass down filtering and ordering data to self.get_paginated_response.
   */
  async followedStoresListRaw(
    requestParameters: FollowedStoresListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedStoreSearchList>> {
    const queryParameters: any = {};

    if (requestParameters.canPickup !== undefined) {
      queryParameters["can_pickup"] = requestParameters.canPickup;
    }

    if (requestParameters.canShip !== undefined) {
      queryParameters["can_ship"] = requestParameters.canShip;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.exportingTo !== undefined) {
      queryParameters["exporting_to"] = requestParameters.exportingTo;
    }

    if (requestParameters.followedStoresOnly !== undefined) {
      queryParameters["followed_stores_only"] = requestParameters.followedStoresOnly;
    }

    if (requestParameters.hasWaitlists !== undefined) {
      queryParameters["has_waitlists"] = requestParameters.hasWaitlists;
    }

    if (requestParameters.joinedSince !== undefined) {
      queryParameters["joined_since"] = (requestParameters.joinedSince as any).toISOString();
    }

    if (requestParameters.locationState !== undefined) {
      queryParameters["location_state"] = requestParameters.locationState;
    }

    if (requestParameters.minPositiveRatingPct !== undefined) {
      queryParameters["min_positive_rating_pct"] = requestParameters.minPositiveRatingPct;
    }

    if (requestParameters.minSellerRatingScore !== undefined) {
      queryParameters["min_seller_rating_score"] = requestParameters.minSellerRatingScore;
    }

    if (requestParameters.minimumListings !== undefined) {
      queryParameters["minimum_listings"] = requestParameters.minimumListings;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.organizationType !== undefined) {
      queryParameters["organization_type"] = requestParameters.organizationType;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.paymentMethods) {
      queryParameters["payment_methods"] = requestParameters.paymentMethods;
    }

    if (requestParameters.paymentPlan !== undefined) {
      queryParameters["payment_plan"] = requestParameters.paymentPlan;
    }

    if (requestParameters.preyFood !== undefined) {
      queryParameters["prey_food"] = requestParameters.preyFood;
    }

    if (requestParameters.preyStatus !== undefined) {
      queryParameters["prey_status"] = requestParameters.preyStatus;
    }

    if (requestParameters.randomFeaturedStoresOnly !== undefined) {
      queryParameters["random_featured_stores_only"] = requestParameters.randomFeaturedStoresOnly;
    }

    if (requestParameters.region !== undefined) {
      queryParameters["region"] = requestParameters.region;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.usarkMembership !== undefined) {
      queryParameters["usark_membership"] = requestParameters.usarkMembership;
    }

    if (requestParameters.willTrade !== undefined) {
      queryParameters["will_trade"] = requestParameters.willTrade;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/followed_stores/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStoreSearchListFromJSON(jsonValue));
  }

  /**
   * Overrides default list implementation to pass down filtering and ordering data to self.get_paginated_response.
   */
  async followedStoresList(requestParameters: FollowedStoresListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaginatedStoreSearchList> {
    const response = await this.followedStoresListRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const FollowedStoresListOrganizationTypeEnum = {
  Advocacy: "advocacy",
  Artist: "artist",
  Breeder: "breeder",
  Charity: "charity",
  Club: "club",
  ContentCreator: "content-creator",
  Courier: "courier",
  Education: "education",
  Expo: "expo",
  FeederBreeder: "feeder-breeder",
  HerpParty: "herp-party",
  ImporterExporter: "importer-exporter",
  Manufacturer: "manufacturer",
  Other: "other",
  PetStore: "pet-store",
  Publisher: "publisher",
  Rescue: "rescue",
  Retail: "retail",
  Seller: "seller",
  ShippingService: "shipping-service",
  Software: "software",
  TestingService: "testing-service",
  Vet: "vet",
  Zoo: "zoo",
} as const;
export type FollowedStoresListOrganizationTypeEnum = (typeof FollowedStoresListOrganizationTypeEnum)[keyof typeof FollowedStoresListOrganizationTypeEnum];
/**
 * @export
 */
export const FollowedStoresListPaymentMethodsEnum = {
  ApplePay: "apple_pay",
  Bitcoin: "bitcoin",
  Cash: "cash",
  Cashapp: "cashapp",
  CashiersCheck: "cashiers_check",
  Chime: "chime",
  CreditCard: "credit_card",
  DebitCard: "debit_card",
  Dogecoin: "dogecoin",
  Ethereum: "ethereum",
  GooglePay: "google_pay",
  MoneyOrder: "money_order",
  Moneygram: "moneygram",
  Other: "other",
  OtherCrypto: "other_crypto",
  PaypalFriendsFamily: "paypal_friends_family",
  PaypalGoodsServices: "paypal_goods_services",
  PersonalCheck: "personal_check",
  Venmo: "venmo",
  WalmartPay: "walmart_pay",
  WesternUnion: "western_union",
  WireTransfer: "wire_transfer",
  Zelle: "zelle",
} as const;
export type FollowedStoresListPaymentMethodsEnum = (typeof FollowedStoresListPaymentMethodsEnum)[keyof typeof FollowedStoresListPaymentMethodsEnum];
/**
 * @export
 */
export const FollowedStoresListPreyFoodEnum = {
  Chicken: "chicken",
  Cricket: "cricket",
  DryFeed: "dry-feed",
  Goat: "goat",
  GuineaPig: "guinea-pig",
  Lamb: "lamb",
  MealReplacement: "meal-replacement",
  Mealworm: "mealworm",
  Mouse: "mouse",
  Other: "other",
  Pig: "pig",
  Quail: "quail",
  Rabbit: "rabbit",
  Rat: "rat",
  Roach: "roach",
  SoftFurredRat: "soft-furred-rat",
  Unknown: "unknown",
  Vegetables: "vegetables",
} as const;
export type FollowedStoresListPreyFoodEnum = (typeof FollowedStoresListPreyFoodEnum)[keyof typeof FollowedStoresListPreyFoodEnum];
/**
 * @export
 */
export const FollowedStoresListPreyStatusEnum = {
  Frozen: "frozen",
  Live: "live",
  Prekilled: "prekilled",
  Unknown: "unknown",
} as const;
export type FollowedStoresListPreyStatusEnum = (typeof FollowedStoresListPreyStatusEnum)[keyof typeof FollowedStoresListPreyStatusEnum];
/**
 * @export
 */
export const FollowedStoresListRegionEnum = {
  Eu: "_eu",
  Us: "_us",
  All: "all",
  Ca: "ca",
  Eu: "eu",
  Gb: "gb",
  Mx: "mx",
  Us: "us",
  Za: "za",
} as const;
export type FollowedStoresListRegionEnum = (typeof FollowedStoresListRegionEnum)[keyof typeof FollowedStoresListRegionEnum];
