import { Source } from 'components/Image/Image';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import UploadableImage from 'components/UploadableImage/UploadableImage';
import { IMAGE_ORDERING } from 'api/common/ordering';
import { DESCENDING } from 'api/ordering';

export const IMAGE_COLUMN = Symbol('IMAGE_COLUMN');

interface ImageColumnRenderProps {
  thumbnail: Source[] | null;
  title: string;
}

const imageColumn = {
  id: IMAGE_COLUMN,
  ordering: IMAGE_ORDERING,
  label: 'Image',
  hideable: true,
  className: style.center,
  defaultDirection: DESCENDING,
  disableOrdering: true,
  render: ({ thumbnail, title }: ImageColumnRenderProps) => {
    return <UploadableImage title={title} srcset={thumbnail} images={[]} imageCount={1} />;
  },
};

export default imageColumn;
