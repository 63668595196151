import { Components, Theme } from '@mui/material';

export const MuiList: Components<Omit<Theme, 'components'>>['MuiList'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
    padding: 0,
  },
};
