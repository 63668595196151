// Purpose:
//
// This is used to render the toasts/modals that are provided via the ModalContext and
// ToastContext.
//
// Usage:
//
// Both of the providers for these sets of context must be part of the
// parent component hierarchy.
//
import { useContext, useEffect, useState } from 'react';

import cn from 'classnames';
import Toaster from 'components/Toaster/Toaster';

import ModalContext from 'context/ModalContext/ModalContext';
import ToastContext from 'context/ToastContext/ToastContext';
import styles from './styles.module.scss';

type ToastAndModalRendererProps = {
  /*
   * This is used to determine whether we want to support toasts in this context. We
   * may not want to support Toasts in mini-React app.s
   */
  supportToasts?: boolean;
  className?: string;
};

const ToastAndModalRenderer = ({ supportToasts = true, className }: ToastAndModalRendererProps) => {
  const { activeModal } = useContext(ModalContext);
  const { toasts } = useContext(ToastContext);

  let toaster = null;
  if (supportToasts) {
    toaster = <Toaster toasts={toasts} />;
  }

  // Mobile devices don't always respect `position: fixed` when there's a virtual keyboard on-screen.
  const [isVirtualKeyboard, setIsVirtualKeyboard] = useState(false);
  useEffect(() => {
    const initialHeight = window.visualViewport?.height || 0;
    const handleResize = () => {
      const vh = window.visualViewport?.height || 0;
      setIsVirtualKeyboard(vh < initialHeight);
      setOffset(window.visualViewport?.pageTop || 0);
    };

    window.visualViewport?.addEventListener('resize', handleResize);
    return () => window.visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  // So we have to switch to `position: absolute` and adjust the `top` offset.
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    // Reset offset when virtual keyboard is closed.
    if (!isVirtualKeyboard) {
      setOffset(0);
      return;
    }

    const handleScroll = () => {
      setOffset(window.visualViewport?.pageTop || 0);
    };

    window.visualViewport?.addEventListener('scroll', handleScroll);
    return () => window.visualViewport?.removeEventListener('scroll', handleScroll);
  }, [isVirtualKeyboard]);

  const inlineStyle: { position?: 'absolute'; top?: number } = isVirtualKeyboard
    ? { position: 'absolute', top: offset }
    : {};

  return (
    <>
      <div className={cn(styles.toasterContainer, className)} style={inlineStyle}>
        {toaster}
      </div>
      <>{activeModal}</>
    </>
  );
};

export default ToastAndModalRenderer;
