import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import ColumnTooltip from 'components/Inventory/Columns/ColumnTooltip/ColumnTooltip';
import { ActivityTypeEnum, AnimalManagerHusbandryView } from 'api/sdk';
import { INQUIRY_DATE_FORMAT } from 'services/constants';
import { camelCaseKeys } from '../../../../../../src/js/utils';
import styles from './styles.module.scss';

export const WATER_CHANGE_ACTIVITY = Symbol('WATER_CHANGE_ACTIVITY');

const WaterChangeColumn = {
  id: WATER_CHANGE_ACTIVITY,
  label: 'Water Change',
  hideable: true,
  disableOrdering: true,
  render: ({ activities }: AnimalManagerHusbandryView) => {
    const camelCasedActivities = activities.map((activity) => camelCaseKeys(activity));
    const latest = camelCasedActivities.find(
      (activity) => activity.activityType === ActivityTypeEnum.WaterChange
    );

    if (!latest) return <div>-</div>;

    const date = latest?.activityDate ? dayjs(latest.activityDate).format(INQUIRY_DATE_FORMAT) : '';

    return (
      <div className={styles.defaultColumn}>
        <span className={styles.value}>
          {latest?.privateNotes && <ColumnTooltip text={latest.privateNotes} icon={faInfoCircle} />}{' '}
        </span>
        {date && <span className={styles.date}>{date}</span>}
      </div>
    );
  },
};

export default WaterChangeColumn;
