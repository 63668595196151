import {
  IMAGE_ORDERING,
  CATEGORY_ORDERING,
  DAM_ORDERING,
  SIRE_ORDERING,
  TRAITS_ORDERING,
} from 'api/common/ordering';
import { ASCENDING, DESCENDING } from 'api/ordering';

// Modes
export const ANIMAL_ID_ORDERING = Symbol('ANIMAL_ID_ORDERING');
export const BIRTH_DATE_ORDERING = Symbol('BIRTH_DATE_ORDERING');
export const CLICKS_ORDERING = Symbol('CLICKS_ORDERING');
export const GROUP_ID_ORDERING = Symbol('GROUP_ID_ORDERING');
export const DIET_ORDERING = Symbol('DIET_ORDERING');
export const IMPRESSIONS_ORDERING = Symbol('IMPRESSIONS_ORDERING');
export const INQUIRIES_ORDERING = Symbol('INQUIRIES_ORDERING');
export const FIRST_LISTED_ORDERING = Symbol('FIRST_LISTED_ORDERING');
export const LAST_UPDATED_ORDERING = Symbol('LAST_UPDATED_ORDERING');
export const MATURITY_ORDERING = Symbol('MATURITY_ORDERING');
export const PRICE_ORDERING = Symbol('PRICE_ORDERING');
export const WHOLESALE_PRICE_ORDERING = Symbol('WHOLESALE_PRICE_ORDERING');
export const QUANTITY_ORDERING = Symbol('QUANTITY_ORDERING');
export const SEX_ORDERING = Symbol('SEX_ORDERING');
export const STATUS_ORDERING = Symbol('STATUS_ORDERING');
export const TITLE_ID_ORDERING = Symbol('TITLE_ID_ORDERING');
export const TITLE_ORDERING = Symbol('TITLE_ORDERING');
export const WEIGHT_ORDERING = Symbol('WEIGHT_ORDERING');
export const TAG_ORDERING = Symbol('TAG_ORDERING');
export const EXPO_ORDERING = Symbol('EXPO_ORDERING');
export const LAST_RENEWED_ORDERING = Symbol('LAST_RENEWED_ORDERING');
export const AVAILABLE_FOR_ORDER_ORDERING = Symbol('AVAILABLE_FOR_ORDER_ORDERING');
export const ACCEPT_OFFERS_ORDERING = Symbol('ACCEPT_OFFERS_ORDERING');
export const TRANSFERRED_FROM_ORDERING = Symbol('TRANSFERRED_FROM_ORDERING');

type ParamsType = [symbol, string];

type Ordering = {
  mode: symbol;
  direction: typeof ASCENDING | typeof DESCENDING;
  param: string;
};

const params: ParamsType[] = [
  [ANIMAL_ID_ORDERING, 'animal_id'],
  [BIRTH_DATE_ORDERING, 'birth_date'],
  [CATEGORY_ORDERING, 'category'],
  [CLICKS_ORDERING, 'clicks'],
  [GROUP_ID_ORDERING, 'group_id'],
  [DAM_ORDERING, 'dams'],
  [DIET_ORDERING, 'diet'],
  [IMAGE_ORDERING, 'image'],
  [IMPRESSIONS_ORDERING, 'impressions'],
  [INQUIRIES_ORDERING, 'inquiries'],
  [FIRST_LISTED_ORDERING, 'first_listed'],
  [LAST_UPDATED_ORDERING, 'last_updated'],
  [MATURITY_ORDERING, 'maturity'],
  [PRICE_ORDERING, 'price'],
  [WHOLESALE_PRICE_ORDERING, 'wholesale_price'],
  [QUANTITY_ORDERING, 'quantity'],
  [SEX_ORDERING, 'sex'],
  [SIRE_ORDERING, 'sires'],
  [STATUS_ORDERING, 'status'],
  [TITLE_ID_ORDERING, 'title_id'],
  [TITLE_ORDERING, 'title'],
  [TRAITS_ORDERING, 'traits'],
  [WEIGHT_ORDERING, 'weight'],
  [TAG_ORDERING, 'tags'],
  [EXPO_ORDERING, 'expo'],
  [LAST_RENEWED_ORDERING, 'last_renewal'],
  [AVAILABLE_FOR_ORDER_ORDERING, 'available_for_order'],
  [ACCEPT_OFFERS_ORDERING, 'accept_offers'],
  [TRANSFERRED_FROM_ORDERING, 'transferred_from'],
];

const inventoryOrdering = params.reduce<Ordering[]>((acc, [mode, param]) => {
  acc.push(
    {
      mode,
      direction: ASCENDING,
      param,
    },
    {
      mode,
      direction: DESCENDING,
      param: `-${param}`,
    }
  );
  return acc;
}, []);

export default inventoryOrdering;
