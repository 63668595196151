import { useEffect } from 'react';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './Toast.module.scss';

const icons = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle
};

const defaultLifetimeMs = {
  success: 10_000,
  warning: Infinity,
  error: Infinity,
  info: Infinity,
};

const Toast = ({ id, message, type, isHtml, lifetimeMs, className, onDelete }) => {
  useEffect(() => {
    let timeoutId;
    const effectiveTimeoutMs = lifetimeMs ?? defaultLifetimeMs[type];

    if (effectiveTimeoutMs < Infinity) {
      timeoutId = window.setTimeout(() => onDelete?.(id), effectiveTimeoutMs);
    }

    return () => window.clearTimeout(timeoutId);
  }, [id, lifetimeMs, type, onDelete]);

  return (
    <button
      type="button"
      className={classNames(style.container, style[type], className)}
      onClick={() => onDelete?.(id)}
    >
      <FontAwesomeIcon icon={icons[type]} className={style.icon} />
      <div className={style.message}>
        {!isHtml ? message : <div dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
      <div className={style.close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </button>
  );
};

Toast.propTypes = {
  id: PropTypes.number,
  message: PropTypes.any, // TODO: When moving to TS, this should be string or ReactNode
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  isHtml: PropTypes.bool,
  lifetimeMs: PropTypes.number,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};

export default Toast;
