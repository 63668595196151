import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { WEIGHT_ORDERING } from 'api/inventory/ordering';
import { Animal } from 'types';

export const WEIGHT_COLUMN = Symbol('WEIGHT_COLUMN');

const weightColumn = {
  id: WEIGHT_COLUMN,
  ordering: WEIGHT_ORDERING,
  label: 'Weight',
  shortLabel: 'Wt',
  hideable: true,
  className: style.weightColumn,

  render: ({ weight_display }: Animal) => weight_display,
};

export default weightColumn;
