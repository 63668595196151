import HumanReadableError from 'errors/HumanReadableError';
import inventoryFilters from 'api/inventory/filters';
import { HTTPMethod } from 'api/sdk';
import { FiltersObject } from 'api/types';
import { filtersToQueryParams } from 'api/url';
import { fetchApi } from 'api/utils';
import { Animal } from 'services/types';
import { camelCaseKeys } from '../../../../src/js/utils';

type Animals = Animal[] | Set<number>;

const animalsToKeys = (animals: Animals) =>
  animals instanceof Set ? [...animals] : animals.map(({ key }) => key);

export const fetchAnimals = async (filters: FiltersObject, abortController: AbortController) => {
  const params = filtersToQueryParams(inventoryFilters, filters, true);

  const paramsWithValue = [...params].filter(([, value]) => (value ?? '') !== '');

  const query = new URLSearchParams(paramsWithValue).toString();
  const url = `animals/${query ? '?' : ''}${query}`;
  const response = await fetchApi(url, { signal: abortController?.signal });

  if (!response.ok) {
    throw new HumanReadableError('Failed to fetch animals.');
  }

  const data = await response.json();

  return data;
};

const getConfig = (
  animals: Animals,
  payload: unknown,
  bulkUrl: string,
  getUrl: (animal: Animal) => string,
  method: HTTPMethod
) => {
  const isBulk = animals instanceof Set || animals.length > 1;
  const body = isBulk ? { keys: animalsToKeys(animals), payload } : payload;
  return {
    url: isBulk ? bulkUrl : getUrl(animals[0]),
    config: {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': window.CSRF_TOKEN,
      },
    },
  };
};

export const editAnimals = async (animals: Animals, payload: unknown) => {
  const bulkUrl = window.API_ENDPOINTS.ANIMALS;
  const { url, config } = getConfig(animals, payload, bulkUrl, (animal) => animal.url, 'PATCH');

  return fetchApi(url, config);
};

export const deleteAnimals = async (animals: Animals) => {
  const bulkUrl = window.API_ENDPOINTS.ANIMALS;
  const { url, config } = getConfig(animals, {}, bulkUrl, (animal) => animal.url, 'DELETE');

  return fetchApi(url, config);
};

export const renewAds = async (animals: Animals) => {
  const bulkUrl = window.API_ENDPOINTS.ANIMALS_RENEW;
  const { url, config } = getConfig(
    animals,
    {},
    bulkUrl,
    (animal) => camelCaseKeys(animal).renewUrl,
    'PATCH'
  );

  return fetchApi(url, config);
};
