/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This serializer should be used for related references,
 * like FKs or M2M to stores. e.g. it is used in the creation(post)
 * method of followed stores.
 * @export
 * @interface StoreReferenceRequest
 */
export interface StoreReferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreReferenceRequest
     */
    id: string;
}

export function StoreReferenceRequestFromJSON(json: any): StoreReferenceRequest {
    return StoreReferenceRequestFromJSONTyped(json, false);
}

export function StoreReferenceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreReferenceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function StoreReferenceRequestToJSON(value?: StoreReferenceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

