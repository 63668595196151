// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version--muae4{text-align:right;font-size:.75rem;opacity:.6;width:100%}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Drawer/components/styles.module.scss","webpack://./mysite/static/react-app/src/style/typo/sizes.scss"],"names":[],"mappings":"AAEA,gBACE,gBAAA,CACA,gBCsBK,CDrBL,UAAA,CACA,UAAA","sourcesContent":["@use \"style/typo\" as *;\n\n.version {\n  text-align: right;\n  font-size: $f_xs;\n  opacity: 0.6;\n  width: 100%;\n}","// Used fonts\n// PX:\n// 40px -> 2x\n// 32px -> 6x\n// 28px -> 4x\n// 24px -> 32x\n// 20px -> 29x\n// 19px -> 19x\n// 18px -> 18x\n// 16px -> 44x\n// 14px -> 71x\n// 13px -> 3x\n// 12px -> 51x\n// 11px -> 9x\n// 10px -> 2x\n// 9.5px -> 1x\n// 8px -> 1x\n// Rems ( A lot - 215x)\n// 1.125rem, 1.375rem\n\n$f_xxxl: 2.5rem; // 40px\n$f_xxl: 2rem; // 32px\n$f_xl: 1.5rem; // 24px\n$f_lg: 1.25rem; // 20px\n$f_md: 1rem; // 16px\n$f_sm: 0.875rem; // 14px\n$f_xs: 0.75rem; // 12px\n$f_xxs: 0.625rem; // 10px\n$f_xxxs: 0.5rem; // 8px\n\n$fonts: (\n  'f_xxxl': $f_xxxl,\n  'f_xxl': $f_xxl,\n  'f_xl': $f_xl,\n  'f_lg': $f_lg,\n  'f_md': $f_md,\n  'f_sm': $f_sm,\n  'f_xs': $f_xs,\n  'f_xxs': $f_xxs,\n  'f_xxxs': $f_xxxs,\n);\n\n:export {\n  @each $size, $value in $fonts {\n    #{unquote($size)}: #{$value};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"f_xxxl": `2.5rem`,
	"f_xxl": `2rem`,
	"f_xl": `1.5rem`,
	"f_lg": `1.25rem`,
	"f_md": `1rem`,
	"f_sm": `0.875rem`,
	"f_xs": `0.75rem`,
	"f_xxs": `0.625rem`,
	"f_xxxs": `0.5rem`,
	"fw_thin": `100`,
	"fw_extra_light": `200`,
	"fw_light": `300`,
	"fw_regular": `400`,
	"fw_regular_medium": `450`,
	"fw_medium": `500`,
	"fw_semi_bold": `600`,
	"fw_bold": `700`,
	"fw_extra_bold": `800`,
	"fw_black": `900`,
	"version": `version--muae4`
};
export default ___CSS_LOADER_EXPORT___;
