/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentsProviderEnum,
    PaymentsProviderEnumFromJSON,
    PaymentsProviderEnumFromJSONTyped,
    PaymentsProviderEnumToJSON,
} from './PaymentsProviderEnum';

/**
 * 
 * @export
 * @interface UserPaymentInfo
 */
export interface UserPaymentInfo {
    /**
     * 
     * @type {boolean}
     * @memberof UserPaymentInfo
     */
    readonly hasActivePaymentMethod: boolean;
    /**
     * 
     * @type {PaymentsProviderEnum}
     * @memberof UserPaymentInfo
     */
    readonly paymentsProvider: PaymentsProviderEnum | null;
    /**
     * This does not apply to PayPal users, as they don't have a card.
     * @type {string}
     * @memberof UserPaymentInfo
     */
    readonly last4DigitsOfCard: string | null;
    /**
     * This does not apply to PayPal users, as they don't have a card.
     * @type {string}
     * @memberof UserPaymentInfo
     */
    readonly cardBrand: string | null;
    /**
     * This does not apply to PayPal users, as they don't have a card.
     * @type {boolean}
     * @memberof UserPaymentInfo
     */
    readonly isExpired: boolean | null;
}

export function UserPaymentInfoFromJSON(json: any): UserPaymentInfo {
    return UserPaymentInfoFromJSONTyped(json, false);
}

export function UserPaymentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPaymentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasActivePaymentMethod': json['has_active_payment_method'],
        'paymentsProvider': PaymentsProviderEnumFromJSON(json['payments_provider']),
        'last4DigitsOfCard': json['last_4_digits_of_card'],
        'cardBrand': json['card_brand'],
        'isExpired': json['is_expired'],
    };
}

export function UserPaymentInfoToJSON(value?: UserPaymentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

