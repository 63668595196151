import { KeyboardEventHandler } from 'react';

/**
 * Helper for Sidebar component. Encapsulates functions that need to be run when the user closes the sidebar via the Escape key.
 */
export const onEscape =
  (close: () => void): KeyboardEventHandler<HTMLDivElement> =>
  (e) => {
    if (e.key === 'Escape') {
      close();
      (e.target as any)?.blur();
    }
  };

/**
 * Helper for Sidebar component. Encapsulates functions that need to be run when the user opens the sidebar by clicking an Accordion menu inside the Sidebar.
 */
export const openSidebar = (open: (e: any) => void) => (e: any) => {
  const button = e.target?.closest('button[id]');

  if (button) {
    open(e);
    setTimeout(() => {
      button?.focus();
    });
  }
};

/**
 * Helper for Sidebar component. Encapsulates functions that need to be run when the user closes the sidebar, such as closing the Accordion menu.
 */
export const closeSidebar = (e: any) => {
  e.currentTarget?.querySelectorAll('[aria-expanded]')?.forEach((node: any) => {
    if (!node.parentNode?.contains(e?.relatedTarget)) {
      node.click();
    }
  });
};
