/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `For Sale` - For Sale
 * * `On Hold` - On Hold
 * * `Sold` - Sold
 * * `Not For Sale` - Not For Sale
 * * `Breeder` - Breeder
 * * `Holdback` - Holdback
 * * `Pet` - Pet
 * * `Loaned` - Loaned
 * * `Archived` - Archived
 * * `On Hold - Auction` - On Hold - Auction
 * * `Sold - Auction` - Sold - Auction
 * @export
 */
export const StateDisplayEnum = {
    ForSale: 'For Sale',
    OnHold: 'On Hold',
    Sold: 'Sold',
    NotForSale: 'Not For Sale',
    Breeder: 'Breeder',
    Holdback: 'Holdback',
    Pet: 'Pet',
    Loaned: 'Loaned',
    Archived: 'Archived',
    OnHoldAuction: 'On Hold - Auction',
    SoldAuction: 'Sold - Auction'
} as const;
export type StateDisplayEnum = typeof StateDisplayEnum[keyof typeof StateDisplayEnum];


export function StateDisplayEnumFromJSON(json: any): StateDisplayEnum {
    return StateDisplayEnumFromJSONTyped(json, false);
}

export function StateDisplayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateDisplayEnum {
    return json as StateDisplayEnum;
}

export function StateDisplayEnumToJSON(value?: StateDisplayEnum | null): any {
    return value as any;
}

