export interface IWindow {
  STORES_DATA_URL: string;
  CATEGORIES_DATA_URL: string;
  MENU_CATEGORIES_DATA_URL: string;
  URLS: {
    BLOCK_STORE: string;
    UNBLOCK_STORE: string;
    FOLLOW_STORE: string;
    UNFOLLOW_STORE: string;
    SEARCH: string;
    STORES: string;
    STORE_BASE: string;
    LOGIN: string;
    NOTIFICATIONS: string;
    INVENTORY: string;
    HUSBANDRY_INVENTORY: string;
    EDIT_INVENTORY: string;
    OLD_INVENTORY: string;
    NEW_INVENTORY: string;
    USER_GROUPS: string;
    PRIVACY: string;
    TOS: string;
    RULES: string;
    PRICING: string;
  };
  API_ENDPOINTS: {
    ANIMALS: string;
    ANIMALS_RENEW: string;
    USER_PREFERENCES: string;
    SAVED_SEARCHES: string;
    OFFSPRING: string;
  };
}

declare global {
  interface Window extends IWindow {}
}

export const { STORES_DATA_URL } = window;
export const { CATEGORIES_DATA_URL } = window;
export const { MENU_CATEGORIES_DATA_URL } = window;
