import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConditionalHook } from 'hooks/utils/useConditionalHook';
import useCustomHistory from './useManageUrls';

const isApp = () => window.IS_APP && window.ReactNativeWebView;
const goBackInApp = () => {
  const message = {
    type: 'BACK_BUTTON_PRESSED',
    payload: {},
  };
  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

/**
 * Handles the logic for: identifying whether the user can go back from the current page and sending the user to the previous page.
 * Works only if both pages are React pages.
 */
export const useBackButton = () => {
  const viaBackButton = useRef<boolean>(false);
  const { pop, push, previousUrls } = useCustomHistory();
  const previousUrl = previousUrls[previousUrls.length - 1] || undefined;
  const canGoBack = previousUrls.length > 1;

  const onLocationChange = () => {
    if (!viaBackButton.current) {
      push();
    }

    viaBackButton.current = false;
  };

  const navigate = useConditionalHook(useNavigate);
  const goBack = () => {
    viaBackButton.current = true;

    if (isApp()) {
      goBackInApp();
    } else {
      pop(navigate);
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('locationchange', onLocationChange);
    return () => window.removeEventListener('locationchange', onLocationChange);
  }, []);

  return {
    canGoBack,
    previousUrl,
    goBack,
  };
};
