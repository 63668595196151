import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { LayoutIconProps } from '../ViewsIcon/ViewsIcon';
import styles from './FilterIcon.module.scss';

export const FilterIcon: React.FC<LayoutIconProps> = ({ className, size }) => {
  return (
    <FontAwesomeIcon
      icon={faBarsFilter}
      className={cn(styles.defaultSize, className)}
      size={size}
    />
  );
};
