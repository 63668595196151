import { useState, createContext } from 'react';

export type Status = 'denied' | 'granted' | 'prompt';

export type GeoContext = {
  isLoading: boolean;
  status: Status;
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GeolocationContext = createContext<GeoContext>({
  isLoading: false,
  status: 'prompt',
  setStatus: () => {},
  setLoading: () => {},
});

export const GeolocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<Status>('prompt');
  const [isLoading, setLoading] = useState(false);

  return (
    <GeolocationContext.Provider value={{ isLoading, setLoading, status, setStatus }}>
      {children}
    </GeolocationContext.Provider>
  );
};
