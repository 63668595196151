import { QueryParam } from 'hooks/utils/useQuery';
import { ASCENDING, DESCENDING } from '../api/ordering';
import { AnimalState, AnimalGender, DeliveryOptions } from '../enums';
import { AnimalImage } from '../types';

export interface Pageable<T> {
  count: number;
  next: any;
  previous: any;
  results: T[];
}

export interface ImageUrl {
  small: string;
  medium: string;
  original: string;
}

export interface SearchEventsFilters {
  eventType?: string;
  searchQuery?: string;
  dateFrom?: string;
  dateTo?: string;
  countryId?: string;
  state?: string;
}

export interface EventDaySchedule {
  date?: string | null;
  description?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  track?: string | null;
}

export interface MorphmarketEventListItem {
  id: number;
  internal_url: string;
  status: MorphmarketEventStatus;
  cover_url: string;
  end_time?: string | null;
  event_type: number;
  facebook_url?: string | null;
  floor_plan_url?: string | null;
  region: string;
  event_url: string;
  home_url?: string | null;
  instagram_url?: string | null;
  name?: string | null;
  organization: Organization;
  formatted_address?: string | null;
  formatted_location?: string | null;
  public_description?: string | null;
  start_time?: string | null;
  status_text?: string | null;
  twitter_url?: string | null;
  vendor_count: number;
  youtube_url?: string | null;
  threads_url?: string | null;
  tiktok_url?: string | null;
  coldblooded_url?: string | null;
  slug?: string;
  schedule?: EventDaySchedule[] | null;
  featured?: boolean;
  time_zone: string;
  registration_status: number;
  animal_quantity: number;
}

export interface Address {
  formatted: string;
  id: number;
  latitude: number;
  locality: Locality;
  longitude: number;
  raw: string;
  route: string;
  street_number: string;
}

export interface Locality {
  id: number;
  name: string;
  postal_code: string;
  state: State;
}

export interface State {
  id: number;
  code: string;
  country: Country;
  name: string;
}

export interface Country {
  id: number;
  code: string;
  name: string;
}

export type MorphmarketEventListSort = typeof ASCENDING | typeof DESCENDING | null;

export interface MorphmarketEvent extends MorphmarketEventListItem {
  vendors: Vendor[];
}

export interface Organization {
  about_text: string | null;
  address: string | null;
  display_name: string;
  facebook_url: string | null;
  home_url: string | null;
  instagram_url: string | null;
  is_nonprofit: boolean;
  owner: User;
  person_name: string;
  phone_number: string;
  twitter_url: string | null;
  youtube_url: string | null;
  threads_url?: string | null;
  tiktok_url?: string | null;
  coldblooded_url?: string | null;
  store_label: string | null;
  store_name: string | null;
  urls: {
    listings: string | null;
    ratings: string | null;
    store_admin: string | null;
    store_public: string | null;
  };
}

export interface Vendor {
  id: number;
  type: string;
  participant: Participant | null;
  position: string;
  position_on_map: {
    position: number[];
    verified: boolean;
    url: string;
  };
  categories: number[];
  display_categories: CategoryLabel[];
  created: string | null;
  description: string | null;
  modified: string | null;
}

export interface CategoryLabel {
  display_name: string;
  key: number;
}

export interface Participant {
  display_name: string;
  person_name: string;
  seller_rating_score?: number;
  membership_badge?: MembershipBadge;
  home_url: string | null;
  twitter_url: string | null;
  instagram_url: string | null;
  facebook_url: string | null;
  youtube_url: string | null;
  threads_url?: string | null;
  tiktok_url?: string | null;
  coldblooded_url?: string | null;
  store_name: string;
  address: string | null;
  image_url: string | null;
  owner?: User;
  currency_code: string;
  approved: boolean;
  average_response_time_message: number | null;
  created: string | null;
  follower_count: number;
  store_label: string;
  ratings: {
    overall_ratings_score: number | null;
    positive_ratings_pct: number | null;
    reciprocated_ratings_pct: number | null;
  };
  responsiveness_rating: ResponsivenessRating;
  urls?: {
    ratings: string | null;
    store_admin: string | null;
    store_public: string | null;
    listings: string | null;
  };
  listings_count: number | null;
  features: {
    enhanced_event_listing: boolean | null;
  };
}

export enum MorphmarketEventStatus {
  ONGOING = 11,
  UPCOMING = 10,
  FINISHED = 12,
  CANCELLED = 3,
}

export enum MorphmarketTestsStatus {
  PAID = 'paid',
  REFUNDED = 'refunded',
  DELETED = 'deleted',
  CANCELLED = 'cancelled',
  PENDING_SHED = 'pending-shed',
  PENDING_PAYMENT = 'pending-payment',
  SHED_RECEIVED = 'shed-received',
  COMPLETE = 'complete',
  NEEDS_SHED_FOR_RERUN = 'needs-shed-for-rerun',
}

export interface User {
  username: string;
  first_name: string;
  last_name: string;
  has_store: boolean;
  has_organization: boolean;
  date_joined: string;
  unseen_messages: Detail;
  needs_reply_messages: Detail;
  max_photos_per_ad: number;
  urls: {
    message_inbox: string | null;
    membership: string | null;
  };
  is_superuser?: boolean;
}

export interface RegisterToEventWithStoreRequest {
  categories: number[];
  tableNumber: string;
  description?: string;
}

export enum OrganisationType {
  SELLER = 100,
  BREEDER = 101,
  PET_STORE = 102,

  ZOO = 103,
  VET = 104,

  RETAIL = 110,
  MANUFACTURER = 111,
  FEEDER_BREEDER = 112,

  ARTIST = 120,
  CONTENT_CREATOR = 121,
  PUBLISHER = 122,

  ADVOCACY = 130,
  EXPO = 131,
  SOFTWARE = 132,

  SHIPPING_SERVICE = 140,
  COURIER = 141,
  IMPORTER_EXPORTER = 142,

  CLUB_SOCIETY = 150,
  EDUCATION = 151,
  RESCUE = 152,
  CHARITY = 153,
  REMOVAL_SERVICE = 154,
  HERP_PARTY = 155,
  TESTING_SERVICE = 156,

  OTHER = 200,
}

export interface Dashboard {
  additional_urls: {
    capacity_explanation_url?: string;
  };
  capacity?: {
    ad_capacity_pct?: number;
    value_capacity_pct?: number;
    collection_capacity_pct?: number;
  };
  collections: {
    listings: {
      live: Listings;
      not_live: Listings;
    };
    collection: {
      active: Collection;
      non_active: Collection;
    };
    history: {
      all: HistoryBlock;
      archived: HistoryBlock;
      sold: HistoryBlock;
      sold_mm: HistoryBlock;
      sold_elsewhere: HistoryBlock;
    };
  };
}

export interface Detail {
  count: number | null;
  url: string | null;
  enabled?: boolean;
}

export interface HistoryBlock {
  categories: Category[];
  counts: {
    Public: Detail;
    Unlisted: Detail;
    Private: Detail;
    _total: number | null;
  };
  distributions: {
    sex: {
      '?': number | null;
      male: number | null;
      female: number | null;
    };
    sav?: {
      Archived: Detail;
      'For Sale': Detail;
      Loaned: Detail;
      Sold: Detail;
      'Not For Sale': Detail;
      'On Hold': Detail;
    };
  };
  engagement_stats: EngagementStats;
  url: string;
  value: Values;
}

export interface Listings {
  counts: {
    _total: number;
    'For Sale': Detail;
    'On Hold': Detail;
    Quantity: Detail;
  };
  value: Values;
  distributions: {
    sex: {
      '?': number | null;
      male: number | null;
      female: number | null;
    };
    sav?: {
      Expired: Detail;
      Inactive: Detail;
      Private: Detail;
      Unlisted: Detail;
    };
  };
  categories: Category[];
  engagement_stats: EngagementStats;
  url: string;
}

export interface Values {
  total: number | null;
  median: number | null;
  min: number | null;
  max: number | null;
}

export interface EngagementStats {
  views: number | null;
  clicks: number | null;
  inquiries: number | null;
  likes: number | null;
}

export interface Collection {
  counts: {
    _total: number;
    Public: Detail;
    Unlisted: Detail;
    Private: Detail;
    Expired: Detail;
    Inactive: Detail;
  };
  value: Values;
  distributions: {
    sex: {
      '?': number | null;
      male: number | null;
      female: number | null;
    };
    sav: {
      'Not For Sale': Detail;
      Loaned: Detail;
      'For Sale': Detail;
      'On Hold': Detail;
    };
  };
  categories: Category[];
  engagement_stats: EngagementStats;
  url: string;
}

export interface Blog {
  title: string;
  publication_date: string;
  excerpt: string;
  categories: BlogCategory[];
  url: string;
}

export interface BlogCategory {
  id: number;
  slug: string | null;
  title: string | null;
}

export interface Category {
  display_name: string;
  url: string;
  count: number;
}

export interface MembershipBadge {
  color: string;
  title: string;
  subtitle: string;
}

export interface ResponsivenessRating {
  score: number | null;
  rating: string;
  message: string;
  short_message:
    | 'Very Responsive'
    | 'Mostly Responsive'
    | 'Somewhat Unresponsive'
    | 'Unresponsive'
    | 'Unknown';
  color: string;
  is_known: false;
}

export type Animal = {
  active: string;
  animal_id: string;
  item_origin: string | null;
  accept_trades?: string | null;
  price_flexibility?: string | null;
  birth_date: {
    day: number | string | null;
    month: number | string | null;
    year: number | string | null;
  };
  proven_breeder: boolean | null;
  video_url: string | null;
  max_shipping: string;
  min_shipping: string;
  birth_date_display: string | null;
  buyer: string | null;
  buyer_display: string | null;
  can_edit: boolean;
  can_renew: boolean;
  category: string;
  category_id: number;
  category_display: string;
  click_count: string;
  clone_url: string;
  clutch_number: string;
  length: number | null;
  length_type: string;
  dams: {
    animal_id: string;
  }[];
  edit_url: string;
  image: string;
  images: AnimalImage[];
  impression_count: string;
  inquiries_count: string;
  key: number;
  last_updated: string;
  maturity: string;
  maturity_display: string;
  prey_food: string;
  prey_food_display: string;
  prey_status: string;
  prey_status_display: string;
  price: number;
  price_currency: string;
  price_display: string;
  quantity: number;
  quantity_display: string;
  remove_url: string;
  renew_url: string;
  sex: AnimalGender;
  sex_display: string;
  shipping_type: DeliveryOptions;
  sires: {
    animal_id: string;
  }[];
  state: AnimalState;
  state_display: string;
  thumb_image: string;
  title: string;
  path?: string;
  title_display: string;
  trait_count: string;
  description: string | null;
  traits: {
    id: number;
    key: string;
    class_label: string;
    name: string;
  }[];
  url: string;
  view_url: string;
  visibility: string;
  weight: number;
  weight_display: string;
  last_renewal: string | null;
  is_on_sale: boolean;
  is_sale_start_allowed?: boolean;
  sale_renewal_allowed_in_days?: string;
  sale_end_time?: string;
  first_listed?: string;
};

export enum EventsTabStatus {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
}

export enum RegistrationStatus {
  OPEN = 0,
  CLOSED = 1,
  DISABLED = 2,
  FUTURE = 3,
}

export interface FieldToQuery {
  field: string;
  check: (value: string[] | undefined, queries: QueryParam) => boolean;
  action: (value: string[] | undefined, queries: QueryParam) => void;
}
