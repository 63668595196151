import { HTMLAttributes, useEffect, useRef } from 'react';
import styles from './RecentlyVisitedAnchor.module.scss';

export type AnchorProps = HTMLAttributes<HTMLAnchorElement> & {
  autoFocus?: boolean;
  name?: string;
  has_wizard?: boolean;
  href?: string;
  active?: boolean;
};

export const RecentlyVisitedAnchor: React.FC<AnchorProps> = ({
  children,
  name,
  autoFocus,
  active,
  has_wizard,
  ...props
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  return (
    <a ref={ref} tabIndex={0} role="menuitem" aria-current={active ? 'page' : undefined} {...props}>
      {children || name} <span className={styles.info}>(Recently viewed)</span>
    </a>
  );
};
