import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteClasses, Popper } from '@mui/material';
import debounce from 'lodash/debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { MessengerSearchIcon } from 'components/experimental/Navigation/components';
import { InputField } from 'components/experimental/Navigation/components/Search/SearchInput';
import { useAboutMe } from 'context/UserContext/UserContext';
import { useUsers as useUsersAPI } from 'api/generated/useUsers';
import { UsersMessageThreadsListMessageCollectionTypeEnum } from 'api/sdk';
import { openMobileFilters } from 'hooks/messages/messages';
import { useOpenState } from 'hooks/utils/useOpenState';
import { useLastScrollDirection } from './useLastScrollDirection';
import styles from './QuickSearch.module.scss';

type Option = {
  href: string;
  label: string;
};

export const QuickSearchMessenger = () => {
  const classes: Partial<AutocompleteClasses> = {
    root: styles.root,
    endAdornment: styles.hidden,
    listbox: styles.listbox,
    option: styles.option,
    noOptions: styles.noOption,
    inputRoot: styles.inputRoot,
  };
  const navigate = useNavigate();
  const { isOpen, open, close } = useOpenState();
  const [value, setValue] = useState('');
  const direction = useLastScrollDirection();
  const [options, setOptions] = useState<Option[]>([]);

  const unwrapped = useUsersAPI();
  const { listMessageThreads } = unwrapped;

  const { about } = useAboutMe();
  const { username: urlUsername } = useParams();
  const fixedUsername = (about?.isSuperuser ? urlUsername : about?.username) || '';

  const getOptions = () => {
    if (!fixedUsername) return;

    listMessageThreads({
      userId: fixedUsername,
      page: 1,
      pageSize: 10,
      messageCollectionType: UsersMessageThreadsListMessageCollectionTypeEnum.Inbox,
      search: value,
    })
      .then((res) => {
        const options = res?.results?.map((thread) => {
          const { id, threadSubject, otherPartyNameUsername, inquiryId } = thread;
          const href = `/messages/${fixedUsername}/${id}`;
          const label = `${otherPartyNameUsername}${inquiryId ? ` - ${threadSubject}` : ''}`;
          return { href, label, onClick: () => navigate(href) };
        });

        setOptions(options || []);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Messenger Search Error', err);
      });
  };

  useEffect(
    function closeOnScrollDown() {
      if (direction === 'down') {
        close();
        setOptions([]);
        setValue('');
      }
    },
    [direction]
  );

  useEffect(
    function debounceOptions() {
      const debouncedGetOptions = debounce(() => {
        if (value.length > 3) {
          getOptions();
        }
      }, 300);

      debouncedGetOptions();

      return () => {
        debouncedGetOptions.cancel();
      };
    },
    [value]
  );

  return (
    <div className={styles.searchBar}>
      <div className={styles.innerContainer}>
        <Autocomplete
          classes={classes}
          open={isOpen}
          onOpen={open}
          onClose={() => {
            close();
            setOptions([]);
            setValue('');
          }}
          inputValue={value}
          clearOnBlur={true}
          slotProps={{ paper: { className: styles.paper } }}
          PopperComponent={({ ...props }) => {
            const anchorEl = props.anchorEl as HTMLElement;

            if (!anchorEl) {
              console.error(
                'Missing hoisting element, the search suggestions might not be positioned properly'
              );
            }

            return (
              <Popper
                {...props}
                placement="bottom-start"
                anchorEl={anchorEl}
                slotProps={{ root: { style: { width: '100%' } } }}
              />
            );
          }}
          onChange={(_e, value) => {
            if (!!value) {
              navigate(value?.href);
            }
          }}
          onInputChange={(e, value, reason) => {
            if (reason === 'reset') {
              setValue('');
            } else if (e && e?.type !== 'keydown') {
              setValue(value);
            }
          }}
          filterOptions={(all) => all}
          options={options}
          noOptionsText="Enter a few more letters..."
          renderInput={(params) => <InputField {...params} placeholder="Search Messages" />}
        />
        <MessengerSearchIcon handleClick={openMobileFilters} />
      </div>
    </div>
  );
};
