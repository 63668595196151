// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version--jSJVW{padding:.25rem .75rem;display:block}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Navigation/components/Menus/Settings.module.scss"],"names":[],"mappings":"AAEA,gBACE,qBAAA,CACA,aAAA","sourcesContent":["@use \"style/spacing\" as *;\n\n.version {\n  padding: $xxxs $xs;\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxxxxl": `6.5rem`,
	"xxxxl": `4rem`,
	"xxxl": `2.5rem`,
	"xxl": `2rem`,
	"xl": `1.5rem`,
	"lg": `1.25rem`,
	"md": `1rem`,
	"sm": `0.875rem`,
	"xs": `0.75rem`,
	"xxs": `0.5rem`,
	"xxxs": `0.25rem`,
	"xxxxs": `0.125rem`,
	"borderRadius": `0.375rem`,
	"version": `version--jSJVW`
};
export default ___CSS_LOADER_EXPORT___;
