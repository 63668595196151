import { useEffect, useMemo, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'pages/Dashboard/components/Tabs/Tabs';
import { ADDITIONAL_MOBILE_SETTINGS_TAB, MANAGE_TAGS_TAB } from 'pages/Messenger/utils/constants';
import { useAboutMe } from 'context/UserContext/UserContext';
import { UnseenThreadCounts } from 'api/sdk';
import { DONE_SELECTING, OPEN_MOBILE_FILTERS, SELECTABLE_MESSAGES } from 'hooks/messages/messages';
import { WEBSOCKETS_MESSAGE_TYPES } from '../../../../../../../../src/js/user-messages-web-sockets';
import style from './styles.module.scss';

type PathValueHandlers = {
  [key: string]: (path: string) => void;
};

const MessengerTabs = ({ unseenCounts }: { unseenCounts?: UnseenThreadCounts }) => {
  const navigate = useNavigate();
  const { about } = useAboutMe();
  const [selectableMessages, setSelectableMessages] = useState(false);

  const { pathname, search, href } = window.location;
  const inboxPathname = `${pathname}?collection=inbox`;
  const tabs = useMemo(() => {
    const baseTabs: Array<Tab> = [
      {
        title: 'Inbox',
        value: inboxPathname,
      },
      {
        title: `Offers${unseenCounts?.offers ? ` (${unseenCounts.offers})` : ''}`,
        value: `${pathname}?filter=offers&collection=all`,
      },
      {
        title: 'Archive',
        value: `${pathname}?collection=archive`,
      },
      {
        title: `Unread${unseenCounts?.collection ? ` (${unseenCounts.collection})` : ''}`,
        value: `${pathname}?filter=unseen&collection=all`,
      },
      {
        title: `Needs Reply${unseenCounts?.needsReply ? ` (${unseenCounts.needsReply})` : ''}`,
        value: `${pathname}?filter=needs-reply&collection=all`,
      },
      {
        title: 'All',
        value: `${pathname}?collection=all`,
      },
      {
        title: 'Manage Tags',
        value: MANAGE_TAGS_TAB,
      },
    ];

    // add elements to the beginning of the array
    if (selectableMessages) {
      baseTabs.unshift({
        title: 'Done',
        value: DONE_SELECTING,
      });
    } else {
      baseTabs.unshift({
        title: 'Additional Actions',
        value: ADDITIONAL_MOBILE_SETTINGS_TAB,
        icon: faEllipsisVertical,
      });
    }

    return baseTabs;
  }, [pathname, JSON.stringify(unseenCounts), about, selectableMessages]);

  const [selected, setSelected] = useState(search ? pathname + search : inboxPathname);

  const resetSelectableMessages = () => {
    // cancel selection if user changes tab
    setSelectableMessages(false);
    document.dispatchEvent(new CustomEvent(DONE_SELECTING));
  };

  const handlePathValue: PathValueHandlers = {
    [MANAGE_TAGS_TAB]: (pathValue: string) => {
      document.dispatchEvent(new CustomEvent(OPEN_MOBILE_FILTERS, { detail: { path: pathValue } }));
      resetSelectableMessages();
    },
    [DONE_SELECTING]: () => {
      resetSelectableMessages();
    },
    [ADDITIONAL_MOBILE_SETTINGS_TAB]: (pathValue: string) => {
      document.dispatchEvent(
        new CustomEvent(ADDITIONAL_MOBILE_SETTINGS_TAB, { detail: { path: pathValue } })
      );
      resetSelectableMessages();
    },
    default: (pathValue: string) => {
      document.dispatchEvent(
        new CustomEvent(WEBSOCKETS_MESSAGE_TYPES.MESSENGER_TABS_CLICK, {
          detail: { path: pathValue },
        })
      );
      resetSelectableMessages();
      navigate(`${pathValue}&page=1`);
    },
  };

  const onChangeTab = (pathValue: string) => {
    const handle = handlePathValue[pathValue] || handlePathValue.default;
    handle(pathValue);
  };

  useEffect(
    function setDefaultTab() {
      setSelected(search ? pathname + search : inboxPathname);
    },
    [href]
  );

  useEffect(function handleWebsocketEvents() {
    const handleStartSelectableMessages = (u: unknown) => {
      const { detail } = u as { detail: { state: boolean } };
      const { state } = detail || {};
      setSelectableMessages(state);
    };

    document.addEventListener(SELECTABLE_MESSAGES, handleStartSelectableMessages);
    return () => {
      document.removeEventListener(SELECTABLE_MESSAGES, handleStartSelectableMessages);
    };
  }, []);

  return (
    <div className={style.tabsWrapper}>
      <Tabs onChange={onChangeTab} value={selected} tabs={tabs} />
    </div>
  );
};

export default MessengerTabs;
