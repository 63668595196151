/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MessageByEnum = {
    System: 'system',
    You: 'you',
    Them: 'them'
} as const;
export type MessageByEnum = typeof MessageByEnum[keyof typeof MessageByEnum];


export function MessageByEnumFromJSON(json: any): MessageByEnum {
    return MessageByEnumFromJSONTyped(json, false);
}

export function MessageByEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageByEnum {
    return json as MessageByEnum;
}

export function MessageByEnumToJSON(value?: MessageByEnum | null): any {
    return value as any;
}

