import { useCallback, useEffect, useState } from 'react';

export type UseAPIList<C extends (...args: any) => any> = {
  data?: Awaited<ReturnType<C>>;
  params?: Partial<Parameters<C>>;
  setParams: (params: Partial<Parameters<C>>) => void;
  parametrizedFetch: () => void;
  refetch: (...args: Parameters<C>) => Promise<void>;
};

/**
 * Automatically fetches list data from the API and exposes it. Also provides a callback for refetching the data.
 */
export const useAPIList = <C extends (...args: any) => any>(
  callback: C,
  autoFetch?: false
): UseAPIList<C> => {
  const [params, setParams] = useState<Partial<Parameters<C>>>();
  const [data, setData] = useState<Awaited<ReturnType<C>>>();
  const fetch = useCallback(
    async (...args: Parameters<C>) => {
      const response = await callback(...args);
      setData(response);
    },
    [callback]
  );

  useEffect(() => {
    if (autoFetch !== false && !data?.length) {
      // Note: if the fetch call to the API is supposed to run on mount, the callback "C" should already include its own arguments,
      // like so: () => callback(...params);
      fetch(...([] as Parameters<C>));
    }
  }, [fetch, autoFetch]);

  return {
    data,
    params,
    setParams,
    parametrizedFetch: () => fetch(params),
    refetch: fetch,
  };
};

/**
 * This custom hook is basically a wrapper around useAPIList with custom typing targeted at the OpenAPI SDK.
 * It should provide type hinting for list request parameters.
 */
export type UseSDKList<C extends (...args: any) => any> = {
  data?: Awaited<ReturnType<C>>;
  params?: Partial<Parameters<C>[0]>;
  setParams: (params: Partial<Parameters<C>[0]>) => void;
  parametrizedFetch: () => void;
  refetch: (args?: Parameters<C>[0]) => Promise<void>;
};

export const useSDKList = <C extends (...args: any) => any>(
  callback: C,
  autoFetch?: false
): UseSDKList<C> => useAPIList(callback, autoFetch) as any;
