import { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<Omit<Theme, 'components'>>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.down('mobileLarge')]: {
        '& .modalContentWrapper': {
          flexDirection: 'column',
        },
      },
    }),
  },
};
