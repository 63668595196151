/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreAuctionStatus
 */
export interface StoreAuctionStatus {
    /**
     * 
     * @type {number}
     * @memberof StoreAuctionStatus
     */
    ongoingCount: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAuctionStatus
     */
    creditsAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAuctionStatus
     */
    planAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAuctionStatus
     */
    maxAuctions: number;
}

export function StoreAuctionStatusFromJSON(json: any): StoreAuctionStatus {
    return StoreAuctionStatusFromJSONTyped(json, false);
}

export function StoreAuctionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreAuctionStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ongoingCount': json['ongoing_count'],
        'creditsAvailable': json['credits_available'],
        'planAvailable': json['plan_available'],
        'maxAuctions': json['max_auctions'],
    };
}

export function StoreAuctionStatusToJSON(value?: StoreAuctionStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ongoing_count': value.ongoingCount,
        'credits_available': value.creditsAvailable,
        'plan_available': value.planAvailable,
        'max_auctions': value.maxAuctions,
    };
}

