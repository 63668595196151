
import { Configuration } from 'api/sdk/runtime';
import { uFetch } from 'services/fetch';

// If we don't set the current domain here, we will
// 1. run into CORS issues with localhost vs. 127.0.0.1 vs. ngrok in development
// 2. not be able to configure for staging vs. production
const currentDomain = () => `${window.location.protocol}//${window.location.host}`;

export const configuration = new Configuration({
  basePath: currentDomain(),
  fetchApi: uFetch,
  headers: {
    'X-CSRFToken': window.CSRF_TOKEN,
  },
  middleware: [
    {
      // This middleware does essentially nothing, we're just putting it here so that the runtime clones() the response
      // This is to avoid situations where the response payload was consumed before reaching its destination due to multiple
      // calls in a short time-span
      post: async (context) => context.response,
    },
  ],
});
