import { sizes } from 'context/ThemeContext/Theme/constants';

export type DrawerAnchor = 'left' | 'top' | 'right' | 'bottom';

export const getPaperStyle = (
  isExpanded: boolean,
  isFullScreen: boolean,
  anchor?: DrawerAnchor
) => {
  const { mobileHeaderHeight } = sizes;

  let paperStyle = {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    padding: isExpanded ? 0 : '0.5rem 0 0.5rem 0', //for full size window drawer instead of modal filters
    maxHeight: `calc(100dvh - ${mobileHeaderHeight})`, // 3rem
    minHeight: isFullScreen ? `calc(100dvh - ${mobileHeaderHeight})` : 'auto', // 3rem
  };

  if (anchor === 'right') {
    paperStyle = {
      ...paperStyle,
      width: '100%',
      marginTop: mobileHeaderHeight,
    };
  }

  return paperStyle;
};
