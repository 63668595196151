/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarkSeenResponseResultEnum,
    MarkSeenResponseResultEnumFromJSON,
    MarkSeenResponseResultEnumFromJSONTyped,
    MarkSeenResponseResultEnumToJSON,
} from './MarkSeenResponseResultEnum';
import {
    MarkSeenResponseUnseenThreadCounts,
    MarkSeenResponseUnseenThreadCountsFromJSON,
    MarkSeenResponseUnseenThreadCountsFromJSONTyped,
    MarkSeenResponseUnseenThreadCountsToJSON,
} from './MarkSeenResponseUnseenThreadCounts';

/**
 * 
 * @export
 * @interface MarkSeenResponse
 */
export interface MarkSeenResponse {
    /**
     * 
     * @type {MarkSeenResponseResultEnum}
     * @memberof MarkSeenResponse
     */
    readonly result: MarkSeenResponseResultEnum | null;
    /**
     * 
     * @type {MarkSeenResponseUnseenThreadCounts}
     * @memberof MarkSeenResponse
     */
    unseenThreadCounts: MarkSeenResponseUnseenThreadCounts;
}

export function MarkSeenResponseFromJSON(json: any): MarkSeenResponse {
    return MarkSeenResponseFromJSONTyped(json, false);
}

export function MarkSeenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkSeenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': MarkSeenResponseResultEnumFromJSON(json['result']),
        'unseenThreadCounts': MarkSeenResponseUnseenThreadCountsFromJSON(json['unseen_thread_counts']),
    };
}

export function MarkSeenResponseToJSON(value?: MarkSeenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unseen_thread_counts': MarkSeenResponseUnseenThreadCountsToJSON(value.unseenThreadCounts),
    };
}

