import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import ColumnTooltip from 'components/Inventory/Columns/ColumnTooltip/ColumnTooltip';
import { ActivityTypeEnum, AnimalManagerHusbandryView } from 'api/sdk';
import { INQUIRY_DATE_FORMAT } from 'services/constants';
import { camelCaseKeys } from '../../../../../../src/js/utils';
import styles from './styles.module.scss';

export const WEIGHT_ACTIVITY = Symbol('WEIGHT_ACTIVITY');

const WeightColumn = {
  id: WEIGHT_ACTIVITY,
  label: 'Weight',
  hideable: true,
  disableOrdering: true,
  render: ({ activities }: AnimalManagerHusbandryView) => {
    const camelCasedActivities = activities.map((activity) => camelCaseKeys(activity));
    const latest = camelCasedActivities.find(
      (activity) => activity.activityType === ActivityTypeEnum.Weight
    );

    if (!latest) return <div>-</div>;

    const date = latest?.activityDate ? dayjs(latest.activityDate).format(INQUIRY_DATE_FORMAT) : '';
    const hasWeightChange = latest?.weightChange !== undefined && latest?.weightChange !== null;

    const isAnimalGainingWeight = hasWeightChange && latest.weightChange > 0;
    const isAnimalLosingWeight = hasWeightChange && latest.weightChange < 0;
    const sign = isAnimalGainingWeight ? '+' : '';
    const changeColor = isAnimalGainingWeight
      ? styles.positive
      : isAnimalLosingWeight
      ? styles.negative
      : '';

    return (
      <div className={styles.defaultColumn}>
        <span className={styles.value}>
          {latest?.weight?.value}
          {latest?.weight?.unit}
          {hasWeightChange && (
            <span className={`${styles.percentage} ${changeColor}`}>
              {sign}
              {latest.weightChange}%
            </span>
          )}
          {latest?.privateNotes && <ColumnTooltip text={latest.privateNotes} icon={faInfoCircle} />}
        </span>
        {date && <span className={styles.date}>{date}</span>}
      </div>
    );
  },
};

export default WeightColumn;
