import { useSearchParams, useLocation } from 'react-router-dom';

import { findCategory } from 'services/CustomURLs/helpers/categories';

declare global {
  interface Window {
    CATEGORY_ID: string;
  }
}

/**
 * Gets the current category, if possible.
 *
 * NB: A more fine-grained approach, suitable esp. for search-like pages, is available as the `useCurrentCategory` hook.
 *
 * TODO: Consolidate these two hooks
 */
export const useCategory = () => {
  const { pathname } = useLocation();
  const matches = pathname.match(/([\w-]+?)\/([\w-]+?)\/?$/);
  const [, subCategory, cat] = matches || [];
  const [params] = useSearchParams() as any;

  const category =
    cat === 'traits'
      ? subCategory
      : cat || params.get('cat') || params.get('category') || window?.CATEGORY_ID;

  return findCategory(category) || findCategory('all');
};
