import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

export default class EntriesJar {
  #entries;

  constructor(entries = []) {
    this.#entries = entries;
  }

  [Symbol.iterator]() {
    return this.#entries.values();
  }

  isEmpty() {
    return this.#entries.length === 0;
  }

  get(key) {
    const [, value] = this.#entries.find(([entryKey]) => entryKey === key) ?? [];

    return value ?? null;
  }

  countEntries() {
    return this.#entries.length;
  }

  countKeys() {
    const keys = this.#entries.map(([key]) => key);

    return new Set(keys).size;
  }

  getValuesByKey(key) {
    return this.#entries.reduce(
      (result, [entryKey, entryValue]) => (entryKey !== key ? result : [...result, entryValue]),
      []
    );
  }

  getKeys() {
    return this.#entries.map(([entryKey]) => entryKey);
  }

  getEntries() {
    return this.#entries;
  }

  setEntries(entries) {
    return new EntriesJar(entries);
  }

  add(key, ...values) {
    return this.setEntries([
      ...this.#entries,
      ...values.filter((value) => value != null).map((value) => [key, value]),
    ]);
  }

  set(key, value) {
    const matchingIndex = this.#entries.findIndex(([entryKey]) => entryKey === key);

    if (value == null) {
      return this.removeByIndex(matchingIndex);
    }

    if (matchingIndex === -1) {
      return this.add(key, value);
    }

    return this.setEntries(
      this.#entries.map(([entryKey, entryValue], entryIndex) => [
        entryKey,
        entryIndex === matchingIndex ? value : entryValue,
      ])
    );
  }

  // Compare Jars - the order of entries needs to match
  isIdentical(jar) {
    if (jar === this) {
      return true;
    }

    if (this.#entries.length !== jar.getEntries().length) {
      return false;
    }

    return this.#entries.every(([key, value], index) => {
      const [entryKey, entryValue] = jar.getEntries()[index];

      return key === entryKey && value === entryValue;
    });
  }

  // Compare Jars - the order of entries doesn't matter
  isEqual(jar) {
    if (jar === this) {
      return true;
    }

    if (this.#entries.length !== jar.getEntries().length) {
      return false;
    }

    const entriesCount = (jar) =>
      jar.#entries.reduce(
        (result, [key, value]) => ({
          ...result,
          [key]: {
            ...result[key],
            [value]: (result[key]?.[value] ?? 0) + 1,
          },
        }),
        {}
      );

    return isEqual(entriesCount(this), entriesCount(jar));
  }

  diffKeys(jar) {
    const keys = uniq([...this.getKeys(), ...jar.getKeys()]);

    return keys.filter((key) => !isEqual(this.getValuesByKey(key), jar.getValuesByKey(key)));
  }

  toggle(key, value = null) {
    if (value === null) {
      return this.has(key) ? this.remove(key) : this.add(key, true);
    }

    return this.hasEntry(key, value) ? this.removeEntry(key, value) : this.add(key, value);
  }

  replace(key, oldValue, newValue) {
    return this.setEntries(
      this.#entries.map(([entryKey, entryValue]) => [
        entryKey,
        entryKey === key && entryValue === oldValue ? newValue : entryValue,
      ])
    );
  }

  has(...keys) {
    return keys.every((key) => this.#entries.some(([entryKey]) => entryKey === key));
  }

  hasEntry(key, value) {
    return this.#entries.some(([entryKey, entryValue]) => entryKey === key && entryValue === value);
  }

  hasAny(...keys) {
    return keys.some((key) => this.#entries.some(([entryKey]) => entryKey === key));
  }

  filter(callback) {
    return this.setEntries(this.#entries.filter(([key, value]) => callback(value, key)));
  }

  only(...keys) {
    return this.setEntries(this.#entries.filter(([entryKey]) => keys.includes(entryKey)));
  }

  remove(...keys) {
    return this.setEntries(this.#entries.filter(([entryKey]) => !keys.includes(entryKey)));
  }

  removeEntry(key, value) {
    return this.setEntries(
      this.#entries.filter(([entryKey, entryValue]) => !(entryKey === key && entryValue === value))
    );
  }

  removeByIndex(index) {
    return this.setEntries(this.#entries.filter((_, entryIndex) => entryIndex !== index));
  }

  empty() {
    return this.setEntries([]);
  }

  copy() {
    return this.setEntries(this.#entries);
  }

  merge(jar) {
    const keysToRemove = jar.getKeys();

    return this.setEntries([...this.remove(...keysToRemove), ...jar]);
  }
}
