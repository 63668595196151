import { createTheme } from '@mui/material';
import { base } from './base';

const dark = createTheme({
  ...base,
  palette: {
    mode: 'dark',
    error: {
      main: '#eb5757',
    },
  },
});

export { dark };
