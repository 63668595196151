import {
  MATURITY_ADULT,
  MATURITY_BABY,
  MATURITY_JUVENILE,
  MATURITY_FILTER,
  MATURITY_NOT_READY,
  MATURITY_SUBADULT,
  MATURITY_UNKNOWN,
} from 'components/Inventory/Filters/MaturityFilter/definitions';
import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import inventoryFilters from 'api/inventory/filters';
import { MATURITY_ORDERING } from 'api/inventory/ordering';
import { MaturityEnum } from 'api/sdk';
import { queryValueToFilterValue } from 'api/url';
import styles from './MaturityColumn.module.scss';

export const MATURITY_COLUMN = Symbol('MATURITY_COLUMN');

const labels = {
  [MATURITY_NOT_READY]: 'Not Ready',
  [MATURITY_BABY]: 'Baby',
  [MATURITY_JUVENILE]: 'Juvenile',
  [MATURITY_SUBADULT]: 'Subadult',
  [MATURITY_ADULT]: 'Adult',
  [MATURITY_UNKNOWN]: null,
};

const maturityColumn = {
  id: MATURITY_COLUMN,
  ordering: MATURITY_ORDERING,
  label: 'Maturity',
  shortLabel: 'Mat',
  hideable: true,
  className: style.center,

  render: ({ maturity }: { maturity: MaturityEnum }) => {
    const maturityId = queryValueToFilterValue(
      inventoryFilters,
      MATURITY_FILTER,
      maturity
    ) as keyof typeof labels;
    const label = labels[maturityId] ?? null;

    return (
      <div className={styles.wrapper}>
        <span>{label ?? '-'}</span>
      </div>
    );
  },
};

export default maturityColumn;
