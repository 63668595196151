/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `100` - Seller
 * * `101` - Breeder
 * * `102` - Pet Store
 * * `152` - Rescue
 * * `130` - Advocacy
 * * `120` - Artist
 * * `153` - Charity
 * * `150` - Club / Society
 * * `121` - Content Creator
 * * `141` - Courier
 * * `151` - Education
 * * `131` - Expo
 * * `112` - Feeder Breeder
 * * `155` - Herp Party
 * * `142` - Importer / Exporter
 * * `111` - Manufacturer
 * * `200` - Other
 * * `122` - Publisher
 * * `110` - Retail
 * * `140` - Shipping Service
 * * `132` - Software
 * * `156` - Testing Service
 * * `104` - Veterinarian
 * * `103` - Zoo / Aquarium
 * @export
 */
export const OrganizationTypeEnum = {
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_152: 152,
    NUMBER_130: 130,
    NUMBER_120: 120,
    NUMBER_153: 153,
    NUMBER_150: 150,
    NUMBER_121: 121,
    NUMBER_141: 141,
    NUMBER_151: 151,
    NUMBER_131: 131,
    NUMBER_112: 112,
    NUMBER_155: 155,
    NUMBER_142: 142,
    NUMBER_111: 111,
    NUMBER_200: 200,
    NUMBER_122: 122,
    NUMBER_110: 110,
    NUMBER_140: 140,
    NUMBER_132: 132,
    NUMBER_156: 156,
    NUMBER_104: 104,
    NUMBER_103: 103
} as const;
export type OrganizationTypeEnum = typeof OrganizationTypeEnum[keyof typeof OrganizationTypeEnum];


export function OrganizationTypeEnumFromJSON(json: any): OrganizationTypeEnum {
    return OrganizationTypeEnumFromJSONTyped(json, false);
}

export function OrganizationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTypeEnum {
    return json as OrganizationTypeEnum;
}

export function OrganizationTypeEnumToJSON(value?: OrganizationTypeEnum | null): any {
    return value as any;
}

