import { Components, Theme } from '@mui/material';
import { colors } from '../constants';
const { gray400, gray000 } = colors;

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
  styleOverrides: {
    root: {
      backgroundColor: gray400,
      borderRadius: 6,
    },
    deleteIcon: {
      color: gray000,
    },
  },
};

// import { Components, Theme } from '@mui/material';
// import { colors, sizes } from '../constants';
// const { gray000, white } = colors;
// const { xxxxs, xxs } = sizes;
// import typo from 'style/typo.scss';

// declare module '@mui/material/Chip' {
//   interface ChipPropsVariantOverrides {
//     filledRounded: true;
//     outlinedRounded: true;
//   }
// }

// // Ready to use
// export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
//   defaultProps: {
//     variant: 'outlinedRounded',
//   },
//   styleOverrides: {
//     root: {
//       borderRadius: '6px',
//       textTransform: 'none',
//       boxShadow: 'none',
//       textDecoration: 'none',
//       padding: 0,
//       height: 24,
//       fontSize: typo.xs,
//       fontWeight: typo.fw_medium,
//       '&:hover': {
//         boxShadow: 'none',
//       },
//     },
//     label: {
//       padding: `${xxxxs} ${xxs}`,
//     },
//     deleteIcon: {
//       fontSize: typo.sm,
//       svg: {
//         fontSize: 'inherit',
//       },
//     },
//   },
//   variants: [
//     {
//       props: { variant: 'outlined' },
//       style: {
//         backgroundColor: white,
//         border: `1px solid ${gray000}`,
//         color: gray000,
//       },
//     },
//     {
//       props: { variant: 'outlinedRounded' },
//       style: {
//         backgroundColor: white,
//         border: `1px solid ${gray000}`,
//         color: gray000,
//         borderRadius: 20,
//       },
//     },
//     {
//       props: { variant: 'filled' },
//       style: {
//         backgroundColor: gray000,
//         color: white,
//       },
//     },
//     {
//       props: { variant: 'filledRounded' },
//       style: {
//         backgroundColor: gray000,
//         color: white,
//         borderRadius: 20,
//       },
//     },
//   ],
// };
