import Popover from '@mui/material/Popover';
import Slide from '@mui/material/Slide';
import { useDropdownMenu } from 'components/experimental/MUI/DropdownMenu';
import { SearchButton } from '../Buttons/Mobile/SearchButton';
import { MobileSearchField } from './MobileSearchField';
import { SearchField } from './SearchInput';
import styles from './Search.module.scss';

const Mobile: React.FC = () => {
  const { toggle, isOpen, close, utils } = useDropdownMenu({
    id: 'mobile-search',
  });
  const {
    button,
    menu: { MenuListProps, anchorEl, ...menu },
  } = utils;

  return (
    <>
      <SearchButton {...button} onClick={toggle} />
      <Popover
        anchorEl={document.body}
        classes={{ root: styles.menuRoot }}
        marginThreshold={0}
        TransitionComponent={Slide}
        transitionDuration={100}
        {...menu}
        {...MenuListProps}
      >
        <SearchField
          disablePortal
          PaperComponent={({ children }) => <div>{children}</div>}
          PopperComponent={({ children }) => <div className={styles.mobilePopper}>{children}</div>}
          renderInput={(props) => <MobileSearchField {...props} isOpen={isOpen} close={close} />}
        />
      </Popover>
    </>
  );
};

/**
 * Basic search form for the Navbar. Mobile version.
 */
export const MobileSearch = Mobile;

/**
 * Basic search form for the Navbar. Desktop version.
 */
export const DesktopSearch = SearchField;
