import { useState } from 'react';
import { generateSingletonHook } from 'context/SingletonContext';

// This global context hook is used to store all truly global attributes. It is an attempt to avoid circular
// import problems that arise from the order of contexts loading within AllGlobalContexts.tsx.
// For example, pageHasUnsavedChanges is used in both NativeAppGesturesContext.tsx and NativeAppPullToReloadContext.tsx.
const useDecoratedHook = () => {
  const [pageHasUnsavedChanges, setPageHasUnsavedChanges] = useState(false);

  return { pageHasUnsavedChanges, setPageHasUnsavedChanges };
};

const [GlobalContext, useGlobalContext] = generateSingletonHook(useDecoratedHook);
export { GlobalContext, useGlobalContext };
