export const messages = {
  success: 'Operation succeeded!',
  error: 'Operation failed!',
};

export const UNKNOWN_API_ERROR_MESSAGE =
  'Something went wrong. Please contact customer support if this is causing problems';

export const ERROR_5XX_MESSAGE =
  'The website is temporarily overloaded and rebooting. Try again in 1-2 minutes.';
