import { IS_AUTHENTICATED, USERNAME } from 'constants/user';
import { useAboutMe } from 'context/UserContext/UserContext';
import { logout as logoutCall } from 'api/user/session';
import { useAPIHook } from 'api/utilities/useAPIHook';
import { useMessagesCount } from 'hooks/utils/useMessagesCount';

/**
 * Returns the basic auth information about a user, if available.
 * @deprecated Use useAboutMe instead.
 */
export const useAuth = () => {
  const { isLoading, error, about: user, preferences } = useAboutMe();
  const { callback: logoutCB } = useAPIHook(logoutCall);
  const { unseenCount: unseenMessages } = useMessagesCount(user?.unseenThreads?.count || 0);
  const logout = () =>
    logoutCB().then(() => {
      // We need to preserve the original functionality of log out -> redirect to login page
      window.location.href = '/accounts/login';
    });
  const getAvatar = () => {
    return user?.storeLogoThumbUrl || user?.imageThumbUrl || undefined;
  };

  const value = {
    user,
    unseenMessages,
    isLoading,
    error,
    preferences,
    // Django provides us with user constants, but these should be overridden once the about() call returns fresh user data
    // This is a temporary measure, to avoid FOUC between the authenticated and unauthenticated user menus
    userName: !error ? user?.username || USERNAME : '',
    avatar: !error && (!!user?.username || IS_AUTHENTICATED) ? getAvatar() : undefined,
    isLoggedIn: !error ? !!user?.username || IS_AUTHENTICATED : false,
    logout,
  };

  return value;
};
