import style from 'components/Inventory/InventoryTable/InventoryTable.module.scss';
import { WHOLESALE_PRICE_ORDERING } from 'api/inventory/ordering';

export const WHOLESALE_PRICE_COLUMN = Symbol('WHOLESALE_PRICE_COLUMN');

const wholesalePriceColumn = {
  id: WHOLESALE_PRICE_COLUMN,
  ordering: WHOLESALE_PRICE_ORDERING,
  label: 'Wholesale',
  hideable: true,
  className: style.priceColumn,

  render: ({ wholesale_price_display }: { wholesale_price_display: string }) => {
    return wholesale_price_display;
  },
};

export default wholesalePriceColumn;
