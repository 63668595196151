// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerWrapper--ZP34x{position:absolute;left:0;top:0;height:100%;width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.spinnerWrapper--ZP34x .loading--cRNTR{margin-top:15px}.spinnerIcon--Wm4bG{width:50px !important;height:50px;color:var(--color-var-light-gray)}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/PreloaderSpinner/PreloaderSpinner.module.scss"],"names":[],"mappings":"AAEA,uBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,uCACE,eAAA,CAIJ,oBACE,qBAAA,CACA,WAAA,CACA,iCAAA","sourcesContent":["@use 'style/colors' as *;\n\n.spinnerWrapper {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .loading {\n    margin-top: 15px;\n  }\n}\n\n.spinnerIcon {\n  width: 50px !important;\n  height: 50px;\n  color: var(--color-var-light-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gold": `#ffa41c`,
	"male": `#468cc8`,
	"female": `#fd798a`,
	"mixed": `#926cdb`,
	"unknown": `#828282`,
	"gray000": `#333`,
	"gray100": `#4a4b4d`,
	"gray200": `#8b8b8b`,
	"gray300": `#b4b4b4`,
	"gray400": `#d9d9d9`,
	"gray500": `#ced4da`,
	"gray600": `#e9ecef`,
	"gray700": `#f9f9f9`,
	"gray800": `#ffffff`,
	"white": `#ffffff`,
	"whiteSmoke": `#f7f7f7`,
	"black": `#000`,
	"spinnerWrapper": `spinnerWrapper--ZP34x`,
	"loading": `loading--cRNTR`,
	"spinnerIcon": `spinnerIcon--Wm4bG`
};
export default ___CSS_LOADER_EXPORT___;
