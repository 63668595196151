/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableActionCodesEnum,
    AvailableActionCodesEnumFromJSON,
    AvailableActionCodesEnumFromJSONTyped,
    AvailableActionCodesEnumToJSON,
} from './AvailableActionCodesEnum';

/**
 * 
 * @export
 * @interface AvailableAction
 */
export interface AvailableAction {
    /**
     * 
     * @type {AvailableActionCodesEnum}
     * @memberof AvailableAction
     */
    code: AvailableActionCodesEnum;
    /**
     * 
     * @type {string}
     * @memberof AvailableAction
     */
    reviewType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AvailableAction
     */
    readonly label: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableAction
     */
    readonly displayMessage: string;
}

export function AvailableActionFromJSON(json: any): AvailableAction {
    return AvailableActionFromJSONTyped(json, false);
}

export function AvailableActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': AvailableActionCodesEnumFromJSON(json['code']),
        'reviewType': !exists(json, 'review_type') ? undefined : json['review_type'],
        'label': json['label'],
        'displayMessage': json['display_message'],
    };
}

export function AvailableActionToJSON(value?: AvailableAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': AvailableActionCodesEnumToJSON(value.code),
        'review_type': value.reviewType,
    };
}

