import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NestedMenuDrawer } from 'components/experimental/Drawer/Drawer';
import { useAboutMe } from 'context/UserContext/UserContext';
import { useLocalStorage } from 'hooks/useRecentlyVisited';
import { useMobileProfileOptions } from './useMobileProfileOptions';

const BadgeIcon = ({ children }: { children: React.ReactNode }) => {
  return (
    <span style={{ position: 'relative', display: 'flex' }}>
      <span
        style={{
          background: 'var(--color-named-red)',
          display: 'block',
          borderRadius: '100%',
          width: 20,
          height: 20,
          position: 'absolute',
          top: '-8px',
          right: '-8px',
        }}
      ></span>
      {children}
    </span>
  );
};

export const ProfileMenu: React.FC = () => {
  const { about, isLoggedIn } = useAboutMe();
  const avatar = about?.storeLogoThumbUrl || about?.imageThumbUrl;
  const options = useMobileProfileOptions();

  // We do not care about value, we just want to know if the menu was clicked
  const { value: isMenuClicked, setValue: setIsMenuClicked } = useLocalStorage({
    name: 'isMenuClicked',
  });

  const userAvatar = !!avatar && (
    <img src={avatar} style={{ borderRadius: '100%', width: 24, height: 24, objectFit: 'cover' }} />
  );

  const defaultIcon = <FontAwesomeIcon icon={faCircleUser} />;

  const getFontAwesomeIcon = () => {
    if (!isLoggedIn) {
      return defaultIcon;
    }

    const icon = avatar ? userAvatar : defaultIcon;

    if (isMenuClicked) {
      return icon;
    }

    return <BadgeIcon>{icon}</BadgeIcon>;
  };

  return (
    <NestedMenuDrawer
      options={options}
      fontAwesomeIcon={getFontAwesomeIcon()}
      ariaLabel="User Profile Menu"
      clickCallback={() => setIsMenuClicked({ isMenuClicked: 'yes' })}
    />
  );
};
