// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktopLogo--A9jv0{margin:.5em 2em .5em 0}.desktopLogo--A9jv0 img{width:6.875rem;height:auto}.mobileLogo--VY0fI img{width:auto;height:30px}`, "",{"version":3,"sources":["webpack://./mysite/static/react-app/src/components/experimental/Navigation/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA,oBACE,sBAAA,CAEA,wBACE,cAAA,CACA,WAAA,CAIJ,uBACE,UAAA,CACA,WAAA","sourcesContent":[".desktopLogo {\n  margin: 0.5em 2em 0.5em 0;\n\n  img {\n    width: 6.875rem;\n    height: auto;\n  }\n}\n\n.mobileLogo img {\n  width: auto;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopLogo": `desktopLogo--A9jv0`,
	"mobileLogo": `mobileLogo--VY0fI`
};
export default ___CSS_LOADER_EXPORT___;
