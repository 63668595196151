import TraitsList from 'components/Inventory/TraitsList/TraitsList';
import style from 'components/Common/columns.module.scss';
import { TRAITS_ORDERING } from 'api/common/ordering';

export const TRAITS_COLUMN = Symbol('TRAITS_COLUMN');

const traitsColumn = {
  id: TRAITS_COLUMN,
  ordering: TRAITS_ORDERING,
  label: 'Traits',
  hideable: true,
  className: style.traitsColumn,

  render: ({ traits }) => <TraitsList traits={traits} />,
};

export default traitsColumn;
