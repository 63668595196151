import React, { useEffect, useRef } from 'react';
import style from '../NativeAppReviewModal.module.scss';

type FeedbackProps = {
  onChange: (feedback: string) => void;
  isError: boolean;
  label?: string;
};

export const Feedback: React.FC<FeedbackProps> = ({ onChange, isError, label }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const labelContent = label || 'How could we improve?';

  useEffect(
    function focusFeedbackTextAreaOnError() {
      if (isError && textAreaRef.current) {
        textAreaRef.current.focus();
      }
    },
    [isError, textAreaRef.current]
  );

  return (
    <div className={style.feedback}>
      <label>{labelContent}</label>
      <textarea
        ref={textAreaRef}
        autoFocus={isError}
        onChange={handleFeedbackChange}
        placeholder="Describe you experience"
      />
    </div>
  );
};
