/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Auction,
    AuctionFromJSON,
    AuctionFromJSONTyped,
    AuctionToJSON,
} from './Auction';
import {
    DurationEnum,
    DurationEnumFromJSON,
    DurationEnumFromJSONTyped,
    DurationEnumToJSON,
} from './DurationEnum';

/**
 * 
 * @export
 * @interface AnimalAuction
 */
export interface AnimalAuction {
    /**
     * 
     * @type {number}
     * @memberof AnimalAuction
     */
    startingBid?: number;
    /**
     * 
     * @type {number}
     * @memberof AnimalAuction
     */
    reservedPrice?: number;
    /**
     * 
     * @type {DurationEnum}
     * @memberof AnimalAuction
     */
    duration: DurationEnum;
    /**
     * 
     * @type {string}
     * @memberof AnimalAuction
     */
    readonly endTime: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalAuction
     */
    readonly meetsReservedPrice: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnimalAuction
     */
    readonly bidCount: number;
    /**
     * 
     * @type {string}
     * @memberof AnimalAuction
     */
    readonly highestBidDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnimalAuction
     */
    readonly startingBidDisplay: string;
}

export function AnimalAuctionFromJSON(json: any): AnimalAuction {
    return AnimalAuctionFromJSONTyped(json, false);
}

export function AnimalAuctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalAuction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingBid': !exists(json, 'starting_bid') ? undefined : json['starting_bid'],
        'reservedPrice': !exists(json, 'reserved_price') ? undefined : json['reserved_price'],
        'duration': DurationEnumFromJSON(json['duration']),
        'endTime': json['end_time'],
        'meetsReservedPrice': json['meets_reserved_price'],
        'bidCount': json['bid_count'],
        'highestBidDisplay': json['highest_bid_display'],
        'startingBidDisplay': json['starting_bid_display'],
    };
}

export function AnimalAuctionToJSON(value?: AnimalAuction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_bid': value.startingBid,
        'reserved_price': value.reservedPrice,
        'duration': DurationEnumToJSON(value.duration),
    };
}

